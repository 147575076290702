const apiUrl = process.env.REACT_APP_API;
const apiUrlCore = process.env.IS_SERVER ? process.env.REACT_APP_API : process.env.REACT_APP_CORE_API;
const apiUrlAccount = process.env.IS_SERVER ? process.env.REACT_APP_API : process.env.REACT_APP_ACCOUNT_API;
const apiUrlHis = process.env.IS_SERVER ? process.env.REACT_HIS_API : process.env.REACT_APP_HIS_API;
const apiUrlSales = process.env.IS_SERVER ? process.env.REACT_APP_SALES_API : process.env.REACT_APP_SALES_API;
const apiConstantKey =  process.env.REACT_APP_API_KEY 
export const api = `${apiUrl}`;
export const coreApi = `${apiUrlCore}`;
export const accountApi = `${apiUrlAccount}`;
export const hisApi = `${apiUrlHis}`;
export const hisSalesApi = `${apiUrlSales}`;
export const apiKey = `${apiConstantKey}`