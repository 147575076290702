import axios from "axios";
import { packageConstants } from "./constants";
import { api } from "../../urlConfig";
import { getAxiosHeaders } from "../../helpers/getAxiosHeader";

let cancelToken = null;

export const getPackage = (query) => {
  return async (dispatch) => {
    dispatch({
      type: packageConstants.GET_PACKAGE_REQUEST,
    });

    let headers = getAxiosHeaders();

    if (cancelToken) {
      cancelToken.cancel("Cancel request get");
    }

    cancelToken = axios.CancelToken.source();

    headers.cancelToken = cancelToken.token;

    const res = await axios
      .get(`${api}/appointments/product-package?${query}`, headers)
      .catch((err) => {
        dispatch({
          type: packageConstants.GET_PACKAGE_FAILURE,
          payload: err,
        });

        return err;
      });

    if (res.status === 200) {
      dispatch({
        type: packageConstants.GET_PACKAGE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getDetailPackage = (id) => {
  return async (dispatch) => {
    dispatch({
      type: packageConstants.GET_DETAIL_PACKAGE_REQUEST,
    });

    let headers = getAxiosHeaders();

    const res = await axios
      .get(`${api}/appointments/product-package/${id}`, headers)
      .catch((err) => {
        dispatch({
          type: packageConstants.GET_DETAIL_PACKAGE_FAILURE,
          payload: err,
        });

        return err;
      });

    if (res.status === 200) {
      dispatch({
        type: packageConstants.GET_DETAIL_PACKAGE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
