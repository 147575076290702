import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button as ButtonReact, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  createAppointment,
  getAllPatients,
  getIdentityType,
  getRelation,
  uploadMedicalDocFile,
} from '../../../actions';
import { patientConstants } from '../../../actions/constants';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCalenderGrey,
  IconCloseGreen,
  IconPlusWhite,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import { InputDate, InputText, SelectOptions } from '../../../components/Input';
import ModalComp from '../../../components/Modal';
import { documentPatientTypes } from '../../../constants/common';
import { isEmail } from '../../../helpers/utils';
import Layout from '../Components/Layout';
import MobilePatient from './MobilePatient';
import './patient.scss';

const Patient = () => {
  /* Router DOM */
  const navigate = useHistory();

  /* Redux */
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patient);
  const accountProfile = useSelector((state) => state.accountProfile);
  const { loading } = useSelector((state) => state.appointment);

  const [patientData, setPatientData] = useState({});
  const [optionJenisIdentitas, setOptionJenisIdentitas] = useState();
  const [txtNomorIdentitas, setTxtNomorIdentitas] = useState();
  const [txtNamaKtp, setTxtNamaKtp] = useState();
  const [optionHubungan, setOptionHubungan] = useState();
  const [optionJenisKelamin, setOptionJenisKelamin] = useState();
  const [txtNomorTelepon, setTxtNomorTelepon] = useState();
  const [txtEmail, setTxtEmail] = useState();
  const [addPatientModal, setAddPatientModal] = useState(false);
  const [countComplaiints, setCountComplaiints] = useState(0);
  const [errorRequired, setErrorRequired] = useState([]);
  const [errorField, setErrorField] = useState(false);

  const [userId, setUserId] = useState(window.localStorage.getItem('userId'));
  const [fullName, setFullName] = useState(
    window.localStorage.getItem('fullName') != 'null'
      ? window.localStorage.getItem('fullName')
      : ''
  );
  const [firstName, setFirstName] = useState(
    window.localStorage.getItem('firstName') != 'null'
      ? window.localStorage.getItem('firstName')
      : ''
  );

  const { state } = useLocation();
  if (!state) window.location = '/';

  const [appointmentData, setAppointmentData] = useState(state.appointmentData);

  const serviceType = appointmentData ? appointmentData.serviceType : null;
  const paymentMethod = appointmentData
    ? appointmentData.paymentMethodtype
    : null;
  state.appointmentData.complaints = appointmentData
    ? appointmentData.complaints
    : '';

  const [selectedPatient, setSelectedPatient] = useState(
    state.selectedPatient ? state.selectedPatient : []
  );
  const [medicalDoc, setMedicalDoc] = useState([]);
  const [docType, setDocType] = useState();
  const [medicalDocModal, setMedicalDocModal] = useState(false);
  const [paymentTeleModal, setPaymentTeleModal] = useState(false);
  const hospitalName = state.appointmentData
    ? state.appointmentData.hospitalName
    : '';

  const prevPageUrl = '/appointment';
  const nextPageUrl1 = '/payment-method/bpjs';
  const nextPageUrl2 = '/payment-method/insurance';

  var config = window.localStorage.getItem('headerData') ? JSON.parse(window.localStorage.getItem('headerData')) : [];

  useEffect(() => {
    dispatch(getAllPatients(userId));
    dispatch(getRelation());
    dispatch(getIdentityType());
  }, [userId]);

  useEffect(() => {
    var urlMedicalDoc = patient.urlMedicalDoc ? patient.urlMedicalDoc.url : '';

    if (urlMedicalDoc) {
      setMedicalDoc([
        ...medicalDoc,
        {
          id: medicalDoc.length + 1,
          docType: docType,
          docUrl: urlMedicalDoc,
        },
      ]);

      initState();
    }
  }, [patient.urlMedicalDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, medicalDoc });
  }, [medicalDoc]);

  const checkPhone = (prefix, phone) => {
    let phoneSubs = phone;
    if (prefix == '0') {
      phoneSubs = phone.substring(1);
    } else if (prefix == '+') {
      if (phone.substring(4, 3) == '0') {
        phoneSubs = phone.substring(4);
      } else {
        phoneSubs = phone.substring(3);
      }
    } else if (prefix == '6') {
      if (phone.substring(3, 2) == '0') {
        phoneSubs = phone.substring(3);
      } else {
        phoneSubs = phone.substring(2);
      }
    }

    return phoneSubs;
  };

  const patientDataSelected = (e) => {
    var patientId = e?.value;
    if (patientId) {
      var selectedPatientData = patient.patientData.detail.filter(
        (e) => e.id == patientId
      );

      if (
        selectedPatientData[0].name &&
        selectedPatientData[0].phone_1 &&
        selectedPatientData[0].dob &&
        selectedPatientData[0].relation &&
        selectedPatientData[0].gender
      ) {
        setSelectedPatient(e);
      } else {
        handleAddPatient(e);
      }
    }
  };

  const initState = () => {
    setPatientData({
      name: '',
      gender: '',
      dob: '',
      phone_1: '',
      email: '',
      relation: '',
      relationname: '',
      identity_type: '',
      identity_name: '',
      identity_number: '',
      user_id: userId,
    });
    setAddPatientModal(false);
    setMedicalDocModal(false);
    setPaymentTeleModal(false);
    setDocType('');
    setErrorRequired([]);
  };

  const handleAddPatient = (patient) => {
    if (patient) {
      setPatientData({
        id: patient.id,
        name: patient.name,
        gender: patient.gender,
        dob: patient.dob  ? moment(patient.dob).format('YYYY-MM-DD') : '',
        phone_1: patient.phone_1,
        email: patient.email,
        relation: patient.relation,
        relationname: patient.relationname,
        identity_type: patient.identity_type,
        identity_name: patient.identity_name,
        identity_number: patient.identity_number,
        user_id: patient.user_id,
      });
    }
    setErrorRequired([]);
    setAddPatientModal(true);
  };

  const handleCloseModal = () => {
    initState();
  };

  const actionAddPatient = () => {
    if (
      (config.add_patient_config[6].code === "name" && config.add_patient_config[6].required && !patientData?.name) || 
      (config.add_patient_config[4].code === "relation" && config.add_patient_config[4].required && !patientData?.relation) ||
      (config.add_patient_config[8].code === "dob" && config.add_patient_config[8].required && !patientData?.dob) ||
      (config.add_patient_config[10].code === "gender" && config.add_patient_config[10].required && !patientData?.gender) || 
      (config.add_patient_config[12].code === "phone_1" && config.add_patient_config[12].required && !patientData?.phone_1) ||
      (config.add_patient_config[0].code === "identity_type" && config.add_patient_config[0].required && !patientData?.identity_type) ||
      (config.add_patient_config[2].code === "identity_number" && config.add_patient_config[2].required && !patientData?.identity_number)
    ) {
      setErrorField(true);
    } else {
      if (patientData.phone_1) {
        let prefix = patientData.phone_1.substring(0, 1);
        patientData.phone_1 = checkPhone(prefix, patientData.phone_1);
      }
     
      dispatch({
        type: patientConstants.GET_ALL_PATIENT_SUCCESS,
        payload: {
          data: { detail: [...patient?.patientData?.detail, patientData] },
        },
      });
      setSelectedPatient({
        ...patientData,
        label: `${patientData?.name} - ${
          patientData?.relationname ? patientData?.relationname : ''
        }`,
        value: `${patientData?.name} - ${
          patientData?.relationname ? patientData?.relationname : ''
        }`,
      });

      initState();
    }
  };

  const renderAddPatient = () => {
    return (
      <ModalComp
        title="Form Pasien"
        show={addPatientModal}
        size="sm"
        setShow={handleCloseModal}
        onSubmit={actionAddPatient}
        buttonSubmitLabel="Kirim"
        useButtonClose
        buttonCloseLabel="Tutup"
      >
        <p className="text-dark font16 font-weight-600">Data Pribadi</p>
        <Row>
          <Col md>
            <SelectOptions
              placeholder="Pilih Jenis Identitas"
              label="Jenis Identitas"
              labelClassName="mt-3"
              background="#ffff"
              value={optionJenisIdentitas}
              fontSize="lg"
              setValue={(e) => {
                setOptionJenisIdentitas(e);
                patientData.identity_type = e?.id;
                patientData.identity_name = e?.name;
              }}
              required={config.add_patient_config[0].required ? true : false}
              isShowWarning={errorField && !optionJenisIdentitas && config.add_patient_config[0].required}
              options={
                accountProfile?.identityData
                  ? accountProfile?.identityData?.map((e) => {
                      return {
                        ...e,
                        label: e?.name,
                        value: e?.id,
                      };
                    })
                  : ''
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <InputText
              label="Nomor Identitas"
              labelClassName="mt-3"
              required={config.add_patient_config[2].required ? true : false}
              placeholder="Masukkan Nomor Identitas"
              isShowWarning={errorField && !txtNomorIdentitas && config.add_patient_config[2].required}
              value={txtNomorIdentitas}
              setValue={(value) => {
                setTxtNomorIdentitas(value);
                patientData.identity_number = value;
              }}
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <InputText
              label="Nama Lengkap (Sesuai Kartu Identitas)"
              labelClassName="mt-3"
              required={config.add_patient_config[6].required ? true : false}
              placeholder="Masukkan Nama Lengkap"
              isShowWarning={errorField && !txtNamaKtp && config.add_patient_config[6].required}
              value={txtNamaKtp}
              setValue={(value) => {
                setTxtNamaKtp(value);
                patientData.name = value;
              }}
              errorMessage="Nama Lengkap tidak boleh kosong!"
              type="alphabet"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <SelectOptions
              placeholder="Pilih Hubungan"
              label="Hubungan"
              labelClassName="mt-3"
              background="#ffff"
              value={optionHubungan}
              fontSize="lg"
              setValue={(e) => {
                setOptionHubungan(e);
                patientData.relation = e?.label;
                patientData.relationname = e.value;
              }}
              required={config.add_patient_config[4].required ? true : false}
              isShowWarning={errorField && !optionHubungan && config.add_patient_config[4].required}
              options={
                patientData.relation == 1
                  ? {
                      label: 'Saya Sendiri',
                      value: 1,
                    }
                  : accountProfile?.relationData
                  ? accountProfile?.relationData?.map((e) => {
                      if (e.id != 1) {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      } else {
                        return {
                          ...e,
                          label: '',
                          value: '',
                        };
                      }
                    })
                  : ''
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <InputDate
              placeholder="mm/dd/yyyy"
              labelClassName="mt-3"
              className="text-lg"
              label="Tanggal Lahir"
              required={config.add_patient_config[8].required}
              setDate={(e) => {
                setPatientData({
                  ...patientData,
                  dob: e,
                });
              }}
              selectedDate={patientData.dob}
              icon={IconCalenderGrey}
              iconPosition="right"
              showMonthDropdown
              showYearDropdown
              format="dd/MM/yyyy"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <SelectOptions
              placeholder="Pilih Jenis Kelamin"
              label="Jenis Kelamin"
              labelClassName="mt-3"
              background="#ffff"
              value={optionJenisKelamin}
              fontSize="lg"
              setValue={(e) => {
                setOptionJenisKelamin(e);
                patientData.gender = e?.value;
              }}
              required={config.add_patient_config[10].required ? true : false}
              isShowWarning={errorField && !optionJenisKelamin && config.add_patient_config[10].required}
              options={[
                {
                  label: 'Laki-Laki',
                  value: 1,
                },
                {
                  label: 'Perempuan',
                  value: 2,
                },
              ]}
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <InputText
              label="Nomor Telepon"
              labelClassName="mt-3"
              placeholder="Masukkan Nomor Telepon"
              isShowWarning={errorField && !txtNomorTelepon && config.add_patient_config[12].required}
              value={txtNomorTelepon}
              maxText={13}
              required={config.add_patient_config[12].required ? true : false}
              setValue={(value) => {
                setTxtNomorTelepon(value);
                patientData.phone_1 = value;
              }}
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <InputText
              label="Email"
              labelClassName="mt-3"
              placeholder="Masukkan Email"
              required={config.add_patient_config[14].required ? true : false}
              isShowWarning={errorField && (!txtEmail || !isEmail(txtEmail)) && config.add_patient_config[14].required}
              errorMessage={
                txtEmail ? !isEmail(txtEmail) && 'Format email salah' : null
              }
              value={txtEmail}
              setValue={(value) => {
                setTxtEmail(value);
                patientData.email = value;
              }}
            />
          </Col>
        </Row>
      </ModalComp>
    );
  };

  //upload dokumen medis
  const uploadDocumment = async () => {
    if (!docType) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tipe Dokumen',
      });
    } else {
      const uploadmedicaldoc =
        document.getElementById('uploadmedicaldoc').files;

      const fileTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf"
      ];
      
      if(fileTypes.includes(uploadmedicaldoc[0].type)){
        dispatch(uploadMedicalDocFile(uploadmedicaldoc[0], docType.value));
      }
      else {
        return Swal.fire({
          icon: 'error',
          text: 'Harap Unggah File .jpeg, .png, atau .pdf!',
        });
      }
    }
  };

  const handleMedicalDocModal = () => {
    setMedicalDocModal(true);
  };

  const renderMedicalDocModal = () => {
    return (
      <ModalComp
        title="Tambah Dokumen Medis"
        show={medicalDocModal}
        setShow={handleCloseModal}
        size="sm"
        onSubmit={handleCloseModal}
        buttonSubmitLabel="Simpan"
        submitLoading={patient?.loading}
      >
        <div style={{ height: '160px' }}>
          <SelectOptions
            placeholder="Pilih dokumen"
            background="#ffff"
            value={docType}
            fontSize="lg"
            setValue={(e) => {
              setDocType(e);
            }}
            options={documentPatientTypes}
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
          />
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <input
                type="file"
                accept="image/png, image/jpeg, .pdf"
                id="uploadmedicaldoc"
                style={{ display: 'none' }}
                onChange={uploadDocumment}
              />
              <label
                htmlFor="uploadmedicaldoc"
                className="label-file-upload"
                style={{ paddingBottom: '30px' }}
              >
                <img src={IconPlusWhite} alt="icon" /> Unggah
              </label>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  const actionTelePayment = () => {
    const form = new FormData();

    var userPhone = window.localStorage.getItem('phone');
    var config = JSON.parse(window.localStorage.getItem('headerData'));

    form.append('doctor_hospital_id', appointmentData.doctor_hospital_id);
    form.append('specialization_id', appointmentData.specialization_id);
    form.append('poli_id', appointmentData.poli_id);
    form.append('hospitalGroupId', config ? config.klinik_group_id : null);
    form.append('userPhone', userPhone);
    form.append('identity_type', selectedPatient.identity_type);
    form.append('identity_name', selectedPatient.identity_name);
    form.append('identity_number', selectedPatient.identity_number);
    form.append('name', selectedPatient.name);
    form.append('gender', selectedPatient.gender);
    form.append('dob', selectedPatient.dob);
    form.append('phone_1', selectedPatient.phone_1);
    form.append('email', selectedPatient.email);
    form.append('relation', selectedPatient.relation);
    form.append('user_id', userId);
    form.append('hospital_id', appointmentData.hospital_id);
    form.append('doctor_id', appointmentData.doctor_id);
    form.append('schedule_id', appointmentData.doctor_schedule_id);
    form.append('doctor_schedule_id', appointmentData.doctor_schedule_id);
    form.append(
      'payerClinicsId',
      appointmentData?.payers &&
        appointmentData?.payers?.filter((e) => e?.payersId === 2)[0]?.id
    );

    if (
      selectedPatient.id != null &&
      selectedPatient.id != undefined &&
      selectedPatient.id != ''
    ) {
      form.append('profile_id', selectedPatient.id);
    }

    form.append(
      'date',
      moment(appointmentData.date, 'DDMMYYYY').format('YYYY-MM-DD')
    );
    form.append('from_time', appointmentData.from_time);
    form.append('to_time', appointmentData.to_time);
    form.append('note', appointmentData.note);

    if (appointmentData.medicalDoc) {
      form.append(
        'medical_doc',
        appointmentData.medicalDoc?.length
          ? JSON.stringify(appointmentData.medicalDoc)
          : ''
      );
    }

    form.append('complaints', appointmentData.complaints);
    form.append('firstName', firstName);
    form.append('status', 9);
    form.append('isCMS', false);
    form.append('scheduleType', 3); // Hardcode because tele always fixed slot
    form.append('consultationType', appointmentData.consultation_type);
    form.append('serviceType', appointmentData.serviceType);
    form.append('mcuProceduresId', appointmentData.mcuProceduresId);
    form.append('urlWeb', window.location.origin + '/home');
    form.append('serviceFee', appointmentData.serviceFee);

    // console.log(
    //   appointmentData.mcuProceduresId,
    //   ' appointmentData.mcuProceduresId >>>'
    // );

    // console.log("form", Object.fromEntries(form))
    dispatch(createAppointment(form))
      .then((response) => {
        const datas = response.data;
        if (datas.data) {
          setTimeout(
            () =>
              Swal.fire({
                icon: 'success',
                title: 'Silahkan Lakukan Pembayaran',
                showConfirmButton: true,
                confirmButtonText: 'OK',
              }).then(() => {
                if (datas.userData) {
                  localStorage.setItem('firstName', datas.userData.firstName);
                  localStorage.setItem('fullName', datas.userData.fullName);
                }
                if (datas.dataPayment) {
                  window.open(datas.dataPayment.linkPayment, '_blank');
                }
                window.location = '/appointment-created/' + datas.data.id;
              }),
            200
          );
        } else {
          return Swal.fire({
            icon: 'error',
            text: response.message,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: 'error',
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: 'error',
            text: err.message,
          });
        }
      });
  };

  const renderPaymentTeleModal = () => {
    // console.log('appoontmentData', appointmentData);
    return (
      <ModalComp
        title="Konfirmasi Data"
        show={paymentTeleModal}
        setShow={handleCloseModal}
        buttonSubmitLabel={
          serviceType === 'PM'
            ? 'Buat Pemeriksaan Medis'
            : 'Buat Telekonsultasi'
        }
        onSubmit={actionTelePayment}
        buttonCloseLabel="Tutup"
        useButtonClose
        size="md"
        submitLoading={loading}
      >
        <p className="font16 text-center text-dark font-weight-normal">
          Halo {fullName}, <br />
          pastikan data yang Anda masukkan telah sesuai.
        </p>
        <div className="paymentMethodConfirmation">
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Jenis Identitas
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {selectedPatient.identity_name}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Nomor Identitas
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {selectedPatient.identity_number}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Nama
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {selectedPatient.name}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Tanggal Lahir
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {moment(selectedPatient.dob).format('D MMMM YYYY')}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Nomor HP
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {'+62' + selectedPatient.phone_1}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Email
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {selectedPatient.email}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Jenis Kelamin
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {selectedPatient.gender == 1 ? 'Laki-Laki' : 'Perempuan'}
              </label>
            </Col>
          </Row>

          {serviceType !== 'PM' && (
            <Row style={{ marginBottom: '5px' }}>
              <Col md style={{ maxWidth: '44%' }}>
                <label
                  style={{
                    display: 'inline',
                    fontSize: '16px',
                    fontWeight: 'normal',
                  }}
                >
                  Nama Dokter
                </label>
              </Col>
              <Col md style={{ maxWidth: '56%' }}>
                <label style={{ display: 'inline', fontSize: '16px' }}>
                  : {appointmentData.doctorName}
                </label>
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Klinik
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                : {appointmentData.hospitalName}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                Tanggal
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                :{' '}
                {moment(appointmentData.date, 'DDMMYYYY').format(
                  'dddd, D MMMM YYYY'
                )}
              </label>
            </Col>
          </Row>
          {serviceType !== 'PM' && (
            <Row style={{ marginBottom: '5px' }}>
              <Col md style={{ maxWidth: '44%' }}>
                <label
                  style={{
                    display: 'inline',
                    fontSize: '16px',
                    fontWeight: 'normal',
                  }}
                >
                  Waktu
                </label>
              </Col>
              <Col md style={{ maxWidth: '56%' }}>
                <label style={{ display: 'inline', fontSize: '16px' }}>
                  :{' '}
                  {appointmentData.from_time +
                    ' - ' +
                    state.appointmentData.to_time +
                    ' ' +
                    state.appointmentData.timezone}{' '}
                </label>
              </Col>
            </Row>
          )}
        </div>
        <label
          style={{
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: 'normal',
            wordWrap: 'break-word',
          }}
        >
          dengan melanjutkan proses reservasi ini, Anda telah menyetujui
          <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
            {' '}
            Syarat dan Ketentuan{' '}
          </a>
          penggunaan dan kebijakan privasi janji temu {hospitalName}
        </label>
      </ModalComp>
    );
  };

  const handleDoctorSchedule = () => {
    navigate.push('/doctor/schedule', [state.appointmentData]);
  };

  return (
    <Layout>
      <div className="content-patient col-12 mt-5 d-xl-block d-none">
        <Row className="col-5">
          <div
            className="cursor-pointer d-flex flex-row"
            onClick={handleDoctorSchedule}
          >
            <img src={IconArrowGreen} alt="icon" />
            <p className="title-page ml-2 text-white">Jadwal</p>
          </div>
        </Row>
        <Row>
          <div className="col-3 mt-5">
            <div className="nav-step">
              <button className="circle-btn-step">1</button>
              <span className="label-step">Jadwal</span>
            </div>
            <div className="nav-step">
              <button className="circle-btn-step activePage">2</button>
              <span className="label-step">
                <b>Data Pasien</b>
              </span>
            </div>
            <div className="nav-step">
              <button className="circle-btn-step">3</button>
              <span className="label-step">
                Pembayaran{' '}
                {paymentMethod == '2'
                  ? '(BPJS)'
                  : serviceType != 'TL'
                  ? `(${appointmentData.consultation_name})`
                  : ''}
              </span>
            </div>
          </div>
          <div className="col-8">
            <div className="content-patient-step">
              <h1>Data Profil Pasien</h1>
              <h2>
                Silahkan lengkapi data profil pasien untuk ke proses pembayaran
              </h2>
              <div className="find-profile">
                <div className="select-option mr-1">
                  <SelectOptions
                    id="selectOptionPatient"
                    placeholder="Profil Pasien"
                    label="Pilih Profil Pasien"
                    labelClassName="mt-5"
                    background="#ffff"
                    value={selectedPatient}
                    fontSize="lg"
                    setValue={(e) => patientDataSelected(e)}
                    required={true}
                    options={
                      patient?.patientData?.detail?.length &&
                      patient?.patientData?.detail?.map((e) => {
                        return {
                          ...e,
                          label: `${e?.name} - ${
                            e.relationname ? e.relationname : ''
                          }`,
                          value: e?.id,
                        };
                      })
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </div>
                <div className="btn-add-patient ml-1 mt-4 w-25">
                  <Button
                    label="Tambah Profil"
                    type="primary-blue"
                    icon={IconPlusWhite}
                    iconPosition="left"
                    onClick={handleAddPatient}
                  />
                </div>
              </div>
              {selectedPatient.length != 0 ? (
                <div className="choose-patient col-13 mx-auto">
                  <div className="profile-patient col-13 mx-auto">
                    <div className="profile-card">
                      {selectedPatient.name ? (
                        <div className="row">
                          <div className="col-2">Nama</div>
                          <div className="col-1">:</div>
                          <div>{selectedPatient.name}</div>
                        </div>
                      ) : (
                        '-'
                      )}
                      <div className="row">
                        <div className="col-2">Hubungan</div>
                        <div className="col-1">:</div>
                        <div>{selectedPatient.relationname}</div>
                      </div>
                      {selectedPatient.dob ? (
                        <div className="row">
                          <div className="col-2">Umur</div>
                          <div className="col-1">:</div>
                          <div>
                            {moment().diff(selectedPatient.dob, 'years') +
                              ' Tahun'}
                          </div>
                        </div>
                      ) : (
                        '-'
                      )}
                      <div className="row">
                        <div className="col-2">No Telepon</div>
                        <div className="col-1">:</div>
                        <div>{'+62' + selectedPatient.phone_1}</div>
                      </div>
                    </div>
                    <div className="pr-0 ml-0 text-dark mb-2 text-left">
                      <b>Keluhan</b>
                    </div>
                    <textarea
                      rows="5"
                      className="form-control"
                      placeholder="Silahkan masukkan keluhan Anda"
                      value={appointmentData.complaints}
                      onChange={(e) => {
                        setAppointmentData({
                          ...appointmentData,
                          complaints: e.target.value,
                        });
                        setCountComplaiints(e.target.value.length);
                      }}
                    />
                    <h4>{countComplaiints}/1000</h4>
                  </div>
                  <div style={{ textAlign: 'left', marginTop: '10px' }}>
                    <div className="pr-0 ml-0 text-dark mb-2 text-left">
                      <b>Unggah Dokumen Medis</b>
                    </div>
                    <div className="w-50">
                      <Button
                        label="Tambah Dokumen"
                        type="primary-blue"
                        icon={IconPlusWhite}
                        iconPosition="left"
                        onClick={handleMedicalDocModal}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '5px',
                      padding: '20px',
                      textAlign: 'left',
                    }}
                  >
                    {medicalDoc.length > 0 ? (
                      medicalDoc.map((data, index) => (
                        <Row key={'row' + index}>
                          <Col md style={{ width: '5px', maxWidth: '3%' }}>
                            <i
                              className="fa fa-check"
                              style={{ color: '#00BD84', marginRight: '5px' }}
                            />
                          </Col>
                          <Col md style={{ width: '10px', maxWidth: '57%' }}>
                            <span
                              style={{
                                display: 'inline',
                                fontSize: '16px',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                color: 'black',
                                fontWeight: 'normal',
                              }}
                            >
                              {data
                                ? data.docUrl.split('/').length == 6
                                  ? data.docUrl.split('/')[5]
                                  : ''
                                : ''}
                            </span>
                            <span
                              style={{
                                display: 'inline',
                                fontSize: '16px',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              &nbsp;
                              {data?.docType === 'Rujukan'
                                ? 'Kartu Rujukan'
                                : data?.docType === 'LabResult'
                                ? 'Hasil Laboratorium'
                                : data?.docType === 'Lainnya'
                                ? 'Dokumen Lainnya'
                                : 'Berhasil Terunggah!'}
                            </span>
                          </Col>
                          <Col md style={{ width: '30px', maxWidth: '30%' }}>
                            <a
                              href={data.docUrl}
                              target="_blank"
                              style={{
                                display: 'inline',
                                fontSize: '16px',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                color: '#00BD84',
                                fontWeight: 'bold',
                              }}
                            >
                              Lihat Dokumen
                            </a>
                          </Col>
                          <Col md>
                            <img
                              src={IconCloseGreen}
                              alt="icon"
                              className="cursor-pointer"
                              onClick={() =>
                                setMedicalDoc(
                                  medicalDoc.filter((e) => e.id !== data.id)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="btn-prev-next">
                {selectedPatient.length == 0 ? (
                  <ButtonReact
                    className="btn-prev inactive"
                    style={{ marginTop: '150px' }}
                    disabled
                  >
                    Pilih Profile Pasien
                  </ButtonReact>
                ) : selectedPatient.name &&
                  selectedPatient.phone_1 &&
                  selectedPatient.dob &&
                  selectedPatient.relation &&
                  selectedPatient.gender ? (
                  +paymentMethod === 2 ? (
                    // bpjs
                    <Link
                      to={{
                        pathname: nextPageUrl1,
                        state: {
                          selectedPatient: selectedPatient,
                          appointmentData: appointmentData,
                        },
                      }}
                    >
                      <ButtonReact
                        className="btn-prev"
                        variant="primary"
                        style={{ marginTop: '30px' }}
                      >
                        Pembayaran dengan BPJS
                      </ButtonReact>
                    </Link>
                  ) : serviceType !== 'TL' && serviceType !== 'PM' ? (
                    // insurance
                    <Link
                      to={{
                        pathname: nextPageUrl2,
                        state: {
                          selectedPatient: selectedPatient,
                          appointmentData: appointmentData,
                        },
                      }}
                    >
                      <ButtonReact
                        className="btn-prev"
                        variant="primary"
                        style={{ marginTop: '30px' }}
                      >
                        Pembayaran dengan Asuransi/Pribadi
                      </ButtonReact>
                    </Link>
                  ) : serviceType === 'PM' ? (
                    // pemeriksaan medis
                    <Button
                      label="Pembayaran Pemeriksaan Medis"
                      type="primary"
                      onClick={() =>
                        appointmentData?.isPrepaid
                          ? navigate.push(nextPageUrl2, {
                              selectedPatient: selectedPatient,
                              appointmentData: appointmentData,
                            })
                          : setPaymentTeleModal(true)
                      }
                    />
                  ) : (
                    // teleconsultation
                    <Button
                      label="Pembayaran"
                      type="primary"
                      onClick={() => setPaymentTeleModal(true)}
                    />
                  )
                ) : (
                  <Button
                    label="Lengkapi Profile Pasien"
                    className="mt-5"
                    type="primary-blue"
                    onClick={() => handleAddPatient(selectedPatient)}
                  />
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
      <MobilePatient
        state={state}
        prevPageUrl={prevPageUrl}
        nextPageUrl1={nextPageUrl1}
        nextPageUrl2={nextPageUrl2}
        patientDataSelected={patientDataSelected}
        patient={patient}
        handleAddPatient={handleAddPatient}
        selectedPatient={selectedPatient}
        appointmentData={appointmentData}
        setAppointmentData={setAppointmentData}
        countComplaiints={countComplaiints}
        setCountComplaiints={setCountComplaiints}
        handleMedicalDocModal={handleMedicalDocModal}
        medicalDoc={medicalDoc}
        paymentMethod={paymentMethod}
        serviceType={serviceType}
        setPaymentTeleModal={setPaymentTeleModal}
        setMedicalDoc={setMedicalDoc}
        handleDoctorSchedule={handleDoctorSchedule}
      />
      {renderAddPatient()}
      {renderMedicalDocModal()}
      {renderPaymentTeleModal()}
    </Layout>
  );
};

export default Patient;
