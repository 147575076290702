import { paymentMethodConstants } from "../actions/constants";

const initState = {
  urlFKTLDoc: [],
  urlInsuranceDoc: [],
  urlJaminanDoc: [],
  insuranceData: [],
  companyData: [],
  payerData: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case paymentMethodConstants.UPLOAD_FKTLDOC_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentMethodConstants.UPLOAD_FKTLDOC_SUCCESS:
      state = {
        ...state,
        urlFKTLDoc: action.payload.data,
        loading: false,
      };
      break;
    case paymentMethodConstants.UPLOAD_FKTLDOC_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentMethodConstants.UPLOAD_INSURANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentMethodConstants.UPLOAD_INSURANCE_SUCCESS:
      state = {
        ...state,
        urlInsuranceDoc: action.payload.data,
        loading: false,
      };
      break;
    case paymentMethodConstants.UPLOAD_INSURANCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentMethodConstants.UPLOAD_JAMINAN_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentMethodConstants.UPLOAD_JAMINAN_SUCCESS:
      state = {
        ...state,
        urlJaminanDoc: action.payload.data,
        loading: false,
      };
      break;
    case paymentMethodConstants.UPLOAD_JAMINAN_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentMethodConstants.GET_HOSPITAL_INSURANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentMethodConstants.GET_HOSPITAL_INSURANCE_SUCCESS:
      state = {
        ...state,
        insuranceData: action.payload.data.insuranceClinics,
        loading: false,
      };
      break;
    case paymentMethodConstants.GET_HOSPITAL_INSURANCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentMethodConstants.GET_COMPANY_INSURANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentMethodConstants.GET_COMPANY_INSURANCE_SUCCESS:
      state = {
        ...state,
        companyData: action.payload.data.insuranceClinics,
        loading: false,
      };
      break;
    case paymentMethodConstants.GET_COMPANY_INSURANCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentMethodConstants.GET_HOSPITAL_PAYER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentMethodConstants.GET_HOSPITAL_PAYER_SUCCESS:
      state = {
        ...state,
        payerData: action.payload.data.payerClinics,
        loading: false,
      };
      break;
    case paymentMethodConstants.GET_HOSPITAL_PAYER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
