import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  getDiagnosis
} from "../../../../actions";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, PDFDownloadLink } from "@react-pdf/renderer";
import { terbilang, formatRupiahV2, formatDigit, today } from "../../../../helpers/formatDigit"


const TemplateInvoice = (props) => {

  let transactionHistory = props.invoiceData;
  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 'semibold' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf', fontWeight: 'normal' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-500.ttf', fontWeight: 'medium' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 'bold' }
    ]
  });


  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      color: "#000000",
      fontSize: "10px",
      marginLeft: "15px",
      marginRight: "15px",
      paddingRight: "15px",
      fontFamily: "Open Sans"
    },
    section: {
      margin: "20px 10px 10px 10px",
      fontSize: "12px",
      fontWeight: "700"
    },
    sectionHeader: {
      display: "flex",
      flexDirection: "row",
      fontSize: 12,
      marginLeft: "10px",
      paddingRight: "10px",
      marginBottom: "4px",
    },
    sectionDetail: {
      display: "flex",
      flexDirection: "row",
      fontSize: 9,
      marginLeft: "10px",
      paddingRight: "10px",
      marginBottom: "4px",
    },
    sectionLine: {
      borderBottom: "1px solid #E7E7E7",
      margin: 10
    },
    sectionTable: {
      flexDirection: "column"
    },
    table: {
      fontSize: 10,
      width: 600,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      marginBottom: "24px",
      borderBottom: "1px solid #dedede"
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 25
    },
    cell: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
      margin: 4
    },
    cellFirst: {
      margin: 4,
      width: "10%"
    },
    cellQty: {
      margin: 4,
      width: "50%"
    },
    cellDescription: {
      margin: 4,
      width: "40%"
    },
    cellBold: {
      //fontFamily:"Open Sans",
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
      margin: 4,
      fontWeight: "bold"
    },
    header: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #dedede",
      borderBottom: "1px solid #dedede",
    },
    headerText: {
      fontSize: 11,
      fontWeight: 1200,
      color: "#1a245c",
      margin: 8
    },
    tableText: {
      margin: 10,
      fontSize: 10,
      color: "neutralDark"
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });
  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
        </View>
        <View style={{ marginLeft: "72px", marginBottom: "18px", width: "40%" }}>
          <Text style={{ fontWeight: "bold" }}>{transactionHistory.hospitalName}</Text>
          <Text style={{}}>{transactionHistory.hospitalAddress}</Text>
          <Text style={{}}>Phone : {transactionHistory.hospitalPhone}</Text>
        </View>
        <View>
        </View>
        <View style={{ textAlign: "center", marginBottom: "18px" }}>
          <Text style={{ fontSize: "18px", fontWeight: "700" }}> Bukti Pembayaran</Text>
          <Text style={{}}>No: {transactionHistory.trxNumber}</Text>
        </View>
        <View style={{ marginBottom: "18px" }}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ width: "30%" }}>Sudah Terima Dari</Text>
            <Text style={{}}> : {transactionHistory.firstName} {transactionHistory.lastName}</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ width: "30%" }}>Nama Pasien / No RM</Text>
            <Text style={{ fontWeight: "700" }}> : {transactionHistory.firstName} {transactionHistory.lastName} (MRN: {transactionHistory.mrNumber})</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ width: "30%" }}>Nama Dokter</Text>
            <Text style={{}}> : {transactionHistory.doctorName}</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ width: "30%" }}>Cara Pembayaran</Text>
            <Text style={{}}> : {transactionHistory.payerName} Rp. {formatDigit(parseInt(transactionHistory.totalBill))}</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ width: "30%" }}>Untuk Pembayaran</Text>
            <Text style={{}}> :</Text>
          </View>
        </View>
        <View style={[styles.table, styles.sectionTable]}>
          <View style={[styles.row, styles.header]}>
            <Text style={[styles.headerText, styles.cellFirst]}>No</Text>
            <Text style={[styles.headerText, styles.cellQty]}>Keterangan</Text>
            <Text style={[styles.headerText, styles.cell]}>Total</Text>
          </View>
          {transactionHistory.transactionCategory && transactionHistory.transactionCategory.length > 0 ?
            transactionHistory.transactionCategory.map((data, index) => (
              <View style={[styles.row]} key={index}>
                <Text style={[styles.cellFirst]}>{index + 1}</Text>
                <Text style={[styles.cellQty]}>{data.name}</Text>
                <Text style={[styles.cell]}>Rp {formatDigit(parseInt(data.total))}</Text>
              </View>
            ))
            : null}
        </View>
        <View style={[styles.table, styles.sectionTable]}>
          <View style={[styles.row]}>
            <Text style={[styles.headerText, styles.cellFirst]}>No</Text>
            <Text style={[styles.headerText, styles.cellDescription]}>Keterangan</Text>
            <Text style={[styles.headerText, styles.cell]}>Harga</Text>
            <Text style={[styles.headerText, styles.cell]}>Jumlah</Text>
            <Text style={[styles.headerText, styles.cell]}>Total</Text>
          </View>
          {transactionHistory.transactionCategory && transactionHistory.transactionCategory.length > 0 ?
            transactionHistory.transactionCategory.map((data, index) => (
              data.items && data.items.length > 0 ?
                data.items.map((dataItem, index2) => (
                  <View style={[styles.row]} key={(index2 + 1) * (index + 1)}>
                    <Text style={[styles.cellFirst]}>{(index2 + 1) * (index + 1)}</Text>
                    <Text style={[styles.cellDescription]}>{dataItem.name}</Text>
                    <Text style={[styles.cell]}>Rp {formatDigit(parseInt(dataItem.price))}</Text>
                    <Text style={[styles.cell]}>{dataItem.quantity}</Text>
                    <Text style={[styles.cell]}>{formatDigit(dataItem.quantity * parseInt(dataItem.price))}</Text>
                  </View>
                )) : null
            ))

            : null}
          <View style={[styles.row]}>
            <Text style={[styles.cellFirst]}></Text>
            <Text style={[styles.cellDescription]}></Text>
            <Text style={[styles.cell]}></Text>
            <Text style={[styles.cell]}>Jumlah</Text>
            <Text style={[styles.cell]}>Rp {formatDigit(parseInt(transactionHistory.totalBill))}</Text>
          </View>
        </View>
        <View style={{ bottom: 30, position: "absolute", width: "100%" }} fixed>
          <Text style={{ fontWeight: "bold" }}>Terbilang : {terbilang(parseInt(transactionHistory.totalBill))}</Text>
          <Text style={{ textAlign: "right", paddingRight: "100px" }}>Terima Kasih</Text>
          <Text style={{ textAlign: "right", paddingRight: "100px" }}>{today()}</Text>
          <Text style={{ fontSize: "8" }}>- Harga obat resep sudah termasuk PPN 11%</Text>
          <Text style={{ fontSize: "8" }}>- Bukti pembayaran ini berlaku juga sebagai kwitansi</Text>
          <Text style={{ fontSize: "8" }}>- Obat/barang sudah dibeli tidak dapat ditukar atau dikembalikan</Text>
          <Text style={{ fontSize: "8" }}>- Kwitansi hanya boleh dicetak 1 kali dan photo copy tidak dapat dilegalisir</Text>
          <Text style={{ fontSize: "8" }}>- Hanya kwitansi asli yang dapat ditukar dengan kwitansi detail</Text>
          <Text style={{ fontSize: "8" }}>- Penukaran/permintaan dokumen pada hari berbeda dikenakan biaya registrasi</Text>
          <Text style={{ fontSize: "8" }}>- Kwitansi jangan hilang, {transactionHistory.hospitalName} tidak dapat mencentak ulang kwitansi</Text>
        </View>
      </Page>
    </Document>
  )
}

export default TemplateInvoice;

