import { findDoctorConstants } from "../actions/constants";

const initState = {
  dataFaskes: [],
  dataSpecializations: [],
  dataConsultationTypes: [],
  dataDoctorAvailabilities:[],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case findDoctorConstants.GET_FASKES_SUCCESS:
      state = {
        ...state,
        dataFaskes: action.payload.data,
        loading: false,
      };
      break;
    case findDoctorConstants.GET_FASKES_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case findDoctorConstants.GET_FASKES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case findDoctorConstants.GET_SPECIALIZATIONS_SUCCESS:
      state = {
        ...state,
        dataSpecializations: action.payload.data,
        loading: false,
      };
      break;
    case findDoctorConstants.GET_SPECIALIZATIONS_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case findDoctorConstants.GET_SPECIALIZATIONS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    break;
    case findDoctorConstants.GET_CONSULTATIONTYPES_SUCCESS:
      state = {
        ...state,
        dataConsultationTypes: action.payload.data,
        loading: false,
      };
    break;
    case findDoctorConstants.GET_CONSULTATIONTYPES_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case findDoctorConstants.GET_CONSULTATIONTYPES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case findDoctorConstants.GET_DOCTORAVAILABILITIES_SUCCESS:
      state = {
        ...state,
        dataDoctorAvailabilities: action.payload.data,
        loading: false,
      };
    break;
    case findDoctorConstants.GET_DOCTORAVAILABILITIES_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case findDoctorConstants.GET_DOCTORAVAILABILITIES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    break;
    case findDoctorConstants.GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_SUCCESS:
      state = {
        ...state,
        dataDoctorAvailabilities: action.payload.data,
        loading: false,
      };
    break;
    case findDoctorConstants.GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case findDoctorConstants.GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    break;
  }
  return state;
};
