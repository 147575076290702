import React from 'react';
import { isAlphabetic, isNumber } from '../../../helpers/utils';
import './Text.style.scss';

const Text = ({
  size = 'sm' | 'md' | 'lg' | 'xl' | null,
  label = '',
  labelClassName = '',
  value = '',
  setValue,
  placeholder = 'Masukkan teks',
  maxText = 120,
  isShowWarning = false,
  readOnly = false,
  type = 'text',
  icon = null,
  iconPosition = 'left',
  required = false,
  iconOnClick,
  enterOnPress,
  styles = {},
  errorMessage = '',
}) => {
  const onChange = (event) => {
    const temp = event.target.value;
    switch (type) {
      case 'number':
        isNumber(temp)
          ? setValue(temp?.length > maxText ? value : temp)
          : setValue(temp.length ? value : temp);
        break;
      case 'alphabet':
        isAlphabetic(temp)
          ? setValue(temp?.length > maxText ? value : temp)
          : setValue(temp.length ? value : temp);
        break;
      default:
        setValue(temp?.length > maxText ? value : temp);
        break;
    }
  };

  return (
    <React.Fragment>
      {label && (
        <p className={`${labelClassName} font-weight-bold label-input-text`}>
          {label} {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div className="container-field">
        <input
          type={type === 'number' ? 'text' : type}
          className={`w-100 form-input-text${
            isShowWarning ? '-warning' : ''
          } mb-0 ${icon && `input-icon-${iconPosition}`}`}
          style={styles}
          value={value}
          onChange={onChange}
          disabled={readOnly}
          placeholder={placeholder}
          readOnly={readOnly}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              enterOnPress();
            }
          }}
        />
        {icon && (
          <img
            src={icon}
            alt={`icon-${iconPosition}`}
            className={`${iconPosition}-icon`}
            onClick={iconOnClick}
          />
        )}
      </div>

      {isShowWarning && (
        <p className="warning-message-input mt-2">
          <i className="fa fa-warning" />{' '}
          {errorMessage ? errorMessage : label || 'Kolom ini'}{' '}
          {!errorMessage && `tidak boleh kosong!`}
        </p>
      )}
    </React.Fragment>
  );
};

export default Text;
