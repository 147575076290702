import axios from '../helpers/axios';
import { doctorScheduleConstants } from './constants';

export const getDisabledDate = (param) => {
  return async (dispatch) => {
    dispatch({ type: doctorScheduleConstants.GET_DISABLEDDATE_REQUEST });
    const res = await axios.post(`/doctor-schedules/generate/v2`, param);
    if (res.status === 200) {
      return res.data.data;
    } else {
      dispatch({
        type: doctorScheduleConstants.GET_DISABLEDDATE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getSlotTime = (param) => {
  return async (dispatch) => {
    dispatch({ type: doctorScheduleConstants.GET_SLOT_REQUEST });
    const res = await axios.post(`/doctor-schedules/schedule/slot/v2`, param);
    if (res.status === 200) {
      dispatch({
        type: doctorScheduleConstants.GET_SLOT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: doctorScheduleConstants.GET_SLOT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getDoctorData = (param) => {
  return async (dispatch) => {
    dispatch({ type: doctorScheduleConstants.GET_DOCTOR_REQUEST });
    const res = await axios.get(`/doctors/detail/${param}`, param);
    if (res.status === 200) {
      // dispatch({
      //   type: doctorScheduleConstants.GET_DOCTOR_SUCCESS,
      //   payload: res.data,
      // });
      return res.data.data;
    } else {
      dispatch({
        type: doctorScheduleConstants.GET_DOCTOR_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
