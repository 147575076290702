import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDoctorAvailabilities } from '../../../actions';
import {
  IconCheckedDisbled,
  IconCheckedEnabled,
  IconDummyAvatar,
  IconDummyAvatarMale,
  IconDummyAvatarFemale,
  IconSearchWhite,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import { consultationTypeCode } from '../../../constants/common';
import { wordingResultDoctorList } from '../../../constants/wording';
import Layout from '../Components/Layout';
import './DoctorDetail.scss';
import MobileDoctorDetail from './MobileDoctorDetail';
import LayoutV2 from "../Components/LayoutV2";
import {
  IconArrowGreen
} from '../../../assets/img/icon';
import { Col, Row, Button as ButtonReact } from 'react-bootstrap';
import Card from '../../../components/Card';

const DoctorDetail = (props) => {
  /* Redux */
  const dispatch = useDispatch();

  /* Router DOM */
  const history = useHistory();

  /* Local State */
  const [doctor, setDoctor] = useState({});

  const search = useMemo(() => {
    return {
      doctor_id:
        props.location.state.length > 0
          ? props.location.state[0].doctor_id
          : '',
      hospital_id:
        props.location.state.length > 0
          ? [props.location.state[0].hospital_id]
          : [],
      specialization_id:
        props.location.state.length > 0
          ? props.location.state[0].specialization_id.trim()
          : '',
      name_doctor:
        props.location.state.length > 0
          ? props.location.state[0].name_doctor
          : '',
      gender:
        props.location.state.length > 0 ? props.location.state[0].gender : '',
      consultation:
        props.location.state.length > 0
          ? props.location.state[0].consultation
          : '',
      serviceType:
        props.location?.state?.length > 0
          ? props.location.state[0].serviceType
          : '',
    };
  }, [props.location.state]);

  useEffect(() => {
    getListDoctor();
  }, []);

  const getListDoctor = () => {
    dispatch(getDoctorAvailabilities(JSON.stringify(search))).then(
      (response) => {
        if (response.data.length > 0) {
          setDoctor(response.data[0]);
        }
      }
    );
  };

  const handleDoctorSchedule = () => {
    history.push('/schedule', props.location.state);
  };

  const handleAnotherDoctor = () => {
    const state = props.location.state;
    const params = {
      ...state[0],
      hospital_id: state[0].search_id_hospital?.length
        ? [state[0].search_id_hospital[0]]
        : [],
      specialization_id: state[0]?.search_specialization_id || null,
      hospital_group_id: state[0]?.hospital_group_id || null,
      schedule_date: state[0]?.schedule_date || null,
      gender: state[0]?.search_gender || null,
      consultation: state[0]?.search_consultation_type || null,
    };
    history.push(`/result-find-doctor`, [params]);
  };

  const longDate = useMemo(() => {
    const date = new Date();
    const result = [];
    for (let i = 0; i < 6; i++) {
      const nextDate = new Date(date.setDate(date.getDate() + 1));
      result.push(nextDate);
    }
    return [new Date(), ...result];
  }, []);

  const schedules = useMemo(() => {
    let bpjsChecked = 0;
    let regularChecked = 0;
    let teleChecked = 0;
    const bpjs = doctor?.scheduleList?.tm_doctor_schedules_v2s?.filter(
      (item) => item?.consultation_type_id === consultationTypeCode.bpjs
    )[0];
    const reguler = doctor?.scheduleList?.tm_doctor_schedules_v2s?.filter(
      (item) => item?.consultation_type_id === consultationTypeCode.reguler
    )[0];
    const tele = doctor?.scheduleList?.tm_doctor_schedules_v2s?.filter(
      (item) => item?.consultation_type_id === consultationTypeCode.tele
    )[0];

    const slotBpjs = longDate.map((date) => {
      if (!bpjs?.tx_schedule_detail_v2s?.length) return null;
      const day = new Date(date).getDay() || 7;
      const tempFilter = bpjs?.tm_doctor_schedule_items?.filter(
        (tdri) => +tdri?.day === day
      );
      if (tempFilter?.length)
        return {
          ...tempFilter[0],
          dateView: date,
          scheduleDetails: bpjs?.tx_schedule_detail_v2s,
        };
      return null;
    });
    const slotReguler = longDate.map((date) => {
      if (!reguler?.tx_schedule_detail_v2s?.length) return null;
      const day = new Date(date).getDay() || 7;
      const tempFilter = reguler?.tm_doctor_schedule_items?.filter(
        (tdri) => +tdri?.day === day
      );
      if (tempFilter?.length)
        return {
          ...tempFilter[0],
          dateView: date,
          scheduleDetails: reguler?.tx_schedule_detail_v2s,
        };
      return null;
    });
    const slotTele = longDate.map((date) => {
      if (!tele?.tx_schedule_detail_v2s?.length) return null;
      const day = new Date(date).getDay() || 7;
      const tempFilter = tele?.tm_doctor_schedule_items?.filter(
        (tdri) => +tdri?.day === day
      );
      if (tempFilter?.length)
        return {
          ...tempFilter[0],
          dateView: date,
          scheduleDetails: tele?.tx_schedule_detail_v2s,
        };
      return null;
    });
    const bpjsFlag = slotBpjs?.filter((e) => e);
    const regulerFlag = slotReguler?.filter((e) => e);
    const teleFlag = slotTele?.filter((e) => e);

    if (bpjsFlag?.length) bpjsChecked = 1;
    if (regulerFlag?.length) regularChecked = 1;
    if (teleFlag?.length) teleChecked = 1;

    return {
      bpjsChecked,
      regularChecked,
      teleChecked,
      doctorHospitalId: doctor?.scheduleList?.id,
      serviceFee: doctor?.scheduleList?.serviceFee,
      doctorId: doctor?.scheduleList?.doctor_id,
      doctorName: doctor?.scheduleList?.tm_doctor?.name,
      doctorImageUrl: doctor?.scheduleList?.tm_doctor?.image_url,
      specializationId: doctor?.scheduleList?.tm_specialization?.id,
      specializationName: doctor?.scheduleList?.tm_specialization?.name,
      poliId: doctor?.scheduleList?.tm_poli?.id,
      hospitalId: doctor?.scheduleList?.hospital_id,
      hospitalName: doctor?.scheduleList?.tm_hospital?.name,
      hospitalImageUrl: doctor?.scheduleList?.tm_hospital?.image_url,
      hospitalType: doctor?.scheduleList?.tm_hospital?.hospital_type,
      hospitalTimezone: doctor?.scheduleList?.tm_hospital?.timezone,
      scheduleType:
        doctor?.scheduleList?.tm_doctor_schedules_v2?.tr_schedule_type,
      consultationType:
        doctor?.scheduleList?.tm_doctor_schedules_v2?.tr_consultation_type,
      slotBpjs,
      slotReguler,
      slotTele,
    };
  }, [doctor]);

  const backToDoctorList = () => {
    if (props.location.state[0].from === "schedule") {
      return history.push(`/schedule`, props.location.state);
    }

    const search = {
      hospital_group_id: localStorage.getItem("klinikGrupId"),
      hospital_id: [localStorage.getItem("klinikId")],
      name_doctor: undefined,
      serviceType: props.location.state[0].serviceType,
      titleList: "",
      filterName: props.location.state[0].filterName,
      filterKlinik: props.location.state[0].filterKlinik,
      filterSpecialization: props.location.state[0].filterSpecialization,
      filterGender: props.location.state[0].filterGender,
      filterConsultation: props.location.state[0].filterConsultation
    };

    history.push(`list-v2/janji-temu`, [search]);
  }

  return (
    
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="fix-nav">
        <Col md={12}>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
              onClick={backToDoctorList}
            />
            &nbsp;&nbsp;
            <span
              className="font-s-18 font-w-700 cursor-point"
              onClick={backToDoctorList}
            >
              Profil Dokter
            </span>
          </div>
        </Col>
      </Row>

      <Row style={{marginTop: "50px"}}>
        <div className={`offset-lg-2 col-lg-8 col-md-12 col-sm-12`}>
          <Card className="mb-3">
            <div className="row">
              <div className="col-12">
                <div className="d-flex">
                  <div className="d-flex">
                    <img
                      src={doctor?.scheduleList?.tm_doctor?.gender == '1' ? IconDummyAvatarMale : doctor?.scheduleList?.tm_doctor?.gender == '2' ? IconDummyAvatarFemale : IconDummyAvatar}
                      alt="icon"
                      className="d-inline my-auto mr-3"
                      height={40}
                      width={40}
                    />
                  </div>
                  <div style={{maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                    <p className="mobile-profile-name d-inline my-auto">
                      <span className="font-s-14 font-w-700">{doctor?.scheduleList?.tm_doctor?.name || '-'}</span><br/>
                      <span className="font-s-12 font-w-500">{doctor?.scheduleList?.tm_specialization?.name || '-'}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="hr-no-margin"/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="font-s-14 font-w-500">{doctor?.scheduleList?.tm_hospital?.name || '-'}</span>
              </div>
            </div>
            <div className="row margin-t-8 margin-b-16">
              <div className="col-12">
                <div className="d-flex">
                  <div className="d-flex">
                    <div>
                      <img
                        src={schedules?.regularChecked === 1 ? "/img/icon/ic_check_green.svg" : "/img/icon/ic_check_gray.svg"}
                        height={16}
                        width={16}
                      />
                    </div>
                    <div>
                      <span className={`margin-l-4 font-s-14 font-w-500 ${schedules?.regularChecked === 1 ? "enabled" : "disabled"}`}>Reguler</span>
                    </div>
                  </div>
                  <div className="d-flex margin-l-16">
                    <div>
                      <img
                        src={schedules?.bpjsChecked  === 1 ? "/img/icon/ic_check_green.svg" : "/img/icon/ic_check_gray.svg"}
                        height={16}
                        width={16}
                      />
                    </div>
                    <div>
                      <span className={`margin-l-4 font-s-14 font-w-500 ${schedules?.bpjsChecked  === 1 ? "enabled" : "disabled"}`}>Melayani BPJS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="font-s-14 font-w-700">Pendidikan</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="font-s-14 font-w-400">{doctor?.scheduleList?.tm_doctor?.education || '-'}</span>
              </div>
            </div>
            <div className="row margin-t-16">
              <div className="col-12">
                <span className="font-s-14 font-w-700">Kondisi dan Minat Klinis</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="font-s-14 font-w-400">{doctor?.scheduleList?.tm_doctor?.clinical_interest || '-'}</span>
              </div>
            </div>
            <div className="row margin-t-16">
              <div className="col-12">
                <span className="font-s-14 font-w-700">Prestasi</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="font-s-14 font-w-400">{doctor?.scheduleList?.tm_doctor?.achievement || '-'}</span>
              </div>
            </div>
            <div className="row margin-t-40">
              <div className="col-12">
                <ButtonReact
                  className="button-green-40-w-100"
                  onClick={() => {handleDoctorSchedule()}}
                >
                  <span className="font-w-600 font-s-16">Buat Janji Temu</span>
                </ButtonReact>
              </div>
            </div>
          </Card>
        </div>
      </Row>

    </LayoutV2>
  );
};

export default DoctorDetail;
