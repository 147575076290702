export const inquiryConstants = {
  CREATE_INQUIRY_REQUEST: "CREATE_INQUIRY_REQUEST",
  CREATE_INQUIRY_FAILURE: "CREATE_INQUIRY_FAILURE",
  CREATE_INQUIRY_SUCCESS: "CREATE_INQUIRY_SUCCESS",
  GET_INQUIRY_REQUEST: "GET_INQUIRY_REQUEST",
  GET_INQUIRY_FAILURE: "GET_INQUIRY_FAILURE",
  GET_INQUIRY_SUCCESS: "GET_INQUIRY_SUCCESS",
  GET_HOSPITAL_INFO_REQUEST: "GET_HOSPITAL_INFO_REQUEST",
  GET_HOSPITAL_INFO_FAILURE: "GET_HOSPITAL_INFO_FAILURE",
  GET_HOSPITAL_INFO_SUCCESS: "GET_HOSPITAL_INFO_SUCCESS",
};
