export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  OTP_VALIDATE_REQUEST: 'OTP_VALIDATE_REQUEST',
  OTP_VALIDATE_SUCCESS: 'OTP_VALIDATE_SUCCESS',
  OTP_VALIDATE_FAILURE: 'OTP_VALIDATE_FAILURE',
  HEADER_VALIDATE_REQUEST: 'HEADER_VALIDATE_REQUEST',
  HEADER_VALIDATE_SUCCESS: 'HEADER_VALIDATE_SUCCESS',
  HEADER_VALIDATE_FAILURE: 'HEADER_VALIDATE_FAILURE',
};

export const userConstants = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
};

export const accountProfileConstants = {
  GET_HOSPITAL_TERMS_PRIVACY_REQUEST: 'GET_HOSPITAL_TERMS_PRIVACY_REQUEST',
  GET_HOSPITAL_TERMS_PRIVACY_SUCCESS: 'GET_HOSPITAL_TERMS_PRIVACY_SUCCESS',
  GET_HOSPITAL_TERMS_PRIVACY_FAILURE: 'GET_HOSPITAL_TERMS_PRIVACY_FAILURE',
  GET_ALL_ACCOUNTPROFILE_REQUEST: 'GET_ALL_ACCOUNTPROFILE_REQUEST',
  GET_ALL_ACCOUNTPROFILE_SUCCESS: 'GET_ALL_ACCOUNTPROFILE_SUCCESS',
  GET_ALL_ACCOUNTPROFILE_FAILURE: 'GET_ALL_ACCOUNTPROFILE_FAILURE',
  UPLOAD_KTP_REQUEST: 'UPLOAD_KTP_REQUEST',
  UPLOAD_KTP_SUCCESS: 'UPLOAD_KTP_SUCCESS',
  UPLOAD_KTP_FAILURE: 'UPLOAD_KTP_FAILURE',
  CREATE_ACCOUNTPROFILE_REQUEST: 'CREATE_ACCOUNTPROFILE_REQUEST',
  CREATE_ACCOUNTPROFILE_SUCCESS: 'CREATE_ACCOUNTPROFILE_SUCCESS',
  CREATE_ACCOUNTPROFILE_FAILURE: 'CREATE_ACCOUNTPROFILE_FAILURE',
  UPDATE_ACCOUNTPROFILE_REQUEST: 'UPDATE_ACCOUNTPROFILE_REQUEST',
  UPDATE_ACCOUNTPROFILE_SUCCESS: 'UPDATE_ACCOUNTPROFILE_SUCCESS',
  UPDATE_ACCOUNTPROFILE_FAILURE: 'UPDATE_ACCOUNTPROFILE_FAILURE',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPLOAD_AVATAR_REQUEST: 'UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS: 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_FAILURE: 'UPLOAD_AVATAR_FAILURE',
  GET_RELATION_REQUEST: 'GET_RELATION_REQUEST',
  GET_RELATION_SUCCESS: 'GET_RELATION_SUCCESS',
  GET_RELATION_FAILURE: 'GET_RELATION_FAILURE',
  GET_IDENTITY_TYPE_REQUEST: 'GET_IDENTITY_TYPE_REQUEST',
  GET_IDENTITY_TYPE_SUCCESS: 'GET_IDENTITY_TYPE_SUCCESS',
  GET_IDENTITY_TYPE_FAILURE: 'GET_IDENTITY_TYPE_FAILURE',
  GET_MARRIAGE_STATUS_REQUEST: 'GET_MARRIAGE_STATUS_REQUEST',
  GET_MARRIAGE_STATUS_SUCCESS: 'GET_MARRIAGE_STATUS_SUCCESS',
  GET_MARRIAGE_STATUS_FAILURE: 'GET_MARRIAGE_STATUS_FAILURE',
  GET_BLOOD_TYPE_REQUEST: 'GET_BLOOD_TYPE_REQUEST',
  GET_BLOOD_TYPE_SUCCESS: 'GET_BLOOD_TYPE_SUCCESS',
  GET_BLOOD_TYPE_FAILURE: 'GET_BLOOD_TYPE_FAILURE',
  GET_ETHNIC_REQUEST: 'GET_ETHNIC_REQUEST',
  GET_ETHNIC_SUCCESS: 'GET_ETHNIC_SUCCESS',
  GET_ETHNIC_FAILURE: 'GET_ETHNIC_FAILURE',
  GET_RELIGION_REQUEST: 'GET_RELIGION_REQUEST',
  GET_RELIGION_SUCCESS: 'GET_RELIGION_SUCCESS',
  GET_RELIGION_FAILURE: 'GET_RELIGION_FAILURE',
  GET_NATIONALITY_REQUEST: 'GET_NATIONALITY_REQUEST',
  GET_NATIONALITY_SUCCESS: 'GET_NATIONALITY_SUCCESS',
  GET_NATIONALITY_FAILURE: 'GET_NATIONALITY_FAILURE',
  GET_EDUCATION_REQUEST: 'GET_EDUCATION_REQUEST',
  GET_EDUCATION_SUCCESS: 'GET_EDUCATION_SUCCESS',
  GET_EDUCATION_FAILURE: 'GET_EDUCATION_FAILURE',
  GET_JOB_REQUEST: 'GET_JOB_REQUEST',
  GET_JOB_SUCCESS: 'GET_JOB_SUCCESS',
  GET_JOB_FAILURE: 'GET_JOB_FAILURE',
  GET_PROVINCE_REQUEST: 'GET_PROVINCE_REQUEST',
  GET_PROVINCE_SUCCESS: 'GET_PROVINCE_SUCCESS',
  GET_PROVINCE_FAILURE: 'GET_PROVINCE_FAILURE',
  GET_CITY_REQUEST: 'GET_CITY_REQUEST',
  GET_CITY_SUCCESS: 'GET_CITY_SUCCESS',
  GET_CITY_FAILURE: 'GET_CITY_FAILURE',
  GET_DISTRICT_REQUEST: 'GET_DISTRICT_REQUEST',
  GET_DISTRICT_SUCCESS: 'GET_DISTRICT_SUCCESS',
  GET_DISTRICT_FAILURE: 'GET_DISTRICT_FAILURE',
  GET_SUBDISTRICT_REQUEST: 'GET_SUBDISTRICT_REQUEST',
  GET_SUBDISTRICT_SUCCESS: 'GET_SUBDISTRICT_SUCCESS',
  GET_SUBDISTRICT_FAILURE: 'GET_SUBDISTRICT_FAILURE',
  GET_POSTALCODE_REQUEST: 'GET_POSTALCODE_REQUEST',
  GET_POSTALCODE_SUCCESS: 'GET_POSTALCODE_SUCCESS',
  GET_POSTALCODE_FAILURE: 'GET_POSTALCODE_FAILURE',
};

export const transactionHistoryConstants = {
  GET_TRANSACTIONHISTORY_REQUEST: 'GET_TRANSACTIONHISTORY_REQUEST',
  GET_TRANSACTIONHISTORY_SUCCESS: 'GET_TRANSACTIONHISTORY_SUCCESS',
  GET_TRANSACTIONHISTORY_FAILURE: 'GET_TRANSACTIONHISTORY_FAILURE',
  PRINT_MEDICALRESULT_REQUEST: 'PRINT_MEDICALRESULT_REQUEST',
  PRINT_MEDICALRESULT_SUCCESS: 'PRINT_MEDICALRESULT_SUCCESS',
  PRINT_MEDICALRESULT_FAILURE: 'PRINT_MEDICALRESULT_FAILURE',
  GET_MEDICALRECORD_REQUEST: 'GET_MEDICALRECORD_REQUEST',
  GET_MEDICALRECORD_SUCCESS: 'GET_MEDICALRECORD_SUCCESS',
  GET_MEDICALRECORD_FAILURE: 'GET_MEDICALRECORD_FAILURE',
  GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',
  GET_DIAGNOSIS_REQUEST: 'GET_DIAGNOSIS_REQUEST',
  GET_DIAGNOSIS_SUCCESS: 'GET_DIAGNOSIS_SUCCESS',
  GET_DIAGNOSIS_FAILURE: 'GET_DIAGNOSIS_FAILURE',
  GET_RECIPE_REQUEST: 'GET_RECIPE_REQUEST',
  GET_RECIPE_SUCCESS: 'GET_RECIPE_SUCCESS',
  GET_RECIPE_FAILURE: 'GET_RECIPE_FAILURE',
};

export const findDoctorConstants = {
  GET_FASKES_REQUEST: 'GET_FASKES_REQUEST',
  GET_FASKES_SUCCESS: 'GET_FASKES_SUCCESS',
  GET_FASKES_FAILURE: 'GET_FASKES_FAILURE',
  GET_SPECIALIZATIONS_REQUEST: 'GET_SPECIALIZATIONS_REQUEST',
  GET_SPECIALIZATIONS_SUCCESS: 'GET_SPECIALIZATIONS_SUCCESS',
  GET_SPECIALIZATIONS_FAILURE: 'GET_SPECIALIZATIONS_FAILURE',
  GET_CONSULTATIONTYPES_REQUEST: 'GET_CONSULTATIONTYPES_REQUEST',
  GET_CONSULTATIONTYPES_SUCCESS: 'GET_CONSULTATIONTYPES_SUCCESS',
  GET_CONSULTATIONTYPES_FAILURE: 'GET_CONSULTATIONTYPES_FAILURE',
  GET_DOCTORAVAILABILITIES_REQUEST: 'GET_DOCTORAVAILABILITIES_REQUEST',
  GET_DOCTORAVAILABILITIES_SUCCESS: 'GET_DOCTORAVAILABILITIES_SUCCESS',
  GET_DOCTORAVAILABILITIES_FAILURE: 'GET_DOCTORAVAILABILITIES_FAILURE',
  GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_REQUEST:
    'GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_REQUEST',
  GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_SUCCESS:
    'GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_SUCCESS',
  GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_FAILURE:
    'GET_DOCTORAVAILABILITIESBYGROUPHOSPITAL_FAILURE',
};

export const patientConstants = {
  GET_ALL_PATIENT_REQUEST: 'GET_ALL_PATIENT_REQUEST',
  GET_ALL_PATIENT_SUCCESS: 'GET_ALL_PATIENT_SUCCESS',
  GET_ALL_PATIENT_FAILURE: 'GET_ALL_PATIENT_FAILURE',
  UPLOAD_MEDICALDOC_REQUEST: 'UPLOAD_MEDICALDOC_REQUEST',
  UPLOAD_MEDICALDOC_SUCCESS: 'UPLOAD_MEDICALDOC_SUCCESS',
  UPLOAD_MEDICALDOC_FAILURE: 'UPLOAD_MEDICALDOC_FAILURE',
};

export const paymentMethodConstants = {
  UPLOAD_FKTLDOC_REQUEST: 'UPLOAD_FKTLDOC_REQUEST',
  UPLOAD_FKTLDOC_SUCCESS: 'UPLOAD_FKTLDOC_SUCCESS',
  UPLOAD_FKTLDOC_FAILURE: 'UPLOAD_FKTLDOC_FAILURE',
  UPLOAD_INSURANCE_REQUEST: 'UPLOAD_INSURANCE_REQUEST',
  UPLOAD_INSURANCE_SUCCESS: 'UPLOAD_INSURANCE_SUCCESS',
  UPLOAD_INSURANCE_FAILURE: 'UPLOAD_INSURANCE_FAILURE',
  UPLOAD_JAMINAN_REQUEST: 'UPLOAD_JAMINAN_REQUEST',
  UPLOAD_JAMINAN_SUCCESS: 'UPLOAD_JAMINAN_SUCCESS',
  UPLOAD_JAMINAN_FAILURE: 'UPLOAD_JAMINAN_FAILURE',
  GET_HOSPITAL_PAYER_REQUEST: 'GET_HOSPITAL_PAYER_REQUEST',
  GET_HOSPITAL_PAYER_SUCCESS: 'GET_HOSPITAL_PAYER_SUCCESS',
  GET_HOSPITAL_PAYER_FAILURE: 'GET_HOSPITAL_PAYER_FAILURE',
  GET_HOSPITAL_INSURANCE_REQUEST: 'GET_HOSPITAL_INSURANCE_REQUEST',
  GET_HOSPITAL_INSURANCE_SUCCESS: 'GET_HOSPITAL_INSURANCE_SUCCESS',
  GET_HOSPITAL_INSURANCE_FAILURE: 'GET_HOSPITAL_INSURANCE_FAILURE',
  GET_COMPANY_INSURANCE_REQUEST: 'GET_COMPANY_INSURANCE_REQUEST',
  GET_COMPANY_INSURANCE_SUCCESS: 'GET_COMPANY_INSURANCE_SUCCESS',
  GET_COMPANY_INSURANCE_FAILURE: 'GET_COMPANY_INSURANCE_FAILURE',
};

export const appointmentConstants = {
  CREATE_APPOINTMENT_REQUEST: 'CREATE_APPOINTMENT_REQUEST',
  CREATE_APPOINTMENT_SUCCESS: 'CREATE_APPOINTMENT_SUCCESS',
  CREATE_APPOINTMENT_FAILURE: 'CREATE_APPOINTMENT_FAILURE',
  CREATE_APPOINTMENT_PACKAGE_REQUEST: 'CREATE_APPOINTMENT_PACKAGE_REQUEST',
  CREATE_APPOINTMENT_PACKAGE_SUCCESS: 'CREATE_APPOINTMENT_PACKAGE_SUCCESS',
  CREATE_APPOINTMENT_PACKAGE_FAILURE: 'CREATE_APPOINTMENT_PACKAGE_FAILURE',
  GET_DETAIL_APPOINTMENT_REQUEST: 'GET_DETAIL_APPOINTMENT_REQUEST',
  GET_DETAIL_APPOINTMENT_SUCCESS: 'GET_DETAIL_APPOINTMENT_SUCCESS',
  GET_DETAIL_APPOINTMENT_FAILURE: 'GET_DETAIL_APPOINTMENT_FAILURE',
  GET_DETAIL_APPOINTMENT_PACKAGE_REQUEST: 'GET_DETAIL_APPOINTMENT_PACKAGE_REQUEST',
  GET_DETAIL_APPOINTMENT_PACKAGE_SUCCESS: 'GET_DETAIL_APPOINTMENT_PACKAGE_SUCCESS',
  GET_DETAIL_APPOINTMENT_PACKAGE_FAILURE: 'GET_DETAIL_APPOINTMENT_PACKAGE_FAILURE',
  CHECKIN_APPOINTMENT_REQUEST: 'CHECKIN_APPOINTMENT_REQUEST',
  CHECKIN_APPOINTMENT_SUCCESS: 'CHECKIN_APPOINTMENT_SUCCESS',
  CHECKIN_APPOINTMENT_FAILURE: 'CHECKIN_APPOINTMENT_FAILURE',
};

export const doctorScheduleConstants = {
  GET_DISABLEDDATE_REQUEST: 'GET_DISABLEDDATE_REQUEST',
  GET_DISABLEDDATE_SUCCESS: 'GET_DISABLEDDATE_SUCCESS',
  GET_DISABLEDDATE_FAILURE: 'GET_DISABLEDDATE_FAILURE',
  GET_SLOT_REQUEST: 'GET_SLOT_REQUEST',
  GET_SLOT_SUCCESS: 'GET_SLOT_SUCCESS',
  GET_SLOT_FAILURE: 'GET_SLOT_FAILURE',
  GET_DOCTOR_REQUEST: 'GET_DOCTOR_REQUEST',
  GET_DOCTOR_SUCCESS: 'GET_DOCTOR_SUCCESS',
  GET_DOCTOR_FAILURE: 'GET_DOCTOR_FAILURE',
};

export const proceduresConstant = {
  GET_PROCEDURE_MCU_REQUEST: 'GET_PROCEDURE_MCU_REQUEST',
  GET_PROCEDURE_MCU_SUCCESS: 'GET_PROCEDURE_MCU_SUCCESS',
  GET_PROCEDURE_MCU_FAILURE: 'GET_PROCEDURE_MCU_FAILURE',
  GET_LOV_PROCEDURE_MCU_REQUEST: 'GET_LOV_PROCEDURE_MCU_REQUEST',
  GET_LOV_PROCEDURE_MCU_SUCCESS: 'GET_LOV_PROCEDURE_MCU_SUCCESS',
  GET_LOV_PROCEDURE_MCU_FAILURE: 'GET_LOV_PROCEDURE_MCU_FAILURE',
};

export const articlesConstants = {
  GET_ARTICLES_REQUEST: 'GET_ARTICLES_REQUEST',
  GET_ARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
  GET_ARTICLES_FAILURE: 'GET_ARTICLES_FAILURE',
  GET_ARTICLES_DETAIL_REQUEST: 'GET_ARTICLES_DETAIL_REQUEST',
  GET_ARTICLES_DETAIL_SUCCESS: 'GET_ARTICLES_DETAIL_SUCCESS',
  GET_ARTICLES_DETAIL_FAILURE: 'GET_ARTICLES_DETAIL_FAILURE',
  GET_BANNER_REQUEST: 'GET_BANNER_REQUEST',
  GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
  GET_BANNER_FAILURE: 'GET_BANNER_FAILURE',
  GET_NEWS_REQUEST: 'GET_NEWS_REQUEST',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_FAILURE: 'GET_NEWS_FAILURE',
  GET_NEWS_DETAIL_REQUEST: 'GET_NEWS_DETAIL_REQUEST',
  GET_NEWS_DETAIL_SUCCESS: 'GET_NEWS_DETAIL_SUCCESS',
  GET_NEWS_DETAIL_FAILURE: 'GET_NEWS_DETAIL_FAILURE',
};
