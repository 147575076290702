import axios from "../helpers/axios";
import axiosFnc from "../helpers/axios.fnc"
import { authConstants } from "./constants";

import {apiKeyGenerate} from "../helpers/utils";

export const login = (user) => {
  // return async (dispatch) => {
  //   dispatch({ type: authConstants.LOGIN_REQUEST });
  //   const res = await axios.post("/users/otp/request", {
  //     ...user,
  //   });

  //   if (res.status === 200) {
  //     const otpStatus = res.data
  //     dispatch({
  //       type: authConstants.LOGIN_SUCCESS,
  //       payload: res.data
  //     });
  //     return otpStatus
  //   } else {
  //     if (res.status === 400) {
  //       dispatch({
  //         type: authConstants.LOGIN_FAILURE,
  //         payload: { error: res.data.error },
  //       });
  //     }
  //   }
  // };
};

export const otpRequest = (form) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const res = await axios.post(`/users/otp/request`, form);
    if (res.status === 200) {
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const validateOTP = (form) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.OTP_VALIDATE_REQUEST });
    const res = await axios.post(`/users/otp/validate`, form);
    if (res.status === 200) {
      dispatch({
        type: authConstants.OTP_VALIDATE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: authConstants.OTP_VALIDATE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const isUserLogin = () => {
  return async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    const user = JSON.parse(localStorage.getItem("user"));
    if (accessToken && user) {
      const parentId = localStorage.getItem("parentId");
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          accessToken,
          user,
          parentId,
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: "User not login" },
      });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({ type: authConstants.LOGOUT_SUCCESS });
  };
};

export const getKlinikHeader = (form) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.HEADER_VALIDATE_REQUEST });
    const res = await axiosFnc(generateOptions()).post(`/users/header/config`, form);
    if (res.status === 200) {
      dispatch({
        type: authConstants.HEADER_VALIDATE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: authConstants.HEADER_VALIDATE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

const generateOptions = () => {
  const options = { 
    headers: { "apikey": apiKeyGenerate() } 
  };
  return options
}
