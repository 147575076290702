import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import {
  getHospitalTermsPrivacy
} from '../../../actions';

const TermsAndConditions = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const hospitalGroupId = localStorage.getItem("klinikGrupId");
  const hospitalId = localStorage.getItem("klinikId");
  const accountProfile = useSelector((state) => state.accountProfile);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getHospitalTermsPrivacy(hospitalGroupId, hospitalId))
  }, []);

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col>
          <div className="d-flex flex-column">
            <span className="font-s-18 font-w-700 margin-b-8">
              Syarat dan Ketentuan
            </span>

            <div className="font-s-14 font-w-500" dangerouslySetInnerHTML={{__html: accountProfile?.termsCondition}}></div>
          </div>
        </Col>
      </Row>
    </LayoutV2>
  );
};

export default TermsAndConditions;
