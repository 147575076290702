import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          return <Component {...props} />;
        } else {
          return <Redirect to={"/home"} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
