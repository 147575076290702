export const consultationTypeCode = {
  reguler: 1,
  bpjs: 2,
  tele: 3,
};

export const documentBpjsTypes = [
  {
    label: 'Surat Rujukan',
    value: 'RujukanFKTL',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];

export const documentPatientTypes = [
  {
    label: 'Surat Rujukan',
    value: 'Rujukan',
  },
  {
    label: 'Hasil Laboratorium',
    value: 'LabResult',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];

export const documentInsuranceTypes = [
  {
    label: 'Kartu Asuransi',
    value: 'KartuAsuransi',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];

export const documentEmployeeTypes = [
  {
    label: 'Kartu Jaminan Perusahaan',
    value: 'KartuJaminan',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];
