import React from "react";

const FooterCopyright = () => {
  return (
    <div className="container-footer-copyright">
      <div className="container-web">
        <span className="font-s-14 font-w-500">
          Powered by Aido Health All Right Reserved&nbsp;&nbsp;
          <a
            className="font-s-14 font-w-700 cl-dark-blue cursor-point"
            href="/tnc"
          >
            Syarat & Ketentuan
          </a>
        </span>
      </div>
    </div>
  );
};

export default FooterCopyright;
