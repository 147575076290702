import React from 'react';
import { Row } from 'react-bootstrap';
import { Calendar, utils } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useHistory } from 'react-router-dom';
import {
  IconArrowGreen,
  IconDummyAvatar,
  IconDummyPlace,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import {
  wordingDoctorSchedule,
  wordingHopitalType,
} from '../../../constants/wording';
import './doctorSchedule.scss';

const MobileDoctorSchedule = ({
  state,
  handleDoctorSearch,
  selectedDay,
  onChangeCalendar,
  disableDate,
  maxDate,
  enabledDate,
  scheduleSlotContainer,
  doctorSchedule,
  handleRadioButton,
  checkedRadioButton,
  getConsultationType,
  payerNonBPJS,
  payerBPJS,
  timeSlotContainer,
  fillTimeSlot,
  initScheduleSlotContainer,
  scheduleSlotIndex,
  fillScheduleData,
  timeSlotData,
  mappingConsultationType,
  validateDate,
}) => {
  /* Router DOM */
  const navigate = useHistory();

  return (
    <React.Fragment>
      <div className="content-schedule-doctor-mobile d-lg-none d-block">
        <Row className="col-5 mt-4">
          <div
            className="cursor-pointer d-flex flex-row"
            onClick={() => navigate.goBack()}
          >
            <img src={IconArrowGreen} alt="icon" />
            <p className="title-page ml-2 text-white">Kembali</p>
          </div>
        </Row>
        <p className="text-center step-mobile-title font-weight-bold mt-3">
          Langkah 1 dari 3
        </p>
        <div className="row mt-4">
          <div className="col-12 mx-0">
            <div className="content-doctorSchedule-step">
              <p className="text-center title1">
                {wordingDoctorSchedule.titleReguler}
              </p>
              <p className="text-center mobile-title2">
                Silahkan pilih jadwal yang tersedia
              </p>
              <div className="card mt-4 py-4">
                <img
                  src={IconDummyAvatar}
                  width="50"
                  height="50"
                  alt="Doctor"
                  className="mx-auto"
                />
                <p className="text-center font-weight-bold mobile-doctor-name my-2">
                  {state[0].doctor_name}
                </p>
                <p className="text-center mobile-doctor-specialization">
                  Dokter Kulit dan Kelamin
                </p>
                <p className="text-center mobile-doctor-specialization">
                  {state[0].hospital_name}
                </p>
              </div>
              <div className="card my-4 py-4">
                <img
                  src={IconDummyPlace}
                  width="50"
                  height="50"
                  alt="Doctor"
                  className="mx-auto"
                />
                <p className="text-center font-weight-bold mobile-doctor-name my-2">
                  {state[0].hospital_name}
                </p>
                <p className="text-center mobile-doctor-specialization">
                  {/* {wordingHopitalType[2]} */}
                </p>
              </div>
              <Row>
                <div className="col-12">
                  <div className="app-card d-flex justify-content-center align-items-center">
                    <Calendar
                      value={selectedDay}
                      onChange={(value) => onChangeCalendar(value)}
                      colorPrimary="#007DFF"
                      calendarClassName="react-calendar"
                      disabledDays={disableDate}
                      minimumDate={utils().getToday()}
                      maximumDate={maxDate}
                      customDaysClassName={enabledDate}
                    />
                  </div>
                  <div className="legend-container col-md-6">
                    <div className="d-flex justify-content-left align-items-center">
                      <div className="legend available"></div>
                      <div className="text-dark mobile-legend-label mr-3">
                        Tersedia
                      </div>
                    </div>
                    <div className="d-flex justify-content-left align-items-center">
                      <div className="legend full"></div>
                      <div className="text-dark mobile-legend-label mr-3">
                        Habis Terpesan
                      </div>
                    </div>
                    {/* Temporary Comment # 27/12/2022
                      <div className="d-flex justify-content-left align-items-center">
                        <div className="legend full-waiting"></div>
                        <div className="label-legend mr-3">
                          Tersedia untuk daftar tunggu
                        </div> 
                      </div> 
                    */}
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-12 mt-3">
                  {scheduleSlotContainer && (
                    <div className="init-app-card ">
                      <div className="card-txt col-12">
                        <div className="card-txt col-12 pt-2 pb-2 pl-0 ml-0">
                          <p className="pl-0 ml-0 mobile-title-card">
                            Tipe Konsultasi
                          </p>
                        </div>
                        <div>
                          <ul>
                            {mappingConsultationType.length > 0 ? (
                              mappingConsultationType
                                ?.filter((e) => {
                                  if (state[0]?.serviceType === 'TL') {
                                    if (+e?.consultation_type === 3) {
                                      return e;
                                    }
                                  } else {
                                    if (+e?.consultation_type !== 3) {
                                      return e;
                                    }
                                  }
                                })
                                ?.map((data) =>
                                  data.consultation_type == 1 &&
                                  payerNonBPJS.length > 0 ? (
                                    <li key={data.consultation_type}>
                                      <input
                                        type="radio"
                                        className={
                                          data.availabilty == false
                                            ? 'fullbook'
                                            : 'hidden'
                                        }
                                        name="cb2"
                                        id={'cb2' + data.consultation_type}
                                        onClick={(e) =>
                                          getConsultationType(
                                            data.consultation_type
                                          )
                                        }
                                        value={data.consultation_type}
                                        onChange={handleRadioButton}
                                        checked={
                                          checkedRadioButton ==
                                          data.consultation_type
                                        }
                                      />
                                      <label
                                        htmlFor={'cb2' + data.consultation_type}
                                        className="mobile-radio-label"
                                      >
                                        {data.children[0].consultation_name}
                                      </label>
                                    </li>
                                  ) : data.consultation_type == 2 &&
                                    payerBPJS.length > 0 ? (
                                    <li key={data.consultation_type}>
                                      <input
                                        type="radio"
                                        className={
                                          data.availabilty == false
                                            ? 'fullbook'
                                            : 'hidden'
                                        }
                                        name="cb2"
                                        id={'cb2' + data.consultation_type}
                                        onClick={(e) =>
                                          getConsultationType(
                                            data.consultation_type
                                          )
                                        }
                                        value={data.consultation_type}
                                        onChange={handleRadioButton}
                                        checked={
                                          checkedRadioButton ==
                                          data.consultation_type
                                        }
                                      />
                                      <label
                                        htmlFor={'cb2' + data.consultation_type}
                                        className="mobile-radio-label"
                                      >
                                        {data.children[0].consultation_name}
                                      </label>
                                    </li>
                                  ) : data.consultation_type == 3 ? (
                                    <li key={data.consultation_type}>
                                      <input
                                        type="radio"
                                        className={
                                          data.availabilty == false
                                            ? 'fullbook'
                                            : 'hidden'
                                        }
                                        name="cb2"
                                        id={'cb2' + data.consultation_type}
                                        onClick={(e) =>
                                          getConsultationType(
                                            data.consultation_type
                                          )
                                        }
                                        value={data.consultation_type}
                                        onChange={handleRadioButton}
                                        checked={
                                          checkedRadioButton ==
                                          data.consultation_type
                                        }
                                      />
                                      <label
                                        htmlFor={'cb2' + data.consultation_type}
                                        className="mobile-radio-label"
                                      >
                                        {data.children[0].consultation_name}
                                      </label>
                                    </li>
                                  ) : (
                                    <></>
                                  )
                                )
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </div>
                      {timeSlotContainer && (
                        <div className="card-txt col-12 mt-3">
                          <div className="card-txt col-12 pt-3 pb-3 pl-0 ml-0">
                            <p className="pl-0 ml-0 mobile-title-card">
                              Pilih Waktu
                            </p>
                          </div>
                          <div className="time-slot">
                            <ul>
                              {doctorSchedule.doctorScheduleData[
                                scheduleSlotIndex
                              ]['slot'] ? (
                                doctorSchedule.doctorScheduleData[
                                  scheduleSlotIndex
                                ]['slot']
                                  ?.filter(
                                    (e) =>
                                      +e.consultation_type ===
                                      +checkedRadioButton
                                  )
                                  ?.map((data) => (
                                    <li key={data.time}>
                                      <input
                                        type="radio"
                                        className="hidden"
                                        name="ca2"
                                        id={data.time + '1'}
                                        value={data.time}
                                        onClick={(e) =>
                                          fillTimeSlot(e.target.value)
                                        }
                                        disabled={
                                          data.full ||
                                          validateDate(
                                            data?.time?.split('-')[1]
                                          )
                                        }
                                        checked={timeSlotData == data.time}
                                      />
                                      <label
                                        htmlFor={data.time + '1'}
                                        className="mobile-radio-label"
                                      >
                                        {data.time}
                                      </label>
                                    </li>
                                  ))
                              ) : (
                                <div className="card-txt col-12 pt-3 pb-3">
                                  <li key="notfound">
                                    <p>
                                      <strong>Jadwal Tidak Ditemukan</strong>
                                    </p>
                                  </li>
                                </div>
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {initScheduleSlotContainer && (
                    <div className="init-app-card d-flex justify-content-center align-items-center">
                      <div>
                        <p
                          style={{ fontSize: '14px' }}
                          className="text-center my-3"
                        >
                          Pilih tanggal yang tersedia terlebih dahulu untuk
                          memilih jam
                        </p>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <img
                            src="https://storage.aido.id/microservices/assets/clinic/time-wait.svg"
                            width="113"
                            height="113"
                            alt="Doctor"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
              <div className="col-12 mt-4 mb-3 pr-0 pl-0">
                <Button
                  label="Selanjutnya"
                  onClick={fillScheduleData}
                  styles={{ fontSize: '16px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileDoctorSchedule;
