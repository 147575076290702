import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import "./SelectOptions.style.scss";

export const animatedComponents = makeAnimated();

const SelectOptions = ({
  label = null,
  labelClassName = "",
  name,
  options,
  value,
  setValue,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  isMulti,
  placeholder,
  isShowWarning = false,
  background = "transparent",
  color = "#CCCCCC",
  fontSize = "md",
  borderColor = "#CCCCCC",
  customIcon = null,
  iconClassName = "",
  required = false,
}) => {
  const [style, setStyle] = useState();

  useEffect(() => {
    const textSize =
      fontSize === "sm"
        ? "10px"
        : fontSize === "md"
        ? "14px"
        : fontSize === "lg"
        ? "16px"
        : "20px";
    setStyle(
      !isShowWarning
        ? {
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                fontSize: textSize,
                color,
              };
            },
            dropdownIndicator: (base) => ({
              ...base,
              color,
            }),
            control: (base, state) => ({
              ...base,
              background,
              color,
              fontSize: textSize,
              fontWeight: "400",
              border: state.isFocused ? "1px solid #00bd84" : null,
              borderRadius: "50px",
              maxHeight: "auto",
              minHeight: "40px",
              paddingLeft: "7px",
              paddingRight: "7px",
              boxShadow: "none",
              "&:hover": {
                borderColor: "none",
              },
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                fontSize: textSize,
                lineHeight: "25px",
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? "#F5F7FA"
                  : isFocused
                  ? "#F5F7FA"
                  : undefined,
                color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
                fontWeight: isSelected ? 700 : null,
                cursor: isDisabled ? "not-allowed" : "default",
                ":active": {
                  ...styles[":active"],
                  backgroundColor: isSelected ? "#F5F7FA" : undefined,
                },
              };
            },
          }
        : {
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                fontSize: textSize,
                fontWeight: "400",
                color,
              };
            },
            dropdownIndicator: (base) => ({
              ...base,
              color,
            }),
            control: (base, state) => ({
              ...base,
              background,
              color,
              fontSize: textSize,
              fontWeight: "400",
              borderColor: "#ff0000",
              borderRadius: "50px",
              maxHeight: "auto",
              minHeight: "40px",
              paddingLeft: "7px",
              paddingRight: "7px",
              boxShadow: state.isFocused ? null : null,
              "&:hover": {
                borderColor: state.isFocused ? "#ff0000" : "#ff0000",
              },
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                fontSize: textSize,
                lineHeight: "25px",
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? "#F5F7FA"
                  : isFocused
                  ? "#F5F7FA"
                  : undefined,
                color: isDisabled ? "#ccc" : isSelected ? "#979797" : "black",
                fontWeight: isSelected ? 700 : null,
                cursor: isDisabled ? "not-allowed" : "default",
                ":active": {
                  ...styles[":active"],
                  backgroundColor: isSelected ? "#F5F7FA" : undefined,
                },
              };
            },
          }
    );
  }, [isShowWarning, fontSize, color]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={customIcon} alt="icon" className={iconClassName} />
      </components.DropdownIndicator>
    );
  };

  return (
    <React.Fragment>
      {label && (
        <p className={`${labelClassName} font-weight-bold label-input-select`}>
          {label} {required && <span className="text-danger">*</span>}
        </p>
      )}
      <Select
        className="basic-single"
        classNamePrefix="select"
        onChange={setValue}
        value={value || null}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        isMulti={isMulti}
        options={options}
        styles={style}
        placeholder={placeholder}
        components={
          customIcon && { DropdownIndicator, IndicatorSeparator: () => null }
        }
      />
      {isShowWarning && (
        <p className="warning-message-input mt-2">
          <i className="fa fa-warning" /> {label || "Kolom ini"} tidak boleh
          kosong!
        </p>
      )}
    </React.Fragment>
  );
};

export default SelectOptions;
