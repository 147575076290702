import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getDoctorAvailabilitiesByGroupHospital,
  getFaskes,
  getLovProcedureMCU,
  getProcedureMCU,
  getSpeciazationsAppointment,
} from '../../../actions';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCheckedDisbled,
  IconCheckedEnabled,
  IconDummyAvatar,
  IconFindDocuments,
  IconLoader,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import FormSearch from '../../../components/Input/FormSearch';
import SelectOptions from '../../../components/Input/SelectOptions';
import { consultationTypeCode } from '../../../constants/common';
import { wordingResultDoctorList } from '../../../constants/wording';
import { formatRupiah } from '../../../helpers/formatDigit';
import Layout from '../Components/Layout';
import Pagination from '../Components/Pagination';
import MobileListResultFindDoctor from './MobileListResultFindDoctor';
import MobileResultFindDoctor from './MobileResultFindDoctor';
import './ResultFindDoctor.scss';
moment.locale('id');

const ResultFindDoctor = (props) => {
  let PageSize = 3;
  const hospital_id = props.location.state[0].hospital_id[0];
  // const hospital_id = '495b64f5-2a34-490e-8801-1944d5424ad9';

  /* Router DOM */
  const history = useHistory();

  /* Redux */
  const dispatch = useDispatch();
  const { lovProcedures } = useSelector((state) => state.procedureMCU);

  /* Local State */
  const [loading, setLoading] = useState(false);
  const [searchClinic, setSeachClinic] = useState();
  const [searchSpecialization, setSeachSpecialization] = useState();
  const [searchGender, setSearchGender] = useState();
  const [searchConsultationType, setSearchConsultationType] = useState();
  const [searchDate, setSearchDate] = useState(new Date());
  const [faskes, setFaskes] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [consultationTypes, setConsultationTypes] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [limit, setLimit] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const hospitalGroupId = localStorage.getItem('klinikGrupId');
  const [search, setSearch] = useState({
    hospital_group_id:
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_group_id
        : '',
    hospital_id:
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_id
        : [],
    specialization_id:
      props.location?.state?.length > 0
        ? props.location.state[0].specialization_id
        : '',
    name_doctor:
      props.location?.state?.length > 0
        ? props.location.state[0].name_doctor
        : '',
    gender:
      props.location?.state?.length > 0 ? props.location.state[0].gender : '',
    consultation:
      props.location?.state?.length > 0
        ? props.location.state[0].consultation
        : '',
    serviceType:
      props.location?.state?.length > 0
        ? props.location.state[0].serviceType
        : '',
    titleList:
      props.location?.state?.length > 0
        ? props.location.state[0].titleList
        : '',

    limit: PageSize,
    limitScheduleItems: 6,
  });

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setData(doctor?.slice(firstPageIndex, lastPageIndex));
    return doctor?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  useEffect(() => {
    if (
      props.location?.location?.length > 0 &&
      props.location?.state[0].search_specialization_id
    ) {
      setSeachSpecialization(
        specializations &&
          specializations?.data
            ?.filter(
              (e) => e?.id === props.location?.state[0].search_specialization_id
            )
            .map((e) => {
              return { ...e, value: e?.id, label: e?.name };
            })
      );
    }
    if (props.location?.state?.length > 0 && props.location?.state[0].gender) {
      setSearchGender({
        value: props.location?.state[0].gender,
        label: props.location?.state[0].gender === '1' ? 'Male' : 'Female',
      });
    }
    if (
      props.location?.state?.length > 0 &&
      props.location?.state[0].search_consultation_type
    ) {
      setSearchConsultationType(
        consultationTypes &&
          consultationTypes?.data
            ?.filter(
              (item) =>
                +item?.consultation_type ===
                props.location?.state[0].search_consultation_type
            )
            ?.map((e) => {
              return {
                ...e,
                value: e?.consultation_type,
                label: e?.consultation_name,
              };
            })
      );
    }
  }, [props.location?.state, specializations, consultationTypes]);

  useEffect(() => {
    setSeachClinic(
      faskes.data &&
        faskes.data
          ?.filter((e) =>
            e?.id === props?.location?.state?.length > 0
              ? props?.location?.state[0]?.hospital_id[0]
              : ''
          )
          ?.map((e) => {
            return { ...e, value: e?.id, label: e?.name };
          })[0]
    );
  }, [props, faskes]);

  useEffect(() => {
    const payload = {
      hospital_id: props.location.state[0].hospital_id[0],
      // hospital_id: '495b64f5-2a34-490e-8801-1944d5424ad9',
      key: '',
    };
    dispatch(getSpeciazationsAppointment(limit)).then((response) => {
      setSpecializations(response);
    });
    dispatch(getFaskes(hospitalGroupId)).then((response) => {
      setFaskes(response);
    });
    fetchProcedureMCU({
      hospital_id,
      key: '',
    });
    dispatch(getLovProcedureMCU({ hospitalId: hospital_id }));
    getListDoctor();
  }, []);

  useEffect(() => {
    getListDoctor();
  }, [searchDate]);

  useEffect(() => {
    currentTableData = doctor?.slice(0, PageSize);
    setData(currentTableData);
  }, [doctor]);

  const fetchProcedureMCU = async (payload) => {
    await dispatch(getProcedureMCU(JSON.stringify(payload))).then(
      (response) => {
        setProcedures(response.data);
      }
    );
  };

  const longDate = useMemo(() => {
    const date = new Date(searchDate);
    const result = [];
    for (let i = 0; i < 6; i++) {
      const nextDate = new Date(date.setDate(date.getDate() + 1));
      result.push(nextDate);
    }
    return [new Date(searchDate), ...result];
  }, [searchDate]);

  const schedules = useMemo(() => {
    let bpjs = null;
    let reguler = null;
    let tele = null;
    let buttonActionType = 1;
    let bpjsChecked = 0;
    let regularChecked = 0;
    let teleChecked = 0;
    const tempData =
      data?.length &&
      data?.map((e) => {
        bpjs = e?.scheduleList?.tm_doctor_schedules_v2s?.filter(
          (item) => item?.consultation_type_id === consultationTypeCode.bpjs
        )[0];
        reguler = e?.scheduleList?.tm_doctor_schedules_v2s?.filter(
          (item) => item?.consultation_type_id === consultationTypeCode.reguler
        )[0];
        tele = e?.scheduleList?.tm_doctor_schedules_v2s?.filter(
          (item) => item?.consultation_type_id === consultationTypeCode.tele
        )[0];

        const slotBpjs = longDate.map((date) => {
          if (!bpjs?.tx_schedule_detail_v2s?.length) return null;
          const day = new Date(date).getDay() || 7;
          const tempFilter = bpjs?.tm_doctor_schedule_items?.filter(
            (tdri) => +tdri?.day === day
          );
          if (tempFilter?.length)
            return {
              ...tempFilter[0],
              dateView: date,
              scheduleDetails: bpjs?.tx_schedule_detail_v2s,
            };
          return null;
        });
        const slotReguler = longDate.map((date) => {
          if (!reguler?.tx_schedule_detail_v2s?.length) return null;
          const day = new Date(date).getDay() || 7;
          const tempFilter = reguler?.tm_doctor_schedule_items?.filter(
            (tdri) => +tdri?.day === day
          );
          if (tempFilter?.length)
            return {
              ...tempFilter[0],
              dateView: date,
              scheduleDetails: reguler?.tx_schedule_detail_v2s,
            };
          return null;
        });
        const slotTele = longDate.map((date) => {
          if (!tele?.tx_schedule_detail_v2s?.length) return null;
          const day = new Date(date).getDay() || 7;
          const tempFilter = tele?.tm_doctor_schedule_items?.filter(
            (tdri) => +tdri?.day === day
          );
          if (tempFilter?.length)
            return {
              ...tempFilter[0],
              dateView: date,
              scheduleDetails: tele?.tx_schedule_detail_v2s,
            };
          return null;
        });
        const bpjsFlag = slotBpjs?.filter((e) => e);
        const regulerFlag = slotReguler?.filter((e) => e);
        const teleFlag = slotTele?.filter((e) => e);

        if (search.serviceType !== 'TL') {
          if (!bpjsFlag?.length && !regulerFlag?.length) {
            buttonActionType = 2;
          } else {
            buttonActionType =
              bpjsFlag[0]?.quota_reservat ||
              regulerFlag[0]?.quota_reservat ||
              teleFlag[0]?.quota_reservat
                ? 1
                : 2;
          }
        } else {
          if (!teleFlag?.length) {
            buttonActionType = 2;
          } else {
            buttonActionType =
              bpjsFlag[0]?.quota_reservat ||
              regulerFlag[0]?.quota_reservat ||
              teleFlag[0]?.quota_reservat
                ? 1
                : 2;
          }
        }

        if (bpjsFlag?.length) bpjsChecked = 1;
        if (regulerFlag?.length) regularChecked = 1;
        if (teleFlag?.length) teleChecked = 1;

        return {
          buttonActionType,
          bpjsChecked,
          regularChecked,
          teleChecked,
          doctorHospitalId: e?.scheduleList?.id,
          serviceFee: e?.scheduleList?.serviceFee,
          doctorId: e?.scheduleList?.doctor_id,
          doctorName: e?.scheduleList?.tm_doctor?.name,
          doctorImageUrl: e?.scheduleList?.tm_doctor?.image_url,
          specializationId: e?.scheduleList?.tm_specialization?.id,
          specializationName: e?.scheduleList?.tm_specialization?.name,
          poliId: e?.scheduleList?.tm_poli?.id,
          hospitalId: e?.scheduleList?.hospital_id,
          hospitalName: e?.scheduleList?.tm_hospital?.name,
          hospitalImageUrl: e?.scheduleList?.tm_hospital?.image_url,
          hospitalType: e?.scheduleList?.tm_hospital?.hospital_type,
          hospitalTimezone: e?.scheduleList?.tm_hospital?.timezone,
          scheduleType:
            e?.scheduleList?.tm_doctor_schedules_v2?.tr_schedule_type,
          consultationType:
            e?.scheduleList?.tm_doctor_schedules_v2?.tr_consultation_type,
          slotBpjs,
          slotReguler,
          slotTele,
        };
      });

    /* Temporary comment -> for hide list suitable to service type */
    // if (tempData?.length) {
    //   return tempData.filter((e) => {
    //     if (search.serviceType === 'TL') {
    //       const slot = e?.slotTele?.filter((slt) => slt);
    //       if (slot?.length) {
    //         return e;
    //       }
    //     } else {
    //       const slotBpjs = e?.slotBpjs?.filter((slt) => slt);
    //       const slotReg = e?.slotReguler?.filter((slt) => slt);
    //       if (slotBpjs?.length && slotReg?.length) {
    //         return e;
    //       }
    //     }
    //   });
    // }
    return tempData;
  }, [searchDate, data, longDate]);

  const getListDoctor = (payload) => {
    setLoading(true);
    dispatch(
      getDoctorAvailabilitiesByGroupHospital(
        JSON.stringify(
          payload
            ? {
                ...payload,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
            : {
                ...search,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
        )
      )
    ).then((result) => {
      setDoctor(result.data);
      setLoading(false);
    });
  };

  const getListProcedures = (payload) => {
    setLoading(true);
    dispatch(
      getProcedureMCU(
        JSON.stringify(
          payload
            ? {
                ...payload,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
            : {
                ...search,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
        )
      )
    ).then((result) => {
      setDoctor(result.data);
      setLoading(false);
    });
  };

  const changeFaskes = (event) => {
    let temp = [];
    temp.push(event.target.value);
    if (event.target.value == '') {
      temp = [];
    }
    let newSearch = search;
    newSearch['hospital_id'] = temp;
    newSearch['name_doctor'] = '';
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeSpecialization = (event) => {
    let newSearch = search;
    newSearch['specialization_id'] = event?.target?.value || null;
    newSearch['name_doctor'] = '';
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeGender = (event) => {
    let newSearch = search;
    newSearch['gender'] = event?.target?.value || null;
    newSearch['name_doctor'] = '';
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeConsultation = (event) => {
    let newSearch = search;
    newSearch['consultation'] = event?.target?.value || null;
    newSearch['name_doctor'] = '';
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeMCUProcedures = (key) => fetchProcedureMCU({ hospital_id, key });

  const handleDoctorSchedule = (
    doctorId,
    hospitalId,
    doctorName,
    doctorImageUrl,
    hospitalName,
    hospitalImageUrl,
    hospitalType,
    timezone,
    specializationName,
    doctorHospitalId,
    specializationId,
    poliId,
    serviceFee,
    isPrepaid = false,
    path = '/doctor/schedule',
    mcuProceduresId
  ) => {
    let params = {};
    params['hospital_group_id'] = props?.location?.state[0]?.hospital_group_id;
    params['name_doctor'] = props?.location?.state[0]?.name_doctor;
    params['search_id_hospital'] =
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_id
        : [];
    params['search_specialization_id'] = search?.specialization_id || null;
    params['search_gender'] = search?.gender || null;
    params['search_consultation_type'] = search?.consultation || null;
    params['hospital_id'] = hospitalId;
    params['doctor_id'] = doctorId;
    params['doctor_hospital_id'] = doctorHospitalId;
    params['doctor_name'] = doctorName;
    params['doctor_imageurl'] = doctorImageUrl;
    params['hospital_name'] = hospitalName;
    params['hospital_image_url'] = hospitalImageUrl;
    params['hospital_type'] = hospitalType;
    params['timezone'] = timezone;
    params['specialization_id'] = specializationId;
    params['specialization_name'] = specializationName;
    params['poli_id'] = poliId;
    params['serviceFee'] = serviceFee;
    params['serviceType'] =
      props.location.state?.length > 0
        ? props.location.state[0].serviceType
        : '';
    params['isPrepaid'] = isPrepaid;
    params['mcuProceduresId'] = mcuProceduresId;
    history.push(path, [params]);
  };

  const [dataHospital, setDataHospital] = useState([]);

  useEffect(() => {
    let options = [];
    if (faskes && faskes.data && faskes.data.length > 0) {
      options.push({
        value: '',
        label: 'Pilih Klinik',
      });
      faskes.data.forEach((data) =>
        options.push({
          value: data.id,
          label: data.name,
        })
      );
      setDataHospital(options);
    }
  }, [faskes]);

  const renderContent = () => {
    switch (search.serviceType) {
      case 'JT':
        return renderConsultation();
      case 'TL':
        return renderConsultation();
      case 'PM':
        return renderMedicalCheckUp();
      default:
        return renderConsultation();
    }
  };

  const renderConsultation = () => {
    return (
      <>
        <div className="title d-xl-block d-none">
          <h1 className="text-center">{search.titleList}</h1>
        </div>
        <div className="col-md-12 d-xl-block d-none">
          <div className="search">
            <div
              className="row"
              style={{
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              {localStorage.getItem('klinikId') === null ||
                (localStorage.getItem('klinikId') === 'null' && (
                  <div className="col start-grid">
                    <SelectOptions
                      borderColor="white"
                      placeholder="Ketik/Pilih Klinik"
                      fontSize="md"
                      options={dataHospital}
                      setValue={(value) => {
                        const formatPayload = {
                          ...value,
                          target: value,
                        };
                        setSeachClinic(value);
                        changeFaskes(formatPayload);
                      }}
                      value={searchClinic}
                      color="#757575"
                      background="white"
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </div>
                ))}
              <div className="col">
                <SelectOptions
                  borderColor="white"
                  placeholder="Ketik/Pilih Spesialis"
                  fontSize="md"
                  isClearable
                  options={
                    specializations &&
                    specializations?.data?.map((e) => {
                      return { ...e, value: e?.id, label: e?.name };
                    })
                  }
                  setValue={(value) => {
                    const formatPayload = {
                      ...value,
                      target: value,
                    };
                    setSeachSpecialization(value);
                    changeSpecialization(formatPayload);
                  }}
                  value={searchSpecialization}
                  color="#757575"
                  background="white"
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />
              </div>
              {/* <div className="col">
                <InputDate
                  placeholder="Tanggal"
                  iconPosition="right"
                  icon={IconCalenderGrey}
                  showMonthDropdown
                  showYearDropdown
                  selectedDate={searchDate}
                  setDate={setSearchDate}
                  format="dd MMMM yyyy"
                  minDate={new Date()}
                />
              </div> */}
              <div className="col">
                <div className="select-option">
                  <SelectOptions
                    borderColor="white"
                    placeholder="Ketik/Pilih Gender"
                    fontSize="md"
                    options={[
                      {
                        value: '1',
                        label: 'Male',
                      },
                      {
                        value: '2',
                        label: 'Female',
                      },
                    ]}
                    setValue={(value) => {
                      const formatPayload = {
                        ...value,
                        target: value,
                      };
                      setSearchGender(value);
                      changeGender(formatPayload);
                    }}
                    value={searchGender}
                    color="#757575"
                    isClearable
                    background="white"
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </div>
              </div>
              {search?.serviceType !== 'TL' && (
                <div className="col end-grid">
                  <div className="select-option">
                    <SelectOptions
                      borderColor="white"
                      placeholder="Pilih Tipe Konsultasi"
                      isClearable
                      fontSize="md"
                      options={
                        consultationTypes &&
                        consultationTypes?.data
                          ?.filter(
                            (e) =>
                              e?.consultation_type !== 3 &&
                              search?.serviceType === 'JT'
                          )
                          ?.map((e) => {
                            return {
                              ...e,
                              value: e?.consultation_type,
                              label: e?.consultation_name,
                            };
                          })
                      }
                      setValue={(value) => {
                        const formatPayload = {
                          ...value,
                          target: value,
                        };
                        setSearchConsultationType(value);
                        changeConsultation(formatPayload);
                      }}
                      value={searchConsultationType}
                      color="#757575"
                      background="white"
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 d-xl-block d-none mt-4">
          {loading ? (
            <img
              src={IconLoader}
              alt="loader"
              className="mt-5 d-flex mx-auto"
            />
          ) : (
            <div className="list-doctor" style={{ marginBottom: '12em' }}>
              {schedules?.length ? (
                schedules?.map((item, index) => (
                  <div className="card-detail-doctor" key={index}>
                    <Card>
                      <Card.Header>
                        <div className="col-md-12 header-card">
                          <Row>
                            <div className="col-md-1">
                              <div className="photo-doctor">
                                <img
                                  src={IconDummyAvatar}
                                  width="64"
                                  height="64"
                                  alt="Doctor"
                                />
                              </div>
                            </div>
                            <div className="col-md-11">
                              <div className="row">
                                <div className="col">
                                  <div className="profile-doctor">
                                    <div
                                      className="name-doctor"
                                      width="100"
                                      role="label"
                                    >
                                      {item?.doctorName || '-'}{' '}
                                    </div>
                                    <div style={{ width: '200px' }}>
                                      <Button
                                        styles={{
                                          fontSize: '16px',
                                          height: '30px',
                                        }}
                                        type="outline-primary"
                                        label="Lihat Profile"
                                        icon={IconArrowGreen}
                                        iconClassName="rotate-180"
                                        iconPosition="right"
                                        onClick={() =>
                                          handleDoctorSchedule(
                                            item?.doctorId,
                                            item?.hospitalId,
                                            item?.doctorName,
                                            item?.doctorImageUrl,
                                            item?.hospitalName,
                                            item?.hospitalImageUrl,
                                            item?.hospitalType,
                                            item?.hospitalTimezone,
                                            item?.specializationName,
                                            item?.doctorHospitalId,
                                            item?.specializationId,
                                            item?.poliId,
                                            item?.serviceFee,
                                            '/doctor-detail'
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="profile-doctor">
                                    <div
                                      className=" font-weight-bold mt-1"
                                      width="100"
                                    >
                                      {item?.specializationName || '-'}
                                    </div>
                                    {search.serviceType === 'TL' ? (
                                      <div className="location-doctor">
                                        Rp {formatRupiah(item?.serviceFee)}
                                      </div>
                                    ) : (
                                      <div className="location-doctor">
                                        {item?.hospitalName}
                                      </div>
                                    )}
                                    {search.serviceType !== 'TL' ? (
                                      <div className="row">
                                        <div className="col-4">
                                          {item?.bpjsChecked === 1 ? (
                                            <div className="d-flex align-items-center consultation-type ">
                                              <img
                                                src={IconCheckedEnabled}
                                                width="20"
                                                height="20"
                                                alt="Doctor"
                                              />
                                              <span className="enabled">
                                                {' '}
                                                BPJS
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="d-flex align-items-center consultation-type ">
                                              <img
                                                src={IconCheckedDisbled}
                                                width="20"
                                                height="20"
                                                alt="Doctor"
                                              />
                                              <span className="disabled">
                                                BPJS
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-4">
                                          {item?.regularChecked === 1 ? (
                                            <div className="d-flex align-items-center consultation-type ">
                                              <img
                                                src={IconCheckedEnabled}
                                                width="20"
                                                height="20"
                                                alt="Doctor"
                                              />
                                              <span className="enabled">
                                                {' '}
                                                Reguler
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="d-flex align-items-center consultation-type ">
                                              <img
                                                src={IconCheckedDisbled}
                                                width="20"
                                                height="20"
                                                alt="Doctor"
                                              />
                                              <span className="disabled">
                                                Reguler
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="mt-2">
                                        {item?.teleChecked ? (
                                          <div className="d-flex align-items-center consultation-type">
                                            <img
                                              src={IconCheckedEnabled}
                                              width="20"
                                              height="20"
                                              alt="Doctor"
                                            />
                                            <span className="enabled">
                                              Telekonsultasi
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex align-items-center consultation-type">
                                            <img
                                              src={IconCheckedDisbled}
                                              width="20"
                                              height="20"
                                              alt="Doctor"
                                            />
                                            <span className="disabled">
                                              Telekonsultasi
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="align-self-center col">
                                  {item?.buttonActionType === 1 ? (
                                    <Button
                                      type="primary"
                                      label={
                                        search.serviceType === 'TL'
                                          ? wordingResultDoctorList.labelAddTele
                                          : wordingResultDoctorList.labelAddReguler
                                      }
                                      onClick={() => {
                                        handleDoctorSchedule(
                                          item?.doctorId,
                                          item?.hospitalId,
                                          item?.doctorName,
                                          item?.doctorImageUrl,
                                          item?.hospitalName,
                                          item?.hospitalImageUrl,
                                          item?.hospitalType,
                                          item?.hospitalTimezone,
                                          item?.specializationName,
                                          item?.doctorHospitalId,
                                          item?.specializationId,
                                          item?.poliId,
                                          item?.serviceFee
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Button
                                      type="primary-blue"
                                      label="Hubungi Klinik"
                                      onClick={() =>
                                        window.open(
                                          'https://wa.me/628118481436'
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Card.Header>
                    </Card>
                  </div>
                ))
              ) : (
                <div
                  className="text-center mt-5"
                  style={{ marginBottom: '14em' }}
                >
                  <img src={IconFindDocuments} alt="icon" />
                  <p
                    className="text-white mt-3 text-center"
                    style={{ fontSize: '18px' }}
                  >
                    Dokter tidak ditemukan
                  </p>
                </div>
              )}
              {doctor ? (
                <Pagination
                  className="pagination-bar d-flex justify-content-center"
                  currentPage={currentPage}
                  totalCount={doctor.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderMedicalCheckUp = () => {
    return (
      <>
        <div className="title d-xl-block d-none">
          <h1 className="text-center">{search.titleList}</h1>
        </div>
        <div className="col-md-12 d-xl-block d-none">
          <div className="search">
            <FormSearch
              setValue={changeMCUProcedures}
              options={
                lovProcedures &&
                lovProcedures?.map((e) => {
                  return { ...e, id: e?.name, label: e?.name };
                })
              }
            />
          </div>
        </div>
        <div className="col-md-12 d-xl-block d-none mt-4">
          {loading ? (
            <img
              src={IconLoader}
              alt="loader"
              className="mt-5 d-flex mx-auto"
            />
          ) : (
            <div className="list-doctor" style={{ marginBottom: '12em' }}>
              <>
                <Row>
                  {procedures?.length ? (
                    procedures?.map((item, index) => (
                      <Col sm={12} xs={12} md={6} lg={4} xl={4}>
                        <div className="card-detail-doctor" key={index}>
                          <Card>
                            <div className="col-md-12 header-card">
                              <Row>
                                <div className="col-12">
                                  <div className="photo-procedures d-flex justify-content-center align-items-center">
                                    <img
                                      src={item?.imageUrl}
                                      width="64"
                                      height="64"
                                      alt="Doctor"
                                      className="card"
                                    />
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                      <div className="profile-doctor">
                                        <div
                                          className="name-procedures"
                                          width="100"
                                          role="label"
                                        >
                                          {item?.name || '-'}{' '}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                      <div className="profile-doctor">
                                        <div className="location-doctor">
                                          Rp {formatRupiah(item?.price)}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="align-self-center col-12 justify-content-center mt-3">
                                      {/* {console.log(
                                        props.location.state[0],
                                        ' props.location.state[0] >>><<'
                                      )} */}
                                      <Button
                                        type="primary"
                                        label="Pilih Paket"
                                        onClick={() => {
                                          handleDoctorSchedule(
                                            item?.clinicProcedureUuid,
                                            props.location.state[0]
                                              .hospital_id[0] || hospital_id,
                                            item?.name,
                                            item?.imageUrl,
                                            item?.hospitalName,
                                            '',
                                            '',
                                            '',
                                            '',
                                            '',
                                            '',
                                            '',
                                            item?.price,
                                            item?.isPrepaid,
                                            undefined,
                                            [
                                              {
                                                id: item.clinicProcedureId,
                                                price: item.price,
                                              },
                                            ]
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div
                      className="text-center mt-5 mx-auto"
                      style={{ marginBottom: '14em' }}
                    >
                      <img src={IconFindDocuments} alt="icon" />
                      <p
                        className="text-white mt-3 text-center"
                        style={{ fontSize: '18px' }}
                      >
                        Layanan tidak ditemukan
                      </p>
                    </div>
                  )}
                </Row>
              </>
              {doctor ? (
                <Pagination
                  className="pagination-bar d-flex justify-content-center"
                  currentPage={currentPage}
                  totalCount={procedures.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Layout>
      <div className="content-result-find-doctor">
        <MobileResultFindDoctor
          filter={search}
          setFaskes={changeFaskes}
          setSpecialization={changeSpecialization}
          setGender={changeGender}
          setConsultation={changeConsultation}
          setSearchDate={setSearchDate}
          serviceType={search.serviceType}
          titleList={search.titleList}
          faskes={
            faskes &&
            faskes?.data?.map((e) => {
              return {
                value: e?.id,
                label: e?.name,
              };
            })
          }
          specializations={
            specializations &&
            specializations?.data?.map((e) => {
              return {
                value: e?.id,
                label: e?.name,
              };
            })
          }
          genders={[
            {
              value: 'Male',
              label: 'Male',
            },
            {
              value: 'Female',
              label: 'Female',
            },
          ]}
          consultations={
            consultationTypes &&
            consultationTypes?.data
              ?.filter(
                (e) =>
                  e?.consultation_type !== 3 && search?.serviceType === 'JT'
              )
              ?.map((e) => {
                return {
                  value: e?.consultation_type,
                  label: e?.consultation_name,
                };
              })
          }
        />

        {/* Mobile View */}
        <MobileListResultFindDoctor
          data={schedules}
          setSchedule={handleDoctorSchedule}
          longDate={longDate}
          serviceType={search.serviceType}
          doctor={doctor}
          currentPage={currentPage}
          PageSize={PageSize}
          setCurrentPage={setCurrentPage}
          loading={loading}
        />
        {renderContent()}
      </div>
    </Layout>
  );
};

export default ResultFindDoctor;
