import React from "react";
import { render } from "react-dom";
import "./index.css";
import Routes from "./Routes";
import "./assets/main.scss";
import "./assets/table.scss";
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import "./helpers/translator";
window.store = store;

render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.register();
