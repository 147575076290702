import axios from '../helpers/axios';
import { axiosGet } from "../helpers/axiosCancel";
import { findDoctorConstants } from './constants';

export const getFaskes = (page) => {
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_FASKES_REQUEST });
    const res = await axios.get(
      `/hospitals/list-hospitalAndGroup?hospitalGroupId=${page}`
    );
    if (res.status === 200) {
      dispatch({
        type: findDoctorConstants.GET_FASKES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: findDoctorConstants.GET_FASKES_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getSpeciazations = (page) => {
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_SPECIALIZATIONS_REQUEST });
    const res = await axios.get(`/specializations/list`);
    if (res.status === 200) {
      dispatch({
        type: findDoctorConstants.GET_SPECIALIZATIONS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: findDoctorConstants.GET_SPECIALIZATIONS_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getSpeciazationsAppointment = (page) => {
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_SPECIALIZATIONS_REQUEST });
    const res = await axios.get(`/specializations/list/appointment`);
    if (res.status === 200) {
      dispatch({
        type: findDoctorConstants.GET_SPECIALIZATIONS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: findDoctorConstants.GET_SPECIALIZATIONS_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getConsultationTypes = (page) => {
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_CONSULTATIONTYPES_REQUEST });
    const res = await axios.get(`/generals/consultation-types`);
    if (res.status === 200) {
      dispatch({
        type: findDoctorConstants.GET_CONSULTATIONTYPES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: findDoctorConstants.GET_CONSULTATIONTYPES_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getConsultationTypesAppointment = (page) => {
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_CONSULTATIONTYPES_REQUEST });
    const res = await axios.get(`/generals/consultation-types/appointment`);
    if (res.status === 200) {
      dispatch({
        type: findDoctorConstants.GET_CONSULTATIONTYPES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: findDoctorConstants.GET_CONSULTATIONTYPES_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getDoctorAvailabilities = (form) => {
  const filter = form ? JSON.parse(form) : null;
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_DOCTORAVAILABILITIES_REQUEST });
    const res = await axios.get(
      `/doctors-hospitals/list/schedule/appointment/${
        filter?.hospital_id[0] || ''
      }?limit=${filter?.limitScheduleItems || 7}${
        filter?.schedule_date
          ? `&schedule_date=${`${new Date(
              filter?.schedule_date
            ).getFullYear()}-${
              new Date(filter?.schedule_date).getMonth() + 1
            }-${new Date(filter?.schedule_date).getDate()}`}`
          : ''
      }${filter?.gender ? `&gender=${filter?.gender}` : ''}${
        filter?.consultation ? `&consultation_type=${filter?.consultation}` : ''
      }${filter?.specialization_id ? `&specializations_id=${filter?.specialization_id}` : ''}${filter?.name_doctor ? `&name_doctor=${filter?.name_doctor}` : ''}${filter?.doctor_id ? `&doctor_id=${filter?.doctor_id}` : ''}${
        filter?.hospital_group_id
          ? `&hospital_group_id=${filter?.hospital_group_id}`
          : ''
      }`
    );
    if (res.status === 200) {
      dispatch({
        type: findDoctorConstants.GET_DOCTORAVAILABILITIES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: findDoctorConstants.GET_DOCTORAVAILABILITIES_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getDoctorAvailabilitiesByGroupHospital = (form) => {
  const filter = form ? JSON.parse(form) : null;
  return async (dispatch) => {
    dispatch({ type: findDoctorConstants.GET_DOCTORAVAILABILITIES_REQUEST });
    const url = `/doctors-hospitals/list/schedule/appointment/group/${
      filter?.hospital_group_id || ''
    }?limit=${filter?.limitScheduleItems || 7}&schedule_date=${
      filter?.schedule_date
    }${filter?.gender ? `&gender=${filter?.gender}` : ''}${
      filter?.consultation ? `&consultation_type=${filter?.consultation}` : ''
    }${
      filter?.specialization_id
        ? `&specializations_id=${filter?.specialization_id}`
        : ''
    }${filter?.name_doctor ? `&name_doctor=${filter?.name_doctor}` : ''}${
      filter?.hospital_id[0] ? `&hospital_id=${filter?.hospital_id[0]}` : ''
    }`
    const res = await axiosGet(axios, url);
    if (res) {
      if (res.status === 200) {
        dispatch({
          type: findDoctorConstants.GET_DOCTORAVAILABILITIES_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: findDoctorConstants.GET_DOCTORAVAILABILITIES_FAILURE,
          payload: { error: res.data.error },
        });
        return res.data;
      }
    }
  };
};
