import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import { getAllNews } from "../../../actions";
import queryString from "query-string";

const News = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [newsData, setNewsData] = useState([])

  const hospitalGroupId = localStorage.getItem("hospitalGrupId");

  const news = useSelector((state) => state.articles.newsData);

  const [pageNumber, setPageNumber] = useState(1);
  const [getData, setGetData] = useState(false);
 

  useEffect(() => {
    if (getData) {
      if(news?.list?.length > 0){
        let data = []
        news.list.map((dataItem) => {
          let checkData = newsData.filter((item) => item.id === dataItem.id)
          if(checkData?.length === 0) {
            var item = {
              id : dataItem.id,
              date : dataItem.createdDate,
              title : dataItem.title,
              imageUrl : dataItem.thumbnail,
              description : dataItem?.content?  dataItem?.content.replace(/<img[^>]*>/g,"").replace(/(<([^>]+)>)/ig,""):"",
              slug: dataItem.slug,
              
            }
          
            data.push(item)
          }
        })
        setNewsData((prevData) => [...prevData, ...data])
      }
    }
  },[news])

  useEffect(() => {
    if(pageNumber) {
      let params = {
        klinik_group_id : hospitalGroupId,
        is_published : true,
        active: '1',
        type: 'news',
        page: pageNumber,
        pageSize: 20,
        sort: JSON.stringify([{"created_date": "DESC"}])
      }
      setGetData(true)
      dispatch(getAllNews(queryString.stringify(params)));
    }
  },[pageNumber])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

    const remainingScroll = scrollHeight - (scrollTop + clientHeight);

    if (remainingScroll < 1 ) {
      
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col xs={12}>
          <span className="font-title-24-16 font-w-700">
            Berita & Informasi
          </span>
        </Col>
      </Row>
      <Row className="margin-b-16">
        {newsData.map((data, index) => (
          <Col xs={12} lg={6} key={index}>
            <Row
              className="margin-b-16 cursor-point"
              onClick={() => history.push(`/news/${data.slug}`)}
            >
              <Col xs={4} lg={3}>
                <img src={data.imageUrl} className="home-article-image" />
              </Col>

              <Col xs={8} lg={9}>
                <div className="d-flex flex-column">
                  <span className="font-s-14 font-w-500 cl-green">
                    {data.date}
                  </span>

                  <span className="font-s-16 font-w-700 margin-b-8">
                    {data.title}
                  </span>

                  <span className="home-article-desc font-s-14 font-w-500"
                  dangerouslySetInnerHTML={{
                    __html: data.description
                  }}
                  >
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </LayoutV2>
  );
};

export default News;
