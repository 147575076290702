import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconArrowWhite } from '../../../../../assets/img/icon';
import Button from '../../../../../components/Button';

const MobileHeader = ({ setShowModal, logo = null }) => {
  /* Router DOM */
  const history = useHistory();
  const location = useLocation();

  return (
    <React.Fragment>
      <div className="container-nav-mobile">
        <div className="d-flex justify-content-between pt-navbar">
          <img
            data-cfsrc={
              logo || 'https://storage.aido.id/microservices/logo-header.svg'
            }
            src={
              logo || 'https://storage.aido.id/microservices/logo-header.svg'
            }
            alt="logo"
            style={{ height: '50px', width: '165px' }}
            className="align-top mt-2"
            onClick={() => history.push('/home')}
          />
          <div className="px-0">
            {localStorage.accessToken ? (
              <div className="text-right mt-3">
                {localStorage?.firstName == 'null' &&
                location.pathname !== '/account-profile' ? (
                  <Button
                    label="Lengkapi Data"
                    icon={IconArrowWhite}
                    type="primary"
                    iconPosition="right"
                    className="pr-2"
                    onClick={() => history.push('/account-profile')}
                  />
                ) : (
                  <div className="avatar">
                    <button
                      className="circle-image-avatar"
                      onClick={() => {
                        window.location = '/account-profile';
                      }}
                    >
                      <img
                        src={
                          localStorage.avatar != 'null'
                            ? localStorage.avatar
                            : 'https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png'
                        }
                        width="40"
                        height="40"
                        className="image-avatar"
                        alt={
                          localStorage.firstName != 'null'
                            ? localStorage.firstName
                            : 'Halo'
                        }
                      />
                    </button>{' '}
                    <span className="username">
                      Halo{' '}
                      {localStorage.firstName != 'null'
                        ? localStorage.firstName
                        : ' '}
                      !
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-2">
                <Button
                  label="Masuk"
                  iconSize="sm"
                  btnSize="sm"
                  disable={false}
                  type="primary"
                  onClick={setShowModal}
                  className="my-0 px-3"
                  styles={{ fontSize: '14px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileHeader;
