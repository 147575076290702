import { authConstants } from "../actions/constants";

const initState = {
  loginData: [],
  headerData:[],
  otpVal: null,
  authenticate: false,
  authenticating: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = {
        ...state,
        authenticate: false,
        authenticating: true,
      };
      break;
    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        otpVal: action.payload.data,
        authenticate: true,
        authenticating: false,
      };
      break;
    case authConstants.LOGIN_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        authenticate: false,
      };
      break;
    case authConstants.LOGOUT_SUCCESS:
      state = {
        ...initState,
      };
      break;
    case authConstants.OTP_VALIDATE_REQUEST:
      state = {
        ...state,
        authenticate: false,
        authenticating: true,
      };
      break;
    case authConstants.OTP_VALIDATE_SUCCESS:
      state = {
        ...state,
        loginData: action.payload.data,
        authenticate: true,
        authenticating: false,
      };
      break;
    case authConstants.OTP_VALIDATE_FAILURE:
      state = {
        ...state,
        authenticate: false,
        error: action.payload.error,
      };
      break;
      case authConstants.HEADER_VALIDATE_SUCCESS:
        state = {
          ...state,
          headerData: action.payload.data,
          authenticate: false,
          error: action.payload.error,
        };
        break;
      case authConstants.HEADER_VALIDATE_FAILURE:
        state = {
          ...state,
          authenticate: false,
          error: action.payload.error,
        };
        break;
  }
  return state;
};
