import { packageConstants } from "../actions/package/constants";

const initState = {
  packageData: [],
  detailPackageData: null,
  packageTotalData: 0,
  packageLoading: false,
  detailPackageLoading: false,
  packageError: null,
  detailPackageError: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case packageConstants.GET_PACKAGE_REQUEST:
      state = {
        ...state,
        packageLoading: true,
      };
      break;
    case packageConstants.GET_PACKAGE_SUCCESS:
      state = {
        ...state,
        packageData: action.payload.data?.data,
        packageTotalData: parseInt(action.payload.data?.totalData),
        packageLoading: false,
      };
      break;
    case packageConstants.GET_PACKAGE_FAILURE:
      state = {
        ...state,
        packageError: action.payload.error,
        packageLoading: false,
      };
      break;
    case packageConstants.GET_DETAIL_PACKAGE_REQUEST:
      state = {
        ...state,
        detailPackageLoading: true,
      };
      break;
    case packageConstants.GET_DETAIL_PACKAGE_SUCCESS:
      state = {
        ...state,
        detailPackageData: action.payload.data?.data,
        detailPackageLoading: false,
      };
      break;
    case packageConstants.GET_DETAIL_PACKAGE_FAILURE:
      state = {
        ...state,
        detailPackageError: action.payload.error,
        detailPackageLoading: false,
      };
      break;
    default:
      return state;
  }
  return state;
};
