import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { IconArrowBackBlack, IconIndoFlag } from '../../../assets/img/icon';

const ModalLogin = ({
  isShow,
  closeModal,
  InputForm,
  setInputForm,
  requestOtp,
  setOtp,
  TimerOnGoing,
  rerequestOtp,
  validateOtp,
  phone,
  setPhone,
  OtpForm,
  setOtpForm,
  SpanPhone,
  status,
  message,
  timer,
  remainingTime,
}) => {
  return (
    <React.Fragment>
      <Modal
        dialogClassName="modal-dialogs"
        show={isShow}
        centered
        onHide={closeModal}
        size="lg"
      >
        <Modal.Body className="modal-container" centered>
          {InputForm && (
            <div>
              <h1 className="mb-3">Selamat Datang</h1>
              <p className="mb-3">
                <span className="information-phone">
                  Masukan dengan Nomor Telepon anda,{' '}
                </span>
                untuk mendapatkan pengalaman layanan terbaik dari kami
              </p>
              {status == 400 && remainingTime !== '00:00' && (
                <>
                  <p className="mb-3" style={{ color: 'red' }}>
                    {message} {remainingTime}
                  </p>
                </>
              )}
              <div className="d-flex align-items-center justify-content-center">
                <div className="input-countrycode mt-lg-0 mt-2">
                  <img
                    src={IconIndoFlag}
                    width="32"
                    height="32"
                    className="img-flag"
                    alt="Flag"
                  />
                  <p className="d-flex my-auto">+62</p>
                </div>
                <div className="input-icons d-flex my-auto">
                  <input
                    type="text"
                    value={phone}
                    className="input-phoneNumber"
                    placeholder="8123456789"
                    onChange={(e) => setPhone(e.target.value)}
                    pattern="[0-9]*"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        requestOtp();
                      }
                    }}
                  />
                  <img
                    onClick={requestOtp}
                    src="https://storage.aido.id/microservices/icon/arrow-circle.svg"
                    width="24"
                    height="24"
                    className="buttonsubs"
                    alt=""
                  />
                </div>
              </div>
              <p className="mb-3 mt-4 mt-lg-3">
                Kami akan mengirimkan kode verifikasi ke nomor ini. Pastikan
                nomor anda aktif dan dapat dihubungi.
              </p>
              <label className="information-tnc">
                Saya menyetujui
                <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  ketentuan pengguna janji temu{' '}
                </a>
                dan
                <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  kebijakan privasi.
                </a>
              </label>
            </div>
          )}
          {OtpForm && status == 400 && (
            <div>
              <h1 className="mb-3">Selamat Datang</h1>
              <p className="mb-3">
                <span className="information-phone">
                  Masukan dengan Nomor Telepon anda,{' '}
                </span>
                untuk mendapatkan pengalaman layanan terbaik dari kami
              </p>
              {status == 400 && remainingTime !== '00:00' && (
                <>
                  <p className="mb-3" style={{ color: 'red' }}>
                    {message} {remainingTime}
                  </p>
                </>
              )}
              <div className="d-flex align-items-center justify-content-center">
                <div className="input-countrycode mt-lg-0 mt-2">
                  <img
                    src={IconIndoFlag}
                    width="32"
                    height="32"
                    className="img-flag"
                    alt="Flag"
                  />
                  <p className="d-flex my-auto">+62</p>
                </div>
                <div className="input-icons d-flex my-auto">
                  <input
                    type="text"
                    value={phone}
                    className="input-phoneNumber"
                    onChange={(e) => setPhone(e.target.value)}
                    pattern="[0-9]*"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        requestOtp();
                      }
                    }}
                  />
                  <img
                    onClick={requestOtp}
                    src="https://storage.aido.id/microservices/icon/arrow-circle.svg"
                    width="24"
                    height="24"
                    className="buttonsubs"
                    alt=""
                  />
                </div>
              </div>
              <p className="mb-3 mt-4 mt-lg-3">
                Kami akan mengirimkan kode verifikasi ke nomor ini. Pastikan
                nomor anda aktif dan dapat dihubungi.
              </p>
              <label className="information-tnc">
                Saya menyetujui
                <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  ketentuan pengguna janji temu{' '}
                </a>
                dan
                <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  kebijakan privasi.
                </a>
              </label>
            </div>
          )}
          <div>
            {OtpForm && status == 200 && (
              <div>
                <div
                  style={{
                    textAlign: 'left',
                    color: 'rgb(0, 189, 132)',
                    cursor: 'pointer',
                  }}
                >
                  <a
                    style={{ color: 'rgb(0, 189, 132)', cursor: 'pointer' }}
                    onClick={(e) => {
                      setOtpForm(false);
                      setInputForm(true);
                    }}
                  >
                    <img
                      src={IconArrowBackBlack}
                      width="24"
                      height="24"
                      className="buttonsubs"
                      alt=""
                      style={{ color: 'rgb(0, 189, 132)' }}
                    />
                  </a>
                </div>
                <h1 className="mb-3 d-none d-md-block">Masuk ke Janji Temu</h1>
                <h4 className="mb-3 d-md-none d-block">Masuk ke Janji Temu</h4>
                <p className="mb-3 mt-4" style={{ fontWeight: 'bold' }}>
                  Masukan 6-digit kode yang dikirim melalui WA ke {SpanPhone}
                </p>
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <PinInput
                    length={6}
                    initialValue=""
                    secret
                    onChange={(value) => {
                      setOtp(value);
                    }}
                    type="numeric"
                    inputMode="number"
                    onComplete={(value, index) => {}}
                    autoSelect
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </div>
                <p className="mb-2">{timer}</p>
                <p className="mb-3 mt-4">
                  <a
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    className="green"
                    onClick={(e) => {
                      setOtpForm(false);
                      setInputForm(true);
                    }}
                  >
                    Gunakan Nomor Lain
                  </a>
                </p>
                <Button
                  className="link-login"
                  style={{
                    fontSize: '14px',
                    marginTop: '30px',
                    height: '40px',
                    paddingTop: '7px',
                  }}
                  onClick={validateOtp}
                >
                  Verifikasi
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalLogin;
