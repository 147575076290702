import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { IconSearch } from '../../../assets/img/icon';
import './FormSearch.style.scss';

const FormSearch = ({ setValue, value, options = [] }) => {
  const [text, setText] = useState();

  useEffect(() => {
    setText(value);
  }, []);
  return (
    <React.Fragment>
      {options && (
        <div className="autocomplete-wrapper w-100">
          <Autocomplete
            items={options}
            shouldItemRender={(item, value) =>
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(item) => item.label}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                style={{
                  background: isHighlighted ? '#0069ff' : 'white',
                  color: isHighlighted ? 'white' : 'black',
                  padding: '3px 10px',
                  fontWeight: isHighlighted ? '600' : 'normal',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                {item.label}
              </div>
            )}
            renderInput={(props) => {
              return (
                <div className="container-field">
                  <input
                    {...props}
                    className={`w-100 form-input-text mb-0 input-icon-right`}
                    placeholder="Pencarian"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        setText(props.value);
                        setValue(props.value);
                      }
                    }}
                  />
                  <img
                    src={IconSearch}
                    alt={`icon-right`}
                    className={`right-icon`}
                    onClick={() => {
                      setText(props.value);
                      setValue(props.value);
                    }}
                  />
                </div>
              );
            }}
            renderMenu={(items, _value, style) => {
              return (
                <div
                  style={{
                    ...style,
                    zIndex: 1,
                    position: 'absolute',
                    top: '43px',
                    left: 0,
                    border: '1px solid gray',
                    borderRadius: '5px',
                  }}
                  className="search-box"
                  children={items}
                />
              );
            }}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onSelect={(value) => {
              setText(value);
              setValue(value);
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FormSearch;
