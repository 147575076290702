import moment from 'moment';
import 'moment/locale/id';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import Layout from '../Components/Layout';
import './TnC.scss';
moment.locale('id');

const TnC = () => {
  /* Router DOM */
  const navigate = useHistory();

  const tnc = useMemo(() => {
    return [
      {
        no: 1,
        desc: `Tentang situs ini. <br/> Sebagian dari layanan dapat dilihat tanpa harus melakukan registrasi terlebih dahulu, tetapi untuk dapat berpartisipasi secara aktif atau menyimpan informasi anda, anda perlu melakukan registrasi sebagai anggota dan memberikan otorisasi untuk penggunaan informasi anda untuk kegunaan pelayanan yang kami berikan sesuai yang tercantum dalam kebijakan privasi.
            Anda menyadari dan mengetahui walaupun sebagian konten mungkin disediakan oleh individu-individu di bidang kesehatan, namun konten tersebut tidak berarti menjadikan informasi ini sebagai pendapat, nasehat medis, diagnosa ataupun pengobatan, tetapi diberikan untuk membantu anda melokasikan pengobatan yang sesuai dari dokter, dokter gigi atau penyedia jasa kesehatan atau disebut secara keseluruhan sebagai “penyedia jasa kesehatan”. “konten” dalam artian kontex, tulisan, informasi, gambar, foto, video, rekaman suara, saran, arahan, dan materi lainnya yang diberikan,
            <br/> Kami tidak dapat memastikan, mewakilkan atau menjamin, baik lisan maupun tulisan, terhadap kualifikasi profesional, kualitas pekerjaan, informasi harga, atau informasi lainnya. Dalam situasi apapun, kami patut bertanggung-jawab kepada anda atau kepada siapapun terhadap keputusan atau tindakan yang diambil oleh anda terhadap konten.`,
      },
      {
        no: 2,
        desc: `Tidak ada nasehat dan anjuran medis <br/>
            Konten yang anda peroleh dari klinika, dan pegawainya, mitranya, pemasang iklan adalah bersifat informatif. Semua informasi yang berhubungan dengan medis, seperti email klinika, iklan klinika, blog klinika, sosial media klinika berasal dari penyedia jasa kesehatan terpisah dan hanya bersifat informatif saja.`,
      },
      {
        no: 3,
        desc: `Bukan merupakan relasi dokter dan pasien <br/>
            Tidak ada relasi antara pasien dan dokter secara langsung dengan menggunakan informasi yang terdapat dan tersedia di situs klinika
            Kami tidak membuat jaminan, pernyataan atau bukti, baik tersurat atau tersirat, sehubungan dengan kualifikasi, keahlian, kualitas kerja atau informasi lainnya di sini. Dalam kondisi apapun kami tidak bertanggung jawab kepada anda atau orang lain untuk keputusan atau tindakan yang diambil oleh anda
            <br/> Kami tidak memiliki kontrol dan tidak dapat menjamin ketersediaan penyedia jasa kesehatan pada waktu waktu yang tercatat di situs klinika.
            <br/>Anda sangat disarankan untuk melakukan pengecekan sendiri sebelum memilih penyedia jasa kesehatan yang ada di klinika dengan melakukan panggilan telepon kepada pihak berwenang dan memverifikasi semua informasi terlebih dahulutentang seorang dokter tertentu, dokter gigi atau penyedia jasa kesehatan dengan mengkonfirmasi dengan asosiasi medis yang berkaitan dengan spesialisasi penyedia jasa kesehatan`,
      },
      {
        no: 4,
        desc: `Hal penting lainnya sehubungan dengan informasi penyedia jasa kesehatan<br/>
            Sehubungan dengan menggunakan situs dan layanan dari klinika untuk mencari dan menjadwalkan janji dengan profesional medis, anda memahami bahwa:
            <br/>Anda yang paling bertanggung jawab <b>memilih penyedia jasa kesehatan anda sendiri.</b>
            <br/>Klinika memilih penyedia yang terdaftar di situs sesuai dengan perjanjian kontrak dengan penyedia.
            <br/>Klinika akan memberikan anda daftar dan / atau profil penyedia jasa Kesehatan yang mungkin cocok untuk memberikan perawatan kesehatan yang anda cari berdasarkan informasi yang anda berikan kepada klinika (seperti informasi lokasi geografis anda, spesialisasi, atau jaringan asuransi).
            <br/>Klinika berupaya untuk memastikan bahwa penyedia jasa kesehatan yang berpartisipasi di dalam klinika.co.id merupakan mereka yang memegang lisensi medis aktif dan semua sertifikasi yang diperlukan untuk memberikan layanan yang ditawarkan oleh mereka untuk pasien`,
      },
      {
        no: 5,
        desc: `Registrasi<br/>
            Sebagai bagian dari proses registrasi, anda akan diminta untuk memberikan email dan membuat kata sandi. Ini adalah kunci kerahasiaan untuk masuk kepada pelayanan yang hanya dapat diberikan kepada anggota. Anda sepatutnya menjaga kunci kerahasiaan pribadi dan tidak membagikannya kepada siapapun. Anda wajib melaporkan segera kepada kami jika kata kunci anda dicuri atau telah diketahui oleh pihak lain dengan mengirimkan email ke <a href="mailto:service@klinika.co.id">service@klinika.co.id</a>`,
      },
      {
        no: 6,
        desc: `Informasi pribadi anda<br/>
            Untuk mendaftar, anda harus memberikan informasi pribadi tentang diri anda. Anda diasumsikan mengetahui dan secara sukarela memberi kuasa klinika dan karyawannya, untuk menggunakan dan / atau mengungkapkan informasi pribadi dan kesehatan terkait tentang anda, termasuk, tanpa batasan, nama, alamat, riwayat medis dan sosial, kebutuhan medis saat ini, dan informasi lainnya yang baik diminta oleh klinika atau diberikan sukarela oleh anda sesuai dengan kebijakan privasi kami.`,
      },
      {
        no: 7,
        desc: `Tanggung jawab anda<br/>
            Meskipun informasi yang ada di situs ini disediakan secara gratis, dan segala biaya medis dan administrasi yang dibebankan oleh penyedia jasa kesehatan adalah merupakan hubungan transaksi antara anda dan penyedia jasa kesehatan dan sepenuhnya menjadi tanggung jawab anda.
            <br/>Anda bertanggung jawab untuk menjaga informasi kata sandi anda. Anda dapat menggunakan situs dan layanan di klinika untuk tujuan non-komersial saja. Anda tidak boleh menggunakan situs ini dengan cara apapun yang dapat merusak, melumpuhkan, membebani, atau merusak server atau jaringan kami, atau mengganggu penggunaan pihak lain akan situs atau layanan klinika. Anda tidak diperbolehkan mencoba untuk mendapatkan akses tidak sah ke suatu layanan, akun pengguna, atau sistem komputer atau jaringan, melalui hacking atau dlsb. Tanpa membatasi yang telah disebutkan sebelumnya, anda setuju bahwa anda tidak akan:
            <br/>Menyalin, merubah, mengadaptasi, menterjemahkan baik sebagian atau keseluruhan dari situs, isi atau materi dan / atau jasa;
            <br/>Menghapus setiap hak cipta, merek dagang atau hak kepemilikan lainnya yang terkandung dalam atau di situs dan / atau layanan atau atau pada isi atau materi lainnya yang diperoleh melalui situs dan / atau layanan
            <br/>Menggunakan robot, spider, pencarian situs / aplikasi pengambilan, atau perangkat otomatis lainnya, memproses atau dimaksudkan untuk mengakses, mengambil atau mengindeks bagian dari situs dan / atau layanan, akses,
            <br/>Mengambil atau mengindeks bagian dari situs dan / atau layanan untuk keperluan membuat atau menguasai pencarian data dari ulasan yang terkait dengan industri perawatan kesehatan atau penyedia;
            <br/>Memformat bagian manapun dari halaman web yang merupakan bagian dari situs dan / atau layanan;
            <br/>Membuat akun pengguna dengan sarana otomatis atau dengan identitas palsu;
            <br/>Mengumpulkan atau menyimpan data pribadi pengguna lain sehubungan dengan kegiatan yang dilarang dimaksud dalam pasal ini atau
            <br/>Menggunakan cara apapun, termasuk sarana perangkat lunak, untuk melakukan perolehan data ("scarping web") dari setiap bagian dari situs, konten atau bahan dan / atau layanan.
            <br/>Kami dapat mengambil tindakan hukum untuk pelanggaran dari penyalahgunaan situs klinika.`,
      },
      {
        no: 8,
        desc: `Perubahan ketentuan penggunaan<br/>
            Kami dapat merubah syarat penggunaan ini setiap saat sebagaimana kami anggap perlu. Perubahan ketentuan penggunaan layanan akan senantiasa kami perbaharui ke dalam situs klinika.co.id. Anda disarankan untuk melakukan pengecekan berkala terhadap syarat penggunaan ini. Jika anda tidak menyetujuinya maka anda disarankan untuk tidak menggunakan layaanan kami.`,
      },
      {
        no: 9,
        desc: `Perubahan layanan<br/>
            Dari waktu ke waktu, kami dapat mengubah, menghapus atau menggantikan layanan yang ada. Anda mengetahui dan setuju bahwa klinika tidak bertanggung jawab atas dampak dari perubahan layanan tersebut kepada anda atau pihak ketiga lainnya.`,
      },
      {
        no: 10,
        desc: `Tautan ke situs lain<br/>
            Situs klinika menyertakan tautan ke situs lain. Beberapa situs situs tsb mungkin mengandung materi yang tidak sah dan tidak akurat atau bahkan mengandung script yang berbahaya bagi anda. Anda mengetahui dan menyetujui bahwa klinika tidak bertanggung ajwab atas isi maupun keakuratan dari situs lain tersebut.`,
      },
      {
        no: 11,
        desc: `Kebijakan mengenai spam<br/>
            Anda tidak boleh menggunakan informasi kontak yang disediakan oleh penyedia jasa kesehatan di dalam situs klinika untuk tujuan pengiriman, atau untuk melengkapi pengiriman, dari komunikasi massal yang tidak diminta seperti "spam". Anda tidak dapat mengizinkan orang lain untuk menggunakan akun anda untuk melanggar ketentuan bagian ini. Kami dapat mengakhiri keanggotaan anda atau akses ke situs secara langsung dan mengambil tindakan hukum lainnya jika anda atau siapa saja yang menggunakan akun anda melanggar ketentuan ini.`,
      },
      {
        no: 12,
        desc: `Ketentuan tambahan<br/>
            Beberapa layanan di klinika mungkin memiliki persyaratan tambahan (seperti kebijakan, pedoman, dan aturan) yang lebih lanjut akan mengatur penggunaan layanan tertentu dan melengkapi ketentuan penggunaan. Anda setuju untuk mematuhi pedoman tambahan dan aturan`,
      },
      {
        no: 13,
        desc: `Penggunaan konten<br/>
            Semua informasi yang tersedia di atau melalui layanan dan / atau situs, termasuk tanpa batasan, teks, foto, grafis dan video dan konten audio, dimiliki oleh kami dan pemberi lisensi dan dilindungi oleh hak cipta, merek dagang, paten, dan perdagangan undang-undang rahasia, hak-hak lain, dan perjanjian internasional. Anda mengakui bahwa layanan dan setiap teknologi yang mendasari atau perangkat lunak yang digunakan dalam hubungannya dengan layanan mengandung informasi yang merupakan milik klinika. Kami memberi anda izin untuk menggunakan konten tersebut untuk tujuan pribadi dan tujuan non-komersial saja dan tidak memindahkan hak kekayaan intelektual anda berdasarkan perizinan penggunaan layanan.
            <br/>Anda dapat mencetak, mengunduh, dan menyimpan informasi dari situs untuk kebutuhan anda sendiri, tetapi anda tidak diperkenankan menyalin, mendistribusikan, menerbitkan, menjual, atau memanfaatkan salah satu konten, atau mengeksploitasi situs secara keseluruhan atau sebagian, untuk keuntungan komersial atau tujuan apapun (tanpa seizin dari klinika).`,
      },
      {
        no: 14,
        desc: `Hal yang bukan merupakan tanggung jawab klinika<br/>
            Kami tidak memiliki relasi khusus dengan atau kewajiban kepada anda. Anda mengetahui bahwa klinika tidak memiliki kendali atas apapun, dan tidak berkewajiban untuk mengambil tindakan terhadap apapun tentang: efek konten yang ada dan / atau layanan kepada anda, bagaimana anda menafsirkan atau menggunakan konten pada situs dan / atau layanan, atau tindakan apapun yang anda lakukan sebagai hasil dari informasi yang dilampirkan di situs dan / atau layanan. Anda membebaskan kami dari semua tanggung jawab anda akan apa yang anda dapatkan atau tidak dapatkan melalui situs dan / atau layanan klinika. Ataupun mengarahkan anda ke situs-situs yang berisi, informasi bahwa beberapa orang dapat mungkin tersunggung ataupun merasa tidak pantas.
            <br/>Kami tidak akan bertanggung jawab atau berkewajiban atas keakuratan, kepatuhan hak cipta, legalitas kesusilaan atau bahan yang terkandung dalam atau diakses melalui situs dan / atau layanan.
            <br/>Kami menyediakan situs dan layanan "selayaknya", "dengan segala kesalahannya" dan "sebagaimana tersedia." kami tidak membuat jaminan tersurat atau tersirat atau jaminan tentang layanan. Termasuk tanpa terbatas jaminan  akan kualitas yang memuaskan, akurat, cocok untuk tujuan tertentu. Kami tidak bertanggung jawab atas kebenaran, keandalan, ketepatan waktu atau kelengkapan informasi yang disediakan oleh penyedia jasa kesehatan. Klinika tidak menjamin bahwa situs atau layanan akan beroperasi bebas dari kesalahan, bahwa hilangnya data tidak akan terjadi, atau bahwa layanan, perangkat lunak atau situs bebas dari virus komputer, tekontaminasi atau hal berbahaya lainnya.`,
      },
      {
        no: 15,
        desc: `Penghentian akun pengguna<br/>
            Kami dapat mengakhiri dan / atau menghentikan akun anda, tanpa pemberitahuan terlebih dahulu, jika telah terjadi pelanggaran atas ketentuan penggunaan atau kebijakan dari situs klinika. Kami juga dapat membatalkan atau menangguhkan pendaftaran anda untuk alasan lain apabila anda tidak aktif untuk jangka waktu yang sangat lama, tetapi kami akan memberitahu sebelum kami melakukan pembatalan tersebut. Klinika tidak bertanggung jawab kepada anda atau pihak ketiga atas setiap penghentian akses anda ke situs dan / atau layanan.`,
      },
      {
        no: 16,
        desc: `Pembebasan dari tuntutan hukum<br/>
            Atas permintaan kami, anda setuju untuk melindungi, dan membebaskan klinika, karyawan, kontraktor, petugas, direktur, agen, perusahaan afiliasi lainnya, dan pemasok dari segala kewajiban, klaim, tuntutan dan biaya, termasuk biaya pengacara, yang dibuat oleh pihak ketiga yang timbul dari atau terkait dengan (a) akses anda ke situs, (b) penggunaan layanan, atau (c) pelanggaran persyaratan penggunaan, atau pelanggan setiap kekayaan intelektual atau hak lain ketika menggunakan akun anda. Kewajiban ganti rugi tersebut di atas tidak berlaku untuk kewajiban, klaim dan biaya yang timbul sebagai akibat dari kelalaian kami sendiri atau kesengajaan.`,
      },
    ];
  }, []);

  return (
    <Layout>
      <div className="content-TnC col-11 mt-5">
        <Row>
          <div className="cursor-pointer" onClick={() => navigate.goBack()}>
            <button className="circle-btn-prev">
              <i className="fa fa-arrow-left" />
            </button>
            <span className="title-page">Kembali</span>
          </div>
        </Row>
        <Row className="background-utama">
          <i className="fa fa-comments circle-tnc" />
          <div className="col-12 d-flex justify-content-center align-items-center">
            <h1>Syarat & Ketentuan</h1>
          </div>
          <div className="col-12 header-tnc">
            <h4>
              Syarat dan kondisi berikut merupakan perjanjian antara anda dan
              klinika ("klinika", "kami"), operator klinika ("situs").
              <br />
              Persyaratan penggunaan ini ("syarat penggunaan") mengatur
              penggunaan anda terhadap situs, baik sebagai pengunjung biasa atau
              sebagai pengguna terdaftar. Dengan menggunakan situs, dan / atau
              dengan terdaftar dengan kami, berarti anda menyetujui persyaratan
              penggunaan, termasuk bahwa anda menyetujui segala informasi yang
              diungkapkan dalam kebijakan privasi kami. Harap dicatat bahwa kami
              menawarkan situs "selayaknya" dan tanpa jaminan. Jika anda
              mendaftarkan akun atau menggunakan situs atas nama perorangan atau
              instansi lain dengan kata lain anda menyatakan bahwa anda
              berwenang untuk mengelola akun atas nama pribadi atau badan tsb
              dan mewakili untuk menerima ketentuan atas syarat penggunaan atas
              nama individu atau nama instansi tsb. Syarat dan kondisi berikut
              merupakan perjanjian antara anda dan klinika ("klinika", "kami"),
              operator klinika.co.id ("situs"). Persyaratan penggunaan ini
              ("syarat penggunaan") mengatur penggunaan anda terhadap situs,
              baik sebagai pengunjung biasa atau sebagai pengguna terdaftar.
              Dengan menggunakan situs, dan / atau dengan terdaftar dengan kami,
              berarti anda menyetujui persyaratan penggunaan, termasuk bahwa
              anda menyetujui segala informasi yang diungkapkan dalam kebijakan
              privasi kami. Harap dicatat bahwa kami menawarkan situs
              "selayaknya" dan tanpa jaminan. Jika anda mendaftarkan akun atau
              menggunakan situs atas nama perorangan atau instansi lain dengan
              kata lain anda menyatakan bahwa anda berwenang untuk mengelola
              akun atas nama pribadi atau badan tsb dan mewakili untuk menerima
              ketentuan atas syarat penggunaan atas nama individu atau nama
              instansi tsb.
            </h4>
          </div>
          <Row className="row-tnc">
            {tnc ? (
              tnc.map((data, index) => (
                <Row key={'key' + index}>
                  <Col className="col-num">{data.no}.</Col>
                  <Col className="col-desc">
                    <div dangerouslySetInnerHTML={{ __html: data.desc }} />
                  </Col>
                </Row>
              ))
            ) : (
              <></>
            )}
          </Row>
        </Row>
      </div>
    </Layout>
  );
};

export default TnC;
