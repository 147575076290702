import axios from "axios";
import { inquiryConstants } from "./constants";
import { api } from "../../urlConfig";
import { getAxiosHeaders } from "../../helpers/getAxiosHeader";

export const createInquiry = (body) => {
  return async (dispatch) => {
    dispatch({
      type: inquiryConstants.CREATE_INQUIRY_REQUEST,
    });

    let headers = getAxiosHeaders();

    const res = await axios
      .post(`${api}/inquiry/create`, body, headers)
      .catch((err) => {
        dispatch({
          type: inquiryConstants.CREATE_INQUIRY_FAILURE,
          payload: err,
        });

        return err;
      });

    if (res.status === 200) {
      dispatch({
        type: inquiryConstants.CREATE_INQUIRY_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getInquiry = (query) => {
  return async (dispatch) => {
    dispatch({
      type: inquiryConstants.GET_INQUIRY_REQUEST,
    });

    let headers = getAxiosHeaders();

    const res = await axios
      .get(`${api}/inquiry?${query}`, headers)
      .catch((err) => {
        dispatch({
          type: inquiryConstants.GET_INQUIRY_FAILURE,
          payload: err,
        });

        return err;
      });

    if (res.status === 200) {
      dispatch({
        type: inquiryConstants.GET_INQUIRY_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getHospitalInfo = (query) => {
  return async (dispatch) => {
    dispatch({
      type: inquiryConstants.GET_HOSPITAL_INFO_REQUEST,
    });

    let headers = getAxiosHeaders();

    const res = await axios
      .get(`${api}/hospital-info?${query}`, headers)
      .catch((err) => {
        dispatch({
          type: inquiryConstants.GET_HOSPITAL_INFO_FAILURE,
          payload: err,
        });

        return err;
      });

    if (res.status === 200) {
      dispatch({
        type: inquiryConstants.GET_HOSPITAL_INFO_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
