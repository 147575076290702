import React from 'react';
import './Card.style.scss';

const Card = ({
  useInnerBorder = false,
  children,
  className = '',
  innerClassName = '',
  type = 'white',
}) => {
  return (
    <React.Fragment>
      <div className={`${className} ${type ? `card-${type}` : ''} w-100`}>
        {useInnerBorder ? (
          <div className={`${innerClassName} card-inner w-100`}>
            {children && children}
          </div>
        ) : (
          children
        )}
      </div>
    </React.Fragment>
  );
};

export default Card;
