import moment from "moment/moment";
import CryptoJS from "crypto-js";
import { apiKey } from "../urlConfig";
const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu'];

export const formatDate = (str, isDay = true, format = 'dd MMMM yyyy') => {
  if (typeof str !== 'string') return false;
  const date = new Date(str);
  const months = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  const getDay = days[date.getDay()];
  let tempDate = '';
  if (format === 'MMMM yyyy') {
    tempDate = `${months[date.getMonth()]} ${date.getFullYear()}`;
  } else if (format === 'dd-mm-yyyy') {
    tempDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  } else {
    tempDate = `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()}`;
  }
  const result = isDay && `${getDay}, ${tempDate}`;
  return result || tempDate;
};

export const customFormatDate = (str = '') => {
  if (typeof str !== 'string') return false;
  const date = new Date(str);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Agu',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ];
  const getDay = days[date.getDay()];
  const getMonth = months[date.getMonth()];
  return `${getDay}, ${date?.getDate()} ${getMonth}`;
};

export const formatDateAsData = (value) => {
  if (!value) return false;
  const d = new Date(value);
  const yyyy = d.getFullYear();
  let mm = d.getMonth() + 1;
  let dd = d.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return yyyy + '-' + mm + '-' + dd;
};

export const isNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const isAlphabetic = (value) => {
  return /[a-zA-Z ]+/.test(value);
};

export const isEmail = (value) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
};

export const apiKeyGenerate = () => {
  const dateNow = moment(new Date()).add(2,'seconds').unix()
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(dateNow),
    apiKey.toString()
  ).toString();

  return data

}