import React from 'react';

const FormatPrice = ({
  value
}) => {
  return (
    
     value? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):0
    
    
  );
};

export default FormatPrice;
