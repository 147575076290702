import moment from 'moment';
import React from 'react';
import { Button as ButtonReact, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCalenderGrey,
  IconCloseGreen,
  IconPlusWhite,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import './patient.scss';
import { InputDate, InputText, SelectOptions } from '../../../components/Input';


const MobilePatient = ({
  state,
  prevPageUrl,
  nextPageUrl1,
  nextPageUrl2,
  patientDataSelected,
  patient,
  handleAddPatient,
  selectedPatient,
  appointmentData,
  setAppointmentData,
  countComplaiints,
  setCountComplaiints,
  handleMedicalDocModal,
  medicalDoc,
  paymentMethod,
  serviceType,
  setPaymentTeleModal,
  setMedicalDoc,
  handleDoctorSchedule,
}) => {
  /* Router DOM */
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="content-patient-mobile d-xl-none d-block mt-4">
        <div
          className="cursor-pointer d-flex flex-row"
          onClick={handleDoctorSchedule}
        >
          <img src={IconArrowGreen} alt="icon" />
          <p className="title-page ml-2 text-white">Jadwal</p>
        </div>
        <p className="text-center step-mobile-title font-weight-bold mt-3">
          Langkah 2 dari 3
        </p>
        <Row>
          <div className="col-11">
            <div className="content-patient-step">
              <p className="text-center title1">Profil Pasien</p>
              <p className="text-center title2">
                Silahkan lengkapi data profil pasien untuk ke proses pembayaran
              </p>
              <div className="find-profile">
                <p className="title2 text-left font-weight-bold mb-2">
                  Profil Pasien
                </p>
                <div className="select-option">
                <SelectOptions
                    id="selectOptionPatient"
                    placeholder="Profil Pasien"
                    label="Pilih Profil Pasien"
                    labelClassName="mt-5"
                    background="#ffff"
                    value={selectedPatient}
                    fontSize="lg"
                    setValue={(e) => patientDataSelected(e)}
                    required={true}
                    options={
                      patient?.patientData?.detail?.length &&
                      patient?.patientData?.detail?.map((e) => {
                        return {
                          ...e,
                          label: `${e?.name} - ${
                            e.relationname ? e.relationname : ''
                          }`,
                          value: e?.id,
                        };
                      })
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </div>
                <Button
                  icon={IconPlusWhite}
                  iconPosition="left"
                  label="Tambah Profil"
                  onClick={handleAddPatient}
                  styles={{ fontSize: '16px' }}
                  className="mt-3"
                  type="primary-blue"
                />
              </div>
              {selectedPatient.length != 0 ? (
                <div className="choose-patient col-13 mx-auto">
                  <div className="profile-patient col-13 mx-auto">
                    <div className="profile-card">
                      <div className="row">
                        <p className="col-3">Nama</p>
                        <p className="col px-0 mx-0">
                          : {selectedPatient.name ? selectedPatient.name : '-'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="col-3">Hubungan</p>
                        <p className="col px-0 mx-0">
                          : {selectedPatient?.relationname || '-'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="col-3">Umur</p>
                        <p className="col px-0 mx-0">
                          :{' '}
                          {selectedPatient.dob
                            ? moment().diff(selectedPatient.dob, 'years') +
                              ' Tahun'
                            : '-'}
                        </p>
                      </div>
                      <p>
                        No Telepon{'  '}: {'+62' + selectedPatient.phone_1}
                      </p>
                    </div>
                    <p className="title2 text-left font-weight-bold mb-2">
                      Keluhan
                    </p>
                    <textarea
                      rows="5"
                      className="form-control"
                      placeholder="Silahkan masukkan keluhan Anda"
                      value={appointmentData.complaints}
                      onChange={(e) => {
                        setAppointmentData({
                          ...appointmentData,
                          complaints: e.target.value,
                        });

                        setCountComplaiints(e.target.value.length);
                      }}
                    />
                    <h4>{countComplaiints}/1000</h4>
                  </div>
                  <div style={{ textAlign: 'left', marginTop: '10px' }}>
                    <p className="title2 text-left font-weight-bold mb-2">
                      Unggah Dokumen Medis
                    </p>
                    <Button
                      icon={IconPlusWhite}
                      iconPosition="left"
                      label="Tambah Dokumen"
                      onClick={() => handleMedicalDocModal()}
                      styles={{ fontSize: '16px' }}
                      type="primary-blue"
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '5px',
                      padding: '20px',
                      textAlign: 'left',
                    }}
                  >
                    {medicalDoc.length > 0 ? (
                      medicalDoc.map((data, index) => (
                        <Row key={'row' + index}>
                          <div style={{ marginBottom: '10px' }}>
                            <span
                              style={{
                                display: 'inline',
                                fontSize: '13px',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                color: 'black',
                                fontWeight: 'lighter',
                              }}
                            >
                              <i
                                className="fa fa-check"
                                style={{ color: '#00BD84', marginRight: '5px' }}
                              />
                              {data
                                ? data.docUrl.split('/').length == 6
                                  ? data.docUrl.split('/')[5]
                                  : ''
                                : ''}
                            </span>
                            <span
                              style={{
                                display: 'inline',
                                fontSize: '12px',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              &nbsp;
                              {data?.docType === 'Rujukan'
                                ? 'Kartu Rujukan'
                                : data?.docType === 'LabResult'
                                ? 'Hasil Laboratorium'
                                : data?.docType === 'Lainnya'
                                ? 'Dokumen Lainnya'
                                : 'Berhasil Terunggah!'}
                            </span>
                            <a
                              href={data.docUrl}
                              target="_blank"
                              style={{
                                display: 'inline',
                                fontSize: '13px',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                color: '#00BD84',
                                fontWeight: 'bold',
                                marginLeft: '10px',
                              }}
                            >
                              Lihat Dokumen
                            </a>
                            <img
                              src={IconCloseGreen}
                              alt="icon"
                              className="cursor-pointer"
                              onClick={() =>
                                setMedicalDoc(
                                  medicalDoc.filter((e) => e.id !== data.id)
                                )
                              }
                            />
                          </div>
                        </Row>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="btn-prev-next">
                {selectedPatient.length == 0 ? (
                  <ButtonReact
                    className="btn-prev inactive"
                    style={{ marginTop: '150px' }}
                    disabled
                  >
                    Pilih Profile Pasien
                  </ButtonReact>
                ) : selectedPatient.name &&
                  selectedPatient.phone_1 &&
                  selectedPatient.dob &&
                  selectedPatient.relation &&
                  selectedPatient.gender ? (
                  paymentMethod == '2' ? (
                    // bpjs
                    <Link
                      to={{
                        pathname: nextPageUrl1,
                        state: {
                          selectedPatient: selectedPatient,
                          appointmentData: appointmentData,
                        },
                      }}
                    >
                      <ButtonReact
                        className="btn-prev"
                        variant="primary"
                        style={{ marginTop: '30px' }}
                      >
                        Pembayaran dengan BPJS
                      </ButtonReact>
                    </Link>
                  ) : serviceType != 'TL' ? (
                    // insurance
                    <Button
                      label="Pembayaran Asuransi/Pribadi"
                      onClick={() => {
                        history.push({
                          pathname: nextPageUrl2,
                          state: {
                            selectedPatient: selectedPatient,
                            appointmentData: appointmentData,
                          },
                        });
                      }}
                      styles={{ fontSize: '16px' }}
                    />
                  ) : (
                    // teleconsultation
                    <ButtonReact
                      className="btn-prev"
                      variant="primary"
                      style={{ marginTop: '30px' }}
                      onClick={() => setPaymentTeleModal(true)}
                    >
                      Pembayaran
                    </ButtonReact>
                  )
                ) : (
                  <ButtonReact
                    className="btn-prev inactive"
                    style={{ marginTop: '30px' }}
                    onClick={() => handleAddPatient(selectedPatient)}
                  >
                    Lengkapi Profile Pasien
                  </ButtonReact>
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MobilePatient;
