import authReducer from "./auth.reducers";
import userReducers from "./user.reducers";
import accountProfileReducer from "./accountProfile.reducers";
import transactionHistoryReducer from "./transactionHistory.reducers";
import findDoctorReducer from "./findDoctor.reducers";
import patientReducer from "./patient.reducers";
import paymentMethodReducer from "./paymentMethod.reducers";
import appointmentReducer from "./appointment.reducers";
import DoctorScheduleReducer from "./doctorSchedule.reducers";
import proceduresReducers from "./procedures.reducers";
import packageReducers from "./package.reducers";
import articlesReducers from "./articles.reducers";
import appointmentWebReducers from "./appointmentWeb.reducers";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  userProfile: userReducers,
  accountProfile: accountProfileReducer,
  transactionHistory: transactionHistoryReducer,
  findDoctor: findDoctorReducer,
  patient: patientReducer,
  paymentMethod: paymentMethodReducer,
  appointment: appointmentReducer,
  doctorSchedule: DoctorScheduleReducer,
  procedureMCU: proceduresReducers,
  productPackage: packageReducers,
  articles: articlesReducers,
  appointmentWeb: appointmentWebReducers
});

export default rootReducer;
