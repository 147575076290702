import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Layout from '../../Components/Layout';
import queryString from "query-string";
import Swal from "sweetalert2";
import Input from "../../Components/Input";
import { Button as ButtonReact, Col, Form, Row } from "react-bootstrap";
import "./InvoicePage.scss";
import DatePicker from "react-datepicker";
import FormatPrice from "../../../../components/Globals/Utils/formatPrice";
import {
  getInvoice
} from "../../../../actions";
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import {terbilang} from "../../../../../src/helpers/formatDigit"
import TemplateInvoice from "../Invoice/templateInvoice";
import {PDFDownloadLink } from "@react-pdf/renderer";


const DetailInvoice = (props) => {



  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + '-' + mm + ' ' + yyyy;

  const detailTransaction = useSelector((state) => state.transactionHistory.invoiceData);
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  
  useEffect(() => {
    const profilId = state[0].profilId
    const registrationId = state[0].registrationId
    dispatch(getInvoice(profilId, registrationId));
  },[])

  const currentURL = window.location.href;
  const pathname = window.location.pathname;
  const baseUrl = currentURL.replace(pathname, "");

  const changePagePrint = () => {
    const payload = {
      profilId: state[0].profilId,
      registrationId: state[0].registrationId,
    };
    history.push('/print/invoice', [payload]);
  }

  const changePageBack = () => {
    history.push({
      pathname: '/history-order'
    })
  }

  let countNumber = 0
  const itemNumber = (num) => {
    let newNum = countNumber + 1
    countNumber = newNum
    return newNum
  }

  const generateMedicineName = (satuan, concoction) => {
    let name = ""
    if ( concoction != null ) {
      const dataConcoction = JSON.parse(concoction)
      name = dataConcoction.name
    } else {
      name = satuan
    }
    return name
  }

  return (
    <>
      <Layout>
        <div className="content-transaction-history">
            <div id="content-detail-invoice">
            <div className="table-wrapper mx-4">
                <div className="header-detail-invoice pb8">

                <div className="header-detail-invoice-addreess">
                    <Row>
                    <div className="col-md-6">
                    <div className="detail-invoice-name-clinic"><strong>{detailTransaction.hospitalName}</strong></div>
                    <div className="detail-invoice-address-clinic">{detailTransaction.hospitalAddress}</div>
                    </div>
                    </Row>
                </div>
                <div className="header-detail-invoice-title d-flex justify-content-center">
                    <Row>
                    <div className="detail-invoice-title-name col-md-12 d-flex justify-content-center">Bukti Pembayaran</div>
                    <div className="detail-invoice-title-number col-md-12 d-flex justify-content-center">No: {detailTransaction.trxNumber}</div>
                    </Row>
                </div>
                </div>
                <div className="info-detail-total mb16">
                <div className="detail-invoice-detail-patien">
                    <div className="detail-invoice-info-patien">
                    <Row>
                        <Col md={3}>
                        Sudah Terima Dari
                        </Col>
                        <Col md={9}>
                        : {detailTransaction.firstName} {detailTransaction.lastName}
                        </Col>
                    </Row>
                    </div>
                    <div className="detail-invoice-info-patien">
                    <Row>
                        <Col md={3}>
                        Nama Pasien / No. Rm
                        </Col>
                        <Col md={9}>
                        : <strong>{detailTransaction.firstName} {detailTransaction.lastName} (MRN: {detailTransaction.mrNumber}) </strong>
                        </Col>
                    </Row>
                    </div>

                    <div className="detail-invoice-info-patien">
                    <Row>
                        <Col md={3}>
                        Nama Dokter
                        </Col>
                        <Col md={9}>
                        : {detailTransaction.doctorName}
                        </Col>
                    </Row>
                    </div>
                    <div className="detail-invoice-info-patien">
                    <Row>
                        <Col md={3}>
                        Cara Pembayaran
                        </Col>
                        <Col md={9}>
                        : {detailTransaction.payerName} Rp. <FormatPrice value={parseInt(detailTransaction.totalBill)}/>
                        </Col>
                    </Row>
                    </div>
                    <div className="detail-invoice-info-patien">
                    <Row>
                        <Col md={3}>
                        Untuk Pembayaran
                        </Col>
                        <Col md={9}>
                        : 
                        </Col>
                    </Row>
                    </div>
                </div>
                </div>
                <div className="body-detail-invoice">
                
                <table className="table-reference">
                    <thead>
                        <tr>
                        <th>No</th>
                        <th>Keterangan</th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {detailTransaction.transactionCategory && detailTransaction.transactionCategory.length > 0 ? 
                        detailTransaction.transactionCategory.map((data, index) => (
                        <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>Rp <FormatPrice value={parseInt(data.total)}/></td>
                        </tr>
                        ))
                    
                    : null }
                    </tbody>
                </table>
                <table className="table-item">
                    <thead>
                        <tr>
                        <th>No</th>
                        <th>Keterangan</th>
                        <th>Harga</th>
                        <th>Jumlah</th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {detailTransaction.transactionCategory && detailTransaction.transactionCategory.length > 0 ? 
                        detailTransaction.transactionCategory.map((data, index) => (
                        data.items && data.items.length > 0 ? 
                            data.items.map((dataItem, index2) => (
                            <tr key={(index2+1) + (index+1) }>
                            <td>{itemNumber(index2)}</td>
                            <td>{generateMedicineName(dataItem.name, dataItem.concoction)}</td>
                            <td>Rp <FormatPrice value={parseInt(dataItem.price)}/></td>
                            <td>{dataItem.quantity}</td>
                            <td><FormatPrice value={dataItem.quantity * parseInt(dataItem.price)}/></td>
                            </tr>
                            )):null 
                        ))
                    
                    : null }
                    <tr className="item-total">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Jumlah</th>
                        <th>Rp <FormatPrice value={parseInt(detailTransaction.totalBill)}/></th>
                    </tr>
                    </tbody>
                </table>
                </div>
                <div className="footer-detail-total mb16">
                <Row id ="footer-detail-invoice">
                    <Col md="12" className="pb-20">
                    <strong>Terbilang: {terbilang(parseInt(detailTransaction.totalBill))}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                    <div className="detail-payment-info">
                        <ul className="detail-payment-list-info">
                        <li>Harga obat resep sudah termasuk PPN 11%</li>
                        <li>Bukti pembayaran ini berlaku juga sebagai kwitansi</li>
                        <li>Obat/barang sudah dibeli tidak dapat ditukar atau dikembalikan</li>
                        <li>Kwitansi hanya boleh dicetak 1 kali dan photo copy tidak dapat dilegalisir</li>
                        <li>Hanya kwitansi asli yang dapat ditukar dengan kwitansi detail</li>
                        <li>Penukaran/permintaan dokumen pada hari berbeda dikenakan biaya registrasi</li>
                        <li>Kwitansi jangan hilang, {detailTransaction.hospitalName} tidak dapat mencentak ulang kwitansi</li>
                        </ul>
                    </div>
                    </Col>
                    <Col md={4}>
                    Terima kasih
                    <br/>
                    {today}
                    </Col>
                </Row>
                </div>
                <div className="col-12 mt-5 mb-2">
                  <div className="d-md-block d-none">
                    <button
                        className='button-primary'
                        style={{ width: "100%" }}
                        onClick={() => changePagePrint()}
                    >
                        Cetak
                    </button>
                  </div>
                  <div className="d-md-none d-block">
                    <PDFDownloadLink document={<TemplateInvoice invoiceData={detailTransaction}
                        />} fileName="invoice.pdf">
                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 
                        (
                          <button
                            className='button-primary'
                            style={{ width: "100%" }}
                            //onClick={() => changePagePrint()}
                          >
                          Cetak 
                        </button> 
                        ))}
                      </PDFDownloadLink>
                  </div>
                </div>
                <div className="col-12">
                <button
                    className='button-outline-primary'
                    style={{ width: "100%" }}
                    onClick={() => changePageBack()}
                >
                    Kembali
                </button>
                </div>
            </div>
            </div>
        </div>
      </Layout>
    </>
  );
};

export default DetailInvoice;
