import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
    getDiagnosis
} from "../../../../actions";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, PDFDownloadLink } from "@react-pdf/renderer";
import { terbilang, formatRupiahV2, formatDigit, today } from "../../../../helpers/formatDigit"


const TemplateDiagnosis = (props) => {
    console.log(props.diagnosisData)

    let detailTransaction = props.diagnosisData;
    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 'semibold' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf', fontWeight: 'normal' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-500.ttf', fontWeight: 'medium' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 'bold' }
        ]
    });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            color: "#000000",
            fontSize: "10px",
            marginLeft: "15px",
            marginRight: "15px",
            paddingRight: "15px",
            fontFamily: "Open Sans"
        },
        section: {
            margin: "20px 10px 10px 10px",
            fontSize: "12px",
            fontWeight: "700"
        },
        sectionHeader: {
            display: "flex",
            flexDirection: "row",
            fontSize: 12,
            marginLeft: "10px",
            paddingRight: "10px",
            marginBottom: "4px",
        },
        sectionDetail: {
            display: "flex",
            flexDirection: "row",
            fontSize: 9,
            marginLeft: "10px",
            paddingRight: "10px",
            marginBottom: "4px",
        },
        sectionLine: {
            borderBottom: "1px solid #E7E7E7",
            margin: 10
        },
        sectionTable: {
            flexDirection: "column"
        },
        table: {
            fontSize: 10,
            width: 600,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            marginBottom: "24px",
            borderBottom: "1px solid #dedede"
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: 25
        },
        cell: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            alignSelf: "stretch",
            margin: 4
        },
        cellFirst: {
            margin: 4,
            width: "10%"
        },
        cellQty: {
            margin: 4,
            width: "50%"
        },
        cellDescription: {
            margin: 4,
            width: "40%"
        },
        cellBold: {
            //fontFamily:"Open Sans",
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            alignSelf: "stretch",
            margin: 4,
            fontWeight: "bold"
        },
        header: {
            backgroundColor: "#ffffff",
            borderTop: "1px solid #dedede",
            borderBottom: "1px solid #dedede",
        },
        headerText: {
            fontSize: 11,
            fontWeight: 1200,
            color: "#1a245c",
            margin: 8
        },
        tableText: {
            margin: 10,
            fontSize: 10,
            color: "neutralDark"
        },
        viewer: {
            width: window.innerWidth, //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
        },
    });
    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                </View>
                <View style={{ marginLeft: "72px", marginBottom: "36px", width: "40%" }}>
                    <Text style={{ fontWeight: "bold" }}>{detailTransaction.hospitalName}</Text>
                    <Text style={{}}>{detailTransaction.hospitalAddress}</Text>
                    <Text style={{}}>Phone : {detailTransaction.hospitalPhone}</Text>
                </View>
                <View style={{ marginBottom: "18px", fontWeight: "bold" }}>
                    <Text style={{ marginBottom: "18px" }}>{detailTransaction.doctorName}</Text>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={{ width: "30%" }}>Tanggal Lahir</Text>
                        <Text style={{}}> : {detailTransaction.dob}</Text>
                    </View><View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={{ width: "30%" }}>No Rekam Medis</Text>
                        <Text style={{}}> : {detailTransaction.mrNumber}</Text>
                    </View>
                </View>
                <View style={{ marginBottom: "24px" }}>
                    <Text style={{}}>Pada Kunjungan tanggal {detailTransaction.consultationDate}</Text>
                    <View style={{ marginBottom: "12px" }}>
                        <Text style={{ textDecoration: "underline" }}>Diagnosa Utama</Text>
                        {detailTransaction && detailTransaction.diagnosis && detailTransaction.diagnosis.main.length > 0 ?
                            detailTransaction.diagnosis.main.map((main, index) => (
                                <View style={{ display: "flex", flexDirection: "row", fontWeight: "bold" }}>
                                    <Text style={{ width: "10%" }}>ICD</Text>
                                    <Text style={{}}> : {main.name}</Text>
                                </View>
                            ))
                            : null
                        }

                    </View>
                    <View style={{}}>
                        <Text style={{ textDecoration: "underline" }}>Diagnosa Tambahan</Text>
                        {detailTransaction && detailTransaction.diagnosis && detailTransaction.diagnosis.main.length > 0 ?
                            detailTransaction.diagnosis.sub.map((sub, index) => (
                                <View style={{ display: "flex", flexDirection: "row", fontWeight: "bold" }}>
                                    <Text style={{ width: "10%" }}>ICD</Text>
                                    <Text style={{}}> : {sub.name}</Text>
                                </View>
                            ))
                            : null
                        }

                    </View>
                </View>

                <View style={{ marginBottom: "36px" }}>
                    <Text style={{}}>Jakarta, {today()}</Text>
                    <Text style={{}}>Dokter yang memerika</Text>
                </View>
                <View style={{ marginBottom: "24px" }}>
                    <Text style={{ fontWeight: "bold" }}>{detailTransaction.doctorName}</Text>
                    <Text style={{}}>{detailTransaction.sipNumber}</Text>
                </View>

            </Page>
        </Document>
    )
}

export default TemplateDiagnosis;

