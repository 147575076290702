import { apiKey } from "../urlConfig";

export const getAxiosHeaders = () => {
    let token = localStorage.getItem("accessToken") || null;
  
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json",
        Accept: "application/json",
        apikey: apiKey,
      },
    };
  };