import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconDummyAvatar, IconPlusWhite } from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { formatDate } from '../../../helpers/utils';
import './AccountProfile.scss';

const MobileListProfile = ({ setAddProfile, data, setEditProfile, logOut }) => {
  /* Router DOM */
  const navigate = useHistory();

  return (
    <React.Fragment>
      <div className="d-xl-none d-block mt-0">
        <p className="title-card text-white mt-0 mb-2 text-center">
          Daftar Profil
        </p>
        <div className="d-flex justify-content-end  mb-3">
          <Button
            label="Tambah Anggota"
            icon={IconPlusWhite}
            iconPosition="right"
            iconSize="sm"
            btnSize="md"
            disable={false}
            type="primary"
            onClick={() => setAddProfile()}
          />
          <div style={{ width: '180px' }} className="ml-2">
            <Button
              label="Keluar"
              iconSize="sm"
              btnSize="md"
              disable={false}
              type="primary-blue"
              onClick={logOut}
            />
          </div>
        </div>
        <div className="mt-md-4 mt-0 w-100">
          {data?.length &&
            data.map((item, index) => {
              return (
                <Card key={index} useInnerBorder className="mb-3">
                  <img
                    src={IconDummyAvatar}
                    alt="icon"
                    className="mx-auto d-flex"
                    height={60}
                    width={60}
                  />
                  <p className="mobile-profile-name text-center my-2">
                    {item?.name || '-'}
                  </p>
                  <p className="mobile-profile-dob my-1">
                    {formatDate(item?.dob, false) || '-'}
                  </p>
                  <p className="mobile-profile-dob my-1">
                    {item?.email || '-'}
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      label="Ubah"
                      className="mr-1 font-weight-normal"
                      onClick={() => setEditProfile(item)}
                    />
                    {/* <Button
                      label="Riwayat"
                      className="ml-1 font-weight-normal"
                      onClick={() =>
                        navigate.push(`/history-order`, {
                          profilId: item.id,
                        })
                      }
                    /> */}
                  </div>
                </Card>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileListProfile;
