import { gapi } from 'gapi-script';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getTransactionHistory,
} from '../../../actions';
import {
  IconCameraBgTransparent,
  IconCloseGreen,
  IconDocumentGreen,
  IconDummyAvatar,
  IconLocationBgTransparent,
  IconPencilGreen,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import ModalComp from '../../../components/Modal';
import Layout from '../Components/Layout';
import Pagination from '../Components/Pagination';
import DesktopFilterTransactionHistory from './DesktopFilterTransactionHistory';
import DesktopTransactionDone from './DesktopTransactionDone';
import MobileTransactionHistory from './MobileTransactionHistory';
import './TransactionHistory.scss';
moment.locale('id');

const TransactionHistory = () => {
  var klinikLogo = window.localStorage.getItem('klinikLogo');
  const navigate = useHistory();
  let PageSize = 5;
  const { state } = useLocation();

  const transactionHistory = useSelector((state) => state.transactionHistory);
  const [detailModal, setDetailModal] = useState(false);
  const [konsultasiModal, setKonsultasiModal] = useState(false);
  const [active, setActive] = useState(1);
  const [profilId, setProfilId] = useState(state ? state.profilId : '');
  const [filter, setFilter] = useState({
    profilId: '',
    consultationType: '',
    scheduleDate: '',
  });
  const [status, setStatus] = useState('9,80,85,81,86');
  const [userId, setUserId] = useState(window.localStorage.getItem('userId'));

  const [listProfil, setListProfil] = useState([]);
  const [listConsultationType, setListConsultationType] = useState([]);
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState([]);
  const [isHovering, setIsHovering] = useState('');
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const dispatch = useDispatch();
  const [viewVersion, setViewVersion] = useState('desktop');

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setData(history.slice(firstPageIndex, lastPageIndex));
    return history.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  useEffect(() => {
    currentTableData = history.slice(0, PageSize);
    setData(currentTableData);
  }, [history]);

  useEffect(() => {
    dispatch(getTransactionHistory(userId, status, filter));
    navigator.geolocation.getCurrentPosition(function (position) {
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
    });
  }, [userId, status, filter]);

  useEffect(() => {
    setFilter({
      ...filter,
      profilId: profilId,
    });
  }, [profilId]);

  useEffect(() => {
    if (transactionHistory?.transactionHistoryData?.listTrans) {
      setHistory(transactionHistory?.transactionHistoryData?.listTrans);
    }

    if (transactionHistory?.transactionHistoryData?.listProfil) {
      setListProfil(transactionHistory?.transactionHistoryData?.listProfil);
    }

    if (transactionHistory?.transactionHistoryData?.listConsultationType) {
      setListConsultationType(
        transactionHistory?.transactionHistoryData?.listConsultationType
      );
    }
  }, [transactionHistory.transactionHistoryData]);

  const resetFilter = () => {
    setFilter({
      profilId: '',
      consultationType: '',
      scheduleDate: '',
    });
  };

  const createCalendar = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: process.env.REACT_APP_CALENDAR_API_KEY,
        clientId: process.env.REACT_APP_CALENDAR_CLIENT_ID,
        discoveryDocs: [process.env.REACT_APP_CALENDAR_DISCOVERY_DOCS_CALENDAR],
        scope: process.env.REACT_APP_CALENDAR_SCOPES,
        plugin_name: 'cale',
      });

      gapi.client.load('calendar', 'v3', () => {
        // console.log('oke');
      });

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: `Appointment with ${detail.doctorname}`, // or event name
            location: `${detail.hospitalname}`, //where it would happen
            description: `Jadwal Kunjungan ke Dokter ${detail.doctorname}`,
            start: {
              dateTime: `${detail.date}T${detail.from_time}+00:00`,
              timeZone: 'Asia/Jakarta',
            },
            end: {
              dateTime: `${detail.date}T${detail.to_time}+00:00`,
              timeZone: 'Asia/Jakarta',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            reminders: {
              useDefault: false,
              overrides: [{ method: 'popup', minutes: 20 }],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          request.execute((event) => {
            window.open(event.htmlLink);
          });
        });
    });
  };

  const handleMouseOver = (e) => {
    setIsHovering(e);
  };

  const handleOngoing = () => {
    setStatus('9,80,85,81,86');
    setActive(1);
  };

  const handleDone = () => {
    setActive(2);
    setStatus('87,88,89,90,99');
  };

  const handleClose = () => {
    setKonsultasiModal(false);
    setDetailModal(false);
  };

  const handleDetailModal = (data) => {
    setDetail(data);
    setDetailModal(true);
  };

  // const handleDetailKonsulModal = (data, version) => {
  //   dispatch(getMedicalRecord(data?.appointmentid)).then((response) => {
  //     var datas = response ? response.data : [];
  //     if (datas) {
  //       var complaints = datas.registrationData
  //         ? datas.registrationData.keluhan
  //         : '';
  //       var diagnosa = datas.registrationData
  //         ? datas.registrationData.objective
  //         : '';
  //       var icd10 = datas.icd10 ? datas.icd10 : [];
  //       var documentRegistration = datas.documentRegistration
  //         ? datas.documentRegistration
  //         : [];
  //       var documentPmr = datas.documentPmr ? datas.documentPmr : [];

  //       data['complaints'] = complaints;
  //       data['diagnosis'] = diagnosa;
  //       data['icd10'] = icd10;
  //       data['documentRegistration'] = documentRegistration;
  //       data['documentPmr'] = documentPmr;
  //     }
  //     setDetail(data);
  //   });
  //   setKonsultasiModal(true);

  //   dispatch(getDiagnosis(data?.profileid, data?.registration_id));
  //   dispatch(getRecipe(data?.profileid, data?.registration_id));
  //   setViewVersion(version);
  // };

  const handlePrintMedicalResult = (payload) => {
    const form = new FormData();
    const data = payload || detail;

    form.append('orderId', data.code);
    form.append('scheduleDate', data.date);
    form.append('patientName', data.profilename);
    form.append('doctorName', data.doctorname);
    form.append('patientDob', data.profiledob);
    form.append('poliName', data.poliname);
    form.append('patientGender', data.profilegender);
    form.append('patientPhone', data.profilephone);
    form.append('complaints', data.complaints);
    form.append('diagnosis', data.diagnosis);
    form.append('SIPNumber', data.SIPNumber);
    form.append('klinikLogo', klinikLogo);

    document.getElementById('btn-cetak').style.display = 'none';
    // document.getElementById('btn-cetak-loading').style.display = 'block';
    html2canvas(document.querySelector('#capture'), {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      let imgWidth = 130;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'JPEG', 40, 20, imgWidth, imgHeight);
      pdf.save(
        data.consultationtype == 3
          ? data.consultationdesc
          : 'Konsultasi ' + data.consultationdesc + '-' + data.code + '.pdf'
      );
      document.getElementById('btn-cetak').style.display = 'block';
      // document.getElementById('btn-cetak-loading').style.display = 'none';
    });
  };

  const renderDetailTrans = () => {
    return (
      <ModalComp
        title="Detail Transaksi"
        show={detailModal}
        setShow={handleClose}
        size="sm"
        useButtonClose
        buttonCloseLabel="Tutup"
      >
        <div style={{ padding: '10px 18% 5px 18%' }}>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                No. Antrian
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                : {detail.no}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                Rekap Medis
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                : {detail.medicalnumber}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                Nama Dokter
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                : {detail.doctorname}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                Nama Poli
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                : {detail.poliname}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                Klinik
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                : {detail.hospitalname}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                Tanggal
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                : {moment(detail.date).format('D MMMM YYYY')}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col md style={{ maxWidth: '44%' }}>
              <label
                style={{
                  display: 'inline',
                  fontSize: '13px',
                  fontWeight: 'lighter',
                }}
              >
                Waktu
              </label>
            </Col>
            <Col md style={{ maxWidth: '56%' }}>
              <label style={{ display: 'inline', fontSize: '13px' }}>
                :{' '}
                {moment(detail.date + ' ' + detail.from_time).format('H:mm') +
                  ' - ' +
                  moment(detail.date + ' ' + detail.to_time).format('H:mm') +
                  ' ' +
                  detail.timezone}
              </label>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '45px',
              textAlign: 'center',
            }}
          >
            <Col>
            <p>
              Bagikan &nbsp;
              <button
                className="circle-share"
                onClick={() =>
                  window.open(
                    'whatsapp://send?text=' +
                      window.location.origin +
                      '/appointment-created/' +
                      detail.appointmentid
                  )
                }
                onMouseOver={() => handleMouseOver('Bagikan ke WA')}
                onMouseOut={() => handleMouseOver('')}
              >
                {' '}
                <i
                  className="fa fa-whatsapp"
                  aria-hidden="true"
                  style={{ fontSize: '21px' }}
                />
              </button>
              <button
                className="circle-share pt-2"
                onClick={() =>
                  navigator.clipboard.writeText(
                    window.location.origin +
                      '/appointment-created/' +
                      detail.appointmentid
                  )
                }
                onMouseOver={() => handleMouseOver('Salin Tautan')}
                onMouseOut={() => handleMouseOver('')}
              >
                {' '}
                <i
                  className="fa fa-link"
                  aria-hidden="true"
                  style={{ fontSize: '18px' }}
                />
              </button>
            </p>
            {/* <p
              style={{
                fontSize: '10px',
                textAlign: 'center',
                marginLeft: '35%',
              }}
            >
              {isHovering}
            </p> */}
            </Col>

          </Row>
        </div>
      </ModalComp>
    );
  };

  const diagnosisData = useSelector(
    (state) => state.transactionHistory.diagnosisData
  );
  const handlePrintDiagnosis = (data) => {
    const payload = {
      profilId: data.profileid,
      registrationId: data.registration_id,
    };

    navigate.push('print/diagnosis', [payload]);
  };

  const handlePrintRecipe = (data) => {
    const payload = {
      profilId: data.profileid,
      registrationId: data.registration_id,
    };
    navigate.push('print/recipe', [payload]);
  };

  const recipeData = useSelector(
    (state) => state.transactionHistory.recipeData
  );
  const handleInvoicePage = (data) => {
    const payload = {
      profilId: data.profileid,
      registrationId: data.registration_id,
    };
    navigate.push('history-order/invoice', [payload]);
  };

  return (
    <Layout>
      {/* Desktop & Mobile Filter */}
      <DesktopFilterTransactionHistory
        filter={filter}
        listConsultationType={listConsultationType}
        listProfil={listProfil}
        resetFilter={resetFilter}
        setFilter={setFilter}
      />
      <div className="header-tab">
        <span
          className={active == 1 ? 'btn-tab active' : 'btn-tab'}
          onClick={handleOngoing}
        >
          <b className={active !== 1 ? 'font-weight-normal font16' : 'font-weight-700 font16'}>
            Berlangsung
          </b>
        </span>
        <span
          className={active !== 1 ? 'btn-tab active' : 'btn-tab'}
          onClick={handleDone}
        >
          <b className={active === 1 ? 'font-weight-normal font16' : 'font-weight-700 font16'}>
            Selesai
          </b>
        </span>
      </div>
      {/* Desktop View */}
      <div className="d-none d-xl-block">
        <Card type="primary" className="mb-3" innerClassName="no-padding">
          {data && data.length ? (
            data.map((data, i) => (
              <>
                {active === 1 ? (
                  <div key={i} className={`card-inner mb-3`}>
                    <div className="row px-3">
                      <div className="col pr-0">
                        <p className="font14 font-weight-600 text-dark">
                          Tipe Konsultasi :
                        </p>
                        <p className="font14 text-dark">
                          {data.consultationtype == 3
                            ? data.consultationdesc
                            : 'Konsultasi ' + data.consultationdesc}
                        </p>
                      </div>
                      <div className="col px-0">
                        <p className="font14 font-weight-600 text-dark">
                          Pasien :
                        </p>
                        <p className="font14 text-dark">
                          {data.profilename || '-'}
                        </p>
                      </div>
                      <div className="col px-0">
                        <p className="font14 font-weight-600 text-dark">
                          No. Order :
                        </p>
                        <p className="font14 text-dark">
                          {data.registrationNumber || '-'}
                        </p>
                      </div>
                      <div className="col px-0">
                        <p className="font14 font-weight-600 text-dark">
                          Status :
                        </p>
                        <p className="font14 text-dark">
                          {data.orderstatusdesc || '-'}
                        </p>
                      </div>
                      <div className="col-3 my-auto">
                        <Button
                          label="Batalkan Pesanan"
                          type="outline-primary"
                          icon={IconCloseGreen}
                          iconPosition="right"
                          size="sm"
                          onClick={() =>
                            window.open('https://wa.me/628118481436')
                          }
                        />
                      </div>
                    </div>
                    <hr className="no-padding" />
                    <div className="row px-3 pb-3">
                      <div className="col pr-0 d-flex justify-content-start">
                        <img
                          src={IconDummyAvatar}
                          alt="profile"
                          height={60}
                          width={60}
                        />
                        <div className="ml-2 my-auto">
                          <p className="font-weight-600 text-dark font16">
                            {data.doctorname || '-'}
                          </p>
                          <p className="text-dark font14">
                            {data.specializationname || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col px-0 my-auto">
                        <p className="font16 font-weight-600 text-dark">
                          Tanggal Konsultasi :
                        </p>
                        <p className="text-dark font14">
                          {moment(data.date).format('dddd, D MMM YYYY')}
                        </p>
                        <p className="text-dark font14">
                          {moment(data.date + ' ' + data.from_time).format(
                            'H:mm'
                          )}{' '}
                          -
                          {moment(data.date + ' ' + data.to_time).format(
                            'H:mm'
                          )}{' '}
                          {data.timezone}
                        </p>
                      </div>
                      <div className="col px-0 my-auto">
                        <div className="w-100">
                          {active == 1 ? (
                            data.consultationtype == 3 ? (
                              <>
                                <Button
                                  label="Masuk Telekonsultasi"
                                  icon={IconCameraBgTransparent}
                                  iconPosition="right"
                                  type="primary-blue"
                                  className="px-3"
                                  onClick={() => {
                                    window.open(data.zoomurl);
                                  }}
                                />
                                <p className="font14 text-dark text-center">
                                  {data.hospitalname || '-'}
                                </p>
                              </>
                            ) : (
                              <>
                                <Button
                                  label="Petunjuk Lokasi"
                                  icon={IconLocationBgTransparent}
                                  iconPosition="right"
                                  type="primary-blue"
                                  size="sm"
                                  onClick={() => {
                                    window.open(
                                      'https://www.google.com/maps/dir/' +
                                        latitude +
                                        ',' +
                                        longitude +
                                        '/' +
                                        data.hospitalname +
                                        '/@' +
                                        data.hospitallatitude +
                                        ',' +
                                        data.hospitallongitude
                                    );
                                  }}
                                />
                                <p className="font14 text-dark text-center">
                                  {data.hospitalname || '-'}
                                </p>
                              </>
                            )
                          ) : (
                            <>
                              <p className="font14 text-dark text-center">
                                {data.hospitalname || '-'}
                              </p>
                              <h5 className="ml-3">
                                &nbsp;{data.hospitaladdress}
                              </h5>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col my-auto">
                        <Button
                          label="Ubah Jadwal"
                          type="outline-primary"
                          icon={IconPencilGreen}
                          iconPosition="right"
                          size="sm"
                          onClick={() =>
                            window.open('https://wa.me/628118481436')
                          }
                        />
                        <Button
                          label="Lihat Detail"
                          type="outline-primary"
                          className="mt-2"
                          icon={IconDocumentGreen}
                          onClick={() => handleDetailModal(data)}
                          iconPosition="right"
                          size="sm"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <DesktopTransactionDone
                    data={data}
                    handlePrintMedicalResult={handlePrintMedicalResult}
                  />
                )}
              </>
            ))
          ) : (
            <Card useInnerBorder>
              <p className="text-dark text-center my-5 font-italic">
                -- Tidak ada data --
              </p>
            </Card>
          )}
        </Card>
        <div className="trans-footer">
          {history && history.length ? (
            <div className="col-12 d-flex justify-content-between align-items-center mb-5">
              <span className="text-footer font14 font-weight-400">
                {PageSize <= history.length
                  ? Math.ceil(parseInt(PageSize) / parseInt(history.length)) *
                    parseInt(PageSize)
                  : history.length}{' '}
                Hasil pencarian ditemukan: Halaman {currentPage} dari{' '}
                {Math.ceil(parseInt(history.length) / parseInt(PageSize)) > 0
                  ? Math.ceil(parseInt(history.length) / parseInt(PageSize))
                  : 1}
              </span>

              <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={history.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />

            </div>
          ) : <div className='col-12'><div className="col-12">&nbsp;</div></div>}
        </div>
      </div>
      <MobileTransactionHistory
        active={active}
        handleDetailModal={handleDetailModal}
        handleInvoicePage={handleInvoicePage}
        list={data}
        PageSize={PageSize}
        currentPage={currentPage}
        history={history}
        latitude={latitude}
        longitude={longitude}
        setCurrentPage={setCurrentPage}
        handlePrintMedicalResult={handlePrintMedicalResult}
      />
      {renderDetailTrans()}
      {/* {renderDetailKonsul()} */}
    </Layout>
  );
};

export default TransactionHistory;
