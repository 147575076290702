import React, { useState } from 'react';
import {
  IconArrowGrey,
  IconNurseBlurBottom,
  IconSearch,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import { wordingFindDoctor } from '../../../constants/wording';
import { InputText, SelectOptions } from '../../../components/Input';
import './FindDoctor.scss';
import { Col, Row } from 'react-bootstrap';

const MobileFindDoctor = ({
  onChangeName,
  onEnterName,
  clinicOptions,
  onChangeClinic,
  onSearch,
  serviceType,
  serviceName
}) => {
  const [doctorName, setDoctorName] = useState();
  const [clinic, setClinic] = useState();

  const onChangeDoctorNameValue = (value) => {
    console.log("value", value);
    const result = value.replace(/[^A-Za-z ]/gi, '');
    setDoctorName(result);
    const tempStr = {
      target: {
        value: result,
      },
    };
    onChangeName(tempStr);
  };

  const onChangeClinicValue = (e) => {
    const formatPayload = {
      target: e,
    };
    setClinic(e);
    onChangeClinic(formatPayload);
  };

  const onSubmitSearch = () => {
    const payload = { name: doctorName, clinic: clinic?.value };
    onSearch(payload);
  };


  const renderContent = () => {
    // console.log("serviceType", serviceType);
    switch (serviceType) {
      case 'JT':
        return renderConsultation()
      case 'TL' :
        return renderConsultation()
      case 'PM': 
        return renderMedicalCheckUp()
      default:
        return renderConsultation()
    }
  }

  const renderConsultation = () => {

    return (
      <Col sm="12" md="12">
        <p className="text-center mobile-title">
          {serviceName}
        </p>
        <div className="input-icons mt-4">
          <InputText
            placeholder="Ketik nama dokter"
            value={doctorName}
            icon={IconSearch}
            iconPosition="right"
            setValue={(e) => onChangeDoctorNameValue(e)}
            // onKeyPress={(e) => {
            //   console.log(e, "key");
            //   if (e.key === 'Enter') {
                
            //     onEnterName({ name: doctorName, clinic: clinic?.value });
            //   }
            // }}
            type="text"
            iconOnClick={() => 
              onEnterName({ name: doctorName, clinic: clinic?.value })
            }
            enterOnPress={() => 
                onEnterName({ name: doctorName, clinic: clinic?.value })
            }

          />
        </div>
        <SelectOptions
          borderColor="transparent"
          placeholder="Klinik"
          fontSize="lg"
          options={clinicOptions}
          customIcon={IconArrowGrey}
          setValue={onChangeClinicValue}
          value={clinic}
          color="#757575"
          background="white"
          iconClassName="mr-1"
        />
        <div className="mt-3">
          <Button
            label="Cari"
            iconSize="sm"
            btnSize="md"
            disable={false}
            type="primary"
            onClick={onSubmitSearch}
          />
        </div>
        <img
          src={IconNurseBlurBottom}
          alt="icon"
          className="mt-5 mx-auto image-dokter-mobile"
          width={370}
        />
      </Col>
    )

  }

  const renderMedicalCheckUp = ()  => {
    return(
      <Col sm="12" md="12">
        <p className="text-center mobile-title">
          {serviceName}
        </p>
        <div className="input-icons mt-4">
          <InputText
            placeholder="Ketik nama dokter"
            value={doctorName}
            icon={IconSearch}
            iconPosition="right"
            setValue={(e) => onChangeDoctorNameValue(e)}
            // onKeyPress={(e) => {
            //   console.log(e, "key");
            //   if (e.key === 'Enter') {
                
            //     onEnterName({ name: doctorName, clinic: clinic?.value });
            //   }
            // }}
            type="text"
            iconOnClick={() => 
              onEnterName({ name: doctorName, clinic: clinic?.value })
            }
            enterOnPress={() => 
                onEnterName({ name: doctorName, clinic: clinic?.value })
            }

          />
        </div>
        <SelectOptions
          borderColor="transparent"
          placeholder="Klinik"
          fontSize="lg"
          options={clinicOptions}
          customIcon={IconArrowGrey}
          setValue={onChangeClinicValue}
          value={clinic}
          color="#757575"
          background="white"
          iconClassName="mr-1"
        />
        <div className="mt-3">
          <Button
            label="Cari"
            iconSize="sm"
            btnSize="md"
            disable={false}
            type="primary"
            onClick={onSubmitSearch}
          />
        </div>
        <img
          src={IconNurseBlurBottom}
          alt="icon"
          className="mt-5 mx-auto image-dokter-mobile"
          width={370}
        />
      </Col>
    )

  }


  return (
    <React.Fragment>
      <div className="d-xl-none d-block">
        <Row>
          {/* <Col sm="12" md="12">
            <p className="text-center mobile-title">
            {serviceType === 'TL'
              ? wordingFindDoctor.titleTele
              : wordingFindDoctor.titleReguler}
            </p>
            <div className="input-icons mt-4">
              <InputText
                placeholder="Ketik nama dokter"
                value={doctorName}
                icon={IconSearch}
                iconPosition="right"
                setValue={(e) => onChangeDoctorNameValue(e)}
                // onKeyPress={(e) => {
                //   console.log(e, "key");
                //   if (e.key === 'Enter') {
                    
                //     onEnterName({ name: doctorName, clinic: clinic?.value });
                //   }
                // }}
                type="text"
                iconOnClick={() => 
                  onEnterName({ name: doctorName, clinic: clinic?.value })
                }
                enterOnPress={() => 
                    onEnterName({ name: doctorName, clinic: clinic?.value })
                }

              />
            </div>
            <SelectOptions
              borderColor="transparent"
              placeholder="Klinik"
              fontSize="lg"
              options={clinicOptions}
              customIcon={IconArrowGrey}
              setValue={onChangeClinicValue}
              value={clinic}
              color="#757575"
              background="white"
              iconClassName="mr-1"
            />
            <div className="mt-3">
              <Button
                label="Cari"
                iconSize="sm"
                btnSize="md"
                disable={false}
                type="primary"
                onClick={onSubmitSearch}
              />
            </div>
            <img
              src={IconNurseBlurBottom}
              alt="icon"
              className="mt-5 mx-auto image-dokter-mobile"
              width={370}
            />
          </Col> */}
          {renderContent()}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MobileFindDoctor;
