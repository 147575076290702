import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useState } from 'react';
import { Button as ReactButton, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  createAppointment,
  getCompanyGuarantee,
  getInsuranceByHospital,
  uploadInsuranceDocFile,
  uploadJaminanDocFile,
} from '../../../../actions';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCloseGreen,
  IconPlusWhite,
} from '../../../../assets/img/icon';
import Button from '../../../../components/Button';
import { InputText, SelectOptions } from '../../../../components/Input';
import ModalComp from '../../../../components/Modal';
import {
  documentEmployeeTypes,
  documentInsuranceTypes,
} from '../../../../constants/common';
import Layout from '../../Components/Layout';
import '../../PaymentMethod/paymentMethod.scss';
import MobileInsurance from './MobileInsurance';
moment.locale('id');

const Insurance = () => {
  /* Router DOM */
  const navigate = useHistory();

  /* Redux */
  const insurance = useSelector((state) => state.paymentMethod);
  const { loading } = useSelector((state) => state.appointment);

  /* State */
  const { state } = useLocation();
  if (!state) window.location = '/patient';
  const doctorName = state.appointmentData
    ? state.appointmentData.doctorName
    : '';
  const hospitalName = state.appointmentData
    ? state.appointmentData.hospitalName
    : '';

  /* Local State */
  const [errorField, setErrorField] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [insuranceName, setInsuranceName] = useState();
  const [insuranceNumber, setInsuranceNumber] = useState();
  const [insuranceMemberName, setInsuranceMemberName] = useState();
  const [companyName, setCompanyName] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [userId, setUserId] = useState(window.localStorage.getItem('userId'));
  const [fullName, setFullName] = useState(
    window.localStorage.getItem('fullName') != 'null'
      ? window.localStorage.getItem('fullName')
      : ''
  );
  const [firstName, setFirstName] = useState(
    window.localStorage.getItem('firstName') != 'null'
      ? window.localStorage.getItem('firstName')
      : ''
  );
  const [appointmentData, setAppointmentData] = useState(state.appointmentData);
  const [paymentTypeId, setPaymentTypeId] = useState(null);

  const [insuranceDoc, setInsuranceDoc] = useState([]);
  const [docInsuranceTypeDisplay, setDocInsuranceTypeDisplay] = useState([]);
  const [insuranceDocModal, setInsuranceDocModal] = useState(false);

  const [docType, setDocType] = useState();

  const [jaminanDoc, setJaminanDoc] = useState([]);
  const [jaminanDocModal, setJaminanDocModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [informationModal, setInformationModal] = useState(false);
  const [errorRequired, setErrorRequired] = useState([]);

  const prevPageUrl = '/patient';

  const dispatch = useDispatch();
  useEffect(() => {
    var hospitalId = appointmentData.hospital_id;
    if (hospitalId) {
      dispatch(getInsuranceByHospital(hospitalId));
      dispatch(getCompanyGuarantee(hospitalId));
    }
  }, [appointmentData.hospital_id]);

  useEffect(() => {
    var urlInsuranceDoc = insurance.urlInsuranceDoc
      ? insurance.urlInsuranceDoc.url
      : '';

    if (urlInsuranceDoc) {
      setInsuranceDoc([
        ...insuranceDoc,
        {
          id: insuranceDoc.length + 1,
          docType: docType,
          docUrl: urlInsuranceDoc,
        },
      ]);

      handleCloseModal();
    }
  }, [insurance.urlInsuranceDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, insuranceDoc });
  }, [insuranceDoc]);

  useEffect(() => {
    var urlJaminanDoc = insurance.urlJaminanDoc
      ? insurance.urlJaminanDoc.url
      : '';

    if (urlJaminanDoc) {
      setJaminanDoc([
        ...jaminanDoc,
        {
          id: jaminanDoc.length + 1,
          docType: docType,
          docUrl: urlJaminanDoc,
        },
      ]);
      handleCloseModal();
    }
  }, [insurance.urlJaminanDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, jaminanDoc });
  }, [jaminanDoc]);

  const paymentTypeSelected = (e) => {
    const paymentTypeId = e.target.value;
    const payerClinicsId = e.target.id;
    const payerClinicsName = e.target.label;

    if (paymentTypeId) {
      setPaymentTypeId(paymentTypeId);
      setAppointmentData({
        ...appointmentData,
        payer: paymentTypeId,
        payer_name: '',
        payer_no: '',
        payer_owner_name: '',
        payerClinicsId: payerClinicsId,
        payerClinicsName: payerClinicsName,
        insuranceClinicsId: 0,
      });
      setErrorRequired([]);
    }
  };

  const onChangeInsuranceName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_name: e.target.label,
      insuranceClinicsId: e?.target?.id,
    });
    if (e.target.value === '8') {
      // document.getElementById('selectOptionPatient').selectedIndex = 3;
      setPaymentTypeId('8');
      setAppointmentData({
        ...appointmentData,
        payer: '8',
      });
    }
  };

  const onChangeCompanyName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_name: e.target.value,
      insuranceClinicsId: e.target?.id,
    });
    if (e.target.value === '8') {
      // document.getElementById(
      //   'selectOptionPatient'
      // ).selectedIndex = 3;
      setPaymentTypeId('8');
      setAppointmentData({
        ...appointmentData,
        payer: '8',
      });
    }
  };

  const onChangeInsuranceNumber = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_no: e,
    });
  };

  const onChangeInsuranceMemberName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_owner_name: e,
    });
  };

  const onChangeEmployeeName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_owner_name: e,
    });
  };

  const uploadDocumment = (e) => {
    var uploadId = e.target.id;

    if (!docType) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tipe Dokumen',
      });
    } else {
      if (uploadId == 'uploadInsurance') {
        var uploadInsurance = document.getElementById('uploadInsurance').files;
        dispatch(uploadInsuranceDocFile(uploadInsurance[0], docType?.value));
      } else {
        var uploadJaminan = document.getElementById('uploadJaminan').files;
        dispatch(uploadJaminanDocFile(uploadJaminan[0], docType?.value));
      }
    }
  };

  const handleInsuranceDocModal = () => {
    setInsuranceDocModal(true);
  };

  const renderInsuranceDocModal = () => {
    return (
      <ModalComp
        title="Dokumen Jaminan Asuransi"
        show={insuranceDocModal}
        setShow={handleCloseModal}
        onSubmit={handleCloseModal}
        size="sm"
        buttonSubmitLabel="Simpan"
        submitLoading={insurance?.loading}
      >
        <div style={{ height: '120px' }}>
          <SelectOptions
            isClearable
            isSearchable
            placeholder="Pilih tipe dokumen"
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
            setValue={setDocType}
            value={docType}
            options={documentInsuranceTypes}
            fontSize="lg"
          />
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <input
                type="file"
                id="uploadInsurance"
                style={{ display: 'none' }}
                onChange={(e) => uploadDocumment(e)}
              />
              <label
                htmlFor="uploadInsurance"
                className="label-file-upload"
                style={{ paddingBottom: '30px' }}
              >
                <img src={IconPlusWhite} alt="icon" /> Unggah
              </label>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  const handleJaminanDocModal = () => {
    setJaminanDocModal(true);
  };

  const renderJaminanDocModal = () => {
    return (
      <ModalComp
        title="Dokumen Jaminan Perusahaan"
        show={jaminanDocModal}
        setShow={handleCloseModal}
        size="sm"
        buttonSubmitLabel="Simpan"
        onSubmit={handleCloseModal}
        submitLoading={insurance?.loading}
      >
        <div style={{ height: '120px' }}>
          <SelectOptions
            isClearable
            isSearchable
            placeholder="Pilih tipe dokumen"
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
            setValue={setDocType}
            value={docType}
            options={documentEmployeeTypes}
            fontSize="lg"
          />
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <input
                type="file"
                id="uploadJaminan"
                style={{ display: 'none' }}
                onChange={(e) => uploadDocumment(e)}
              />
              <label
                htmlFor="uploadJaminan"
                className="label-file-upload"
                style={{ paddingBottom: '30px' }}
              >
                <img src={IconPlusWhite} alt="icon" /> Unggah
              </label>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  const renderInformationModal = () => {
    return (
      <ModalComp
        show={informationModal}
        setShow={() => {
          setJaminanDocModal(false);
          setInsuranceDocModal(false);
          setInformationModal(false);
          setConfirmationModal(true);
        }}
        useButtonClose
        useHeaderButtonClose
        buttonCloseLabel="Saya Setuju"
        title="Informasi Untuk Anda"
      >
        <p
          className="text-center warning-modal-text"
          style={{
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#424242',
            marginTop: '32px',
            marginBottom: '72px',
          }}
        >
          {+paymentType?.value === 2
            ? 'Pembayaran untuk konsultasi akan dilakukan di klinik/ rumah sakit setelah konsultasi selesai.'
            : `Jaminan ini hanya berlaku sementara untuk melanjutkan pesanan kamu.
              Sebelum konsultasi, pihak Klinik akan melakukan verifikasi jaminan
              kamu dan jika tidak lolos verifikasi atau ada potensi ekses biaya,
              pihak klinik akan menginformasikannya saat anda melakukan registrasi
              di klinik.`}
        </p>
      </ModalComp>
    );
  };

  //konfirmasi data
  const handleConfirmationModal = () => {
    if (paymentTypeId == '3') {
      if (
        appointmentData.payer_name &&
        appointmentData.payer_no &&
        appointmentData.payer_owner_name
      ) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_name) {
          errorData.push('payer_name');
        }
        if (!appointmentData.payer_no) {
          errorData.push('payer_no');
        }
        if (!appointmentData.payer_owner_name) {
          errorData.push('payer_owner_name');
        }
        setErrorRequired(errorData);
        setErrorField(true);
      }
    } else if (paymentTypeId == '4') {
      if (appointmentData.payer_name && appointmentData.payer_owner_name) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_name) {
          errorData.push('payer_name');
        }
        if (!appointmentData.payer_owner_name) {
          errorData.push('payer_owner_name');
        }
        setErrorRequired(errorData);
        setErrorField(true);
      }
    } else if (paymentTypeId == '8') {
      if (appointmentData.payer_name) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_name) {
          errorData.push('payer_name');
        }
        setErrorRequired(errorData);
        setErrorField(true);
      }
    } else {
      setInformationModal(true);
    }
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setInsuranceDocModal(false);
    setJaminanDocModal(false);
    setDocType('');
  };

  const actionCreateAppointment = () => {
    const form = new FormData();

    var userPhone = window.localStorage.getItem('phone');
    var config = JSON.parse(window.localStorage.getItem('headerData'));

    form.append('doctor_hospital_id', appointmentData.doctor_hospital_id);
    form.append('specialization_id', appointmentData.specialization_id);
    form.append('poli_id', appointmentData.poli_id);
    form.append('hospitalGroupId', config ? config.klinik_group_id : null);
    form.append('userPhone', userPhone);
    form.append('identity_type', state.selectedPatient.identity_type);
    form.append('identity_name', state.selectedPatient.identity_name);
    form.append('identity_number', state.selectedPatient.identity_number);
    form.append('name', state.selectedPatient.name);
    form.append('gender', state.selectedPatient.gender);
    form.append(
      'dob',
      moment(state.selectedPatient.dob, 'YYYY-MM-DD').format('YYYY-MM-DD')
    );
    form.append('phone_1', state.selectedPatient.phone_1);
    form.append('email', state.selectedPatient.email);
    form.append('relation', state.selectedPatient.relationname);
    form.append('user_id', userId);

    form.append('hospital_id', appointmentData.hospital_id);
    form.append('doctor_id', appointmentData.doctor_id);
    form.append('schedule_id', appointmentData.doctor_schedule_id);
    form.append('doctor_schedule_id', appointmentData.doctor_schedule_id);

    if (
      state.selectedPatient.id != null &&
      state.selectedPatient.id != undefined &&
      state.selectedPatient.id != ''
    ) {
      form.append('profile_id', state.selectedPatient.id);
    }

    form.append(
      'date',
      moment(appointmentData.date, 'DDMMYYYY').format('YYYY-MM-DD')
    );
    form.append('from_time', appointmentData.from_time);
    form.append('to_time', appointmentData.to_time);
    form.append('note', appointmentData.note);

    if (appointmentData.medicalDoc) {
      form.append(
        'medical_doc',
        appointmentData.medicalDoc?.length
          ? JSON.stringify(appointmentData.medicalDoc)
          : ''
      );
    }

    form.append('payer', appointmentData.payer);
    form.append('payerClinicsId', appointmentData.payerClinicsId);
    form.append('insuranceClinicsId', appointmentData.insuranceClinicsId);
    if (appointmentData.payer_name) {
      form.append('payer_name', appointmentData.payer_name);
    }

    if (appointmentData.payer_no) {
      form.append('payer_no', appointmentData.payer_no);
    }

    if (appointmentData.payer_owner_name) {
      form.append('payer_owner_name', appointmentData.payer_owner_name);
    }

    if (paymentTypeId == '3' && insuranceDoc.length != 0) {
      form.append('payer_doc', JSON.stringify(insuranceDoc));
    }

    if (paymentTypeId == '4' && jaminanDoc.length != 0) {
      form.append('payer_doc', JSON.stringify(jaminanDoc));
    }

    form.append('complaints', appointmentData.complaints);
    form.append('firstName', firstName);
    form.append('status', 85);
    form.append('isCMS', false);
    form.append('scheduleType', appointmentData.scheduleType);
    form.append('consultationType', appointmentData.consultation_type);
    form.append('mcuProceduresId', appointmentData.mcuProceduresId);
    form.append('serviceType', appointmentData.serviceType);

    if (appointmentData.is_waiting_list) {
      form.append('is_waiting_list', appointmentData.is_waiting_list);
    }

    dispatch(createAppointment(form))
      .then((response) => {
        var datas = response.data;
        if (datas.data) {
          setTimeout(
            () =>
              Swal.fire({
                icon: 'success',
                title: 'Janji Temu Berhasil Dibuat',
                showConfirmButton: true,
                confirmButtonText: 'OK',
              }).then(() => {
                if (datas.userData) {
                  localStorage.setItem('firstName', datas.userData.firstName);
                  localStorage.setItem('fullName', datas.userData.fullName);
                }

                if (datas.data) {
                  window.location = '/appointment-created/' + datas.data.id;
                }
              }),
            200
          );
        } else {
          return Swal.fire({
            icon: 'error',
            text: response.message,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: 'error',
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: 'error',
            text: err.message,
          });
        }
      });
  };

  const renderConfirmationModal = () => {
    return (
      <ModalComp
        title="Konfirmasi Data"
        show={confirmationModal}
        setShow={handleCloseModal}
        buttonSubmitLabel={
          appointmentData.serviceType === 'PM'
            ? 'Buat Pemeriksaan Medis'
            : 'Buat Janji Temu'
        }
        onSubmit={actionCreateAppointment}
        buttonCloseLabel="Tutup"
        useButtonClose
        size="md"
        submitLoading={loading}
      >
        <Row
          style={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 'bolder',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <span>
            Halo {fullName}, <br />
            pastikan data yang Anda masukkan telah sesuai.
          </span>
        </Row>
        <div className="paymentMethodConfirmation">
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">
                Jenis Identitas :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.identity_name}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">
                Nomor Identitas :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.identity_number}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Nama :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.name}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Tanggal Lahir :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {moment(state.selectedPatient.dob).format('D MMMM YYYY')}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Nomor HP :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {'+62' + state.selectedPatient.phone_1}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Email :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.email}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Jenis Kelamin :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {' '}
                {state.selectedPatient.gender == 1 ? 'Laki-Laki' : 'Perempuan'}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Nama Dokter :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.appointmentData.doctorName}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Klinik :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.appointmentData.hospitalName}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Tanggal :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {' '}
                {moment(state.appointmentData.date, 'DDMMYYYY').format(
                  'dddd, D MMMM YYYY'
                )}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Waktu :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {' '}
                {state.appointmentData.from_time +
                  ' - ' +
                  state.appointmentData.to_time +
                  ' ' +
                  state.appointmentData.timezone}{' '}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className="label-confirmation-left">Jenis Jaminan :</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {appointmentData.payerClinicsName}
              </label>
            </Col>
          </Row>
          <label
            style={{
              fontSize: '16px',
              textAlign: 'center',
              fontWeight: 'normal',
              wordWrap: 'break-word',
            }}
          >
            dengan melanjutkan proses reservasi ini, Anda telah menyetujui
            <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
              {' '}
              Syarat dan Ketentuan{' '}
            </a>
            penggunaan dan kebijakan privasi janji temu {hospitalName}
          </label>
        </div>
      </ModalComp>
    );
  };

  return (
    <Layout>
      <div className="content-payment-method col-12 mt-5 d-xl-block d-none">
        <Row className="col-5">
          <div
            className="cursor-pointer d-flex flex-row"
            onClick={() =>
              navigate.push({
                pathname: prevPageUrl,
                state: {
                  selectedPatient: state.selectedPatient,
                  appointmentData: appointmentData,
                },
              })
            }
          >
            <img src={IconArrowGreen} alt="icon" />
            <p className="title-page ml-2 text-white">Data Pasien</p>
          </div>
        </Row>
        <Row>
          <div className="col-3 mt-5 ml-5">
            <div className="nav-step">
              <button className="circle-btn-step">1</button>
              <span className="label-step">Jadwal</span>
            </div>
            <div className="nav-step">
              <button className="circle-btn-step">2</button>
              <span className="label-step">Data Pasien</span>
            </div>
            <div className="nav-step">
              <button className="circle-btn-step activePage">3</button>
              <span className="label-step">
                <b>
                  Pembayaran{' '}
                  {appointmentData.consultation_name &&
                    `(${appointmentData.consultation_name})`}
                </b>
              </span>
            </div>
          </div>
          <div className="col-8">
            <div className="content-paymentMethod-step">
              <h1>Jaminan</h1>
              <h2>Silahkan pilih jenis jaminan yang Anda inginkan</h2>
              <SelectOptions
                placeholder="Pilih jenis jaminan"
                label="Pilih Jenis Jaminan"
                labelClassName="mt-5"
                background="#ffff"
                value={paymentType}
                fontSize="lg"
                setValue={(e) => {
                  const formatPayload = {
                    target: e,
                  };
                  setPaymentType(e);
                  paymentTypeSelected(formatPayload);
                  setErrorField(false);
                }}
                options={
                  appointmentData?.hospitalPayer?.length &&
                  appointmentData?.hospitalPayer?.map((e) => {
                    return {
                      ...e,
                      label: e?.payerName,
                      value: e?.payersId,
                    };
                  })
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />
              {paymentTypeId ? (
                <div className="choose-paymentType col-13 mx-auto">
                  {paymentTypeId == '3' ? (
                    <div className="paymentType-card col-13 mx-auto">
                      <SelectOptions
                        placeholder="Pilih nama asuransi"
                        label="Nama Asuransi"
                        labelClassName="mt-4"
                        background="#ffff"
                        fontSize="lg"
                        value={insuranceName}
                        isShowWarning={errorField && !insuranceName}
                        setValue={(e) => {
                          const formatPayload = {
                            target: e,
                          };
                          setInsuranceName(e);
                          onChangeInsuranceName(formatPayload);
                        }}
                        options={
                          insurance?.insuranceData?.length &&
                          insurance?.insuranceData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        }
                        customIcon={IconArrowGrey}
                        iconClassName="mr-2"
                      />
                      <InputText
                        label="Nomor Peserta"
                        labelClassName="mt-4"
                        placeholder="Masukkan nomor peserta"
                        value={insuranceNumber}
                        isShowWarning={errorField && !insuranceNumber}
                        setValue={(value) => {
                          setInsuranceNumber(value);
                          onChangeInsuranceNumber(value);
                        }}
                        type="number"
                      />
                      <InputText
                        label="Nama Peserta"
                        labelClassName="mt-4"
                        placeholder="Masukkan nama peserta"
                        value={insuranceMemberName}
                        isShowWarning={errorField && !insuranceMemberName}
                        setValue={(value) => {
                          const result = value.replace(/[^A-Za-z ]/gi, '');
                          setInsuranceMemberName(result);
                          onChangeInsuranceMemberName(result);
                        }}
                        type="text"
                      />
                      <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        <p className="label-input-text font-weight-bold mt-4">
                          Unggah Dokumen Asuransi
                        </p>
                        <div className="w-50">
                          <Button
                            label="Tambah Dokumen"
                            type="primary-blue"
                            onClick={handleInsuranceDocModal}
                            icon={IconPlusWhite}
                            iconPosition="left"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: '5px',
                          padding: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {insuranceDoc.length > 0 ? (
                          insuranceDoc.map((data, index) => (
                            <Row key={'row' + index}>
                              <Col md style={{ width: '5px', maxWidth: '3%' }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    color: '#00BD84',
                                    marginRight: '5px',
                                  }}
                                />
                              </Col>
                              <Col
                                md
                                style={{ width: '10px', maxWidth: '60%' }}
                              >
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'lighter',
                                  }}
                                >
                                  {data
                                    ? data.docUrl.split('/').length == 6
                                      ? data.docUrl.split('/')[5]
                                      : ''
                                    : ''}
                                </span>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  &nbsp;
                                  {data?.docType === 'KartuAsuransi'
                                    ? 'Kartu Asuransi'
                                    : data?.docType === 'Lainnya'
                                    ? 'Dokumen Lainnya'
                                    : 'Berhasil Terunggah!'}
                                </span>
                              </Col>
                              <Col
                                md
                                style={{ width: '30px', maxWidth: '30%' }}
                              >
                                <a
                                  href={data.docUrl}
                                  target="_blank"
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: '#00BD84',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Lihat Dokumen
                                </a>
                              </Col>
                              <Col md>
                                <img
                                  src={IconCloseGreen}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setInsuranceDoc(
                                      insuranceDoc.filter(
                                        (e) => e.id !== data.id
                                      )
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : paymentTypeId == '4' ? (
                    <div className="paymentType-card col-13 mx-auto">
                      <SelectOptions
                        placeholder="Pilih nama perusahaan"
                        label="Nama Perusahaan"
                        labelClassName="mt-4"
                        background="#ffff"
                        fontSize="lg"
                        value={companyName}
                        isShowWarning={errorField && !companyName}
                        setValue={(e) => {
                          const formatPayload = {
                            target: e,
                          };
                          setCompanyName(e);
                          onChangeCompanyName(formatPayload);
                        }}
                        options={
                          insurance?.companyData?.length &&
                          insurance?.companyData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        }
                        customIcon={IconArrowGrey}
                        iconClassName="mr-2"
                      />
                      <InputText
                        label="Nama Karyawan"
                        labelClassName="mt-4"
                        placeholder="Masukkan nama karyawan"
                        value={employeeName}
                        isShowWarning={errorField && !employeeName}
                        setValue={(value) => {
                          const result = value.replace(/[^A-Za-z ]/gi, '');
                          setEmployeeName(result);
                          onChangeEmployeeName(result);
                        }}
                        type="text"
                      />
                      <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        <p className="label-input-text font-weight-bold mt-4">
                          Unggah Dokumen Karyawan
                        </p>
                        <div className="w-50">
                          <Button
                            label="Tambah Dokumen"
                            type="primary-blue"
                            onClick={handleJaminanDocModal}
                            icon={IconPlusWhite}
                            iconPosition="left"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: '5px',
                          padding: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {jaminanDoc.length > 0 ? (
                          jaminanDoc.map((data, index) => (
                            <Row key={'row' + index}>
                              <Col md style={{ width: '5px', maxWidth: '3%' }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    color: '#00BD84',
                                    marginRight: '5px',
                                  }}
                                />
                              </Col>
                              <Col
                                md
                                style={{ width: '10px', maxWidth: '60%' }}
                              >
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'lighter',
                                  }}
                                >
                                  {data
                                    ? data.docUrl.split('/').length == 6
                                      ? data.docUrl.split('/')[5]
                                      : ''
                                    : ''}
                                </span>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  &nbsp;
                                  {data?.docType === 'KartuJaminan'
                                    ? 'Kartu Jaminan'
                                    : data?.docType === 'Lainnya'
                                    ? 'Dokumen Lainnya'
                                    : 'Berhasil Terunggah!'}
                                </span>
                              </Col>
                              <Col
                                md
                                style={{ width: '30px', maxWidth: '30%' }}
                              >
                                <a
                                  href={data.docUrl}
                                  target="_blank"
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: '#00BD84',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Lihat Dokumen
                                </a>
                              </Col>
                              <Col md>
                                <img
                                  src={IconCloseGreen}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setJaminanDoc(
                                      jaminanDoc.filter((e) => e.id !== data.id)
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : paymentTypeId == '8' ? (
                    <div className="paymentType-card col-13 mx-auto">
                      <h3>Nama Asuransi</h3>
                      <Form.Control
                        type="text"
                        className={
                          errorRequired.find((val) => val == 'payer_name')
                            ? 'errorRequired'
                            : ''
                        }
                        placeholder="Masukan Nama Asuransi"
                        value={appointmentData.payer_name}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            payer_name: e.target.value,
                          });
                          if (e.target.value) {
                            e.target.className = 'form-control';
                            var removeError = errorRequired.filter(
                              (val) => val != 'payer_name'
                            );
                            setErrorRequired(removeError);
                          } else {
                            e.target.className = 'form-control errorRequired';
                            setErrorRequired([...errorRequired, 'payer_name']);
                          }
                        }}
                      />
                      {errorRequired.find((val) => val == 'payer_name') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nama Asuransi harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="btn-prev-next">
                {paymentTypeId ? (
                  <ReactButton
                    className="btn-prev"
                    variant="primary"
                    style={{ marginTop: '30px' }}
                    onClick={() => handleConfirmationModal()}
                  >
                    {appointmentData.serviceType === 'PM'
                      ? 'Buat Pemeriksaan Medis'
                      : 'Buat Janji Temu'}
                  </ReactButton>
                ) : (
                  <ReactButton
                    className="btn-prev inactive"
                    style={{ marginTop: '150px' }}
                    disabled
                  >
                    Pilih Jenis Jaminan
                  </ReactButton>
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
      <MobileInsurance
        state={state}
        prevPageUrl={prevPageUrl}
        appointmentData={appointmentData}
        setAppointmentData={setAppointmentData}
        paymentTypeSelected={paymentTypeSelected}
        paymentTypeId={paymentTypeId}
        setPaymentTypeId={setPaymentTypeId}
        errorRequired={errorRequired}
        setErrorRequired={setErrorRequired}
        insurance={insurance}
        insuranceDoc={insuranceDoc}
        handleInsuranceDocModal={handleInsuranceDocModal}
        jaminanDoc={jaminanDoc}
        handleJaminanDocModal={handleJaminanDocModal}
        handleConfirmationModal={handleConfirmationModal}
        setInsuranceDoc={setInsuranceDoc}
        setJaminanDoc={setJaminanDoc}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
      />
      {renderConfirmationModal()}
      {renderInsuranceDocModal()}
      {renderJaminanDocModal()}
      {renderInformationModal()}
    </Layout>
  );
};

export default Insurance;
