import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import moment from "moment";
import Swal from "sweetalert2";
import { InputDate, InputText, SelectOptions } from "../../../components/Input";
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCalenderGrey,
  IconPlusWhite,
} from "../../../assets/img/icon";
import {
  createAppointmentPackage,
  getAllPatients,
  getIdentityType,
  getRelation,
} from "../../../actions";
import ModalComp from "../../../components/Modal";
import { patientConstants } from "../../../actions/constants";
import { isEmail } from "../../../helpers/utils";
import Button from "../../../components/Button";
import "./index.scss";

const PatientV2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId") || null;
  const hospitalGroupId = localStorage.getItem("klinikGrupId") || null;
  const config = localStorage.getItem("headerData")
    ? JSON.parse(localStorage.getItem("headerData"))
    : null;

  const patient = useSelector((state) => state.patient);
  const accountProfile = useSelector((state) => state.accountProfile);

  const { state } = useLocation();
  if (!state) window.location = "/";

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientData, setPatientData] = useState({
    id: "new",
    name: "",
    gender: "",
    dob: "",
    phone_1: "",
    email: "",
    relation: "",
    relationname: "",
    identity_type: "",
    identity_name: "",
    identity_number: "",
    user_id: userId,
  });
  const [optionJenisIdentitas, setOptionJenisIdentitas] = useState(null);
  const [txtNomorIdentitas, setTxtNomorIdentitas] = useState("");
  const [txtNamaKtp, setTxtNamaKtp] = useState("");
  const [optionHubungan, setOptionHubungan] = useState(null);
  const [optionJenisKelamin, setOptionJenisKelamin] = useState(null);
  const [txtNomorTelepon, setTxtNomorTelepon] = useState("");
  const [txtEmail, setTxtEmail] = useState("");

  const [addPatientModal, setAddPatientModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [errorField, setErrorField] = useState(false);

  const initState = () => {
    setPatientData({
      id: "new",
      name: "",
      gender: "",
      dob: "",
      phone_1: "",
      email: "",
      relation: "",
      relationname: "",
      identity_type: "",
      identity_name: "",
      identity_number: "",
      user_id: userId,
    });
    setOptionJenisIdentitas(null);
    setTxtNomorIdentitas("");
    setTxtNamaKtp("");
    setOptionHubungan(null);
    setOptionJenisKelamin(null);
    setTxtNomorTelepon("");
    setTxtEmail("");
    setAddPatientModal(false);
    setErrorField(false);
  };

  useEffect(() => {
    dispatch(getAllPatients(userId));
    dispatch(getRelation());
    dispatch(getIdentityType());
  }, [userId]);

  const handleAddPatient = (patient) => {
    if (patient) {
      setPatientData({
        id: patient.id || "new",
        name: patient.name,
        gender: patient.gender,
        dob: patient.dob ? moment(patient.dob).format("YYYY-MM-DD") : "",
        phone_1: patient.phone_1,
        email: patient.email,
        relation: patient.relation,
        relationname: patient.relationname,
        identity_type: patient.identity_type,
        identity_name: patient.identity_name,
        identity_number: patient.identity_number,
        user_id: patient.user_id,
      });
    }

    setAddPatientModal(true);
  };

  const patientDataSelected = (e) => {
    let patientId = e?.value;

    if (patientId) {
      let selectedPatientData = patient.patientData.detail.filter(
        (e) => e.id == patientId
      );

      if (
        selectedPatientData[0].name &&
        selectedPatientData[0].phone_1 &&
        selectedPatientData[0].dob &&
        selectedPatientData[0].relation &&
        selectedPatientData[0].gender
      ) {
        setSelectedPatient(e);
      } else {
        handleAddPatient(e);
      }
    }
  };

  const handleCloseModal = () => {
    initState();
  };

  const checkPhone = (prefix, phone) => {
    let phoneSubs = phone;
    if (prefix == "0") {
      phoneSubs = phone.substring(1);
    } else if (prefix == "+") {
      if (phone.substring(4, 3) == "0") {
        phoneSubs = phone.substring(4);
      } else {
        phoneSubs = phone.substring(3);
      }
    } else if (prefix == "6") {
      if (phone.substring(3, 2) == "0") {
        phoneSubs = phone.substring(3);
      } else {
        phoneSubs = phone.substring(2);
      }
    }

    return phoneSubs;
  };

  const actionAddPatient = () => {
    if (
      (config.add_patient_config[6].code === "name" &&
        config.add_patient_config[6].required &&
        !patientData?.name) ||
      (config.add_patient_config[4].code === "relation" &&
        config.add_patient_config[4].required &&
        !patientData?.relation) ||
      (config.add_patient_config[8].code === "dob" &&
        config.add_patient_config[8].required &&
        !patientData?.dob) ||
      (config.add_patient_config[10].code === "gender" &&
        config.add_patient_config[10].required &&
        !patientData?.gender) ||
      (config.add_patient_config[12].code === "phone_1" &&
        config.add_patient_config[12].required &&
        !patientData?.phone_1) ||
      (config.add_patient_config[0].code === "identity_type" &&
        config.add_patient_config[0].required &&
        !patientData?.identity_type) ||
      (config.add_patient_config[2].code === "identity_number" &&
        config.add_patient_config[2].required &&
        !patientData?.identity_number)
    ) {
      setErrorField(true);
    } else {
      if (patientData.phone_1) {
        let prefix = patientData.phone_1.substring(0, 1);

        setPatientData({
          ...patientData,
          phone_1: checkPhone(prefix, patientData.phone_1),
        });
      }

      dispatch({
        type: patientConstants.GET_ALL_PATIENT_SUCCESS,
        payload: {
          data: { detail: [...patient?.patientData?.detail, patientData] },
        },
      });

      setSelectedPatient({
        ...patientData,
        label: `${patientData?.name} - ${
          patientData?.relationname ? patientData?.relationname : ""
        }`,
        value: patientData.id,
      });

      initState();
    }
  };

  const DateInput = ({ value, onClick }) => (
    <input
      className={`w-100 form-input-text${
        errorField && !patientData.dob && config.add_patient_config[8].required
          ? "-warning"
          : ""
      }`}
      placeholder="Pilih tanggal lahir"
      value={value}
      onClick={onClick}
      readOnly={true}
    />
  );

  const renderAddPatient = () => {
    return (
      <ModalComp
        title="Tambah Profil Pasien"
        show={addPatientModal}
        size="lg"
        setShow={handleCloseModal}
        onSubmit={actionAddPatient}
        buttonSubmitLabel="Tambah Profil"
        useButtonClose
        buttonCloseLabel="Batal"
      >
        <Row>
          <Col md>
            <SelectOptions
              placeholder="Pilih jenis identitas"
              label="Jenis Identitas"
              labelClassName="mt-3"
              background="#ffff"
              value={optionJenisIdentitas}
              fontSize="lg"
              setValue={(e) => {
                setOptionJenisIdentitas(e);
                setPatientData({
                  ...patientData,
                  identity_type: e?.id,
                  identity_name: e?.name,
                });
              }}
              required={config.add_patient_config[0].required ? true : false}
              isShowWarning={
                errorField &&
                !optionJenisIdentitas &&
                config.add_patient_config[0].required
              }
              options={
                accountProfile?.identityData
                  ? accountProfile?.identityData?.map((e) => {
                      return {
                        ...e,
                        label: e?.name,
                        value: e?.id,
                      };
                    })
                  : ""
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>

          <Col md>
            <SelectOptions
              placeholder="Pilih hubungan"
              label="Hubungan"
              labelClassName="mt-3"
              background="#ffff"
              value={optionHubungan}
              fontSize="lg"
              setValue={(e) => {
                setOptionHubungan(e);
                setPatientData({
                  ...patientData,
                  relation: e?.value,
                  relationname: e?.label,
                });
              }}
              required={config.add_patient_config[4].required ? true : false}
              isShowWarning={
                errorField &&
                !optionHubungan &&
                config.add_patient_config[4].required
              }
              options={
                patientData.relation == 1
                  ? {
                      label: "Saya Sendiri",
                      value: 1,
                    }
                  : accountProfile?.relationData
                  ? accountProfile?.relationData
                      ?.filter((data) => data.id !== 1)
                      .map((e) => {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      })
                  : ""
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <InputText
              label="Nomor Identitas"
              labelClassName="mt-3"
              required={config.add_patient_config[2].required ? true : false}
              placeholder="Masukkan nomor identitas"
              isShowWarning={
                errorField &&
                !txtNomorIdentitas &&
                config.add_patient_config[2].required
              }
              value={txtNomorIdentitas}
              setValue={(value) => {
                setTxtNomorIdentitas(value);
                setPatientData({
                  ...patientData,
                  identity_number: value,
                });
              }}
              type="number"
            />
          </Col>

          <Col md>
            <InputText
              label="Nomor Telepon"
              labelClassName="mt-3"
              placeholder="Masukkan nomor telepon"
              isShowWarning={
                errorField &&
                !txtNomorTelepon &&
                config.add_patient_config[12].required
              }
              value={txtNomorTelepon}
              maxText={13}
              required={config.add_patient_config[12].required ? true : false}
              setValue={(value) => {
                setTxtNomorTelepon(value);
                setPatientData({
                  ...patientData,
                  phone_1: value,
                });
              }}
              type="number"
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <InputText
              label="Nama Sesuai Identitas"
              labelClassName="mt-3"
              required={config.add_patient_config[6].required ? true : false}
              placeholder="Masukkan nama sesuai identitas"
              isShowWarning={
                errorField &&
                !txtNamaKtp &&
                config.add_patient_config[6].required
              }
              value={txtNamaKtp}
              setValue={(value) => {
                setTxtNamaKtp(value);
                setPatientData({
                  ...patientData,
                  name: value,
                });
              }}
              type="alphabet"
            />
          </Col>

          <Col md>
            <SelectOptions
              placeholder="Pilih jenis kelamin"
              label="Jenis Kelamin"
              labelClassName="mt-3"
              background="#ffff"
              value={optionJenisKelamin}
              fontSize="lg"
              setValue={(e) => {
                setOptionJenisKelamin(e);
                setPatientData({
                  ...patientData,
                  gender: e?.value,
                });
              }}
              required={config.add_patient_config[10].required ? true : false}
              isShowWarning={
                errorField &&
                !optionJenisKelamin &&
                config.add_patient_config[10].required
              }
              options={[
                {
                  label: "Laki-Laki",
                  value: 1,
                },
                {
                  label: "Perempuan",
                  value: 2,
                },
              ]}
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <InputDate
              placeholder="Pilih tanggal lahir"
              labelClassName="mt-3"
              className="text-lg"
              label="Tanggal Lahir"
              required={config.add_patient_config[8].required}
              isShowWarning={
                errorField &&
                !patientData.dob &&
                config.add_patient_config[8].required
              }
              setDate={(e) => {
                setPatientData({
                  ...patientData,
                  dob: e,
                });
              }}
              selectedDate={patientData.dob}
              icon={IconCalenderGrey}
              iconPosition="right"
              showMonthDropdown
              showYearDropdown
              format="dd/MM/yyyy"
              maxDate={new Date()}
              customInput={<DateInput />}
            />
          </Col>

          <Col md>
            <InputText
              label="Email"
              labelClassName="mt-3"
              placeholder="Masukkan email"
              required={config.add_patient_config[14].required ? true : false}
              isShowWarning={
                errorField &&
                (!txtEmail || !isEmail(txtEmail)) &&
                config.add_patient_config[14].required
              }
              errorMessage={
                txtEmail ? !isEmail(txtEmail) && "Format email salah" : null
              }
              value={txtEmail}
              setValue={(value) => {
                setTxtEmail(value);
                setPatientData({
                  ...patientData,
                  email: value,
                });
              }}
            />
          </Col>
        </Row>
      </ModalComp>
    );
  };

  const toIdr = (value, locale = "id-Id") => {
    if (!value || isNaN(value)) return 0;

    const currency = new Intl.NumberFormat(locale);

    return currency.format(value);
  };

  const handleSubmit = () => {
    let body = {
      hospitalId: state.clinicId,
      clinicPackageId: state.packageId,
      clinicPackageName: state.packageName,
      expiredDate: moment()
        .add(state.packageValidDays, "days")
        .format("YYYY-MM-DD"),
      price: parseInt(state.packagePrice),
      relation: selectedPatient?.relation ? `${selectedPatient.relation}` : "",
      user_id: userId,
      createdBy: "app",
      updatedBy: "app",
      patient: {
        active: "1",
        profileId:
          selectedPatient?.id && selectedPatient.id !== "new"
            ? selectedPatient.id
            : null,
        dob: moment(selectedPatient?.dob).format("YYYY-MM-DD"),
        email:
          selectedPatient?.email && selectedPatient.email !== "undefined"
            ? selectedPatient.email
            : "",
        firstName: selectedPatient?.name,
        gender:
          selectedPatient?.gender == 1
            ? "L"
            : selectedPatient?.gender == 2
            ? "P"
            : "",
        identityNumber:
          selectedPatient?.identity_number &&
          selectedPatient.identity_number !== "undefined"
            ? selectedPatient.identity_number
            : null,
        identityTypesId: selectedPatient?.identity_type,
        identityTypesName: selectedPatient?.identity_name,
        phoneNumber: selectedPatient?.phone_1,
        waNumber: selectedPatient?.phone_1,
        createdBy: "app",
        updatedBy: "app",
        hospitalGroupId: hospitalGroupId,
      },
    };

    dispatch(createAppointmentPackage(body))
      .then((response) => {
        const datas = response.data;
        if (datas) {
          setTimeout(
            () =>
              Swal.fire({
                icon: "success",
                title: "Silahkan Lakukan Pembayaran",
                showConfirmButton: true,
                confirmButtonText: "OK",
              }).then(() => {
                // if (datas.patient) {
                //   localStorage.setItem("firstName", datas.patient.firstName);
                //   localStorage.setItem("fullName", datas.patient.firstName);
                // }
                if (datas.payment) {
                  window.open(datas.payment.linkPayment, "_blank");
                }
                window.location =
                  "/appointment-package-created/" + datas.appointment.id;
              }),
            200
          );
        } else {
          return Swal.fire({
            icon: "error",
            text: response.message,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderConfirmation = () => {
    return (
      <ModalComp
        title="Konfirmasi Data"
        show={confirmationModal}
        size="md"
        setShow={() => setConfirmationModal(false)}
        onSubmit={handleSubmit}
        buttonSubmitLabel="Pembayaran"
        useButtonClose
        buttonCloseLabel="Batal"
      >
        <Row className="margin-b-24">
          <Col className="text-center">
            <span className="font-s-16 font-w-500">
              {`Halo ${selectedPatient?.name}, pastikan data yang Anda masukkan telah sesuai.`}
            </span>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Col className="w-100-p" xs={9}>
            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Hubungan</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {selectedPatient?.relationname || "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Nomor Identitas</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {selectedPatient?.identity_number &&
                    selectedPatient.identity_number !== "undefined"
                      ? selectedPatient.identity_number
                      : "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Nama</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {selectedPatient?.name || "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Nomor HP</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {selectedPatient?.phone_1 || "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Email</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {selectedPatient?.email &&
                    selectedPatient.email !== "undefined"
                      ? selectedPatient.email
                      : "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Jenis Kelamin</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {selectedPatient?.gender == 1
                      ? "Laki-laki"
                      : selectedPatient?.gender == 2
                      ? "Perempuan"
                      : "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Nama Paket</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {state.packageName || "-"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Harga</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">{`Rp${toIdr(
                    state.packagePrice
                  )}`}</span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={5}>
                <div className="d-flex justify-content-between">
                  <span className="font-s-14 font-w-500">Klinik</span>
                  <span className="font-s-14 font-w-500">:</span>
                </div>
              </Col>
              <Col xs={7}>
                <div className="d-flex">
                  <span className="font-s-14 font-w-700">
                    {state.clinicName || "-"}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalComp>
    );
  };

  return (
    <LayoutV2
      header={true}
      headerGlobal={true}
      footer={true}
      footerCopyright={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
              onClick={() =>
                history.push({
                  pathname: `product-package/detail/${state.packageUuid}`,
                  state: {
                    clinicId: state.clinicId,
                    clinicName: state.clinicName,
                  },
                })
              }
            />
            &nbsp;
            <span
              className="font-s-18 font-w-700 cl-green cursor-point"
              onClick={() =>
                history.push({
                  pathname: `product-package/detail/${state.packageUuid}`,
                  state: {
                    clinicId: state.clinicId,
                    clinicName: state.clinicName,
                  },
                })
              }
            >
              Kembali
            </span>
          </div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center margin-b-16">
        <Col xs={10}>
          <div className="card-patient-v2">
            <span className="font-s-24 font-w-700">Profil Pasien</span>
            <span className="font-s-16 font-w-500 margin-b-16">
              Silahkan pilih profil pasien untuk ke tahap selanjutnya
            </span>

            <Row className="margin-b-24">
              <Col lg={9}>
                <SelectOptions
                  id="selectOptionPatient"
                  placeholder="Silahkan pilih profil pasien"
                  label="Profil Pasien"
                  background="#ffff"
                  value={selectedPatient}
                  fontSize="lg"
                  setValue={(e) => patientDataSelected(e)}
                  options={
                    patient?.patientData?.detail?.length &&
                    patient?.patientData?.detail?.map((e) => {
                      return {
                        ...e,
                        label: `${e?.name} - ${
                          e.relationname ? e.relationname : ""
                        }`,
                        value: e?.id,
                      };
                    })
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />
              </Col>

              <Col className="d-flex flex-column-reverse" lg={3}>
                <Button
                  label="Tambah Profil"
                  type="primary-blue"
                  icon={IconPlusWhite}
                  iconPosition="left"
                  onClick={handleAddPatient}
                />
              </Col>
            </Row>

            {selectedPatient ? (
              <Row className="margin-b-24">
                <Col>
                  <div className="card-info-patient-v2">
                    <Row>
                      <Col xs={2}>
                        <div className="d-flex justify-content-between">
                          <span>Nama</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col xs={10}>
                        <span>{selectedPatient.name || "-"}</span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2}>
                        <div className="d-flex justify-content-between">
                          <span>Hubungan</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col xs={10}>
                        <span>{selectedPatient.relationname || "-"}</span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2}>
                        <div className="d-flex justify-content-between">
                          <span>Umur</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col xs={10}>
                        <span>
                          {moment().diff(selectedPatient.dob, "years") +
                            " Tahun"}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2}>
                        <div className="d-flex justify-content-between">
                          <span>No Telepon</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col xs={10}>
                        <span>{selectedPatient.phone_1 || "-"}</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col>
                <Button
                  label="Selanjutnya"
                  type="primary"
                  disable={selectedPatient ? false : true}
                  onClick={() => setConfirmationModal(true)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {renderAddPatient()}
      {renderConfirmation()}
    </LayoutV2>
  );
};

export default PatientV2;
