import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LayoutV2 from "../../Components/LayoutV2";
import { getAllNewsDetail } from "../../../../actions";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const DetailNews = (props) => {
  const { uuid } = useParams();
  const dispatch = useDispatch();

  let slug = props?.match?.params?.slug
  const hospitalGroupId = localStorage.getItem("hospitalGrupId");

  const newsDetail = useSelector((state) => state.articles);

  const [newsDataDetail, setNewsDataDetail] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if(slug) {

      let params = {
        klinik_group_id : hospitalGroupId,
        slug: slug,
        type: "news"
      }
      dispatch(getAllNewsDetail(queryString.stringify(params)));
    }
  },[slug])

  useEffect(() => {
    if(newsDetail?.newsDataDetail?.list?.length > 0){
      let data = []
      newsDetail.newsDataDetail.list.map((dataItem) => {
        var item = {
          id : dataItem.id,
          date : dataItem.createdDate,
          title : dataItem.title,
          imageUrl : dataItem.thumbnail,
          description : dataItem.content,
          slug: dataItem.slug
        }
       
        data.push(item)
      })
      setNewsDataDetail(data)
    }
  },[newsDetail])

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col xs={12}>
          <span className="font-title-24-16 font-w-700">
            Berita & Informasi
          </span>
        </Col>
      </Row>

      <Row className="margin-b-8">
        <Col>
          <div className="d-flex flex-column">
            <span className="font-s-14 font-w-500 cl-green">
              {newsDataDetail?.[0]?.date}
            </span>

            <span className="font-s-18 font-w-700 margin-b-16">
              {newsDataDetail?.[0]?.title}
            </span>

            <span className="font-s-14 font-w-500"
              dangerouslySetInnerHTML={{
                __html: newsDataDetail?.[0]?.description
              }}
            >
              
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col className="article-share">
          <span className="font-s-14 font-w-500 margin-r-8">Bagikan</span>
          <img
            src="/img/icon/icon-wa.svg"
            className="margin-r-8 cursor-point"
            onClick={() =>
              window.open('whatsapp://send?text=' + `${window.location}`)
            }
          />

          <img src="/img/icon/icon-link.svg" className="cursor-point"

            onClick={() =>
              {
                navigator.clipboard.writeText(`${window.location}`);
                Toast.fire({
                  icon: "info",
                  title: "Berhasil menyalin link URL",
                });
              }
            }  
          />
        </Col>
      </Row>
    </LayoutV2>
  );
};

export default DetailNews;
