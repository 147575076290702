import React, { useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import moment from "moment";
import Swal from "sweetalert2";
import {
  InputDate,
  InputText,
  InputTextArea,
  SelectOptions,
} from "../../../components/Input";
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCalenderGrey,
  IconCloseGreen,
  IconPlusWhite,
} from "../../../assets/img/icon";
import {
  getAllPatients,
  getIdentityType,
  getRelation,
  uploadMedicalDocFile,
  createProfile
} from "../../../actions";
import ModalComp from "../../../components/Modal";
import { patientConstants } from "../../../actions/constants";
import { isEmail } from "../../../helpers/utils";
import Button from "../../../components/Button";
import "./index.scss";
import { documentPatientTypes } from "../../../constants/common";
import { set } from "date-fns";

const PatientV3 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId") || null;
  // const hospitalGroupId = localStorage.getItem("klinikGrupId") || null;
  const config = localStorage.getItem("headerData")
    ? JSON.parse(localStorage.getItem("headerData"))
    : null;

  const patient = useSelector((state) => state.patient);
  const accountProfile = useSelector((state) => state.accountProfile);

  const { state } = useLocation();
  if (!state) window.location = "/";

  const [appointmentData, setAppointmentData] = useState(state.appointmentData);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientData, setPatientData] = useState({
    id: "new",
    name: "",
    gender: "",
    dob: "",
    phone_1: "",
    email: "",
    relation: "",
    relationname: "",
    identity_type: "",
    identity_name: "",
    identity_number: "",
    user_id: userId,
  });

  const [optionJenisIdentitas, setOptionJenisIdentitas] = useState(null);
  const [txtNomorIdentitas, setTxtNomorIdentitas] = useState("");
  const [txtNamaKtp, setTxtNamaKtp] = useState("");
  const [optionHubungan, setOptionHubungan] = useState(null);
  const [optionJenisKelamin, setOptionJenisKelamin] = useState(null);
  const [txtNomorTelepon, setTxtNomorTelepon] = useState("");
  const [txtEmail, setTxtEmail] = useState("");
  const [docType, setDocType] = useState(null);
  const [medicalDoc, setMedicalDoc] = useState([]);

  const [addPatientModal, setAddPatientModal] = useState(false);
  const [medicalDocModal, setMedicalDocModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorField, setErrorField] = useState(false);

  const serviceType = appointmentData ? appointmentData.serviceType : null;
  const paymentMethod = appointmentData
    ? appointmentData.paymentMethodtype
    : null;
  state.appointmentData.complaints = appointmentData
    ? appointmentData.complaints
    : "";

  const initState = useCallback(() => {
    setPatientData({
      id: "new",
      name: "",
      gender: "",
      dob: "",
      phone_1: "",
      email: "",
      relation: "",
      relationname: "",
      identity_type: "",
      identity_name: "",
      identity_number: "",
      user_id: userId,
    });
    setOptionJenisIdentitas(null);
    setTxtNomorIdentitas("");
    setTxtNamaKtp("");
    setOptionHubungan(null);
    setOptionJenisKelamin(null);
    setTxtNomorTelepon("");
    setTxtEmail("");
    setDocType(null);
    setAddPatientModal(false);
    setMedicalDocModal(false);
    setErrorField(false);
  }, [userId]);

  useEffect(() => {
    dispatch(getAllPatients(userId));
    dispatch(getRelation());
    dispatch(getIdentityType());
  }, [dispatch, userId]);

  useEffect(() => {
    let urlMedicalDoc = patient.urlMedicalDoc ? patient.urlMedicalDoc.url : "";

    if (urlMedicalDoc) {
      setMedicalDoc([
        ...medicalDoc,
        {
          id: medicalDoc.length + 1,
          docType: docType,
          docUrl: urlMedicalDoc,
        },
      ]);

      initState();
    }
  }, [initState, patient.urlMedicalDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, medicalDoc });
  }, [medicalDoc]);

  const handleAddPatient = (patient) => {
    if (patient) {
      setPatientData({
        id: patient.id || "new",
        name: patient.name,
        gender: patient.gender,
        dob: patient.dob ? moment(patient.dob).format("YYYY-MM-DD") : "",
        phone_1: patient.phone_1,
        email: patient.email,
        relation: patient.relation,
        relationname: patient.relationname,
        identity_type: patient.identity_type,
        identity_name: patient.identity_name,
        identity_number: patient.identity_number,
        user_id: patient.user_id,
      });
    }

    setAddPatientModal(true);
  };

  const patientDataSelected = (e) => {
    let patientId = e?.value;

    if (patientId) {
      let selectedPatientData = patient.patientData.detail.filter(
        (e) => e.id == patientId
      );

      if (
        selectedPatientData[0].name &&
        selectedPatientData[0].phone_1 &&
        selectedPatientData[0].dob &&
        selectedPatientData[0].relation &&
        selectedPatientData[0].gender
      ) {
        setSelectedPatient(e);
      } else {
        handleAddPatient(e);
      }
    }
  };

  const handleCloseModal = () => {
    initState();
  };

  const checkPhone = (prefix, phone) => {
    let phoneSubs = phone;
    if (prefix == "0") {
      phoneSubs = phone.substring(1);
    } else if (prefix == "+") {
      if (phone.substring(4, 3) == "0") {
        phoneSubs = phone.substring(4);
      } else {
        phoneSubs = phone.substring(3);
      }
    } else if (prefix == "6") {
      if (phone.substring(3, 2) == "0") {
        phoneSubs = phone.substring(3);
      } else {
        phoneSubs = phone.substring(2);
      }
    }

    return phoneSubs;
  };

  const actionAddPatient = () => {
    if (
      (config.add_patient_config[6].code === "name" &&
        config.add_patient_config[6].required &&
        !patientData?.name) ||
      (config.add_patient_config[4].code === "relation" &&
        config.add_patient_config[4].required &&
        !patientData?.relation) ||
      (config.add_patient_config[8].code === "dob" &&
        config.add_patient_config[8].required &&
        !patientData?.dob) ||
      (config.add_patient_config[10].code === "gender" &&
        config.add_patient_config[10].required &&
        !patientData?.gender) ||
      (config.add_patient_config[12].code === "phone_1" &&
        config.add_patient_config[12].required &&
        !patientData?.phone_1) ||
      (config.add_patient_config[0].code === "identity_type" &&
        config.add_patient_config[0].required &&
        !patientData?.identity_type) ||
      (config.add_patient_config[2].code === "identity_number" &&
        config.add_patient_config[2].required &&
        !patientData?.identity_number)
    ) {
      setErrorField(true);
    } else {
      if (patientData.phone_1) {
        let prefix = patientData.phone_1.substring(0, 1);

        setPatientData({
          ...patientData,
          phone_1: checkPhone(prefix, patientData.phone_1),
        });
      }

      dispatch({
        type: patientConstants.GET_ALL_PATIENT_SUCCESS,
        payload: {
          data: { detail: [...patient?.patientData?.detail, patientData] },
        },
      });

      setSelectedPatient({
        ...patientData,
        label: `${patientData?.name} - ${
          patientData?.relationname ? patientData?.relationname : ""
        }`,
        value: patientData.id,
      });
      const profileData = {
        identity_type: patientData.identity_type ? patientData.identity_type :null,
        identity_number: patientData.identity_number ? patientData.identity_number: null,
        name: patientData.name ? patientData.name: null,
        dob: patientData.dob ? patientData.dob : null,
        email: patientData.email ? patientData.email : null,
        gender: patientData.gender ? patientData.gender : null,
        phone_1: patientData.phone_1 ? patientData.phone_1 : null,
        relation: patientData.relation ? patientData.relation : null,
        userId:userId ? userId : ""
      }

      const form = new FormData();
      const groupId = localStorage.getItem('klinikGrupId');
      form.append('hospitalGroupId', groupId ? groupId : "");
      form.append('name', patientData?.name ? patientData?.name : "");
      form.append('gender', patientData?.gender ? patientData?.gender : "");
      form.append('dob', patientData?.dob ? moment(patientData.dob).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD'));
      form.append('phone_1', patientData?.phone_1 ? patientData?.phone_1 : "");
      form.append('email', patientData?.email ? patientData?.email : "");
      form.append('identity_type', patientData?.identity_type ? patientData?.identity_type : "");
      form.append('identity_name', patientData?.identity_name ? patientData?.identity_name : "");
      form.append('identity_number', patientData?.identity_number ? patientData?.identity_number : "");
      form.append('relation', patientData?.relation ? patientData?.relation : 0);
      form.append('user_id', userId);

      dispatch(createProfile(form))
      .then((response) => {
        setPatientData({
          ...patientData,
          patient_id: response.data.patient_id,
          id: response.data.id
        });

        setSelectedPatient({
          ...patientData,
          patient_id: response.data.id,
        });

      })
      .catch(() => {
        setPatientData({})
        setSelectedPatient({});

          Swal.fire({
            icon: 'error',
            text: "Gagal menyimpan profil baru",
          });
      })
      initState();
    }
  };

  const DateInput = ({ value, onClick }) => (
    <input
      className={`w-100 form-input-text${
        errorField && !patientData.dob && config.add_patient_config[8].required
          ? "-warning"
          : ""
      }`}
      placeholder="Pilih tanggal lahir"
      value={value}
      onClick={onClick}
      readOnly={true}
    />
  );

  const renderAddPatient = () => {
    return (
      <ModalComp
        title="Tambah Profil Pasien"
        show={addPatientModal}
        size="lg"
        setShow={handleCloseModal}
        onSubmit={actionAddPatient}
        buttonSubmitLabel="Tambah Profil"
        useButtonClose
        buttonCloseLabel="Batal"
      >
        <Row>
          <Col md>
            <SelectOptions
              placeholder="Pilih jenis identitas"
              label="Jenis Identitas"
              labelClassName="mt-3"
              background="#ffff"
              value={optionJenisIdentitas}
              fontSize="lg"
              setValue={(e) => {
                setOptionJenisIdentitas(e);
                setPatientData({
                  ...patientData,
                  identity_type: e?.id,
                  identity_name: e?.name,
                });
              }}
              required={config.add_patient_config[0].required ? true : false}
              isShowWarning={
                errorField &&
                !optionJenisIdentitas &&
                config.add_patient_config[0].required
              }
              options={
                accountProfile?.identityData
                  ? accountProfile?.identityData?.map((e) => {
                      return {
                        ...e,
                        label: e?.name,
                        value: e?.id,
                      };
                    })
                  : ""
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>

          <Col md>
            <SelectOptions
              placeholder="Pilih hubungan"
              label="Hubungan"
              labelClassName="mt-3"
              background="#ffff"
              value={optionHubungan}
              fontSize="lg"
              setValue={(e) => {
                setOptionHubungan(e);
                setPatientData({
                  ...patientData,
                  relation: e?.value,
                  relationname: e?.label,
                });
              }}
              required={config.add_patient_config[4].required ? true : false}
              isShowWarning={
                errorField &&
                !optionHubungan &&
                config.add_patient_config[4].required
              }
              options={
                patientData.relation == 1
                  ? [{
                      label: "Saya Sendiri",
                      value: 1,
                    }]
                  : accountProfile?.relationData
                  ? accountProfile?.relationData
                      ?.filter((data) => patient?.patientData?.detail?.length > 0 && patient?.patientData?.detail[0].patient_id == null ? data.id > 0 : data.id !== 1)
                      .map((e) => {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      })
                  : ""
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <InputText
              label="Nomor Identitas"
              labelClassName="mt-3"
              required={config.add_patient_config[2].required ? true : false}
              placeholder="Masukkan nomor identitas"
              isShowWarning={
                errorField &&
                !txtNomorIdentitas &&
                config.add_patient_config[2].required
              }
              value={txtNomorIdentitas}
              setValue={(value) => {
                setTxtNomorIdentitas(value);
                setPatientData({
                  ...patientData,
                  identity_number: value,
                });
              }}
              type={patientData.identity_name === "Passport" ? 'text' : 'number'}
            />
          </Col>

          <Col md>
            <InputText
              label="Nomor Telepon"
              labelClassName="mt-3"
              placeholder="Masukkan nomor telepon"
              isShowWarning={
                errorField &&
                !txtNomorTelepon &&
                config.add_patient_config[12].required
              }
              value={txtNomorTelepon}
              maxText={13}
              required={config.add_patient_config[12].required ? true : false}
              setValue={(value) => {
                setTxtNomorTelepon(value);
                setPatientData({
                  ...patientData,
                  phone_1: value,
                });
              }}
              type="number"
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <InputText
              label="Nama Sesuai Identitas"
              labelClassName="mt-3"
              required={config.add_patient_config[6].required ? true : false}
              placeholder="Masukkan nama sesuai identitas"
              isShowWarning={
                errorField &&
                !txtNamaKtp &&
                config.add_patient_config[6].required
              }
              value={txtNamaKtp}
              setValue={(value) => {
                setTxtNamaKtp(value);
                setPatientData({
                  ...patientData,
                  name: value,
                });
              }}
              type="alphabet"
            />
          </Col>

          <Col md>
            <SelectOptions
              placeholder="Pilih jenis kelamin"
              label="Jenis Kelamin"
              labelClassName="mt-3"
              background="#ffff"
              value={optionJenisKelamin}
              fontSize="lg"
              setValue={(e) => {
                setOptionJenisKelamin(e);
                setPatientData({
                  ...patientData,
                  gender: e?.value,
                });
              }}
              required={config.add_patient_config[10].required ? true : false}
              isShowWarning={
                errorField &&
                !optionJenisKelamin &&
                config.add_patient_config[10].required
              }
              options={[
                {
                  label: "Laki-Laki",
                  value: 1,
                },
                {
                  label: "Perempuan",
                  value: 2,
                },
              ]}
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <InputDate
              placeholder="Pilih tanggal lahir"
              labelClassName="mt-3"
              className="text-lg"
              label="Tanggal Lahir"
              required={config.add_patient_config[8].required}
              isShowWarning={
                errorField &&
                !patientData.dob &&
                config.add_patient_config[8].required
              }
              setDate={(e) => {
                setPatientData({
                  ...patientData,
                  dob: e,
                });
              }}
              selectedDate={patientData.dob}
              icon={IconCalenderGrey}
              iconPosition="right"
              showMonthDropdown
              showYearDropdown
              format="dd/MM/yyyy"
              maxDate={new Date()}
              customInput={<DateInput />}
            />
          </Col>

          <Col md>
            <InputText
              label="Email"
              labelClassName="mt-3"
              placeholder="Masukkan email"
              required={config.add_patient_config[14].required ? true : false}
              isShowWarning={
                errorField &&
                (!txtEmail || !isEmail(txtEmail)) &&
                config.add_patient_config[14].required
              }
              errorMessage={
                txtEmail ? !isEmail(txtEmail) && "Format email salah" : null
              }
              value={txtEmail}
              setValue={(value) => {
                setTxtEmail(value);
                setPatientData({
                  ...patientData,
                  email: value,
                });
              }}
            />
          </Col>
        </Row>
      </ModalComp>
    );
  };

  const uploadDocumment = () => {
    if (!docType) {
      const formUpload = document.getElementById("formUpload");

      formUpload.reset();

      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Tipe Dokumen",
      });
    } else {
      const uploadmedicaldoc =
        document.getElementById("uploadmedicaldoc").files;

      const fileTypes = ["image/jpeg", "image/png", "application/pdf"];

      if (fileTypes.includes(uploadmedicaldoc[0].type)) {
        dispatch(uploadMedicalDocFile(uploadmedicaldoc[0], docType.value)).then(
          () => setSuccessModal(true)
        );
      } else {
        return Swal.fire({
          icon: "error",
          text: "Harap Unggah File .jpeg, .png, atau .pdf!",
        });
      }
    }
  };

  const renderSuccessModal = () => {
    return (
      <ModalComp
        title="Informasi"
        show={successModal}
        setShow={() => setSuccessModal(false)}
        size="sm"
        onSubmit={() => setSuccessModal(false)}
        buttonSubmitLabel="Tutup"
      >
        <Row className="margin-b-8">
          <Col className="d-flex justify-content-center">
            <img alt="AIDO" src="/img/icon/icon-success-blue.svg" />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            <span className="font-s-16 font-w-400">
              Dokumen berhasil di upload
            </span>
          </Col>
        </Row>
      </ModalComp>
    );
  };

  const renderMedicalDocModal = () => {
    return (
      <ModalComp
        title="Tambah Dokumen Medis"
        show={medicalDocModal}
        setShow={handleCloseModal}
        size="sm"
        onSubmit={handleCloseModal}
        buttonSubmitLabel="Simpan"
        submitLoading={patient?.loading}
      >
        <div style={{ height: "160px" }}>
          <SelectOptions
            placeholder="Pilih dokumen"
            background="#ffff"
            value={docType}
            fontSize="lg"
            setValue={(e) => {
              setDocType(e);
            }}
            options={documentPatientTypes}
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
          />
          <Row>
            <Col style={{ textAlign: "right" }}>
              <form id="formUpload">
                <input
                  type="file"
                  accept="image/png, image/jpeg, .pdf"
                  id="uploadmedicaldoc"
                  style={{ display: "none" }}
                  onChange={uploadDocumment}
                />
                <label
                  htmlFor="uploadmedicaldoc"
                  className="label-file-upload"
                  style={{ paddingBottom: "30px" }}
                >
                  <img src={IconPlusWhite} alt="icon" /> Unggah
                </label>
              </form>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  const handleDoctorSchedule = () => {
    history.push("/schedule", [state.appointmentData]);
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="fix-nav">
        <Col>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
              alt="AIDO"
              onClick={handleDoctorSchedule}
            />
            &nbsp;&nbsp;
            <span
              className="font-s-18 font-w-700 cursor-point"
              onClick={handleDoctorSchedule}
            >
              Pilih Profil Pasien
            </span>
          </div>
        </Col>
      </Row>

      <Row className="margin-t-66 margin-b-16 text-center">
        <Col xs={12}>
          <span className="font-s-14 font-w-400">Langkah 2 Dari 3</span>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center margin-b-8">
        <Col xs={12} lg={10}>
          <Row className="d-flex justify-content-end">
            <Col xs={8} lg={3}>
              <Button
                label="Tambah Profil"
                type="primary"
                icon={IconPlusWhite}
                iconPosition="left"
                onClick={handleAddPatient}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center margin-b-16">
        <Col xs={12} lg={10}>
          <div className="card-patient-v3">
            <Row>
              <Col lg={12}>
                <SelectOptions
                  id="selectOptionPatient"
                  placeholder="Pilih profil pasien"
                  label="Profil Pasien"
                  background="#ffff"
                  value={selectedPatient}
                  fontSize="lg"
                  setValue={(e) => patientDataSelected(e)}
                  options={
                    patient?.patientData?.detail?.length &&
                    patient?.patientData?.detail?.map((e) => {
                      return {
                        ...e,
                        label: `${e?.name} - ${
                          e.relationname ? e.relationname : ""
                        }`,
                        value: e?.id,
                      };
                    })
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />
              </Col>
            </Row>

            {selectedPatient ? (
              <>
                <Row className="margin-t-16 margin-b-16">
                  <Col>
                    <div className="card-info-patient-v3">
                      <Row>
                        <Col xs={4} lg={2}>
                          <div>
                            <span>Nama</span>
                          </div>
                        </Col>
                        <Col xs={8} lg={10}>
                          <span>:</span>&nbsp;
                          <span>{selectedPatient.name || "-"}</span>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={4} lg={2}>
                          <div>
                            <span>Hubungan</span>
                          </div>
                        </Col>
                        <Col xs={8} lg={10}>
                          <span>:</span>&nbsp;
                          <span>{selectedPatient.relationname || "-"}</span>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={4} lg={2}>
                          <div>
                            <span>Umur</span>
                          </div>
                        </Col>
                        <Col xs={8} lg={10}>
                          <span>:</span>&nbsp;
                          <span>
                            {moment().diff(selectedPatient.dob, "years") +
                              " Tahun"}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={4} lg={2}>
                          <div>
                            <span>No Telepon</span>
                          </div>
                        </Col>
                        <Col xs={8} lg={10}>
                          <span>:</span>&nbsp;
                          <span>{selectedPatient.phone_1 || "-"}</span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col>
                    <InputTextArea
                      label="Keluhan"
                      placeholder="Masukkan keluhan"
                      value={appointmentData.complaints}
                      setValue={(value) => {
                        setAppointmentData({
                          ...appointmentData,
                          complaints: value,
                        });
                      }}
                      maxText={1000}
                    />
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col>
                    <div className="margin-b-8">
                      <span className="font-s-16 font-w-700">
                        Upload Dokumen Medis
                      </span>
                    </div>

                    <Button
                      label="Upload Dokumen"
                      type="primary"
                      onClick={() => setMedicalDocModal(true)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {medicalDoc.length > 0 ? (
                      medicalDoc.map((data, index) => (
                        <Row key={index}>
                          <Col xs={12}>
                            <span
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                color: "black",
                                fontWeight: "normal",
                              }}
                            >
                              {data
                                ? data.docUrl.split("/")[
                                    data.docUrl.split("/").length - 1
                                  ]
                                : ""}
                            </span>
                            &nbsp;&nbsp;
                            <a
                              href={data.docUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                color: "#00BD84",
                                fontWeight: "bold",
                              }}
                            >
                              Lihat Dokumen
                            </a>
                            &nbsp;
                            <img
                              src={IconCloseGreen}
                              alt="icon"
                              className="cursor-point"
                              onClick={() =>
                                setMedicalDoc(
                                  medicalDoc.filter((e) => e.id !== data.id)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </>
            ) : null}

            <Row className="margin-t-40">
              <Col>
                {!selectedPatient ? (
                  <Button label="Selanjutnya" type="primary" disable={true} />
                ) : selectedPatient.name &&
                  selectedPatient.phone_1 &&
                  selectedPatient.dob &&
                  selectedPatient.relation &&
                  selectedPatient.gender ? (
                  +paymentMethod === 2 ? (
                    <Button
                      label="Selanjutnya"
                      type="primary"
                      disable={true}
                      //   onClick={() => ()}
                    />
                  ) : serviceType !== "TL" && serviceType !== "PM" ? (
                    // insurance
                    <Link
                      to={{
                        pathname: "/payment",
                        state: {
                          selectedPatient: selectedPatient,
                          appointmentData: appointmentData,
                        },
                      }}
                    >
                      <Button label="Selanjutnya" type="primary" />
                    </Link>
                  ) : // <Button
                  //   label="Selanjutnya"
                  //   type="primary"
                  // />
                  serviceType === "PM" ? (
                    // pemeriksaan medis
                    <Button
                      label="Pembayaran Pemeriksaan Medis"
                      type="primary"
                      disable={true}
                      //   onClick={() =>
                      //     appointmentData?.isPrepaid
                      //       ? navigate.push(nextPageUrl2, {
                      //           selectedPatient: selectedPatient,
                      //           appointmentData: appointmentData,
                      //         })
                      //       : setPaymentTeleModal(true)
                      //   }
                    />
                  ) : (
                    // teleconsultation
                    <Button
                      label="Selanjutnya"
                      type="primary"
                      disable={true}
                      //   onClick={() => setPaymentTeleModal(true)}
                    />
                  )
                ) : (
                  <Button label="Selanjutnya" type="primary" disable={true} />
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {renderAddPatient()}
      {renderMedicalDocModal()}
      {renderSuccessModal()}
    </LayoutV2>
  );
};

export default PatientV3;
