import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  getDiagnosis
} from "../../../../actions";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, PDFDownloadLink } from "@react-pdf/renderer";
import { terbilang, formatRupiahV2, formatDigit, today } from "../../../../helpers/formatDigit"


const TemplateRecipe = (props) => {

  let detailTransaction = props.recipeData;
  Font.register({
    family: 'Open Sans',
        fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 'semibold' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf', fontWeight: 'normal' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-500.ttf', fontWeight: 'medium' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 'bold' }
        ]
  });

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      color: "#000000",
      fontSize: "10px",
      marginLeft: "15px",
      marginRight: "15px",
      paddingRight: "15px",
      fontFamily: "Open Sans"
    },
    section: {
      margin: "20px 10px 10px 10px",
      fontSize: "12px",
      fontWeight: "700"
    },
    sectionHeader: {
      display: "flex",
      flexDirection: "row",
      fontSize: 12,
      marginLeft: "10px",
      paddingRight: "10px",
      marginBottom: "4px",
    },
    sectionDetail: {
      display: "flex",
      flexDirection: "row",
      fontSize: 9,
      marginLeft: "10px",
      paddingRight: "10px",
      marginBottom: "4px",
    },
    sectionLine: {
      borderBottom: "1px solid #E7E7E7",
      margin: 10
    },
    sectionTable: {
      flexDirection: "column"
    },
    table: {
      fontSize: 10,
      width: 600,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      marginBottom: "24px",
      borderBottom: "1px solid #dedede"
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 25
    },
    cell: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
      margin: 4
    },
    cellFirst: {
      margin: 4,
      width: "10%"
    },
    cellQty: {
      margin: 4,
      width: "50%"
    },
    cellDescription: {
      margin: 4,
      width: "40%"
    },
    cellBold: {
      //fontFamily:"Open Sans",
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
      margin: 4,
      fontWeight: "bold"
    },
    header: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #dedede",
      borderBottom: "1px solid #dedede",
    },
    headerText: {
      fontSize: 11,
      fontWeight: 1200,
      color: "#1a245c",
      margin: 8
    },
    tableText: {
      margin: 10,
      fontSize: 10,
      color: "neutralDark"
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  const generateMedicineName = (satuan, concoction) => {
    let name = ""
    if ( concoction != null ) {
      const dataConcoction = JSON.parse(concoction)
      name = dataConcoction.name
    } else {
      name = satuan
    }
    return name
  }

  return (
    <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
          </View>
          <View style={{width: "220px"}}>
            <View style={{textAlign: "center", paddingBottom: "4px", marginBottom: "4px", borderBottom:"1px solid #000000"}}>
              <Text style={{fontWeight: "bold", fontSize: "16px"}}>{detailTransaction.hospitalName}</Text>
            </View>
            <View style={{textAlign: "center",paddingBottom: "4px", marginBottom: "4px", borderBottom:"1px solid #000000"}}>
              <Text style={{fontWeight: "bold", fontSize: "12px"}}>KHUSUS ASKES RAWAT JALAN/INAP</Text>
            </View>
            <View style={{paddingBottom: "4px", marginBottom: "4px",borderBottom:"1px solid #000000"}}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ width: "30%" }}>Tgl</Text>
                <Text style={{}}> : {today()}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ width: "30%" }}>Dr</Text>
                <Text style={{}}> : {detailTransaction.doctorName}</Text>
              </View>
            </View>
            <View style={{paddingBottom: "4px", marginBottom: "4px", borderBottom:"1px solid #000000"}}>
              {detailTransaction.medicine && detailTransaction.medicine.length > 0 ? detailTransaction.medicine.map((data, index) => (
                <Text style={{}}>R/ {generateMedicineName(data.name, data.concoction)}</Text>
              ))
              :null}
            </View>
            <View style={{paddingBottom: "4px", marginBottom: "4px", borderBottom:"1px solid #000000"}}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ width: "30%" }}>Pro</Text>
                <Text style={{}}> : {detailTransaction.firstName} {detailTransaction.lastName}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ width: "30%" }}>Umur</Text>
                <Text style={{}}> : {detailTransaction.patientAge}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ width: "30%" }}>Alamat</Text>
                <Text style={{width: "70%"}}> : {detailTransaction.patientAddressDetail}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ width: "30%" }}>No. RM</Text>
                <Text style={{}}> : {detailTransaction.mrNumber}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
  )
}

export default TemplateRecipe;

