import axios from '../helpers/axios';
import axiosAccountApi from '../helpers/axiosAccountApi';
import { paymentMethodConstants } from './constants';

export const uploadFKTLDocFile = (file, docName) => {
  const formData = new FormData();
  formData.append('file', file);

  return async (dispatch) => {
    dispatch({ type: paymentMethodConstants.UPLOAD_FKTLDOC_REQUEST });
    const res = await axios.post(
      `/appointments/upload-doc?docType=bpjs&docName=${docName}`,
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: paymentMethodConstants.UPLOAD_FKTLDOC_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: paymentMethodConstants.UPLOAD_FKTLDOC_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const uploadInsuranceDocFile = (file, docName) => {
  const formData = new FormData();
  formData.append('file', file);

  return async (dispatch) => {
    dispatch({ type: paymentMethodConstants.UPLOAD_INSURANCE_REQUEST });
    const res = await axios.post(
      `/appointments/upload-doc?docType=insurance&docName=${docName}`,
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: paymentMethodConstants.UPLOAD_INSURANCE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: paymentMethodConstants.UPLOAD_INSURANCE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const uploadJaminanDocFile = (file, docName) => {
  const formData = new FormData();
  formData.append('file', file);

  return async (dispatch) => {
    dispatch({ type: paymentMethodConstants.UPLOAD_JAMINAN_REQUEST });
    const res = await axios.post(
      `/appointments/upload-doc?docType=jaminan&docName=${docName}`,
      formData
    );
    if (res.status === 200) {
      const { data, status } = res;
      console.log('uploadJaminanDocFile', data);
      dispatch({
        type: paymentMethodConstants.UPLOAD_JAMINAN_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: paymentMethodConstants.UPLOAD_JAMINAN_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getHospitalPayer = (hospitalId) => {
  return async (dispatch) => {
    dispatch({ type: paymentMethodConstants.GET_HOSPITAL_PAYER_REQUEST });
    const res = await axiosAccountApi.get(
      `/v1/api/payer_clinics?hospitalId=${hospitalId}`
    );
    if (res.status === 200) {
      dispatch({
        type: paymentMethodConstants.GET_HOSPITAL_PAYER_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: paymentMethodConstants.GET_HOSPITAL_PAYER_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getInsuranceByHospital = (hospitalId) => {
  return async (dispatch) => {
    dispatch({ type: paymentMethodConstants.GET_HOSPITAL_INSURANCE_REQUEST });
    const res = await axiosAccountApi.get(
      `/v1/api/insurance_clinics?isInsurance=true&hospitalId=${hospitalId}`
    );
    if (res.status === 200) {
      dispatch({
        type: paymentMethodConstants.GET_HOSPITAL_INSURANCE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: paymentMethodConstants.GET_HOSPITAL_INSURANCE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getCompanyGuarantee = (hospitalId) => {
  return async (dispatch) => {
    dispatch({ type: paymentMethodConstants.GET_COMPANY_INSURANCE_REQUEST });
    const res = await axiosAccountApi.get(
      `/v1/api/insurance_clinics?isInsurance=false&hospitalId=${hospitalId}`
    );
    if (res.status === 200) {
      dispatch({
        type: paymentMethodConstants.GET_COMPANY_INSURANCE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: paymentMethodConstants.GET_COMPANY_INSURANCE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};
