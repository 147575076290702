import { accountProfileConstants } from "../actions/constants";

const initState = {
  userData: [],
  profileData: [],
  urlKTP: [],
  urlAvatar: [],
  relationData: [],
  identityData: [],
  marriageData: [],
  bloodTypeData: [],
  ethnicData: [],
  religionData: [],
  nationalityData: [],
  educationData: [],
  jobData: [],
  provinceData: [],
  cityData: [],
  districtData: [],
  subdistrictData: [],
  postalCodeData: [],
  termsCondition: null,
  privacyPolicy: null,
  aboutUs: null,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case accountProfileConstants.GET_HOSPITAL_TERMS_PRIVACY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_HOSPITAL_TERMS_PRIVACY_SUCCESS:
      state = {
        ...state,
        termsCondition: action.payload.data.termsCondition,
        privacyPolicy: action.payload.data.privacyPolicy,
        aboutUs: action.payload.data.aboutUs,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_HOSPITAL_TERMS_PRIVACY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_ALL_ACCOUNTPROFILE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_ALL_ACCOUNTPROFILE_SUCCESS:
      state = {
        ...state,
        profileData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_ALL_ACCOUNTPROFILE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.CREATE_ACCOUNTPROFILE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.CREATE_ACCOUNTPROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case accountProfileConstants.CREATE_ACCOUNTPROFILE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.UPDATE_ACCOUNTPROFILE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.UPDATE_ACCOUNTPROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case accountProfileConstants.UPDATE_ACCOUNTPROFILE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.UPLOAD_KTP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.UPLOAD_KTP_SUCCESS:
      state = {
        ...state,
        urlKTP: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.UPLOAD_KTP_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.UPDATE_USER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.UPDATE_USER_SUCCESS:
      state = {
        ...state,
        userData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.UPDATE_USER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.UPLOAD_AVATAR_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.UPLOAD_AVATAR_SUCCESS:
      state = {
        ...state,
        urlAvatar: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.UPLOAD_AVATAR_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_RELATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_RELATION_SUCCESS:
      state = {
        ...state,
        relationData: action.payload.data.familyRelations,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_RELATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_IDENTITY_TYPE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_IDENTITY_TYPE_SUCCESS:
      state = {
        ...state,
        identityData: action.payload.data.identities,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_IDENTITY_TYPE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_MARRIAGE_STATUS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_MARRIAGE_STATUS_SUCCESS:
      state = {
        ...state,
        marriageData: action.payload.data.marriage_status,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_MARRIAGE_STATUS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_BLOOD_TYPE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_BLOOD_TYPE_SUCCESS:
      state = {
        ...state,
        bloodTypeData: action.payload.data.blood_type,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_BLOOD_TYPE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_ETHNIC_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_ETHNIC_SUCCESS:
      state = {
        ...state,
        ethnicData: action.payload.data.ethnicities,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_ETHNIC_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_RELIGION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_RELIGION_SUCCESS:
      state = {
        ...state,
        religionData: action.payload.data.religions,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_RELIGION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_NATIONALITY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_NATIONALITY_SUCCESS:
      state = {
        ...state,
        nationalityData: action.payload.data.nationalities,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_NATIONALITY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_EDUCATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_EDUCATION_SUCCESS:
      state = {
        ...state,
        educationData: action.payload.data.educations,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_EDUCATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_JOB_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_JOB_SUCCESS:
      state = {
        ...state,
        jobData: action.payload.data.jobs,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_JOB_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_PROVINCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_PROVINCE_SUCCESS:
      state = {
        ...state,
        provinceData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_PROVINCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_CITY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_CITY_SUCCESS:
      state = {
        ...state,
        cityData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_CITY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_DISTRICT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_DISTRICT_SUCCESS:
      state = {
        ...state,
        districtData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_DISTRICT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_SUBDISTRICT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_SUBDISTRICT_SUCCESS:
      state = {
        ...state,
        subdistrictData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_SUBDISTRICT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_POSTALCODE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountProfileConstants.GET_POSTALCODE_SUCCESS:
      state = {
        ...state,
        postalCodeData: action.payload.data,
        loading: false,
      };
      break;
    case accountProfileConstants.GET_POSTALCODE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
