import React, { useEffect, useState } from 'react';
import {
  IconArrowGrey,
  IconCalenderGrey,
  IconFilterWhiteOutline,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import { InputDate, SelectOptions } from '../../../components/Input';
import { formatDateAsData } from '../../../helpers/utils';

const DesktopFilterTransactionHistory = ({
  filter,
  setFilter,
  listConsultationType,
  listProfil,
  resetFilter,
}) => {
  /* Local State */
  const [searchName, setSearchName] = useState();
  const [searchConsultationType, setSearchConsultationType] = useState();
  const [searchDate, setSeachDate] = useState();

  useEffect(() => {
    initiateForm();
  }, [filter, listProfil]);

  const initiateForm = () => {
    if (filter?.profilId) {
      return setSearchName(
        listProfil &&
          listProfil
            ?.filter((e) => e?.id === filter?.profilId)
            .map((e) => {
              return {
                ...e,
                value: e?.id,
                label: `${e?.name} - ${e?.relationname || ''}`,
              };
            })[0]
      );
    }
  };

  const resetForm = () => {
    setSearchConsultationType();
    setSearchName(
      listProfil &&
        listProfil
          ?.filter((e) => +e?.relation === 1)
          .map((e) => {
            return {
              ...e,
              value: e?.id,
              label: `${e?.name} - ${e?.relationname || ''}`,
            };
          })[0]
    );
    setSeachDate();
    resetFilter();
  };

  return (
    <React.Fragment>
      <p className="text-white h3 pr-0 font-weight-bold text-center mt-3">
        Riwayat Transaksi
      </p>
      <div className="row py-3">
        <div className="mt-xl-0 mt-2 col-xl-4 col-12">
          <SelectOptions
            placeholder="Ketik/pilih nama"
            fontSize="lg"
            color="#757575"
            background="white"
            options={
              listProfil &&
              listProfil.map((e) => {
                return {
                  ...e,
                  value: e?.id,
                  label: `${e?.name} - ${e?.relationname || ''}`,
                };
              })
            }
            value={searchName}
            setValue={(e) => {
              setFilter({
                ...filter,
                profilId: e?.value,
              });
              setSearchName(e);
            }}
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
          />
        </div>
        <div className="mt-xl-0 mt-2 col-xl-3 col-12">
          <SelectOptions
            placeholder="Tipe konsultasi"
            fontSize="lg"
            color="#757575"
            background="white"
            options={
              listConsultationType &&
              listConsultationType.map((e) => {
                return {
                  ...e,
                  value: e?.consultation_type,
                  label: `${e?.consultation_name}`,
                };
              })
            }
            value={searchConsultationType}
            setValue={(e) => {
              setFilter({
                ...filter,
                consultationType: e?.value,
              });
              setSearchConsultationType(e);
            }}
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
          />
        </div>
        <div className="mt-xl-0 mt-2 col-xl-3 col-12">
          <InputDate
            placeholder="dd/mm/yyyy"
            className="text-lg"
            setDate={(e) => {
              setFilter({
                ...filter,
                scheduleDate: formatDateAsData(e),
              });
              setSeachDate(e);
            }}
            selectedDate={searchDate}
            icon={IconCalenderGrey}
            iconPosition="right"
            showMonthDropdown
            showYearDropdown
            format="dd/MM/yyyy"
          />
          <p className="text-secondary position-absolute ml-3 text-light font14">
            *tanggal konsultasi
          </p>
        </div>
        <div className="mt-xl-0 mt-4 col-xl-2 col-12">
          <Button
            label="Reset"
            icon={IconFilterWhiteOutline}
            type="primary"
            iconPosition="right"
            onClick={resetForm}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesktopFilterTransactionHistory;
