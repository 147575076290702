import React from "react";
import Modal from "react-bootstrap/Modal";
import { IconCloseGreen, IconLoader } from "../../assets/img/icon";
import Button from "../Button";
import "./Modal.style.scss";

const ModalComp = ({
  show = false,
  setShow,
  children,
  useButtonClose = false,
  useHeaderButtonClose = true,
  className = "",
  buttonCloseLabel = null,
  title = null,
  size = "lg" | "sm" | "md",
  buttonSubmitLabel,
  onSubmit,
  submitLoading = false,
}) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-dialogs modal-mobile-v2 modal-dialogs-margin-auto"
        className={`modal-container-${size || "sm"} ${className}`}
        contentClassName={`modal-${size || "sm"}`}
        centered
      >
        <div className="p-0">
          <div className="d-flex justify-content-between p-4 mb-0">
            <p className="my-auto d-flex font-weight-bold font24 text-dark">
              {title}
            </p>
            {useHeaderButtonClose && (
              <img
                src={IconCloseGreen}
                alt="icon"
                className="img-head-close cursor-point"
                onClick={() => setShow(false)}
              />
            )}
          </div>
          {title && <hr className="pt-0 mt-0" />}
          <div className="px-4 pb-4 modal-comp-container">
            {children}

            <div className="margin-t-24">
              {buttonSubmitLabel &&
                (submitLoading ? (
                  <Button
                    label=""
                    type="primary"
                    disable={submitLoading}
                    icon={IconLoader}
                    iconPosition="right"
                    iconSize="lg"
                    iconClassName="mt-1"
                    className="mb-2 font16"
                  />
                ) : (
                  <Button
                    label={buttonSubmitLabel || "Submit"}
                    type="primary"
                    disable={false}
                    iconClassName="mt-1"
                    onClick={() => {
                      if (onSubmit) onSubmit();
                      else setShow(false);
                    }}
                    className="mb-2 font16"
                  />
                ))}

              {useButtonClose && (
                <Button
                  label={buttonCloseLabel || "Tutup"}
                  disable={false}
                  type={`${buttonSubmitLabel ? "outline-" : ""}primary`}
                  onClick={() => setShow(false)}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ModalComp;
