import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Button as ButtonReact } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getDoctorAvailabilitiesByGroupHospital,
  getFaskes,
  getLovProcedureMCU,
  getProcedureMCU,
  getSpeciazationsAppointment,
} from '../../../actions';
import {
  IconArrowGreen,
  IconArrowGrey,
  // IconCheckedDisbled,
  // IconCheckedEnabled,
  IconDummyAvatar,
  IconDummyAvatarMale,
  IconDummyAvatarFemale,
  IconFindDocuments,
  IconLoader,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import { InputText } from '../../../components/Input';
import FormSearch from '../../../components/Input/FormSearch';
import SelectOptions from '../../../components/Input/SelectOptions';
import { consultationTypeCode } from '../../../constants/common';
// import { wordingResultDoctorList } from '../../../constants/wording';
import { formatRupiah } from '../../../helpers/formatDigit';
import Pagination from '../Components/Pagination';
// import MobileListResultFindDoctor from './MobileListResultFindDoctor';
// import MobileResultFindDoctor from './MobileResultFindDoctor';
import './ResultFindDoctor.scss';
import LayoutV2 from "../Components/LayoutV2";
moment.locale('id');

const ResultFindDoctor = (props) => {
  let maxPageSize = 6
  const hospital_id = props.location.state[0].hospital_id[0];
  // const hospital_id = '495b64f5-2a34-490e-8801-1944d5424ad9';

  /* Router DOM */
  const history = useHistory();

  /* Redux */
  const dispatch = useDispatch();
  const { lovProcedures } = useSelector((state) => state.procedureMCU);

  /* Local State */
  const [loading, setLoading] = useState(false);
  const [searchClinic, setSeachClinic] = useState();
  const [searchSpecialization, setSeachSpecialization] = useState();
  const [searchGender, setSearchGender] = useState();
  const [searchConsultationType, setSearchConsultationType] = useState();
  const [searchDate, ] = useState(new Date());
  const [faskes, setFaskes] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [consultationTypes, ] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [limit, ] = useState(0);
  const [data, setData] = useState([]);
  const [PageSize, setPageSize] = useState(maxPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [doctorFilterName, setDoctorFilterName] = useState();
  const [hideFilter, setHideFilter] = useState(false);
  const hospitalGroupId = localStorage.getItem('klinikGrupId');
  
  const [search, setSearch] = useState({
    hospital_group_id:
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_group_id
        : '',
    hospital_id:
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_id
        : [],
    specialization_id:
      props.location?.state?.length > 0
        ? props.location.state[0].specialization_id
        : '',
    name_doctor:
      props.location?.state?.length > 0
        ? props.location.state[0].name_doctor
        : '',
    gender:
      props.location?.state?.length > 0 ? props.location.state[0].gender : '',
    consultation:
      props.location?.state?.length > 0
        ? props.location.state[0].consultation
        : '',
    serviceType:
      props.location?.state?.length > 0
        ? props.location.state[0].serviceType
        : '',
    titleList:
      props.location?.state?.length > 0
        ? props.location.state[0].titleList
        : '',

    limit: PageSize,
    limitScheduleItems: 6,
  });

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setData(doctor?.slice(firstPageIndex, lastPageIndex));
    return doctor?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize]);

  useEffect(() => {
    if (
      props.location?.location?.length > 0 &&
      props.location?.state[0].search_specialization_id
    ) {
      setSeachSpecialization(
        specializations &&
          specializations?.data
            ?.filter(
              (e) => e?.id === props.location?.state[0].search_specialization_id
            )
            .map((e) => {
              return { ...e, value: e?.id, label: e?.name };
            })
      );
    }
    if (props.location?.state?.length > 0 && props.location?.state[0].gender) {
      setSearchGender({
        value: props.location?.state[0].gender,
        label: props.location?.state[0].gender === '1' ? 'Male' : 'Female',
      });
    }
    if (
      props.location?.state?.length > 0 &&
      props.location?.state[0].search_consultation_type
    ) {
      setSearchConsultationType(
        consultationTypes &&
          consultationTypes?.data
            ?.filter(
              (item) =>
                +item?.consultation_type ===
                props.location?.state[0].search_consultation_type
            )
            ?.map((e) => {
              return {
                ...e,
                value: e?.consultation_type,
                label: e?.consultation_name,
              };
            })
      );
    }
  }, [props.location?.state, specializations, consultationTypes]);

  useEffect(() => {
    if (props.location?.state && props.location?.state.length > 0) {
      let payloadName = {}
      if (props.location.state[0].filterName) {
        payloadName = {
          target: props.location.state[0].filterName,
        };
        setDoctorFilterName(props.location.state[0].filterName);
      }

      let payloadKlinik = {}
      if (props.location.state[0].filterKlinik) {
        payloadKlinik = {
          ...props.location.state[0].filterKlinik,
          target: props.location.state[0].filterKlinik,
        };
        setSeachClinic(props.location.state[0].filterKlinik);
      }

      let payloadSpec = {}
      if (props.location.state[0].filterSpecialization) {
        payloadSpec = {
          ...props.location.state[0].filterSpecialization,
          target: props.location.state[0].filterSpecialization,
        };
        setSeachSpecialization(props.location.state[0].filterSpecialization);
      }

      let payloadGender = {}
      if (props.location.state[0].filterGender) {
        payloadGender = {
          ...props.location.state[0].filterGender,
          target: props.location.state[0].filterGender,
        };
        setSearchGender(props.location.state[0].filterGender);
      }

      let payloadConsultation = {}
      if (props.location.state[0].filterConsultation) {
        payloadConsultation = {
          ...props.location.state[0].filterConsultation,
          target: props.location.state[0].filterConsultation,
        };
        setSearchConsultationType(props.location.state[0].filterConsultation);
      }

      changeFilterProps(payloadName, payloadKlinik, payloadSpec, payloadGender, payloadConsultation)
      setPageSize(maxPageSize);
    }
  }, [props.location?.state])
  
  // useEffect(() => {
  //   setSeachClinic(
  //     faskes.data &&
  //       faskes.data
  //         ?.filter((e) =>
  //           e?.id === props?.location?.state?.length > 0
  //             ? props?.location?.state[0]?.hospital_id[0]
  //             : ''
  //         )
  //         ?.map((e) => {
  //           return { ...e, value: e?.id, label: e?.name };
  //         })[0]
  //   );
  // }, [props, faskes]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setHideFilter(true)
    }
  }, [])

  useEffect(() => {
    const payload = {
      hospital_id: props.location.state[0].hospital_id[0],
      // hospital_id: '495b64f5-2a34-490e-8801-1944d5424ad9',
      key: '',
    };
    dispatch(getSpeciazationsAppointment(limit)).then((response) => {
      setSpecializations(response);
    });
    dispatch(getFaskes(hospitalGroupId)).then((response) => {
      setFaskes(response);
    });
    fetchProcedureMCU({
      hospital_id,
      key: '',
    });
    dispatch(getLovProcedureMCU({ hospitalId: hospital_id }));
    getListDoctor();
  }, []);

  useEffect(() => {
    getListDoctor();
  }, [searchDate]);

  useEffect(() => {
    currentTableData = doctor?.slice(0, PageSize);
    setData(currentTableData);
  }, [doctor]);

  const fetchProcedureMCU = async (payload) => {
    await dispatch(getProcedureMCU(JSON.stringify(payload))).then(
      (response) => {
        setProcedures(response.data);
      }
    );
  };

  const longDate = useMemo(() => {
    const date = new Date(searchDate);
    const result = [];
    for (let i = 0; i < 6; i++) {
      const nextDate = new Date(date.setDate(date.getDate() + 1));
      result.push(nextDate);
    }
    return [new Date(searchDate), ...result];
  }, [searchDate]);

  const schedules = useMemo(() => {
    const tempData =
      data?.length &&
      data?.map((e) => {
        let bpjs = null;
        let reguler = null;
        let tele = null;
        let buttonActionType = 1;
        let bpjsChecked = 0;
        let regularChecked = 0;
        let teleChecked = 0;

        bpjs = e?.scheduleList?.tm_doctor_schedules_v2s?.filter(
          (item) => item?.consultation_type_id === consultationTypeCode.bpjs
        )[0];
        reguler = e?.scheduleList?.tm_doctor_schedules_v2s?.filter(
          (item) => item?.consultation_type_id === consultationTypeCode.reguler
        )[0];
        tele = e?.scheduleList?.tm_doctor_schedules_v2s?.filter(
          (item) => item?.consultation_type_id === consultationTypeCode.tele
        )[0];

        const slotBpjs = longDate.map((date) => {
          if (!bpjs?.tx_schedule_detail_v2s?.length) return null;
          const day = new Date(date).getDay() || 7;
          const tempFilter = bpjs?.tm_doctor_schedule_items?.filter(
            (tdri) => +tdri?.day === day
          );
          if (tempFilter?.length)
            return {
              ...tempFilter[0],
              dateView: date,
              scheduleDetails: bpjs?.tx_schedule_detail_v2s,
            };
          return null;
        });
        const slotReguler = longDate.map((date) => {
          if (!reguler?.tx_schedule_detail_v2s?.length) return null;
          const day = new Date(date).getDay() || 7;
          const tempFilter = reguler?.tm_doctor_schedule_items?.filter(
            (tdri) => +tdri?.day === day
          );
          if (tempFilter?.length)
            return {
              ...tempFilter[0],
              dateView: date,
              scheduleDetails: reguler?.tx_schedule_detail_v2s,
            };
          return null;
        });
        const slotTele = longDate.map((date) => {
          if (!tele?.tx_schedule_detail_v2s?.length) return null;
          const day = new Date(date).getDay() || 7;
          const tempFilter = tele?.tm_doctor_schedule_items?.filter(
            (tdri) => +tdri?.day === day
          );
          if (tempFilter?.length)
            return {
              ...tempFilter[0],
              dateView: date,
              scheduleDetails: tele?.tx_schedule_detail_v2s,
            };
          return null;
        });
        const bpjsFlag = slotBpjs?.filter((e) => e);
        const regulerFlag = slotReguler?.filter((e) => e);
        const teleFlag = slotTele?.filter((e) => e);

        if (search.serviceType !== 'TL') {
          if (!bpjsFlag?.length && !regulerFlag?.length) {
            buttonActionType = 2;
          } else {
            buttonActionType =
              bpjsFlag[0]?.quota_reservat ||
              regulerFlag[0]?.quota_reservat ||
              teleFlag[0]?.quota_reservat
                ? 1
                : 2;
          }
        } else {
          if (!teleFlag?.length) {
            buttonActionType = 2;
          } else {
            buttonActionType =
              bpjsFlag[0]?.quota_reservat ||
              regulerFlag[0]?.quota_reservat ||
              teleFlag[0]?.quota_reservat
                ? 1
                : 2;
          }
        }

        if (bpjsFlag?.length) bpjsChecked = 1;
        if (regulerFlag?.length) regularChecked = 1;
        if (teleFlag?.length) teleChecked = 1;
        
        return {
          buttonActionType,
          bpjsChecked,
          regularChecked,
          teleChecked,
          doctorHospitalId: e?.scheduleList?.id,
          serviceFee: e?.scheduleList?.serviceFee,
          doctorId: e?.scheduleList?.doctor_id,
          doctorName: e?.scheduleList?.tm_doctor?.name,
          doctorGender: e?.scheduleList?.tm_doctor?.gender,
          doctorImageUrl: e?.scheduleList?.tm_doctor?.image_url,
          specializationId: e?.scheduleList?.tm_specialization?.id,
          specializationName: e?.scheduleList?.tm_specialization?.name,
          poliId: e?.scheduleList?.tm_poli?.id,
          hospitalId: e?.scheduleList?.hospital_id,
          hospitalName: e?.scheduleList?.tm_hospital?.name,
          hospitalImageUrl: e?.scheduleList?.tm_hospital?.image_url,
          hospitalType: e?.scheduleList?.tm_hospital?.hospital_type,
          hospitalTimezone: e?.scheduleList?.tm_hospital?.timezone,
          scheduleType:
            e?.scheduleList?.tm_doctor_schedules_v2?.tr_schedule_type,
          consultationType:
            e?.scheduleList?.tm_doctor_schedules_v2?.tr_consultation_type,
          slotBpjs,
          slotReguler,
          slotTele,
        };
      });

    return tempData;
  }, [searchDate, data, longDate]);

  const getListDoctor = (payload) => {
    setLoading(true);
    dispatch(
      getDoctorAvailabilitiesByGroupHospital(
        JSON.stringify(
          payload
            ? {
                ...payload,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
            : {
                ...search,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
        )
      )
    ).then((result) => {
      if (result) {
        setDoctor(result?.data ? result.data : []);
        setLoading(false);
      }
    });
  };

  /*const getListProcedures = (payload) => {
    setLoading(true);
    dispatch(
      getProcedureMCU(
        JSON.stringify(
          payload
            ? {
                ...payload,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
            : {
                ...search,
                schedule_date: moment(searchDate).format('YYYY-MM-DD'),
                consultation: search.serviceType === 'TL' ? 3 : '',
              }
        )
      )
    ).then((result) => {
      setDoctor(result.data);
      setLoading(false);
    });
  };*/

  const handleNameDoctor = (event) => {
    let newSearch = search;
    let temp = [];
    if (searchClinic?.value && event.target.value != '') {
      temp.push(searchClinic?.value);
    }
    newSearch['hospital_id'] = temp;
    newSearch['name_doctor'] = event.target;
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeFaskes = (event) => {
    let temp = [];
    temp.push(event.target.value);
    if (event.target.value == '') {
      temp = [];
    }
    let newSearch = search;
    newSearch['hospital_id'] = temp;
    newSearch['name_doctor'] = doctorFilterName;
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeSpecialization = (event) => {
    let newSearch = search;
    let temp = [];
    if (searchClinic && searchClinic?.value) {
      temp.push(searchClinic?.value);
    }
    newSearch['hospital_id'] = temp;
    newSearch['specialization_id'] = event?.target?.value || null;
    newSearch['name_doctor'] = doctorFilterName;
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeGender = (event) => {
    let newSearch = search;
    let temp = [];
    if (searchClinic && searchClinic?.value) {
      temp.push(searchClinic?.value);
    }
    newSearch['hospital_id'] = temp;
    newSearch['gender'] = event?.target?.value || null;
    newSearch['name_doctor'] = doctorFilterName;
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeConsultation = (event) => {
    let newSearch = search;
    let temp = [];
    if (searchClinic && searchClinic?.value) {
      temp.push(searchClinic?.value);
    }
    newSearch['hospital_id'] = temp;
    newSearch['consultation'] = event?.target?.value || null;
    newSearch['name_doctor'] = doctorFilterName;
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const changeFilterProps = (payloadName, payloadKlinik, payloadSpec, payloadGender, payloadConsultation) => {
    let temp = [];
    if (payloadKlinik && payloadKlinik?.value) {
      temp.push(payloadKlinik?.value);
    }
    let newSearch = search;
    newSearch['name_doctor'] = payloadName?.target ? payloadName.target : null;
    newSearch['hospital_id'] = temp;
    newSearch['specialization_id'] = payloadSpec?.value || null;
    newSearch['gender'] = payloadGender?.value || null;
    newSearch['consultation'] = payloadConsultation?.value || null;
    setSearch(newSearch);
    getListDoctor(newSearch);
  };

  const handleDoctorScheduleProfile = (
    doctorId,
    hospitalId,
    doctorName,
    doctorImageUrl,
    hospitalName,
    hospitalImageUrl,
    hospitalType,
    timezone,
    specializationName,
    doctorHospitalId,
    specializationId,
    poliId,
    serviceFee,
    isPrepaid = false,
    path = '/doctor/schedule',
    mcuProceduresId,
    regularChecked,
    bpjsChecked
  ) => {
    let params = {};
    params['hospital_group_id'] = props?.location?.state[0]?.hospital_group_id;
    params['name_doctor'] = doctorName;
    params['search_id_hospital'] =
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_id
        : [];
    params['search_specialization_id'] = search?.specialization_id || null;
    params['search_gender'] = search?.gender || null;
    params['search_consultation_type'] = search?.consultation || null;
    params['hospital_id'] = hospitalId;
    params['doctor_id'] = doctorId;
    params['doctor_hospital_id'] = doctorHospitalId;
    params['doctor_name'] = doctorName;
    params['doctor_imageurl'] = doctorImageUrl;
    params['hospital_name'] = hospitalName;
    params['hospital_image_url'] = hospitalImageUrl;
    params['hospital_type'] = hospitalType;
    params['timezone'] = timezone;
    params['specialization_id'] = specializationId;
    params['specialization_name'] = specializationName;
    params['poli_id'] = poliId;
    params['serviceFee'] = serviceFee;
    params['serviceType'] =
      props.location.state?.length > 0
        ? props.location.state[0].serviceType
        : '';
    params['isPrepaid'] = isPrepaid;
    params['mcuProceduresId'] = mcuProceduresId;
    params['regularChecked'] = regularChecked;
    params['bpjsChecked'] = bpjsChecked;
    params['filterName'] = doctorFilterName;
    params['filterKlinik'] = searchClinic;
    params['filterSpecialization'] = searchSpecialization;
    params['filterGender'] = searchGender;
    params['filterConsultation'] = searchConsultationType;
    history.push('/doctor-detail-v2', [params]);
  };

  const changeMCUProcedures = (key) => fetchProcedureMCU({ hospital_id, key });

  const handleDoctorSchedule = (
    doctorId,
    hospitalId,
    doctorName,
    doctorImageUrl,
    hospitalName,
    hospitalImageUrl,
    hospitalType,
    timezone,
    specializationName,
    doctorHospitalId,
    specializationId,
    poliId,
    serviceFee,
    isPrepaid = false,
    path = '/schedule',
    mcuProceduresId,
    regularChecked,
    bpjsChecked
  ) => {
    let params = {};
    params['hospital_group_id'] = props?.location?.state[0]?.hospital_group_id;
    params['name_doctor'] = search?.name_doctor || null;
    params['search_id_hospital'] = 
      props.location?.state?.length > 0
        ? props.location.state[0].hospital_id
        : [];
    params['search_specialization_id'] = search?.specialization_id || null;
    params['search_gender'] = search?.gender || null;
    params['search_consultation_type'] = search?.consultation || null;
    params['hospital_id'] = hospitalId;
    params['doctor_id'] = doctorId;
    params['doctor_hospital_id'] = doctorHospitalId;
    params['doctor_name'] = doctorName;
    params['doctor_imageurl'] = doctorImageUrl;
    params['hospital_name'] = hospitalName;
    params['hospital_image_url'] = hospitalImageUrl;
    params['hospital_type'] = hospitalType;
    params['timezone'] = timezone;
    params['specialization_id'] = specializationId;
    params['specialization_name'] = specializationName;
    params['poli_id'] = poliId;
    params['serviceFee'] = serviceFee;
    params['serviceType'] =
      props.location.state?.length > 0
        ? props.location.state[0].serviceType
        : '';
    params['isPrepaid'] = isPrepaid;
    params['mcuProceduresId'] = mcuProceduresId;
    params['regularChecked'] = regularChecked;
    params['bpjsChecked'] = bpjsChecked;
    params['filterName'] = doctorFilterName;
    params['filterKlinik'] = searchClinic;
    params['filterSpecialization'] = searchSpecialization;
    params['filterGender'] = searchGender;
    params['filterConsultation'] = searchConsultationType;

    history.push(path, [params]);
  };

  const [dataHospital, setDataHospital] = useState([]);

  useEffect(() => {
    let options = [];
    if (faskes && faskes.data && faskes.data.length > 0) {
      faskes.data.forEach((data, index) => {
        if (index == 0 && !props?.location?.state[0].filterKlinik) {
          const valuePayload = {
            value: data.id,
            label: data.name
          }
          const formatPayload = {
            ...valuePayload,
            target: valuePayload,
          };
          setSeachClinic(valuePayload);
          setPageSize(maxPageSize);
          changeFaskes(formatPayload);
        }
        options.push({
          value: data.id,
          label: data.name,
        })
      });
      setDataHospital(options);
    }
  }, [faskes]);

  const renderContent = () => {
    switch (search.serviceType) {
      case 'JT':
        return renderConsultation();
      case 'TL':
        return renderConsultation();
      case 'PM':
        return renderMedicalCheckUp();
      default:
        return renderConsultation();
    }
  };

  const renderConsultation = () => {
    return (
      <>
        <div className="col-md-12 col-sm-12 d-xl-block" style={{marginTop: "50px"}}>
          <div className="search">
            <div
              className="row row-no-margin"
            >
              <div className="col-lg-eq-5 col-md-10 col-sm-10 col-10 mb-3">
                <InputText
                  placeholder="Ketik Nama Dokter"
                  value={doctorFilterName}
                  size='lg'
                  setValue={(value) => {
                    const result = value.replace(/[^A-Za-z ]/gi, '');
                    const formatPayload = {
                      target: result,
                    };
                    setDoctorFilterName(result);
                    setPageSize(maxPageSize);
                    handleNameDoctor(formatPayload);
                  }}
                  type="text"
                />
              </div>
              <div className="d-lg-none col-md-2 col-sm-2 col-2 mb-3" style={{paddingLeft: "5px"}}>
                <img
                  className="cursor-point"
                  src={hideFilter ? "/img/icon/ic_minimize_down.svg" : "/img/icon/ic_minimize_up.svg"}
                  onClick={() => {setHideFilter(!hideFilter)}}
                  height={40}
                  width={40}
                />
              </div>
              {!hideFilter ? (
                <>
                  <div className="col-lg-eq-5 col-md-10 col-sm-10 col-10 mb-3">
                    <SelectOptions
                      borderColor="white"
                      placeholder="Ketik/Pilih Klinik"
                      fontSize="md"
                      options={dataHospital}
                      setValue={(value) => {
                        const formatPayload = {
                          ...value,
                          target: value,
                        };
                        setSeachClinic(value);
                        setPageSize(maxPageSize);
                        changeFaskes(formatPayload);
                      }}
                      value={searchClinic}
                      color="#757575"
                      background="white"
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </div>
                  <div className="col-lg-eq-5 col-md-10 col-sm-10 col-10 mb-3">
                    <SelectOptions
                      borderColor="white"
                      placeholder="Ketik/Pilih Spesialis"
                      fontSize="md"
                      isClearable
                      options={
                        specializations &&
                        specializations?.data?.map((e) => {
                          return { ...e, value: e?.id, label: e?.name };
                        })
                      }
                      setValue={(value) => {
                        const formatPayload = {
                          ...value,
                          target: value,
                        };
                        setSeachSpecialization(value);
                        setPageSize(maxPageSize);
                        changeSpecialization(formatPayload);
                      }}
                      value={searchSpecialization}
                      color="#757575"
                      background="white"
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </div>
                  <div className="col-lg-eq-5 col-md-10 col-sm-10 col-10 mb-3">
                    <div className="select-option">
                      <SelectOptions
                        borderColor="white"
                        placeholder="Ketik/Pilih Gender"
                        fontSize="md"
                        options={[
                          {
                            value: '1',
                            label: 'Male',
                          },
                          {
                            value: '2',
                            label: 'Female',
                          },
                        ]}
                        setValue={(value) => {
                          const formatPayload = {
                            ...value,
                            target: value,
                          };
                          setSearchGender(value);
                          setPageSize(maxPageSize);
                          changeGender(formatPayload);
                        }}
                        value={searchGender}
                        color="#757575"
                        isClearable
                        background="white"
                        customIcon={IconArrowGrey}
                        iconClassName="mr-2"
                      />
                    </div>
                  </div>
                  {search?.serviceType !== 'TL' && (
                    <div className="col-lg-eq-5 col-md-10 col-sm-10 col-10 mb-3">
                      <div className="select-option">
                        <SelectOptions
                          borderColor="white"
                          placeholder="Pilih Tipe Konsultasi"
                          isClearable
                          fontSize="md"
                          options={
                            consultationTypes &&
                            consultationTypes?.data
                              ?.filter(
                                (e) =>
                                  e?.consultation_type !== 3 &&
                                  search?.serviceType === 'JT'
                              )
                              ?.map((e) => {
                                return {
                                  ...e,
                                  value: e?.consultation_type,
                                  label: e?.consultation_name,
                                };
                              })
                          }
                          setValue={(value) => {
                            const formatPayload = {
                              ...value,
                              target: value,
                            };
                            setSearchConsultationType(value);
                            setPageSize(maxPageSize);
                            changeConsultation(formatPayload);
                          }}
                          value={searchConsultationType}
                          color="#757575"
                          background="white"
                          customIcon={IconArrowGrey}
                          iconClassName="mr-2"
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-12 d-xl-block mt-4">
          {loading ? (
            <img
              src={IconLoader}
              alt="loader"
              className="mt-5 d-flex mx-auto"
            />
          ) : (
            <div className="list-doctor w-100 row row-no-margin" style={{ marginBottom: '12em' }}>
              {schedules?.length ? (
                schedules?.map((item, index) => (
                  <div key={index} className={`col-lg-4 col-md-12 col-sm-12`}>
                    <Card key={index} className="mb-3">
                      <div className="row">
                        <div className="col-10">
                          <div className="d-flex">
                            <div className="d-flex">
                              <img
                                src={item?.doctorGender == '1' ? IconDummyAvatarMale : item?.doctorGender == '2' ? IconDummyAvatarFemale : IconDummyAvatar}
                                alt="icon"
                                className="d-inline my-auto mr-3"
                                height={40}
                                width={40}
                              />
                            </div>
                            <div style={{maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                              <p className="mobile-profile-name d-inline my-auto">
                                <span className="font-s-14 font-w-700">{item?.doctorName || '-'}{' '}</span><br/>
                                <span className="font-s-12 font-w-500">{item?.specializationName || '-'}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-end my-auto">
                          <img
                            src="/img/icon/ic_view.svg"
                            className="icon-edit"
                            height={24}
                            width={24}
                            onClick={() =>
                              handleDoctorScheduleProfile(
                                item?.doctorId,
                                item?.hospitalId,
                                item?.doctorName,
                                item?.doctorImageUrl,
                                item?.hospitalName,
                                item?.hospitalImageUrl,
                                item?.hospitalType,
                                item?.hospitalTimezone,
                                item?.specializationName,
                                item?.doctorHospitalId,
                                item?.specializationId,
                                item?.poliId,
                                item?.serviceFee,
                                false,
                                undefined,
                                undefined,
                                item?.regularChecked,
                                item?.bpjsChecked
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <hr className="hr-no-margin"/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <span className="font-s-14 font-w-500">{searchClinic.label}</span>
                        </div>
                      </div>
                      <div className="row margin-t-8 margin-b-16">
                        <div className="col-12">
                          <div className="d-flex">
                            <div className="d-flex">
                              <div>
                                <img
                                  src={item?.regularChecked === 1 ? "/img/icon/ic_check_green.svg" : "/img/icon/ic_check_gray.svg"}
                                  height={16}
                                  width={16}
                                />
                              </div>
                              <div>
                                <span className={`margin-l-4 font-s-14 font-w-500 ${item?.regularChecked === 1 ? "enabled" : "disabled"}`}>Reguler</span>
                              </div>
                            </div>
                            <div className="d-flex margin-l-16">
                              <div>
                                <img
                                  src={item?.bpjsChecked === 1 ? "/img/icon/ic_check_green.svg" : "/img/icon/ic_check_gray.svg"}
                                  height={16}
                                  width={16}
                                />
                              </div>
                              <div>
                                <span className={`margin-l-4 font-s-14 font-w-500 ${item?.bpjsChecked === 1 ? "enabled" : "disabled"}`}>Melayani BPJS</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <ButtonReact
                            className="button-green-40-w-100"
                            onClick={() => {
                              handleDoctorSchedule(
                                item?.doctorId,
                                item?.hospitalId,
                                item?.doctorName,
                                item?.doctorImageUrl,
                                item?.hospitalName,
                                item?.hospitalImageUrl,
                                item?.hospitalType,
                                item?.hospitalTimezone,
                                item?.specializationName,
                                item?.doctorHospitalId,
                                item?.specializationId,
                                item?.poliId,
                                item?.serviceFee,
                                false,
                                undefined,
                                undefined,
                                item?.regularChecked,
                                item?.bpjsChecked
                              );
                            }}
                          >
                            <span className="font-w-600 font-s-16">Buat Janji Temu</span>
                          </ButtonReact>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))
              ) : (
                <div
                  className="text-center mt-5 col-12"
                  style={{ marginBottom: '14em' }}
                >
                  <img src={IconFindDocuments} alt="icon" />
                  <p
                    className="mt-3 text-center"
                    style={{ fontSize: '18px' }}
                  >
                    Dokter tidak ditemukan
                  </p>
                </div>
              )}
              {doctor && doctor.length > 0 && (data.length < doctor.length) ? (
                // <Pagination
                //   className="pagination-bar d-flex justify-content-center"
                //   currentPage={currentPage}
                //   totalCount={doctor.length}
                //   pageSize={PageSize}
                //   onPageChange={(page) => setCurrentPage(page)}
                // />
                <div
                  className="text-center col-12 font-s-14 font-w-700 cl-green cursor-point"
                  onClick={() => setPageSize(PageSize + maxPageSize)}
                >
                  Lihat Lebih Banyak
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderMedicalCheckUp = () => {
    return (
      <>
        <div className="title d-xl-block d-none">
          <h1 className="text-center">{search.titleList}</h1>
        </div>
        <div className="col-md-12 d-xl-block d-none">
          <div className="search">
            <FormSearch
              setValue={changeMCUProcedures}
              options={
                lovProcedures &&
                lovProcedures?.map((e) => {
                  return { ...e, id: e?.name, label: e?.name };
                })
              }
            />
          </div>
        </div>
        <div className="col-md-12 d-xl-block d-none mt-4">
          {loading ? (
            <img
              src={IconLoader}
              alt="loader"
              className="mt-5 d-flex mx-auto"
            />
          ) : (
            <div className="list-doctor" style={{ marginBottom: '12em' }}>
              <>
                <Row>
                  {procedures?.length ? (
                    procedures?.map((item, index) => (
                      <Col sm={12} xs={12} md={6} lg={4} xl={4}>
                        <div className="card-detail-doctor" key={index}>
                          <Card>
                            <div className="col-md-12 header-card">
                              <Row>
                                <div className="col-12">
                                  <div className="photo-procedures d-flex justify-content-center align-items-center">
                                    <img
                                      src={item?.imageUrl}
                                      width="64"
                                      height="64"
                                      alt="Doctor"
                                      className="card"
                                    />
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                      <div className="profile-doctor">
                                        <div
                                          className="name-procedures"
                                          width="100"
                                          role="label"
                                        >
                                          {item?.name || '-'}{' '}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                      <div className="profile-doctor">
                                        <div className="location-doctor">
                                          Rp {formatRupiah(item?.price)}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="align-self-center col-12 justify-content-center mt-3">
                                      <Button
                                        type="primary"
                                        label="Pilih Paket"
                                        onClick={() => {
                                          handleDoctorSchedule(
                                            item?.clinicProcedureUuid,
                                            props.location.state[0]
                                              .hospital_id[0] || hospital_id,
                                            item?.name,
                                            item?.imageUrl,
                                            item?.hospitalName,
                                            '',
                                            '',
                                            '',
                                            '',
                                            '',
                                            '',
                                            '',
                                            item?.price,
                                            item?.isPrepaid,
                                            undefined,
                                            [
                                              {
                                                id: item.clinicProcedureId,
                                                price: item.price,
                                              },
                                            ]
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div
                      className="text-center mt-5 mx-auto"
                      style={{ marginBottom: '14em' }}
                    >
                      <img src={IconFindDocuments} alt="icon" />
                      <p
                        className="text-white mt-3 text-center"
                        style={{ fontSize: '18px' }}
                      >
                        Layanan tidak ditemukan
                      </p>
                    </div>
                  )}
                </Row>
              </>
              {doctor ? (
                <Pagination
                  className="pagination-bar d-flex justify-content-center"
                  currentPage={currentPage}
                  totalCount={procedures.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="fix-nav">
        <Col md={12}>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
                onClick={() =>
                  history.push('/home')
                }
            />
            &nbsp;&nbsp;
            <span
              className="font-s-18 font-w-700 cursor-point"
                onClick={() =>
                  history.push('/home')
                }
            >
              Janji Temu - Daftar Dokter
            </span>
          </div>
        </Col>
      </Row>

      {renderContent()}

    </LayoutV2>
  );
};

export default ResultFindDoctor;
