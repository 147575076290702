import axios from "../helpers/axios";
import axiosHisApi from "../helpers/axiosHisApi";
import axiosSalesApi from "../helpers/axiosSalesApi"
import { transactionHistoryConstants } from "./constants";

export const getTransactionHistory = (userId, status, filter) => {
  var queryParam = `userId=${userId}`;
  if(status){
    queryParam += `&status=${status}`;
  }

  if(filter.profilId){
    queryParam += `&profilId=${filter.profilId}`;
  }

  if(filter.consultationType){
    queryParam += `&consultationType=${filter.consultationType}`;
  }

  if(filter.scheduleDate){
    queryParam += `&scheduleDate=${filter.scheduleDate}`;
  }

  return async (dispatch) => {
    dispatch({ type: transactionHistoryConstants.GET_TRANSACTIONHISTORY_REQUEST });
    // const res = await axios.get(`/appointments/historyTrans?userId=${userId}&limit=2&offset=${offset}`);
    const res = await axios.get(`/appointments/historyTrans?${queryParam}`);
    if (res.status === 200) {
      dispatch({
        type: transactionHistoryConstants.GET_TRANSACTIONHISTORY_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: transactionHistoryConstants.GET_TRANSACTIONHISTORY_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getInvoice = (profilId, registerId) => {
  // registrationId = '1c7c73cb-5b73-4e1e-b0ff-8f39eb5fdfd1'
return async (dispatch) => {
  dispatch({ type: transactionHistoryConstants.GET_INVOICE_REQUEST });
    const res = await axiosSalesApi.get(`/v1/api/transactions/invoice/${registerId}?appointment=1&userId=${profilId}&isPaid=1`);
    if (res.status === 200) {
      dispatch({
        type: transactionHistoryConstants.GET_INVOICE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: transactionHistoryConstants.GET_INVOICE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  }
};

export const getDiagnosis = (profilId, registerId) => {
return async (dispatch) => {
  dispatch({ type: transactionHistoryConstants.GET_DIAGNOSIS_REQUEST });
    const res = await axiosSalesApi.get(`/v1/api/transactions/diagnosis/${registerId}?appointment=1&userId=${profilId}`);
    if (res.status === 200) {
      dispatch({
        type: transactionHistoryConstants.GET_DIAGNOSIS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: transactionHistoryConstants.GET_DIAGNOSIS_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  }
};

export const getRecipe = (profilId, registerId) => {
  return async (dispatch) => {
    dispatch({ type: transactionHistoryConstants.GET_RECIPE_REQUEST });
      const res = await axiosSalesApi.get(`/v1/api/transactions/recipe/${registerId}?appointment=1&userId=${profilId}`);
      if (res.status === 200) {
        dispatch({
          type: transactionHistoryConstants.GET_RECIPE_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: transactionHistoryConstants.GET_RECIPE_FAILURE,
          payload: { error: res.data.error },
        });
        return res.data;
      }
    }
  };

export const printMedicalResult = (form) => {
  return async (dispatch) => {
    dispatch({ type: transactionHistoryConstants.PRINT_MEDICALRESULT_REQUEST });
    const res = await axios.post(`/appointments/medical-result/print`, form);
    if (res.data.status === 200) {
      dispatch({
        type: transactionHistoryConstants.PRINT_MEDICALRESULT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: transactionHistoryConstants.PRINT_MEDICALRESULT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getMedicalRecord = (registrationId) => {
  // registrationId = '1c7c73cb-5b73-4e1e-b0ff-8f39eb5fdfd1'
  return async (dispatch) => {
    dispatch({ type: transactionHistoryConstants.GET_MEDICALRECORD_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/mr/all-data/${registrationId}`);
    if (res.status === 200) {
      dispatch({
        type: transactionHistoryConstants.GET_MEDICALRECORD_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: transactionHistoryConstants.GET_MEDICALRECORD_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};