import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LayoutV2 from "../Components/LayoutV2";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { getHospitalInfo } from "../../../actions/appointmentWeb/appointmentWeb.actions";
import "./index.scss";

const PartnerUs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const klinikGrupId = localStorage.getItem("klinikGrupId");

  const appointmentWeb = useSelector((state) => state.appointmentWeb);

  const [hospitalData, setHospitalData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    let values = {
      id: id,
    };

    let query = queryString.stringify(values);

    dispatch(getHospitalInfo(query));
  }, []);

  useEffect(() => {
    if (appointmentWeb.hospitalInfoData.length) {
      setHospitalData(appointmentWeb.hospitalInfoData[0]);
    }
  }, [appointmentWeb.hospitalInfoData]);

  const getIframeSrc = (data) => {
    if (!data) return "";

    return data.split(`"`)[1];
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col xs={12}>
          <div className="d-flex flex-column">
            <span className="font-s-18 font-w-700 margin-b-16">Mitra Kami</span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={2}>
          <Row className="margin-b-16">
            <Col
              xs={12}
              className="d-flex flex-column justify-content-center align-items-center text-center"
            >
              <img className="partner-us-image" src={hospitalData?.logo_url} />

              <span className="font-s-14 font-w-700">{hospitalData?.name}</span>
            </Col>
          </Row>
        </Col>

        <Col lg={10}>
          <Row className="margin-b-16">
            <Col xs={12}>
              <div className="partner-us-card">
                <Row>
                  {hospitalData?.desc && (
                    <Col xs={12} className="margin-b-16">
                      <span className="font-s-14 font-w-500">
                        {hospitalData.desc}
                      </span>
                    </Col>
                  )}

                  <Col xs={12}>
                    <Row>
                      {hospitalData?.address && hospitalData?.address_map ? (
                        <Col xs={12} lg={6}>
                          <Row>
                            {hospitalData?.address && (
                              <Col xs={12} className="margin-b-16">
                                <div className="d-flex flex-column">
                                  <span className="font-s-12 font-w-500 margin-b-4">
                                    Alamat
                                  </span>

                                  <span className="font-s-14 font-w-700">
                                    {hospitalData.address}
                                  </span>
                                </div>
                              </Col>
                            )}

                            {hospitalData?.address_map && (
                              <Col xs={12}>
                                <div className="d-flex flex-column">
                                  <iframe
                                    className="partner-iframe-gmap"
                                    src={getIframeSrc(hospitalData.address_map)}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                  ></iframe>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      ) : null}

                      <Col xs={12} lg={6}>
                        <Row>
                          {hospitalData?.phone_1 && (
                            <Col xs={12} lg={6} className="d-block margin-b-16">
                              <div>
                                <span className="font-s-12 font-w-500 margin-b-4">
                                  Nomor Telepon
                                </span>
                              </div>

                              <div>
                                <span
                                  className="font-s-14 font-w-700 cl-green cursor-point overflow-line-1"
                                  onClick={() =>
                                    window.open(
                                      `https://wa.me/${hospitalData.phone_1}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {hospitalData.phone_1}
                                </span>
                              </div>
                            </Col>
                          )}

                          {hospitalData?.phone_2 && (
                            <Col xs={12} lg={6} className="d-block margin-b-16">
                              <div>
                                <span className="font-s-12 font-w-500 margin-b-4">
                                  Nomor Telepon 2
                                </span>
                              </div>

                              <div>
                                <span className="font-s-14 font-w-700 cl-green cursor-point overflow-line-1">
                                  {hospitalData.phone_2}
                                </span>
                              </div>
                            </Col>
                          )}

                          {hospitalData?.email && (
                            <Col xs={12} lg={6} className="d-block margin-b-16">
                              <div>
                                <span className="font-s-12 font-w-500 margin-b-4">
                                  Email
                                </span>
                              </div>

                              <div>
                                <span
                                  className="font-s-14 font-w-700 cl-green cursor-point overflow-line-1"
                                  onClick={() =>
                                    window.open(
                                      `mailto:${hospitalData.email}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {hospitalData.email}
                                </span>
                              </div>
                            </Col>
                          )}

                          {hospitalData?.website_url && (
                            <Col xs={12} lg={6} className="d-block margin-b-16">
                              <div>
                                <span className="font-s-12 font-w-500 margin-b-4">
                                  Website
                                </span>
                              </div>

                              <div>
                                <span
                                  className="font-s-14 font-w-700 cl-green cursor-point overflow-line-1"
                                  onClick={() =>
                                    window.open(
                                      hospitalData.website_url,
                                      "_blank"
                                    )
                                  }
                                >
                                  {hospitalData.website_url}
                                </span>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutV2>
  );
};

export default PartnerUs;
