import React from 'react';
import {
  IconCheckedDisbled,
  IconCheckedEnabled,
  IconDummyAvatar,
  IconSearchWhite,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { wordingResultDoctorList } from '../../../constants/wording';
import './DoctorDetail.scss';

const MobileDoctorDetail = ({
  doctor,
  handleAnotherDoctor,
  handleDoctorSchedule,
  schedules,
  search,
}) => {
  return (
    <React.Fragment>
      <div className="pt-4 d-block d-md-none" style={{ marginBottom: '220px' }}>
        <div
          className="position-absolute"
          style={{ width: '200px', marginTop: '60px' }}
        >
          <Button
            label="Cari Dokter Lain"
            icon={IconSearchWhite}
            iconPosition="right"
            onClick={handleAnotherDoctor}
          />
        </div>
        <p
          className="font-weight-bold text-center text-white"
          style={{ fontSize: '32px', marginBottom: '70px' }}
        >
          Profil Dokter
        </p>
        <Card useInnerBorder>
          <div className="mx-auto">
            <img
              src={IconDummyAvatar}
              alt="icon"
              className="d-flex mx-auto"
              height={60}
              width={60}
            />
            <p className="mobile-profile-name text-center mt-1 mb-2">
              {doctor?.scheduleList?.tm_doctor?.name || '-'}
            </p>
          </div>
          <div className="mx-auto">
            <p className="mobile-profile-dob text-center">
              {doctor?.scheduleList?.tm_specialization?.name || '-'}
            </p>
            <p className="mobile-profile-dob text-center mt-2 mb-3">
              {doctor?.scheduleList?.tm_hospital?.name || '-'}
            </p>
            {search.serviceType !== 'TL' ? (
              <div className="d-flex justify-content-start">
                {schedules?.regularChecked ? (
                  <div className="d-flex align-items-center consultation-type">
                    <img
                      src={IconCheckedEnabled}
                      width="20"
                      height="20"
                      alt="Doctor"
                    />
                    <span className="font14 enabled">Reguler</span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center consultation-type">
                    <img
                      src={IconCheckedDisbled}
                      width="20"
                      height="20"
                      alt="Doctor"
                    />
                    <span className="font14 disabled">Reguler</span>
                  </div>
                )}
                {schedules?.bpjsChecked ? (
                  <div className="d-flex align-items-center consultation-type ml-4">
                    <img
                      src={IconCheckedEnabled}
                      width="20"
                      height="20"
                      alt="Doctor"
                    />
                    <span className="font14 enabled">Melayani BPJS</span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center consultation-type ml-4">
                    <img
                      src={IconCheckedDisbled}
                      width="20"
                      height="20"
                      alt="Doctor"
                    />
                    <span className="font14 disabled">Melayani BPJS</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center px-2 my-3">
                {schedules?.teleChecked ? (
                  <div className="d-flex align-items-center consultation-type">
                    <img
                      src={IconCheckedEnabled}
                      width="20"
                      height="20"
                      alt="Doctor"
                    />
                    <span className="font14 enabled">Telekonsultasi</span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center consultation-type">
                    <img
                      src={IconCheckedDisbled}
                      width="20"
                      height="20"
                      alt="Doctor"
                    />
                    <span className="font14 disabled">Telekonsultasi</span>
                  </div>
                )}
              </div>
            )}
            <p className="font-weight-bold text-dark font14 mt-3">
              Pendidikan:
            </p>
            <p className="text-dark font14">Universitas Indonesia</p>
            <p className="font-weight-bold text-dark font14 mt-2">
              Kondisi dan Minat Klinis:
            </p>
            <ul className="pl-4">
              <li className="text-dark font14">Selulitis</li>
              <li className="text-dark font14">Dermatofitosis (kurap)</li>
              <li className="text-dark font14">
                Kelainan kulit pada bayi baru lahir yang di dapat saat kehamilan
                atau persalinan ibu
              </li>
              <li className="text-dark font14">Penyakit genetik pada kulit</li>
              <li className="text-dark font14">Hiperhidrosis osmidrosis</li>
              <li className="text-dark font14">Kelainan rambut</li>
            </ul>
            <p className="font-weight-bold text-dark font14 mt-2">Prestasi:</p>
            <ul className="pl-4">
              <li className="text-dark font14">
                Tahun 2011 IDI (Ikatan Dokter Indonesia)
              </li>
              <li className="text-dark font14">
                Tahun 2013 IDI (Ikatan Dokter Indonesia)
              </li>
              <li className="text-dark font14">
                Tahun 2017 IDI (Ikatan Dokter Indonesia)
              </li>
              <li className="text-dark font14">
                Tahun 2020 IDI (Ikatan Dokter Indonesia)
              </li>
              <li className="text-dark font14">
                Tahun 2021 IDI (Ikatan Dokter Indonesia)
              </li>
            </ul>
          </div>
          <Button
            label={
              search.serviceType === 'TL'
                ? wordingResultDoctorList.labelAddTele
                : wordingResultDoctorList.labelAddReguler
            }
            className="mt-3"
            onClick={handleDoctorSchedule}
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default MobileDoctorDetail;
