import axios from "axios";

let cancelToken;


export const axiosGet = async (url, path) => {

  if (cancelToken) {
      cancelToken.cancel("Cancel request get")
  }
  cancelToken = axios.CancelToken.source();
  
  const currentURL = window.location.href;
  const pathname = window.location.pathname;
  const baseUrl = currentURL.replace(pathname, "");
  
  let res;
  try {
      res = await url.get(path, {
        cancelToken: cancelToken.token
      });
  } catch(e) {
    if( e.response?.status === 403 ||  e.response?.status === 401){
      localStorage.clear();
      window.location.href = baseUrl;
    }
  }

  return res
};