import { doctorScheduleConstants } from "../actions/constants";

const initState = {
  doctorScheduleData: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case doctorScheduleConstants.GET_DISABLEDDATE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case doctorScheduleConstants.GET_DISABLEDDATE_SUCCESS:
      state = {
        ...state,
        doctorScheduleData: action.payload.data,
        loading: false,
      };
      break;
    case doctorScheduleConstants.GET_DISABLEDDATE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
      case doctorScheduleConstants.GET_SLOT_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case doctorScheduleConstants.GET_SLOT_SUCCESS:
        state = {
          ...state,
          doctorScheduleData: action.payload.data,
          loading: false,
        };
        break;
      case doctorScheduleConstants.GET_DISABLEDDATE_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
  }
  return state;
};
