import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getDistance, getPreciseDistance } from 'geolib';
import { getDetailAppointment, checkIn } from '../../../actions';
import './Checkin.scss';
import { Row, Button, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Layout from '../Components/Layout';
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const Checkin = () => {
  var { id } = useParams();

  const appointment = useSelector((state) => state.appointment);
  const [appointmentData, setAppointmentData] = useState([]);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetailAppointment(id));
    navigator.geolocation.getCurrentPosition(function (position) {
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
    });
  }, [id]);

  useEffect(() => {
    if (appointment.appointmentDetail) {
      if (appointment.appointmentDetail.length > 0) {
        var appointmentDetail = appointment.appointmentDetail[0];
        setAppointmentData(appointmentDetail);

        if(appointmentDetail.orderstatus == 81){
          return Swal.fire({
            icon: 'info',
            title: `Hallo ${appointmentDetail.profilename}, <br/> Kamu Sudah Check In!`,
          }).then(() => {
            window.location = '/appointment-created/' + appointmentDetail.appointmentid;
          });

        }
      }
    } else {
      window.location = '/home';
    }
  }, [appointment.appointmentDetail]);

  const actionCheckIn = () => {
    if (!longitude || !latitude) {
      return Swal.fire({
        icon: 'error',
        text: 'Izinkan sistem mendeteksi lokasi anda saat ini',
      }).then(() => {
        window.location.reload();
      });
    }

    if (!appointmentData) {
      return Swal.fire({
        icon: 'error',
        text: 'Url yang anda masukkan salah, silahkan periksa kembali',
      });
    }

    var pdis = getPreciseDistance(
      //in meter
      { latitude: latitude, longitude: longitude },
      {
        latitude: appointmentData.hospitallatitude,
        longitude: appointmentData.hospitallongitude,
      }
    );

    var pDistance = parseFloat(pdis) / 1000; //in km

    if (pDistance > 1) {
      return Swal.fire({
        icon: 'error',
        text: 'Anda gagal untuk melakukan Check In (Anda berada diluar jangkauan)',
      });
    }

    const form = new FormData();
    form.append('appointmentId', id);
    form.append('registration_id', appointmentData.registration_id);
    form.append('patientId', appointmentData.patientid);
    form.append('klinikUrl', appointmentData.klinikurl);
    form.append('hospitalId', appointmentData.hospitalid);
    form.append('doctorId', appointmentData.doctorid);
    form.append('registrationDate', appointmentData.created_date);
    form.append('payerClinicsId', appointmentData.payerClinicsId);

    dispatch(checkIn(form))
      .then((response) => {
        var datas = response.data;
        if (datas) {
          setTimeout(
            () =>
              Swal.fire({
                icon: 'success',
                title: response.message,
                showConfirmButton: true,
                confirmButtonText: 'OK',
              }).then(() => {
                window.location = '/appointment-created/' + datas[0].id;
              }),
            200
          );
        } else {
          return Swal.fire({
            icon: 'error',
            text: response.message,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: 'error',
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: 'error',
            text: err.message,
          });
        }
      });
  };

  return (
    <Layout>
      <div className="content-checkin mt-5">
        <div className="background-utama">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <span>
              Halo {appointmentData.profilename},
              <br />
              Untuk mempercepat proses registrasi di {appointmentData.hospitalname}. <br/>kamu bisa melakukan <b>Check In</b> untuk janji temu berikut : 
            </span>
          </div>
        </div>

        <div className="order-card">
          <Row>
              <Col className="col1">
                  Nomor Reservasi
              </Col>
              <Col className="col2">
                  : {appointmentData.registrationNumber}
              </Col>
          </Row>
          <Row>
              <Col className="col1">
                  Nama Pasien
              </Col>
              <Col className="col2">
                  : {appointmentData.profilename}
              </Col>
          </Row>
          <Row>
              <Col className="col1">
                  Jadwal Kunjungan
              </Col>
              <Col className="col2">
                  : {moment(appointmentData.date).format("dddd, D MMMM YYYY")}
              </Col>
          </Row>
          <Row>
              <Col className="col1">
                  Jam
              </Col>
              <Col className="col2">
                  : {appointmentData.from_time+" - "+appointmentData.to_time}
              </Col>
          </Row>
          <Row>
              <Col className="col1">
                  Dokter
              </Col>
              <Col className="col2">
                  : {appointmentData.doctorname}
              </Col>
          </Row>
          <Row>
              <Col className="col1">
                  Klinik
              </Col>
              <Col className="col2">
                  : {appointmentData.hospitalname}
              </Col>
          </Row>
        </div>

        <div className="info-checkin col-12 justify-content-center align-items-center mt-2" >
          Pastikan kamu sudah berada disekitar {appointmentData.hospitalname} (kurang lebih dalam jarak 1 KM) 
        </div>

        <div style={{ padding: '20px' }}>
          <Button
            className="btn-done"
            variant="primary"
            onClick={() => actionCheckIn()}
          >
            Check In
          </Button>
          <Button
            className="btn-cancel"
            variant="primary"
            onClick={() => {
              window.location = '/home';
            }}
          >
            Tidak
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Checkin;
