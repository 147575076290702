import { articlesConstants } from "../actions/constants";

const initState = {
  articlesData: [],
  bannerData: [],
  articlesDataDetail: [],
  newsData: [],
  newsDataDetail: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case articlesConstants.GET_ARTICLES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case articlesConstants.GET_ARTICLES_SUCCESS:
      state = {
        ...state,
        articlesData: action.payload.data,
        loading: false,
      };
      break;
    case articlesConstants.GET_ARTICLES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case articlesConstants.GET_ARTICLES_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case articlesConstants.GET_ARTICLES_DETAIL_SUCCESS:
      state = {
        ...state,
        articlesDataDetail: action.payload.data,
        loading: false,
      };
      break;
    case articlesConstants.GET_ARTICLES_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case articlesConstants.GET_BANNER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case articlesConstants.GET_BANNER_SUCCESS:
      state = {
        ...state,
        bannerData: action.payload.data,
        loading: false,
      };
      break;
    case articlesConstants.GET_BANNER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
      case articlesConstants.GET_NEWS_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case articlesConstants.GET_NEWS_SUCCESS:
        state = {
          ...state,
          newsData: action.payload.data,
          loading: false,
        };
        break;
      case articlesConstants.GET_NEWS_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
    case articlesConstants.GET_NEWS_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case articlesConstants.GET_NEWS_DETAIL_SUCCESS:
      state = {
        ...state,
        newsDataDetail: action.payload.data,
        loading: false,
      };
      break;
    case articlesConstants.GET_NEWS_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
