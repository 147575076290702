import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../../../actions";

const DropdownMenu = ({ setIsMenuShow }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());

    history.push("/home");
  };

  return (
    <div className="dropdown-menu-list w-100-p max-w-1320">
      <Row>
        <Col>
          <span
            className="font-s-18 font-w-600 hover-cl-green cursor-point"
            onClick={() => {
              history.push("/about-us");
              setIsMenuShow(false);
            }}
          >
            Tentang Kami
          </span>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col>
          <span
            className="font-s-18 font-w-600 hover-cl-green cursor-point"
            onClick={() => {
              history.push("/news");
              setIsMenuShow(false);
            }}
          >
            Berita & Informasi
          </span>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col>
          <span
            className="font-s-18 font-w-600 hover-cl-green cursor-point"
            onClick={() => {
              history.push("/article");
              setIsMenuShow(false);
            }}
          >
            Artikel Kesehatan
          </span>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col>
          <span
            className="font-s-18 font-w-600 hover-cl-green cursor-point"
            onClick={() => {
              history.push("/contact-us");
              setIsMenuShow(false);
            }}
          >
            Hubungi Kami
          </span>
        </Col>
      </Row>

      {localStorage.accessToken && localStorage?.firstName != "null" ? (
        <>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col>
              <span
                className="font-s-18 font-w-600 hover-cl-green cursor-point"
                onClick={() => logOut()}
              >
                Keluar
              </span>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default DropdownMenu;
