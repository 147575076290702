import axios from "../helpers/axios";
import { articlesConstants } from "./constants";

export const getAllArticles = (search) => {
  return async (dispatch) => {
    dispatch({ type: articlesConstants.GET_ARTICLES_REQUEST });
    const res = await axios.get(`/articles/public?${search}`);
    if (res.status === 200) {
      dispatch({
        type: articlesConstants.GET_ARTICLES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: articlesConstants.GET_ARTICLES_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getAllArticlesDetail = (search) => {

  return async (dispatch) => {
    dispatch({ type: articlesConstants.GET_ARTICLES_DETAIL_REQUEST });
    const res = await axios.get(`/articles/public?${search}`);
    if (res.status === 200) {
      dispatch({
        type: articlesConstants.GET_ARTICLES_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: articlesConstants.GET_ARTICLES_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getAllNews = (search) => {
  return async (dispatch) => {
    dispatch({ type: articlesConstants.GET_NEWS_REQUEST });
    const res = await axios.get(`/articles/public?${search}`);
    if (res.status === 200) {
      dispatch({
        type: articlesConstants.GET_NEWS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: articlesConstants.GET_NEWS_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getAllNewsDetail = (search) => {

  return async (dispatch) => {
    dispatch({ type: articlesConstants.GET_NEWS_DETAIL_REQUEST });
    const res = await axios.get(`/articles/public?${search}`);
    if (res.status === 200) {
      dispatch({
        type: articlesConstants.GET_NEWS_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: articlesConstants.GET_NEWS_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getAllBanner = (search) => {
  return async (dispatch) => {
    dispatch({ type: articlesConstants.GET_BANNER_REQUEST });
    const res = await axios.get(`/articles/public?${search}`);
    if (res.status === 200) {
      dispatch({
        type: articlesConstants.GET_BANNER_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: articlesConstants.GET_BANNER_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

