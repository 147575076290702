import React, { useEffect, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LayoutV2 from "../Components/LayoutV2";
import "./index.scss";
import {
  InputText,
  InputTextArea,
  SelectOptions,
} from "../../../components/Input";
import { IconArrowGrey } from "../../../assets/img/icon";
import Button from "../../../components/Button";
import ModalComp from "../../../components/Modal";
import queryString from "query-string";
import {
  createInquiry,
  getHospitalInfo,
} from "../../../actions/appointmentWeb/appointmentWeb.actions";
import Swal from "sweetalert2";

const ContactUs = () => {
  const dispatch = useDispatch();
  const klinikGrupId = localStorage.getItem("klinikGrupId");

  const appointmentWeb = useSelector((state) => state.appointmentWeb);

  const [parentHospitalData, setParentHospitalData] = useState(null);
  const [partnerHospitalData, setPartnerHospitalData] = useState([]);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    desc: "",
    shadow: "",
  });
  const [error, setError] = useState(false);

  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    let values = {
      klinikGroupId: klinikGrupId,
      active: "1",
      sort: `[{"created_date": "ASC"}]`,
    };

    let query = queryString.stringify(values);

    dispatch(getHospitalInfo(query));
  }, []);

  useEffect(() => {
    if (appointmentWeb.hospitalInfoData.length) {
      let partnerData = [];

      appointmentWeb.hospitalInfoData.map((data) => {
        if (data.is_parent) {
          setParentHospitalData(data);
        } else {
          partnerData.push(data);
        }
      });

      setPartnerHospitalData(partnerData);
    }
  }, [appointmentWeb.hospitalInfoData]);

  const renderSuccessModal = () => {
    return (
      <ModalComp
        title="Informasi"
        show={successModal}
        setShow={() => setSuccessModal(false)}
        size="sm"
        onSubmit={() => setSuccessModal(false)}
        buttonSubmitLabel="Tutup"
      >
        <Row className="margin-b-8">
          <Col className="d-flex justify-content-center">
            <img src="/img/icon/icon-success-blue.svg" />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            <span className="font-s-16 font-w-400">
              Inquiry form berhasil terkirim
            </span>
          </Col>
        </Row>
      </ModalComp>
    );
  };

  const handleSubmit = () => {
    if (formData.type && formData.name && formData.email && formData.desc) {
      if (formData.shadow) {
        setFormData({
          type: "",
          name: "",
          email: "",
          desc: "",
        });
        setError(false);

        return setSuccessModal(true);
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(formData.email)) {
        return Swal.fire({
          icon: "error",
          text: "Email tidak valid!",
        });
      }

      let body = {
        userId: "app",
        klinik_group_id: klinikGrupId,
        type: formData.type.value,
        name: formData.name,
        email: formData.email,
        desc: formData.desc,
      };

      dispatch(createInquiry(body)).then(() => {
        setFormData({
          type: "",
          name: "",
          email: "",
          desc: "",
        });
        setError(false);

        return setSuccessModal(true);
      });
    } else {
      return setError(true);
    }
  };

  const getIframeSrc = (data) => {
    if (!data) return "";

    return data.split(`"`)[1];
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col lg={8}>
          <Row>
            <Col xs={12}>
              <div className="d-flex flex-column">
                <span className="font-s-18 font-w-700 margin-b-16">
                  Hubungi Kami
                </span>
              </div>
            </Col>
          </Row>

          <Carousel
            className="carousel-contact-us"
            autoPlay={false}
            interval={null}
            indicators={false}
          >
            {parentHospitalData && (
              <Carousel.Item>
                <Row className="margin-b-16">
                  <Col xs={12}>
                    <div className="d-flex flex-column">
                      <iframe
                        className="iframe-gmap"
                        src={getIframeSrc(parentHospitalData.address_map)}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="contact-us-card">
                      <Row>
                        <Col
                          className="d-flex align-items-center margin-b-16"
                          xs={12}
                          lg={4}
                        >
                          <div className="d-flex flex-column">
                            <span className="font-s-16 font-w-700 cl-green margin-b-4">
                              {parentHospitalData.name}
                            </span>

                            <span className="font-s-14 font-w-500">
                              {parentHospitalData.address}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="d-flex align-items-center margin-b-16"
                          xs={12}
                          lg={4}
                        >
                          <Row>
                            <Col className="d-flex align-items-center" xs={6}>
                              <div className="d-flex flex-column">
                                <span className="font-s-12 font-w-500 margin-b-4">
                                  Nomor Telepon
                                </span>

                                <span
                                  className="font-s-14 font-w-700 cl-green cursor-point"
                                  onClick={() =>
                                    window.open(
                                      `https://wa.me/${parentHospitalData.phone_1}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {parentHospitalData.phone_1}
                                </span>
                              </div>
                            </Col>

                            <Col className="d-flex align-items-center" xs={6}>
                              <div className="d-flex flex-column">
                                <span className="font-s-12 font-w-500 margin-b-4 v-hidden">
                                  Fax
                                </span>

                                <span className="font-s-14 font-w-700 cl-green cursor-point">
                                  {parentHospitalData.phone_2}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          className="d-flex align-items-center margin-b-16"
                          xs={12}
                          lg={4}
                        >
                          <div className="d-flex flex-column">
                            <span className="font-s-12 font-w-500 margin-b-4">
                              Email
                            </span>

                            <span
                              className="font-s-14 font-w-700 cl-green cursor-point"
                              onClick={() =>
                                window.open(
                                  `mailto:${parentHospitalData.email}`,
                                  "_blank"
                                )
                              }
                            >
                              {parentHospitalData.email}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
            )}

            {partnerHospitalData.map((data, index) => (
              <Carousel.Item key={index}>
                <Row className="margin-b-16">
                  <Col xs={12}>
                    <div className="d-flex flex-column">
                      <iframe
                        className="iframe-gmap"
                        src={getIframeSrc(data.address_map)}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="contact-us-card">
                      <Row>
                        <Col
                          className="d-flex align-items-center margin-b-16"
                          xs={12}
                          lg={4}
                        >
                          <div className="d-flex flex-column">
                            <span className="font-s-16 font-w-700 cl-green margin-b-4">
                              {data.name}
                            </span>

                            <span className="font-s-14 font-w-500">
                              {data.address}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="d-flex align-items-center margin-b-16"
                          xs={12}
                          lg={4}
                        >
                          <Row>
                            <Col className="d-flex align-items-center" xs={6}>
                              <div className="d-flex flex-column">
                                <span className="font-s-12 font-w-500 margin-b-4">
                                  Nomor Telepon
                                </span>

                                <span
                                  className="font-s-14 font-w-700 cl-green cursor-point"
                                  onClick={() =>
                                    window.open(
                                      `https://wa.me/${data.phone_1}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {data.phone_1}
                                </span>
                              </div>
                            </Col>

                            <Col className="d-flex align-items-center" xs={6}>
                              <div className="d-flex flex-column">
                                <span className="font-s-12 font-w-500 margin-b-4 v-hidden">
                                  Fax
                                </span>

                                <span className="font-s-14 font-w-700 cl-green cursor-point">
                                  {data.phone_2}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          className="d-flex align-items-center margin-b-16"
                          xs={12}
                          lg={4}
                        >
                          <div className="d-flex flex-column">
                            <span className="font-s-12 font-w-500 margin-b-4">
                              Email
                            </span>

                            <span
                              className="font-s-14 font-w-700 cl-green cursor-point"
                              onClick={() =>
                                window.open(`mailto:${data.email}`, "_blank")
                              }
                            >
                              {data.email}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>

        <Col lg={4}>
          <Row>
            <Col xs={12}>
              <div className="d-flex flex-column">
                <span className="font-s-18 font-w-700 margin-b-16">
                  Inquiry Form
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <SelectOptions
                placeholder="Pilih jenis formulir"
                label="Jenis Formulir"
                background="#ffff"
                fontSize="lg"
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
                value={formData.type}
                setValue={(e) => {
                  setFormData({
                    ...formData,
                    type: e,
                  });
                }}
                options={[
                  {
                    label: "Keluhan / Saran",
                    value: "Keluhan / Saran",
                  },
                  {
                    label: "Ulasan",
                    value: "Ulasan",
                  },
                  {
                    label: "Lainnya",
                    value: "Lainnya",
                  },
                ]}
                required
                isShowWarning={error && !formData.type}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <InputText
                label="Nama"
                labelClassName="mt-3"
                placeholder="Masukkan nama"
                type="alphabet"
                value={formData.name}
                setValue={(e) => {
                  setFormData({
                    ...formData,
                    name: e,
                  });
                }}
                required
                isShowWarning={error && !formData.name}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <InputText
                label="Email"
                labelClassName="mt-3"
                placeholder="Masukkan email"
                type="alphabet"
                value={formData.email}
                setValue={(e) => {
                  setFormData({
                    ...formData,
                    email: e,
                  });
                }}
                required
                isShowWarning={error && !formData.email}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <InputTextArea
                label="Deskripsi"
                labelClassName="mt-3"
                placeholder="Masukkan deskripsi"
                value={formData.desc}
                setValue={(e) => {
                  setFormData({
                    ...formData,
                    desc: e,
                  });
                }}
                required
                isShowWarning={error && !formData.desc}
                maxText={1000}
              />
            </Col>
          </Row>

          <Row className="d-none">
            <Col xs={12}>
              <InputText
                label="Shadow"
                labelClassName="mt-3"
                placeholder="_danger_"
                type="alphabet"
                value={formData.shadow}
                setValue={(e) => {
                  setFormData({
                    ...formData,
                    shadow: e,
                  });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Button
                label="Kirim"
                type="primary"
                className="mt-2 font-s-16"
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {renderSuccessModal()}
    </LayoutV2>
  );
};

export default ContactUs;
