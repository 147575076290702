export const formatDigit = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDollar = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
};

export const formatBillion = (value) => {
  return (Math.abs(Number(value)) / 1.0e9).toFixed(2);
};

export const formatMillion = (value) => {
  return (Math.abs(Number(value)) / 1.0e6).toFixed(2);
};

export const today = (a) => {
  var datenow = new Date();
  var dd = String(datenow.getDate()).padStart(2, '0');
  var mm = String(datenow.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = datenow.getFullYear();
  datenow = dd + '-' + mm + ' ' + yyyy;
  return String(datenow);
}

export const  terbilang = (value) => {
  const separation = value.toString().split('.')
  // return separation.length
  const bilne=["","satu","dua","tiga","empat","lima","enam","tujuh","delapan","sembilan","sepuluh","sebelas"];
  if(separation.length > 1) {
    const terbilangPrefix = terbilang(parseInt(separation[0]))
    const terbilangKoma = terbilang(parseInt(separation[1])) 
    return terbilangPrefix + ' koma ' + terbilangKoma 
  }
  if(value < 12){

      return bilne[value];

  }else if(value < 20){

      return terbilang(value-10)+" belas";

  }else if(value < 100){

      return terbilang(Math.floor(parseInt(value)/10))+" puluh "+terbilang(parseInt(value)%10);

  }else if(value < 200){

      return "seratus "+terbilang(parseInt(value)-100);

  }else if(value < 1000){

      return terbilang(Math.floor(parseInt(value)/100))+" ratus "+terbilang(parseInt(value)%100);

  }else if(value < 2000){

      return "seribu "+terbilang(parseInt(value)-1000);

  }else if(value < 1000000){

      return terbilang(Math.floor(parseInt(value)/1000))+" ribu "+terbilang(parseInt(value)%1000);

  }else if(value < 1000000000){

      return terbilang(Math.floor(parseInt(value)/1000000))+" juta "+terbilang(parseInt(value)%1000000);

  }else if(value < 1000000000000){

      return terbilang(Math.floor(parseInt(value)/1000000000))+" milyar "+terbilang(parseInt(value)%1000000000);

  }else if(value < 1000000000000000){

      return terbilang(Math.floor(parseInt(value)/1000000000000))+" trilyun "+terbilang(parseInt(value)%1000000000000);

  }

}
export const formatRupiah = (value) => {
  if (!value) return null;
  value = value?.toString();
  let number_string = value?.replace(/[^,\d]/g, '')?.toString(),
    split = number_string?.split(','),
    sisa = split[0]?.length % 3,
    rupiah = split[0]?.substr(0, sisa),
    ribuan = split[0]?.substr(sisa)?.match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan?.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return rupiah;
};

export const formatRupiahV2 = (value) => {
  return String(value)
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3}\B)/g, '$1.')
    .split('')
    .reverse()
    .join('')
    .replace(/\.00$/, '');
};