import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getDetailAppointmentPackage } from "../../../actions";
import LayoutV2 from "../Components/LayoutV2";
import "./OrderPackageDone.scss";
moment.locale("id");

const OrderPackageDone = () => {
  var { id } = useParams();

  const [firstName, setFirstName] = useState(
    window.localStorage.getItem("firstName") != "null"
      ? window.localStorage.getItem("firstName")
      : ""
  );
  const appointment = useSelector((state) => state.appointment);
  const [appointmentData, setAppointmentData] = useState([]);
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState("");

  const handleMouseOver = (e) => {
    setIsHovering(e);
    console.log(e);
  };

  const handleMouseOut = () => {
    setIsHovering("");
  };

  useEffect(() => {
    dispatch(getDetailAppointmentPackage(id));
  }, [id]);

  useEffect(() => {
    if (appointment.appointmentDetail) {
      if (appointment.appointmentDetail.length != 0) {
        var appointmentDetail = appointment.appointmentDetail[0];

        setAppointmentData({
          patientName: appointmentDetail.profileName,
          hospitalName: appointmentDetail.hospitalName,
          status: appointmentDetail.orderStatus,
          linkPayment: appointmentDetail.linkPayment,
          packageName: appointmentDetail.packageName,
          packagePrice: appointmentDetail.packagePrice,
        });
      }
    } else {
      window.location = "/home";
    }
  }, [appointment.appointmentDetail]);

  return (
    <LayoutV2
      header={true}
      headerGlobal={true}
      footer={true}
      footerCopyright={true}
    >
      <div className="content-orderDone my-5">
        <div className="background-utama">
          <img
            className="image"
            style={{ marginTop: "30px" }}
            src={"/img/icon/success.png"}
            data-cfsrc={"/img/icon/success.png"}
            alt={"klinika-logo"}
          />
          <div className="col-12 d-flex justify-content-center align-items-center mt-3">
            {appointmentData.status == 85 || appointmentData.status == 80 ? (
              <span>
                Halo {firstName ? firstName : appointmentData.patientName},{" "}
                <br />
                Selamat reservasi Anda berhasil dibuat
              </span>
            ) : appointmentData.status == 81 ? (
              <span>
                Halo {firstName ? firstName : appointmentData.patientName},{" "}
                <br />
                Selamat Anda berhasil Check In
              </span>
            ) : appointmentData.status == 9 ? (
              <span>
                Halo {firstName ? firstName : appointmentData.patientName},{" "}
                <br />
                Silahkan Lakukan Pembayaran
              </span>
            ) : appointmentData.status == 10 ? (
              <span>
                Halo {firstName ? firstName : appointmentData.patientName},{" "}
                <br />
                Reservasi Anda Masuk Daftar Tunggu
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="order-card col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
          <Row>
            <Col className="col1">Nama Pasien</Col>
            <Col className="col2">: {appointmentData.patientName}</Col>
          </Row>
          <Row>
            <Col className="col1">Nama Paket</Col>
            <Col className="col2">: {appointmentData.packageName}</Col>
          </Row>
          <Row>
            <Col className="col1">Harga</Col>
            <Col className="col2">: {appointmentData.packagePrice}</Col>
          </Row>
          <Row>
            <Col className="col1">Klinik</Col>
            <Col className="col2">: {appointmentData.hospitalName}</Col>
          </Row>

          {appointmentData.status == 9 ? (
            <Row>
              <Col className="col1">Link Pembayaran</Col>
              <Col className="col2">
                :{" "}
                <a
                  href={appointmentData.linkPayment}
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  Klik Disini
                </a>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {appointmentData.status == 80 &&
          appointmentData.consultationType == 3 ? (
            <>
              <Row>
                <Col className="col1">Tautan Zoom Meeting</Col>
                <Col className="col2">
                  : <a href={appointmentData.zoomUrl}>Zoom Meeting URL</a>
                </Col>
              </Row>
              <Row>
                <Col className="col1">Meeting ID</Col>
                <Col className="col2">: {appointmentData.meetingId}</Col>
              </Row>
              <Row>
                <Col className="col1">Kata Sandi</Col>
                <Col className="col2">: {appointmentData.zoomPin}</Col>
              </Row>
            </>
          ) : appointmentData.status != 9 ? (
            appointmentData.status == 81 ? (
              <label className="msg-info">
                Anda telah melakukan check in secara mandiri. Silahkan langsung
                menuju poli {appointmentData.poliname} untuk melakukan
                konsultasi dengan {appointmentData.doctorName}
              </label>
            ) : appointmentData.status == 10 ? (
              <label className="msg-info">
                Anda akan mendapatkan konfirmasi ketersediaan jadwal selambat -
                lambatnya H-1 dari jadwal yang anda pilih.
              </label>
            ) : appointmentData.scheduleType == 1 ? ( //FIFO
              <label className="msg-info">
                Nomor antrian akan ditentukan berdasarkan urutan kedatangan di
                klinik.
              </label>
            ) : appointmentData.scheduleType == 2 ? ( //Hourly
              <label className="msg-info">
                Pastikan Anda hadir di klinik pada rentang waktu yang telah
                ditentukan.
              </label>
            ) : (
              //Fixed
              <label className="msg-info">
                Pastikan Anda hadir di klinik pada jadwal yang telah ditentukan.
              </label>
            )
          ) : (
            <></>
          )}
        </div>

        <div className="col-12 justify-content-center align-items-center">
          <h3>
            Saya Menyetujui
            <a
              href="/tnc"
              target="_blank"
              style={{ color: "#00BD84", fontWeight: "bold" }}
            >
              {" "}
              syarat dan ketentuan{" "}
            </a>
            penggunaan dan kebijakan privasi janji temu{" "}
            {appointmentData.hospitalName}
            <br />
            Terima kasih telah memilih kami sebagai klinik pilihan Anda, kami
            akan menghubungi Anda melalui email/Whatsapp
          </h3>
          <br />
          <br />
        </div>
        <div className="col-12 justify-content-center align-items-center fixed-height">
          <p className="text-center">
            Bagikan &nbsp;
            <button
              className="circle-share pt-2"
              onClick={() =>
                window.open(
                  "whatsapp://send?text=" +
                    `${
                      firstName ? firstName : appointmentData.patientName
                    }%20ingin%20membagikan%20konfirmasi%20Pembelian%20Paket%20${
                      appointmentData.packageName
                    }%20di%20${
                      appointmentData.hospitalName
                    }.%20Silahkan%20klik%20link%20berikut%20untuk%20membuka%20halaman%20konfirmasi%20${
                      window.location
                    }`
                )
              }
              onMouseOver={() => handleMouseOver("Bagikan ke WA")}
              onMouseOut={() => handleMouseOver("")}
            >
              {" "}
              <i
                className="fa fa-whatsapp"
                aria-hidden="true"
                style={{ fontSize: "28.66px" }}
              />
            </button>
            <button
              className="circle-share pt-2"
              onClick={() =>
                navigator.clipboard.writeText(
                  `${
                    firstName ? firstName : appointmentData.patientName
                  } ingin membagikan konfirmasi Janji Temu di ${
                    appointmentData.hospitalName
                  } pada tanggal ${
                    appointmentData.appointmentDate
                  }. Silahkan klik link berikut untuk membuka halaman konfirmasi ${
                    window.location
                  }`
                )
              }
              onMouseOver={() => handleMouseOver("Salin Tautan")}
              onMouseOut={() => handleMouseOver("")}
            >
              {" "}
              <i
                className="fa fa-link"
                aria-hidden="true"
                style={{ fontSize: "27.35px" }}
              />
            </button>
          </p>
          <p className="col-12 text-center mt-0">{isHovering}</p>
        </div>

        <div style={{ padding: "20px" }}>
          <Button
            className="btn-done"
            variant="primary"
            onClick={() => {
              window.location = "/home";
            }}
          >
            Selesai
          </Button>
        </div>
      </div>
    </LayoutV2>
  );
};

export default OrderPackageDone;
