import React from 'react';
import './footer.scss';

const Footer = () => {
  return (
    <footer>
      <p
        className="margin-top-footer text-center text-md-left d-md-block d-none text-white position-relative bottom-0 ml-3"
        style={{ fontSize: '14px' }}
      >
        <a href="/tnc" className="font-weight-bold">
          Syarat dan Ketentuan
        </a>{' '}
        <br />
        Powered by Aido Health All Right Reserved{' '}
      </p>
      <p
        className="text-center d-block d-md-none text-white position-relative bottom-0"
        style={{ fontSize: '14px', width: '100%', marginTop: '15%' }}
      >
        <a href="/tnc" className="font-weight-bold">
          Syarat dan Ketentuan
        </a>{' '}
        <br />
        Powered by Aido Health All Right Reserved{' '}
      </p>
    </footer>
  );
};

export default Footer;
