import axios from "axios";
import { hisApi } from "../urlConfig";

const accessToken = window.localStorage.getItem("accessToken");
// const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInJvbGVOYW1lIjoidXNlciIsImVtYWlsIjoic3VwZXJhZG1pbkBhaWRvLmlkIiwicGhvbmUiOiIxMjM0NTY3ODEyIiwiaWF0IjoxNjYyNzMwMTI1LCJleHAiOjE2OTQyNjYxMjV9.4Eu1tlbzJBfGS1cgr4LzPNqz5I_p53f-pbKDsG76N6Q";

const axiosIntance = axios.create({
  baseURL: hisApi,
  headers: {
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export default axiosIntance;
