import moment from 'moment';
import Card from '../../../components/Card';
import React, { useState } from 'react';
import {
  IconArrow,
  IconCameraBgBlue,
  IconCrossCircle,
  IconDetail,
  IconDummyAvatar,
  IconLocationBgBlue,
  IconPencilCircle,
} from '../../../assets/img/icon';
import Dropdowns from '../../../components/Dropdown';
import './TransactionHistory.scss';
import Pagination from '../Components/Pagination';
import ConsultationResult from './ConsultationResult';

const MobileTransactionHistory = ({
  list,
  active,
  handleDetailModal,
  handleInvoicePage,
  latitude,
  longitude,
  history,
  PageSize,
  currentPage,
  setCurrentPage,
  handlePrintMedicalResult,
}) => {
  /* Local State */
  const [detail, setDetail] = useState();
  const [showModalDetail, setShowModalDetail] = useState(false);

  const handleMenus = (data) => {
    let menus = [];

    if (active == 1) {
      if (data.consultationtype == 3) {
        menus.push({
          menuLabel: (
            <>
              <img
                src={IconCameraBgBlue}
                alt="icon"
                style={{ marginRight: '10px' }}
                className="d-inline"
              />
              <p className="text-dark font-weight-normal d-inline">
                Masuk Telekonsultasi
              </p>
            </>
          ),
          onClick: () => {
            window.open(data.zoomurl);
          },
        });
      } else {
        menus.push({
          menuLabel: (
            <>
              <img
                src={IconLocationBgBlue}
                alt="icon"
                style={{ marginRight: '10px' }}
                className="d-inline"
              />
              <p className="text-dark font-weight-normal d-inline">
                Petunjuk Lokasi
              </p>
            </>
          ),
          onClick: () => {
            window.open(
              'https://www.google.com/maps/dir/' +
                latitude +
                ',' +
                longitude +
                '/' +
                data.hospitalname +
                '/@' +
                data.hospitallatitude +
                ',' +
                data.hospitallongitude
            );
          },
        });
      }

      menus.push(
        {
          menuLabel: (
            <>
              <img
                src={IconDetail}
                alt="icon"
                style={{ marginRight: '10px' }}
                className="d-inline"
              />
              <p className="text-dark font-weight-normal d-inline">Detail</p>
            </>
          ),
          onClick: () => {
            handleDetailModal(data);
          },
        },
        {
          menuLabel: (
            <>
              <img
                src={IconPencilCircle}
                alt="icon"
                style={{ marginRight: '10px' }}
                className="d-inline"
              />
              <p className="text-dark font-weight-normal d-inline">Ubah</p>
            </>
          ),
          onClick: () => window.open('https://wa.me/628118481436'),
        },
        {
          menuLabel: (
            <>
              <img
                src={IconCrossCircle}
                alt="icon"
                style={{ marginRight: '10px' }}
                className="d-inline"
              />
              <p className="text-dark font-weight-normal d-inline">Batalkan</p>
            </>
          ),
          onClick: () => window.open('https://wa.me/628118481436'),
        }
      );
    } else if (active == 2) {
      menus.push({
        menuLabel: (
          <>
            <img
              src={IconDetail}
              alt="icon"
              style={{ marginRight: '10px' }}
              className="d-inline"
            />
            <p className="text-dark font-weight-normal d-inline">
              Hasil Konsultasi
            </p>
          </>
        ),
        onClick: () => {
          setDetail(data);
          setShowModalDetail(true);
        },
      });
    }

    return menus;
  };

  return (
    <React.Fragment>
      <ConsultationResult
        detail={detail}
        handlePrintMedicalResult={handlePrintMedicalResult}
        setShowModalDetail={setShowModalDetail}
        showModalDetail={showModalDetail}
      />
      <div className="d-xl-none d-block text-dark">
        <Card type="primary" className="mb-3" innerClassName="no-padding">
          {list?.length ? (
            list.map((data) => (
              <div key={'card' + data.code} className="mb-3 card-inner">
                <img
                  src={IconDummyAvatar}
                  alt="Profile"
                  className="mx-auto d-flex"
                />
                <p className="mobile-profile-name text-center my-2">
                  {data?.doctorname || '-'}
                </p>
                <p className="text-dark font14 text-center">
                  {moment(data.date).format('dddd, D MMM YYYY')}{' '}
                  {moment(data.date + ' ' + data.from_time).format('H:mm')} -
                  {moment(data.date + ' ' + data.to_time).format('H:mm')}{' '}
                  {data.timezone}
                </p>
                <p className="text-dark font18 font-weight-600 text-center my-4">
                  {data?.hospitalname || '-'}
                </p>
                {data?.orderstatus === 88 ? null : (
                  <Dropdowns
                    className="h-100 border-0 w-100"
                    label={
                      <>
                        <p className="d-inline text-white font16 font-weight-600">
                          Ubah
                        </p>
                        <img
                          src={IconArrow}
                          alt="icon"
                          height={20}
                          className="d-inline ml-2"
                        />
                      </>
                    }
                    menus={handleMenus(data)}
                  />
                )}
              </div>
            ))
          ) : (
            <p className="font-italic text-dark text-center my-4">
              -- Tidak ada data --
            </p>
          )}
        </Card>
      </div>
      {history ? (
        <div className="d-flex justify-content-center d-xl-none">
          <Pagination
            className="pagination-bar color-gray text-dark"
            currentPage={currentPage}
            totalCount={history.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default MobileTransactionHistory;
