import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import { Button as ReactButton, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  createAppointment,
  getCompanyGuarantee,
  getInsuranceByHospital,
  uploadInsuranceDocFile,
  uploadJaminanDocFile,
} from "../../../actions";
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCloseGreen,
  IconPlusWhite,
} from "../../../assets/img/icon";
import Button from "../../../components/Button";
import { InputText, SelectOptions } from "../../../components/Input";
import ModalComp from "../../../components/Modal";
import {
  documentEmployeeTypes,
  documentInsuranceTypes,
} from "../../../constants/common";
import LayoutV2 from "../Components/LayoutV2";
moment.locale("id");

const Payment = () => {
  /* Router DOM */
  const history = useHistory();
  const dispatch = useDispatch();

  /* Redux */
  const insurance = useSelector((state) => state.paymentMethod);
  const { loading } = useSelector((state) => state.appointment);

  /* State */
  const { state } = useLocation();
  if (!state) window.location = "/";

  const doctorName = state?.appointmentData
    ? state.appointmentData.doctorName
    : "";
  const hospitalName = state?.appointmentData
    ? state.appointmentData.hospitalName
    : "";

  /* Local State */
  const [errorField, setErrorField] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [insuranceName, setInsuranceName] = useState();
  const [insuranceNumber, setInsuranceNumber] = useState();
  const [insuranceMemberName, setInsuranceMemberName] = useState();
  const [companyName, setCompanyName] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [userId, setUserId] = useState(window.localStorage.getItem("userId"));
  const [fullName, setFullName] = useState(
    window.localStorage.getItem("fullName") != "null"
      ? window.localStorage.getItem("fullName")
      : ""
  );
  const [firstName, setFirstName] = useState(
    window.localStorage.getItem("firstName") != "null"
      ? window.localStorage.getItem("firstName")
      : ""
  );
  const [appointmentData, setAppointmentData] = useState(state.appointmentData);
  const [paymentTypeId, setPaymentTypeId] = useState(null);

  const [insuranceDoc, setInsuranceDoc] = useState([]);
  const [docInsuranceTypeDisplay, setDocInsuranceTypeDisplay] = useState([]);
  const [insuranceDocModal, setInsuranceDocModal] = useState(false);

  const [docType, setDocType] = useState();

  const [jaminanDoc, setJaminanDoc] = useState([]);
  const [jaminanDocModal, setJaminanDocModal] = useState(false);

  const [agreement, setAgreement] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [informationModal, setInformationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorRequired, setErrorRequired] = useState([]);

  useEffect(() => {
    var hospitalId = appointmentData.hospital_id;
    if (hospitalId) {
      dispatch(getInsuranceByHospital(hospitalId));
      dispatch(getCompanyGuarantee(hospitalId));
    }
  }, [appointmentData.hospital_id]);

  useEffect(() => {
    var urlInsuranceDoc = insurance.urlInsuranceDoc
      ? insurance.urlInsuranceDoc.url
      : "";

    if (urlInsuranceDoc) {
      setInsuranceDoc([
        ...insuranceDoc,
        {
          id: insuranceDoc.length + 1,
          docType: docType,
          docUrl: urlInsuranceDoc,
        },
      ]);

      handleCloseModal();
    }
  }, [insurance.urlInsuranceDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, insuranceDoc });
  }, [insuranceDoc]);

  useEffect(() => {
    var urlJaminanDoc = insurance.urlJaminanDoc
      ? insurance.urlJaminanDoc.url
      : "";

    if (urlJaminanDoc) {
      setJaminanDoc([
        ...jaminanDoc,
        {
          id: jaminanDoc.length + 1,
          docType: docType,
          docUrl: urlJaminanDoc,
        },
      ]);
      handleCloseModal();
    }
  }, [insurance.urlJaminanDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, jaminanDoc });
  }, [jaminanDoc]);

  const paymentTypeSelected = (e) => {
    const paymentTypeId = e.target.value;
    const payerClinicsId = e.target.id;
    const payerClinicsName = e.target.label;

    if (paymentTypeId) {
      setPaymentTypeId(paymentTypeId);
      setAppointmentData({
        ...appointmentData,
        payer: paymentTypeId,
        payer_name: "",
        payer_no: "",
        payer_owner_name: "",
        payerClinicsId: payerClinicsId,
        payerClinicsName: payerClinicsName,
        insuranceClinicsId: 0,
      });
      setErrorRequired([]);
    }
  };

  const onChangeInsuranceName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_name: e.target.label,
      insuranceClinicsId: e?.target?.id,
    });
    if (e.target.value === "8") {
      setPaymentTypeId("8");
      setAppointmentData({
        ...appointmentData,
        payer: "8",
      });
    }
  };

  const onChangeCompanyName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_name: e.target.value,
      insuranceClinicsId: e.target?.id,
    });
    if (e.target.value === "8") {
      setPaymentTypeId("8");
      setAppointmentData({
        ...appointmentData,
        payer: "8",
      });
    }
  };

  const onChangeInsuranceNumber = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_no: e,
    });
  };

  const onChangeInsuranceMemberName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_owner_name: e,
    });
  };

  const onChangeEmployeeName = (e) => {
    setAppointmentData({
      ...appointmentData,
      payer_owner_name: e,
    });
  };

  const uploadDocumment = (e) => {
    var uploadId = e.target.id;

    if (!docType) {
      const formUpload = document.getElementById("formUpload");

      formUpload.reset();

      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Tipe Dokumen",
      });
    } else {
      if (uploadId == "uploadInsurance") {
        const uploadInsurance =
          document.getElementById("uploadInsurance").files;
        const fileTypes = ["image/jpeg", "image/png", "application/pdf"];

        if (fileTypes.includes(uploadInsurance[0].type)) {
          dispatch(
            uploadInsuranceDocFile(uploadInsurance[0], docType?.value)
          ).then(() => {
            setSuccessModal(true);
          });
        } else {
          return Swal.fire({
            icon: "error",
            text: "Harap Unggah File .jpeg, .png, atau .pdf!",
          });
        }
      } else {
        const uploadJaminan = document.getElementById("uploadJaminan").files;
        const fileTypes = ["image/jpeg", "image/png", "application/pdf"];

        if (fileTypes.includes(uploadJaminan[0].type)) {
          dispatch(uploadJaminanDocFile(uploadJaminan[0], docType?.value)).then(
            () => {
              setSuccessModal(true);
            }
          );
        } else {
          return Swal.fire({
            icon: "error",
            text: "Harap Unggah File .jpeg, .png, atau .pdf!",
          });
        }
      }
    }
  };

  const handleInsuranceDocModal = () => {
    setInsuranceDocModal(true);
  };

  const renderInsuranceDocModal = () => {
    return (
      <ModalComp
        title="Dokumen Jaminan Asuransi"
        show={insuranceDocModal}
        setShow={handleCloseModal}
        onSubmit={handleCloseModal}
        size="sm"
        buttonSubmitLabel="Simpan"
        submitLoading={insurance?.loading}
      >
        <div style={{ height: "160px" }}>
          <SelectOptions
            placeholder="Pilih tipe dokumen"
            fontSize="lg"
            value={docType}
            setValue={setDocType}
            options={documentInsuranceTypes}
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
          />

          <Row>
            <Col style={{ textAlign: "right" }}>
              <form id="formUpload">
                <input
                  type="file"
                  id="uploadInsurance"
                  style={{ display: "none" }}
                  onChange={(e) => uploadDocumment(e)}
                />
                <label
                  htmlFor="uploadInsurance"
                  className="label-file-upload"
                  style={{ paddingBottom: "30px" }}
                >
                  <img src={IconPlusWhite} alt="icon" /> Unggah
                </label>
              </form>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  const handleJaminanDocModal = () => {
    setJaminanDocModal(true);
  };

  const renderJaminanDocModal = () => {
    return (
      <ModalComp
        title="Dokumen Jaminan Perusahaan"
        show={jaminanDocModal}
        setShow={handleCloseModal}
        size="sm"
        buttonSubmitLabel="Simpan"
        onSubmit={handleCloseModal}
        submitLoading={insurance?.loading}
      >
        <div style={{ height: "160px" }}>
          <SelectOptions
            placeholder="Pilih tipe dokumen"
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
            setValue={setDocType}
            value={docType}
            options={documentEmployeeTypes}
            fontSize="lg"
          />
          <Row>
            <Col style={{ textAlign: "right" }}>
              <form id="formUpload">
                <input
                  type="file"
                  id="uploadJaminan"
                  style={{ display: "none" }}
                  onChange={(e) => uploadDocumment(e)}
                />
                <label
                  htmlFor="uploadJaminan"
                  className="label-file-upload"
                  style={{ paddingBottom: "30px" }}
                >
                  <img src={IconPlusWhite} alt="icon" /> Unggah
                </label>
              </form>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  const renderInformationModal = () => {
    return (
      <ModalComp
        title="Informasi"
        show={informationModal}
        setShow={() => {
          setJaminanDocModal(false);
          setInsuranceDocModal(false);
          setInformationModal(false);
          setConfirmationModal(true);
        }}
        size="sm"
        buttonSubmitLabel="Tutup"
        onSubmit={() => {
          setJaminanDocModal(false);
          setInsuranceDocModal(false);
          setInformationModal(false);
          setConfirmationModal(true);
        }}
      >
        <p
          className="text-center warning-modal-text"
          style={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#424242",
            marginTop: "32px",
            marginBottom: "72px",
          }}
        >
          {+paymentType?.value === 2
            ? "Pembayaran dilakukan setelah Anda melakukan konsultasi dokter."
            : `Jaminan ini hanya berlaku sementara untuk melanjutkan pesanan Anda. Sebelum konsultasi, pihak Klinik akan melakukan verifikasi jaminan Anda dan jika tidak lolos verifikasi atau ada potensi ekses biaya, pihak klinik akan menginformasikannya saat Anda melakukan registrasi di klinik.`}
        </p>
      </ModalComp>
    );
  };

  //konfirmasi data
  const handleConfirmationModal = () => {
    if (paymentTypeId == "3") {
      if (
        appointmentData.payer_name &&
        appointmentData.payer_no &&
        appointmentData.payer_owner_name
      ) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_name) {
          errorData.push("payer_name");
        }
        if (!appointmentData.payer_no) {
          errorData.push("payer_no");
        }
        if (!appointmentData.payer_owner_name) {
          errorData.push("payer_owner_name");
        }
        setErrorRequired(errorData);
        setErrorField(true);
      }
    } else if (paymentTypeId == "4") {
      if (appointmentData.payer_name && appointmentData.payer_owner_name) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_name) {
          errorData.push("payer_name");
        }
        if (!appointmentData.payer_owner_name) {
          errorData.push("payer_owner_name");
        }
        setErrorRequired(errorData);
        setErrorField(true);
      }
    } else if (paymentTypeId == "8") {
      if (appointmentData.payer_name) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_name) {
          errorData.push("payer_name");
        }
        setErrorRequired(errorData);
        setErrorField(true);
      }
    } else {
      setInformationModal(true);
    }
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setInsuranceDocModal(false);
    setJaminanDocModal(false);
    setDocType("");
  };

  const actionCreateAppointment = () => {
    const form = new FormData();

    var userPhone = window.localStorage.getItem("phone");
    var config = JSON.parse(window.localStorage.getItem("headerData"));

    form.append("doctor_hospital_id", appointmentData.doctor_hospital_id);
    form.append("specialization_id", appointmentData.specialization_id);
    form.append("poli_id", appointmentData.poli_id);
    form.append("hospitalGroupId", config ? config.klinik_group_id : null);
    form.append("userPhone", userPhone);
    form.append("patient_id", state.selectedPatient.patient_id);
    form.append("identity_type", state.selectedPatient.identity_type);
    form.append("identity_name", state.selectedPatient.identity_name);
    form.append("identity_number", state.selectedPatient.identity_number);
    form.append("name", state.selectedPatient.name);
    form.append("gender", state.selectedPatient.gender);
    form.append(
      "dob",
      moment(state.selectedPatient.dob, "YYYY-MM-DD").format("YYYY-MM-DD")
    );
    form.append("phone_1", state.selectedPatient.phone_1);
    form.append("email", state.selectedPatient.email);
    form.append("relation", state.selectedPatient.relation);
    form.append("user_id", userId);

    form.append("hospital_id", appointmentData.hospital_id);
    form.append("doctor_id", appointmentData.doctor_id);
    form.append("schedule_id", appointmentData.doctor_schedule_id);
    form.append("doctor_schedule_id", appointmentData.doctor_schedule_id);

    if (
      state.selectedPatient.id != null &&
      state.selectedPatient.id != undefined &&
      state.selectedPatient.id != "" &&
      state.selectedPatient.id != "new"
    ) {
      form.append("profile_id", state.selectedPatient.id);
    }

    form.append(
      "date",
      moment(appointmentData.date, "DDMMYYYY").format("YYYY-MM-DD")
    );
    form.append("from_time", appointmentData.from_time);
    form.append("to_time", appointmentData.to_time);
    form.append("note", appointmentData.note);

    if (appointmentData.medicalDoc) {
      form.append(
        "medical_doc",
        appointmentData.medicalDoc?.length
          ? JSON.stringify(appointmentData.medicalDoc)
          : ""
      );
    }

    form.append("payer", appointmentData.payer);
    form.append("payerClinicsId", appointmentData.payerClinicsId);
    form.append("insuranceClinicsId", appointmentData.insuranceClinicsId);
    if (appointmentData.payer_name) {
      form.append("payer_name", appointmentData.payer_name);
    }

    if (paymentTypeId == "3" && insuranceNumber) {
      form.append("payer_no", insuranceNumber);
    }

    if (appointmentData.payer_owner_name) {
      form.append("payer_owner_name", appointmentData.payer_owner_name);
    }

    if (paymentTypeId == "3" && insuranceDoc.length != 0) {
      form.append("payer_doc", JSON.stringify(insuranceDoc));
    }

    if (paymentTypeId == "4" && jaminanDoc.length != 0) {
      form.append("payer_doc", JSON.stringify(jaminanDoc));
    }

    form.append("complaints", appointmentData.complaints);
    form.append("firstName", firstName);
    form.append("status", 85);
    form.append("isCMS", false);
    form.append("scheduleType", appointmentData.scheduleType);
    form.append("consultationType", appointmentData.consultation_type);
    form.append("mcuProceduresId", appointmentData.mcuProceduresId);
    form.append("serviceType", appointmentData.serviceType);

    if (appointmentData.is_waiting_list) {
      form.append("is_waiting_list", appointmentData.is_waiting_list);
    }

    dispatch(createAppointment(form))
      .then((response) => {
        var datas = response.data;
        if (datas.data) {
          setTimeout(
            () =>
              Swal.fire({
                icon: "success",
                title: "Janji Temu Berhasil Dibuat",
                showConfirmButton: true,
                confirmButtonText: "OK",
              }).then(() => {
                if (datas.userData) {
                  localStorage.setItem("firstName", datas.userData.firstName);
                  localStorage.setItem("fullName", datas.userData.fullName);
                }

                if (datas.data) {
                  window.location = "/appointment-created/" + datas.data.id;
                }
              }),
            200
          );
        } else {
          return Swal.fire({
            icon: "error",
            text: response.message,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const handleChangeAgreement = (e) => {
    setAgreement(e.target.checked);
  };

  const renderConfirmationModal = () => {
    return (
      <ModalComp
        title="Konfirmasi Data"
        show={confirmationModal}
        setShow={handleCloseModal}
        size="sm"
      >
        <Row className="d-flex justify-content-center text-center margin-b-8">
          <Col xs={9}>
            <span className="font-s-16 font-w-500">
              Halo {fullName}, pastikan data yang Anda masukkan telah sesuai.
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Jenis Identitas</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.identity_name || "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Nomor Identitas</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.identity_number || "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Nama</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.name || "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Tanggal Lahir</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.dob
                ? moment(state.selectedPatient.dob).format("D MMMM YYYY")
                : "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Nomor HP</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.phone_1
                ? "+62" + state.selectedPatient.phone_1
                : "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Email</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.email &&
              state.selectedPatient.email != "undefined"
                ? state.selectedPatient.email
                : "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Jenis Kelamin</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.selectedPatient.gender == 1
                ? "Laki-Laki"
                : state.selectedPatient.gender == 2
                ? "Perempuan"
                : "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Nama Dokter</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.appointmentData.doctorName || "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Klinik</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.appointmentData.hospitalName || "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Tanggal</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.appointmentData.date
                ? moment(state.appointmentData.date, "DDMMYYYY").format(
                    "dddd, D MMMM YYYY"
                  )
                : "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Waktu</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {state.appointmentData.from_time +
                " - " +
                state.appointmentData.to_time +
                " " +
                state.appointmentData.timezone}
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <div>
              <span className="font-s-14 font-w-500">Jenis Jaminan</span>
            </div>
          </Col>
          <Col xs={7}>
            <span>:</span>&nbsp;
            <span className="font-s-14 font-w-700">
              {appointmentData.payerClinicsName || "-"}
            </span>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>

        <Row className="margin-b-32">
          <Col>
            <div className="d-flex">
              <input
                className="margin-r-8"
                id="agreement"
                type="checkbox"
                checked={agreement}
                onChange={(e) => handleChangeAgreement(e)}
              />

              <label for="agreement" className="font-s-14 font-w-500">
                Saya menyetujui{" "}
                <span
                  className="font-w-700 cl-green cursor-point"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/terms-and-conditions");
                  }}
                >
                  Syarat dan Ketentuan
                </span>{" "}
                dan{" "}
                <span
                  className="font-w-700 cl-green cursor-point"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/privacy-policy");
                  }}
                >
                  Kebijakan Privasi
                </span>{" "}
                janji temu.
              </label>
            </div>
          </Col>
        </Row>

        <Row className="margin-b-12">
          <Col>
            <Button
              className="font-s-16 font-w-500"
              label="Kirim"
              type="primary"
              disable={!agreement}
              onClick={actionCreateAppointment}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              className="font-s-16 font-w-500"
              label="Batal"
              type="primary"
              onClick={handleCloseModal}
            />
          </Col>
        </Row>
      </ModalComp>
    );
  };

  const renderSuccessModal = () => {
    return (
      <ModalComp
        title="Informasi"
        show={successModal}
        setShow={() => setSuccessModal(false)}
        size="sm"
        onSubmit={() => setSuccessModal(false)}
        buttonSubmitLabel="Tutup"
      >
        <Row className="margin-b-8">
          <Col className="d-flex justify-content-center">
            <img src="/img/icon/icon-success-blue.svg" />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            <span className="font-s-16 font-w-400">
              Dokumen berhasil di upload
            </span>
          </Col>
        </Row>
      </ModalComp>
    );
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="fix-nav">
        <Col>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
              onClick={() =>
                history.push({
                  pathname: "patient-v3",
                  state: {
                    selectedPatient: state.selectedPatient,
                    appointmentData: appointmentData,
                  },
                })
              }
            />
            &nbsp;&nbsp;
            <span
              className="font-s-18 font-w-700 cursor-point"
              onClick={() =>
                history.push({
                  pathname: "patient-v3",
                  state: {
                    selectedPatient: state.selectedPatient,
                    appointmentData: appointmentData,
                  },
                })
              }
            >
              Pembayaran
            </span>
          </div>
        </Col>
      </Row>

      <Row className="margin-t-66 margin-b-16 text-center">
        <Col xs={12}>
          <span className="font-s-14 font-w-400">Langkah 3 Dari 3</span>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center margin-b-16">
        <Col xs={12} lg={10}>
          <div className="card-patient-v3">
            <Row>
              <Col lg={12}>
                <SelectOptions
                  placeholder="Pilih jenis jaminan"
                  label="Pilih Jenis Jaminan"
                  background="#ffff"
                  value={paymentType}
                  fontSize="lg"
                  setValue={(e) => {
                    const formatPayload = {
                      target: e,
                    };
                    setPaymentType(e);
                    paymentTypeSelected(formatPayload);
                    setErrorField(false);
                  }}
                  options={
                    appointmentData?.hospitalPayer?.length &&
                    appointmentData.hospitalPayer
                      .filter((data) => data.payersId != 5)
                      .map((e) => {
                        return {
                          ...e,
                          label: e?.payerName,
                          value: e?.payersId,
                        };
                      })
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />
              </Col>
            </Row>

            {paymentTypeId == "3" ? (
              <>
                <Row className="margin-t-16 margin-b-16">
                  <Col lg={12}>
                    <SelectOptions
                      required
                      placeholder="Pilih asuransi"
                      label="Pilih Asuransi"
                      background="#ffff"
                      fontSize="lg"
                      value={insuranceName}
                      isShowWarning={errorField && !insuranceName}
                      setValue={(e) => {
                        const formatPayload = {
                          target: e,
                        };
                        setInsuranceName(e);
                        onChangeInsuranceName(formatPayload);
                      }}
                      options={
                        insurance?.insuranceData?.length &&
                        insurance?.insuranceData?.map((e) => {
                          return {
                            ...e,
                            label: e?.name,
                            value: e?.id,
                          };
                        })
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col lg={12}>
                    <InputText
                      required
                      label="Nomor Peserta"
                      placeholder="Masukkan nomor peserta"
                      value={insuranceNumber}
                      isShowWarning={errorField && !insuranceNumber}
                      setValue={(value) => {
                        setInsuranceNumber(value);
                        onChangeInsuranceNumber(value);
                      }}
                      type="number"
                    />
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col lg={12}>
                    <InputText
                      required
                      label="Nama Peserta"
                      placeholder="Masukkan nama peserta"
                      value={insuranceMemberName}
                      isShowWarning={errorField && !insuranceMemberName}
                      setValue={(value) => {
                        const result = value.replace(/[^A-Za-z ]/gi, "");
                        setInsuranceMemberName(result);
                        onChangeInsuranceMemberName(result);
                      }}
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col>
                    <div className="margin-b-8">
                      <span className="font-s-16 font-w-700">
                        Upload Dokumen Asuransi
                      </span>
                    </div>

                    <Button
                      label="Upload Dokumen"
                      type="primary"
                      onClick={handleInsuranceDocModal}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {insuranceDoc.length > 0 ? (
                      insuranceDoc.map((data, index) => (
                        <Row key={index}>
                          <Col xs={12}>
                            <span
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                color: "black",
                                fontWeight: "normal",
                              }}
                            >
                              {data
                                ? data.docUrl.split("/")[
                                    data.docUrl.split("/").length - 1
                                  ]
                                : ""}
                            </span>
                            &nbsp;&nbsp;
                            <a
                              href={data.docUrl}
                              target="_blank"
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                color: "#00BD84",
                                fontWeight: "bold",
                              }}
                            >
                              Lihat Dokumen
                            </a>
                            &nbsp;
                            <img
                              src={IconCloseGreen}
                              alt="icon"
                              className="cursor-point"
                              onClick={() =>
                                setInsuranceDoc(
                                  insuranceDoc.filter((e) => e.id !== data.id)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </>
            ) : paymentTypeId == "4" ? (
              <>
                <Row className="margin-t-16 margin-b-16">
                  <Col lg={12}>
                    <SelectOptions
                      required
                      placeholder="Pilih perusahaan"
                      label="Pilih Perusahaan"
                      background="#ffff"
                      fontSize="lg"
                      value={companyName}
                      isShowWarning={errorField && !companyName}
                      setValue={(e) => {
                        const formatPayload = {
                          target: e,
                        };
                        setCompanyName(e);
                        onChangeCompanyName(formatPayload);
                      }}
                      options={
                        insurance?.companyData?.length &&
                        insurance?.companyData?.map((e) => {
                          return {
                            ...e,
                            label: e?.name,
                            value: e?.id,
                          };
                        })
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col lg={12}>
                    <InputText
                      required
                      label="Nama Karyawan"
                      placeholder="Masukkan nama karyawan"
                      value={employeeName}
                      isShowWarning={errorField && !employeeName}
                      setValue={(value) => {
                        const result = value.replace(/[^A-Za-z ]/gi, "");

                        setEmployeeName(result);
                        onChangeEmployeeName(result);
                      }}
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col>
                    <div className="margin-b-8">
                      <span className="font-s-16 font-w-700">
                        Upload Dokumen Karyawan
                      </span>
                    </div>

                    <Button
                      label="Upload Dokumen"
                      type="primary"
                      onClick={handleJaminanDocModal}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {jaminanDoc.length > 0 ? (
                      jaminanDoc.map((data, index) => (
                        <Row key={index}>
                          <Col xs={12}>
                            <span
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                color: "black",
                                fontWeight: "normal",
                              }}
                            >
                              {data
                                ? data.docUrl.split("/")[
                                    data.docUrl.split("/").length - 1
                                  ]
                                : ""}
                            </span>
                            &nbsp;&nbsp;
                            <a
                              href={data.docUrl}
                              target="_blank"
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                color: "#00BD84",
                                fontWeight: "bold",
                              }}
                            >
                              Lihat Dokumen
                            </a>
                            &nbsp;
                            <img
                              src={IconCloseGreen}
                              alt="icon"
                              className="cursor-point"
                              onClick={() =>
                                setJaminanDoc(
                                  jaminanDoc.filter((e) => e.id !== data.id)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}

            <Row className="margin-t-40">
              <Col>
                <Button
                  label="Buat Janji Temu"
                  type="primary"
                  disable={!paymentTypeId}
                  onClick={handleConfirmationModal}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {renderConfirmationModal()}
      {renderInsuranceDocModal()}
      {renderJaminanDocModal()}
      {renderInformationModal()}
      {renderSuccessModal()}
    </LayoutV2>
  );
};

export default Payment;
