import moment from 'moment';
import React, { useState } from 'react';
import { IconDocumentGreen, IconDummyAvatar } from '../../../assets/img/icon';
import Button from '../../../components/Button';
import ConsultationResult from './ConsultationResult';
import './TransactionHistory.scss';

const DesktopTransactionDone = ({ data, handlePrintMedicalResult }) => {
  /* Local State */
  
  const [detail, setDetail] = useState();
  const [showModalDetail, setShowModalDetail] = useState(false);
  // console.log("data", data);
  return (
    <React.Fragment>
      <ConsultationResult
        detail={detail}
        handlePrintMedicalResult={handlePrintMedicalResult}
        setShowModalDetail={setShowModalDetail}
        showModalDetail={showModalDetail}
      />
      <div className="card-inner mb-3">
        <div className="row px-3 pt-3">
          <div className="col pr-0">
            <p className="font14 font-weight-600 text-dark">
              Tipe Konsultasi :
            </p>
            <p className="font14 text-dark">
              {data.consultationtype == 3
                ? data.consultationdesc
                : 'Konsultasi ' + data.consultationdesc}
            </p>
          </div>
          <div className="col px-0">
            <p className="font14 font-weight-600 text-dark">Pasien :</p>
            <p className="font14 text-dark">{data.profilename || '-'}</p>
          </div>
          <div className="col px-0">
            <p className="font14 font-weight-600 text-dark">
              Tanggal Pemesanan :
            </p>
            <p className="font14 text-dark">
              {moment(data.created_date).format('dddd, D MMM YYYY')}
            </p>
          </div>
          <div className="col px-0">
            <p className="font14 font-weight-600 text-dark">No. Order :</p>
            <p className="font14 text-dark">{data.registrationNumber || '-'}</p>
          </div>
          <div className="col px-0">
            <p className="font14 font-weight-600 text-dark">Status :</p>
            <p className="font14 text-dark">{data.orderstatusdesc || '-'}</p>
          </div>
          {/* Temporary Comment
          {data?.orderstatus === 88 ? null : (
            <div className="col-3 my-auto">
              <Button
                label="Lihat Invoice"
                type="outline-primary"
                icon={IconDocumentGreen}
                iconPosition="right"
                size="sm"
                onClick={() => window.open('https://wa.me/628118481436')}
              />
            </div>
          )} */}
        </div>
        <hr className="no-padding" />
        <div className="row px-3 pb-3">
          <div className="col pr-0 d-flex justify-content-start">
            <img src={IconDummyAvatar} />
            <div className="ml-2 my-auto">
              <p className="font-weight-600 text-dark font16">
                {data.doctorname || '-'}
              </p>
              <p className="text-dark font14">
                {data.specializationname || '-'}
              </p>
            </div>
          </div>
          <div className="col px-0 my-auto">
            <p className="font16 font-weight-600 text-dark">
              Tanggal Konsultasi :
            </p>
            <p className="text-dark font14">
              {moment(data.date).format('dddd, D MMM YYYY')}
            </p>
            <p className="text-dark font14">
              {moment(data.date + ' ' + data.from_time).format('H:mm')} -
              {moment(data.date + ' ' + data.to_time).format('H:mm')}{' '}
              {data.timezone}
            </p>
          </div>
          <div className="col px-0 my-auto">
            <div className="w-100">
              <p className="font14 text-dark text-center">
                {data.hospitalname || '-'}
              </p>
            </div>
          </div>
          {data?.orderstatus === 88 ? null : (
            <div className="col my-auto">
              <Button
                label="Hasil Konsultasi"
                type="outline-primary"
                icon={IconDocumentGreen}
                iconPosition="right"
                size="sm"
                onClick={() => {
                  setDetail(data);
                  setShowModalDetail(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesktopTransactionDone;
