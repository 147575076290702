import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import {
  createProfile,
  editProfile,
  editUser,
  getAllAccountProfile,
  getBloodType,
  getCity,
  getDistrict,
  getEducation,
  getEthnic,
  getIdentityType,
  getJob,
  getMarriageStatus,
  getNationality,
  getPostalCode,
  getProvince,
  getRelation,
  getReligion,
  getSubdistrict,
  logout,
  uploadAvatarFile,
} from '../../../actions';
import ModalComp from '../../../components/Modal';
import Layout from '../Components/Layout';
import './AccountProfile.scss';
import ListProfile from './ListProfile';
import { InputDate, InputText, SelectOptions, InputTextArea } from '../../../components/Input';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCalenderGrey,
  IconCloseGreen,
  IconPlusWhite,
} from '../../../assets/img/icon';

const AccountProfile = () => {
  let PageSize = 8;

  const [profileData, setProfileData] = useState({
    id: '',
    name: '',
    gender: '',
    dob: '',
    phone_1: '',
    email: '',
    identity_type: '',
    identity_name: '',
    identity_number: '',
    identity_file: '',
    emergency_contact_name: '',
    emergency_contact_phone: '',
    emergency_contact_email: '',
    emergency_contact_relation: '',
    relation: '',
    user_id: '',
    weight: '',
    height: '',
    age: '',
    marital_status: '',
    blood_type: '',
    ethincities: '',
    nationality: '',
    education: '',
    occupation: '',
    religion: '',
    province_id: '',
    city_id: '',
    district_id: '',
    sub_district_id: '',
    postal_code: '',
    identity_address: '',
  });
  const [userId, setUserId] = useState(window.localStorage.getItem('userId'));
  const [userData, setUserData] = useState([]);
  const [addProfileModal, setAddProfileModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [errorRequired, setErrorRequired] = useState([]);
  const [errorFormat, setErrorFormat] = useState([]);
  const [offset, setOffset] = useState(0);
  const accountProfile = useSelector((state) => state.accountProfile);

  const [profil, setProfil] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const [optionJenisIdentitas, setOptionJenisIdentitas] = useState();
  const [optionStatusPernikahan, setOptionStatusPernikahan] = useState();
  const [optionEtnis, setOptionEtnis] = useState();
  const [optionGolonganDarah, setOptionGolonganDarah] = useState();
  const [optionAgama, setOptionAgama] = useState();
  const [optionKewarganegaraan, setOptionKewarganegaraan] = useState();
  const [optionTingkatPendidikan, setOptionTingkatPendidikan] = useState();
  const [optionPekerjaan, setOptionPekerjaan] = useState();
  const [optionPropinsi, setOptionPropinsi] = useState();
  const [optionKabupaten, setOptionKabupaten] = useState();
  const [optionKecamatan, setOptionKecamatan] = useState();
  const [optionKelurahan, setOptionKelurahan] = useState();
  const [txtNomorIdentitas, setTxtNomorIdentitas] = useState();
  const [optionHubunganPasien, setOptionHubunganPasien] = useState();
  const [optionKodePos, setOptionKodePos] = useState();
  const [txtNamaKtp, setTxtNamaKtp] = useState();
  const [optionHubungan, setOptionHubungan] = useState();
  const [optionJenisKelamin, setOptionJenisKelamin] = useState();
  const [txtNomorTelepon, setTxtNomorTelepon] = useState();
  const [txtNomorTeleponKerabat, setTxtNomorTeleponKerabat] = useState();
  const [txtEmail, setTxtEmail] = useState();
  const [txtKerabat, setTxKerabat] = useState();
  const [txtTanggalLahir, setTxtTanggalLahir] = useState(moment());
  const [txtAlamat, setTxtAlamat] = useState();
  const [errorField, setErrorField] = useState(false);
  const [tempProfile, setTempProfile] = useState();

  var config = window.localStorage.getItem('headerData') ? JSON.parse(window.localStorage.getItem('headerData')) : [];

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setData(profil?.slice(firstPageIndex, lastPageIndex));
    return profil?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  useEffect(() => {
    currentTableData = profil?.slice(0, PageSize);
    setData(currentTableData);
  }, [profil]);

  useEffect(() => {
    if (localStorage?.firstName === 'null' && data?.length) {
      handleEditProfile(
        data?.filter(
          (e) => e?.relationname?.toUpperCase() === 'SAYA SENDIRI'
        )[0]
      );
    }
  }, [data]);

  const initState = () => {
    setProfileData({
      name: '',
      gender: '',
      dob: '',
      phone_1: '',
      email: '',
      identity_type: '',
      identity_name: '',
      identity_number: '',
      identity_file: '',
      emergency_contact_name: '',
      emergency_contact_phone: '',
      emergency_contact_email: '',
      emergency_contact_relation: '',
      relation: '',
      user_id: '',
      weight: '',
      height: '',
      age: '',
      marital_status: '',
      blood_type: '',
      ethincities: '',
      nationality: '',
      education: '',
      occupation: '',
      religion: '',
      province_id: '',
      city_id: '',
      district_id: '',
      sub_district_id: '',
      postal_code: '',
      identity_address: '',
    });

    setOptionJenisIdentitas('')
    setOptionStatusPernikahan('')
    setOptionEtnis('')
    setOptionGolonganDarah('')
    setOptionAgama('')
    setOptionKewarganegaraan('')
    setOptionTingkatPendidikan('')
    setOptionPekerjaan('')
    setOptionPropinsi('')
    setOptionKabupaten('')
    setOptionKecamatan('')
    setOptionKelurahan('')
    setTxtNomorIdentitas('')
    setOptionHubunganPasien('')
    setOptionKodePos('')
    setTxtNamaKtp('')
    setOptionHubungan('')
    setOptionJenisKelamin('')
    setTxtNomorTelepon('')
    setTxtNomorTeleponKerabat('')
    setTxtEmail('')
    setTxKerabat('')
    setTxtTanggalLahir(moment())
    setTxtAlamat('')
    
    setAddProfileModal(false);
    setEditProfileModal(false);
    setEditUserModal(false);
    setErrorRequired([]);
    setErrorField(false);
    setTempProfile();
  };

  useEffect(() => {
    dispatch(getAllAccountProfile(userId, offset));
    dispatch(getRelation());
    dispatch(getIdentityType());
    dispatch(getMarriageStatus());
    dispatch(getBloodType());
    dispatch(getEthnic());
    dispatch(getReligion());
    dispatch(getNationality());
    dispatch(getEducation());
    dispatch(getJob());
    dispatch(getProvince());
  }, [userId, offset]);

  useEffect(() => {
    if (profileData?.province_id) {
      dispatch(getCity(profileData?.province_id));
    }
  }, [profileData?.province_id]);

  useEffect(() => {
    if (profileData?.city_id) {
      dispatch(getDistrict(profileData?.city_id));
    }
  }, [profileData?.city_id]);

  useEffect(() => {
    if (profileData?.district_id) {
      dispatch(getSubdistrict(profileData?.district_id));
    }
  }, [profileData?.district_id]);

  useEffect(() => {
    if (profileData?.sub_district_id) {
      dispatch(getPostalCode(profileData?.sub_district_id));
    }
  }, [profileData?.sub_district_id]);

  useEffect(() => {
    if (accountProfile.profileData?.userData) {
      setUserData(accountProfile.profileData?.userData);
    }

    if (accountProfile.profileData?.detail) {
      setProfil(accountProfile.profileData?.detail);
      
      let totalPage = Math.ceil(profil?.length / PageSize);
      setTotalPage(totalPage);
    }
  }, [accountProfile.profileData]);

  useEffect(() => {
    var userDataVal = accountProfile.userData ? accountProfile.userData : [];
    if (userDataVal.length !== 0) {
      localStorage.setItem('fullName', userDataVal.fullName);
      localStorage.setItem('firstName', userDataVal.firstName);
      localStorage.setItem('lastName', userDataVal.lastName);
      localStorage.setItem('phone', userDataVal.phone);
      localStorage.setItem('avatar', userDataVal.image);
    }
  }, [accountProfile.userData]);

  useEffect(() => {
    var urlKTP = accountProfile.urlKTP ? accountProfile.urlKTP.url : '';
    setProfileData({ identity_file: urlKTP });
  }, [accountProfile.urlKTP]);

  useEffect(() => {
    var urlAvatar = accountProfile.urlAvatar
      ? accountProfile.urlAvatar.url
      : '';
    if (urlAvatar) {
      setUserData({ image: urlAvatar });
    }
  }, [accountProfile.urlAvatar]);

  const logOut = () => {
    dispatch(logout());
    window.location = '/home';
  };

  const handleAddProfile = () => {
    setErrorRequired([]);
    setAddProfileModal(true);
  };

  const handleCloseModal = () => {
    initState();
  };

  const uploadAvatar = async () => {
    var uploadavatar = document.getElementById('uploadavatar').files;
    dispatch(uploadAvatarFile(uploadavatar[0])).then((response) => {
      Swal.fire({
        icon: 'success',
        title: 'Upload Avatar Success',
        showConfirmButton: true,
        confirmButtonText: 'Close',
      });
    });
  };

  const actionAddProfile = () => {
    var tempErrorFormat = [];
    var errorData = [];
    if (config.add_patient_config[6].code === "name" && config.add_patient_config[6].required && !profileData?.name) {
      errorData.push('name');
    }

    if (config.add_patient_config[4].code === "relation" && config.add_patient_config[4].required && !profileData?.relation) {
      errorData.push('relation');
    }

    if (config.add_patient_config[8].code === "dob" && config.add_patient_config[8].required && !profileData?.dob) {
      errorData.push('dob');
    }

    if (config.add_patient_config[10].code === "gender" && config.add_patient_config[10].required && !profileData?.gender) {
      errorData.push('gender');
    }

    if (config.add_patient_config[12].code === "phone_1" && config.add_patient_config[12].required && !profileData?.phone_1) {
      errorData.push('phone');
    }

    if (config.add_patient_config[14].code === "email" && config.add_patient_config[14].required&& !profileData?.email) {
      errorData.push('email');
    }

    if (config.add_patient_config[0].code === "identity_type" && config.add_patient_config[0].required && !profileData?.identity_type) {
      errorData.push('identity_type');
    }

    if (config.add_patient_config[2].code === "identity_number" && config.add_patient_config[2].required && !profileData?.identity_number) {
      errorData.push('identity_number');
    }

    if (config.add_patient_config[15].code === "province_id" && config.add_patient_config[15].required && !profileData?.province_id) {
      errorData.push('province_id');
    }

    if (config.add_patient_config[16].code === "city_id" && config.add_patient_config[16].required && !profileData?.city_id) {
      errorData.push('city_id');
    }

    if (config.add_patient_config[17].code === "district_id" && config.add_patient_config[17].required && !profileData?.district_id) {
      errorData.push('district_id');
    }

    if (config.add_patient_config[18].code === "sub_district_id" && config.add_patient_config[18].required && !profileData?.sub_district_id) {
      errorData.push('sub_district_id');
    }

    if (config.add_patient_config[19].code === "postal_code" && config.add_patient_config[19].required && !profileData?.postal_code) {
      errorData.push('postal_code');
    }

    if (config.add_patient_config[20].code === "identity_address" && config.add_patient_config[20].required && !profileData?.identity_address) {
      errorData.push('identity_address');
    }

    if (config.add_patient_config[21].code === "emergency_contact_relation" && config.add_patient_config[21].required && !profileData?.emergency_contact_relation) {
      errorData.push('emergency_contact_relation');
    }

    if (config.add_patient_config[22].code === "emergency_contact_name" && config.add_patient_config[22].required && !profileData?.emergency_contact_name) {
      errorData.push('emergency_contact_name');
    }

    if (config.add_patient_config[23].code === "emergency_contact_phone" && config.add_patient_config[23].required && !profileData?.emergency_contact_phone) {
      errorData.push('emergency_contact_phone');
    }
    if (errorData.length === 0 && profileData.name) {
      if (profileData?.email && !isEmail(profileData?.email)) {
        tempErrorFormat.push('email');
      } else {
        const form = new FormData();
        var groupId = localStorage.getItem('klinikGrupId');
        form.append('hospitalGroupId', groupId ? groupId : "");
        form.append('name', profileData?.name ? profileData?.name : "");
        form.append('gender', profileData?.gender ? profileData?.gender : "");
        form.append('dob', profileData?.dob ? moment(profileData.dob).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD'));
        form.append('phone_1', profileData?.phone_1 ? profileData?.phone_1 : "");
        form.append('email', profileData?.email ? profileData?.email : "");
        form.append('identity_type', profileData?.identity_type ? profileData?.identity_type : "");
        form.append('identity_name', profileData?.identity_name ? profileData?.identity_name : "");
        form.append('identity_number', profileData?.identity_number ? profileData?.identity_number : "");
        form.append('identity_file', profileData?.identity_file ? profileData?.identity_file : "");
        form.append(
          'emergency_contact_name',
          profileData?.emergency_contact_name ? profileData?.emergency_contact_name : ""
        );
        form.append(
          'emergency_contact_phone',
          profileData?.emergency_contact_phone ? profileData?.emergency_contact_phone : ""
        );
        form.append(
          'emergency_contact_email',
          profileData?.emergency_contact_email ? profileData?.emergency_contact_email : ""
        );
        form.append(
          'emergency_contact_relation',
          profileData?.emergency_contact_relation ? profileData?.emergency_contact_relation : ""
        );
        form.append('relation', profileData?.relation ? profileData?.relation : 0);
        form.append('marital_status', profileData?.marital_status ? profileData?.marital_status : "");
        form.append('blood_type', profileData?.blood_type ? profileData?.blood_type : "");
        form.append('ethincities', profileData?.ethincities ? profileData?.ethincities : 0);
        form.append('nationality', profileData?.nationality ? profileData?.nationality : 0);
        form.append('education', profileData?.education ? profileData?.education : 0);
        form.append('occupation', profileData?.occupation ? profileData?.occupation : "");
        form.append('religion', profileData?.religion ? profileData?.religion : ""); 
        form.append('province_id', profileData?.province_id ? profileData?.province_id : "");
        form.append('city_id', profileData?.city_id ? profileData?.city_id : "");
        form.append('district_id', profileData?.district_id ? profileData?.district_id : "");
        form.append('sub_district_id', profileData?.sub_district_id ? profileData?.sub_district_id : ""); 
        form.append('postal_code', profileData?.postal_code ? profileData?.postal_code : "");
        form.append('identity_address', profileData?.identity_address ? profileData?.identity_address : "");
        form.append('user_id', userId);

        dispatch(createProfile(form))
          .then(() => {
            initState()
            setTimeout(
              () =>
                Swal.fire({
                  icon: 'success',
                  title: 'New Account Profile Data Created',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                }).then(() => {
                  window.location.reload();
                }),
              500
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: 'error',
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: 'error',
                text: err.message,
              });
            }
          });
        }
    } else{
      setErrorField(true);
    }

  };

  const renderAddProfile = () => {
    return (
      <ModalComp
        title="Form Pasien"
        show={addProfileModal}
        setShow={handleCloseModal}
        useButtonClose
        buttonCloseLabel="Batal"
        buttonSubmitLabel="Kirim"
        onSubmit={actionAddProfile}
        size="lg"
      >
        <Row>
          <Col md>Data Pribadi</Col>
        </Row>
        <div style={{ padding: '10px', marginBottom: '20px' }}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <Row>
                {config.add_patient_config[0].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Jenis Identitas"
                    label="Jenis Identitas"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionJenisIdentitas}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionJenisIdentitas(e);
                      setProfileData({
                        ...profileData,
                        identity_type: e?.value,
                        identity_name: e?.label
                      });
                    }}
                    required={config.add_patient_config[0].required ? true : false}
                    isShowWarning={errorField && !optionJenisIdentitas && config.add_patient_config[0].required}
                    options={
                      accountProfile?.identityData
                        ? accountProfile?.identityData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                :null}
                {config.add_patient_config[2].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <InputText
                    label="Nomor Identitas"
                    labelClassName="mt-3"
                    placeholder="Masukkan Nomor Identitas"
                    required={config.add_patient_config[2].required ? true : false}
                    isShowWarning={errorField && !txtNomorIdentitas && config.add_patient_config[2].required}
                    value={txtNomorIdentitas}
                    setValue={(value) => {
                      setTxtNomorIdentitas(value);
                      setProfileData({
                        ...profileData,
                        identity_number: value,
                      });
                      
                    }}
                    type="number"
                  />
                </Col>
                : null }
                {config.add_patient_config[4].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Status Hubungan"
                    label="Hubungan Dengan User"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionHubungan}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionHubungan(e);
                      setProfileData({
                        ...profileData,
                        relation: e?.id,
                      });
                    }}
                    required={config.add_patient_config[4].required ? true : false}
                    isShowWarning={errorField && !optionHubungan && config.add_patient_config[4].required}
                    options={
                        accountProfile?.relationData
                        ? accountProfile?.relationData?.map((e) => {
                            if (e.id !== 1) {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            } else {
                              return {
                                ...e,
                                label: '',
                                value: '',
                              };
                            }
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                : null}
                {config.add_patient_config[6].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >

                  <InputText
                    label="Nama Lengkap (Sesuai Kartu Identitas)"
                    labelClassName="mt-3"
                    placeholder="Masukkan Nama Lengkap"
                    required={config.add_patient_config[6].required ? true : false}
                    isShowWarning={errorField && !txtNamaKtp && config.add_patient_config[6].required}
                    value={txtNamaKtp}
                    setValue={(value) => {
                      setTxtNamaKtp(value);
                      setProfileData({
                        ...profileData,
                        name: value,
                      });
                    }}
                    errorMessage="Nama Lengkap tidak boleh kosong!"
                    type="alphabet"
                  />
                </Col>
                : null}
                {config.add_patient_config[8].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <InputDate
                    placeholder="mm/dd/yyyy"
                    labelClassName="mt-3"
                    className="text-lg"
                    label="Tanggal Lahir"
                    required={config.add_patient_config[8].required ? true : false}
                    isShowWarning={errorField && !txtTanggalLahir && config.add_patient_config[8].required}
                    setDate={(e) => {
                      console.log(e)
                      setTxtTanggalLahir(e)
                      setProfileData({
                        ...profileData,
                        dob: e,
                      });
                      
                    }}
                    selectedDate={profileData?.dob ? profileData?.dob : moment()}
                    icon={IconCalenderGrey}
                    iconPosition="right"
                    showMonthDropdown
                    showYearDropdown
                    format="MM/dd/yyyy"
                  />
                </Col>
                : null }
                {config.add_patient_config[10].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <div className="select-option">
                    <SelectOptions
                      placeholder="Pilih Jenis Kelamin"
                      label="Jenis Kelamin"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionJenisKelamin}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionJenisKelamin(e);
                        setProfileData({
                          ...profileData,
                          gender: e.value,
                        });

                      }}
                      required={config.add_patient_config[10].required ? true : false}
                      isShowWarning={errorField && !optionJenisKelamin && config.add_patient_config[10].required}
                      options={[
                        {
                          label: 'Laki-Laki',
                          value: 1,
                        },
                        {
                          label: 'Perempuan',
                          value: 2,
                        },
                      ]}
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </div>
                  {errorRequired.find((val) => val === 'gender') ? (
                    <span className="span-error">
                      <i className="fa fa-warning" /> Jenis Kelamin harus diisi
                    </span>
                  ) : (
                    ''
                  )}
                </Col>
                : null }
                {config.add_patient_config[12].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <InputText
                    label="Nomor Telepon"
                    labelClassName="mt-3"
                    placeholder="Masukan No. Telepon: 08xxxxxxxxx"
                    isShowWarning={errorField && !txtNomorTelepon && config.add_patient_config[12].required}
                    required={config.add_patient_config[12].required ? true : false}
                    value={txtNomorTelepon}
                    maxText={13}
                    setValue={(value) => {
                      setTxtNomorTelepon(value);
                      setProfileData({
                        ...profileData,
                        phone_1: value,
                      });

                    }}
                    
                    type="number"
                  />
                </Col>
                : null }
                {config.add_patient_config[14].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <InputText
                    label="Email"
                    labelClassName="mt-3"
                    placeholder="Masukkan Email"
                    required={config.add_patient_config[14].required ? true : false}
                    isShowWarning={errorField && (!txtEmail || !isEmail(txtEmail)) && config.add_patient_config[14].required}
                    errorMessage={
                      txtEmail ? !isEmail(txtEmail) && 'Format email salah' : null
                    }
                    value={txtEmail}
                    setValue={(value) => {
                      setTxtEmail(value);
                      setProfileData({
                        ...profileData,
                        email: value,
                      });
                    }}
                  />
                </Col>
                : null }
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <Row>
                {config.add_patient_config[1].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Status Pernikahan"
                    label="Status Pernikahan"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionStatusPernikahan}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionStatusPernikahan(e);
                      setProfileData({
                        ...profileData,
                        marital_status: e.value,
                      })

                    }}
                    required={config.add_patient_config[1].required ? true : false}
                    isShowWarning={errorField && !optionStatusPernikahan && config.add_patient_config[1].required}
                    options={
                      accountProfile?.marriageData
                        ? accountProfile?.marriageData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                : null }
                {config.add_patient_config[3].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Etnis"
                    label="Etnis"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionEtnis}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionEtnis(e);
                      setProfileData({
                        ...profileData,
                        ethincities: e.value,
                      });

                    }}
                    required={config.add_patient_config[3].required ? true : false}
                    isShowWarning={errorField && !optionEtnis && config.add_patient_config[3].required}
                    options={
                      accountProfile?.ethnicData
                        ? accountProfile?.ethnicData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />

                </Col>
                : null }
                {config.add_patient_config[5].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Golongan Darah"
                    label="Golongan Darah"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionGolonganDarah}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionGolonganDarah(e);
                      setProfileData({
                        ...profileData,
                        blood_type: e.value,
                      });

                    }}
                    required={config.add_patient_config[5].required ? true : false}
                    isShowWarning={errorField && !optionGolonganDarah && config.add_patient_config[5].required}
                    options={
                      accountProfile?.bloodTypeData
                        ? accountProfile?.bloodTypeData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />

                </Col>
                : null }
                {config.add_patient_config[7].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Agama"
                    label="Agama"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionAgama}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionAgama(e);
                      setProfileData({
                        ...profileData,
                        religion: e.value,
                      });

                    }}
                    required={config.add_patient_config[7].required ? true : false}
                    isShowWarning={errorField && !optionAgama && config.add_patient_config[7].required}
                    options={
                      accountProfile?.religionData
                        ? accountProfile?.religionData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                : null }
                {config.add_patient_config[9].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Kewarganegaraan"
                    label="Kewarganegaraan"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionKewarganegaraan}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionKewarganegaraan(e);
                      setProfileData({
                        ...profileData,
                        nationality: e.value,
                      });

                    }}
                    required={config.add_patient_config[9].required ? true : false}
                    isShowWarning={errorField && !optionKewarganegaraan && config.add_patient_config[9].required}
                    options={
                      accountProfile?.nationalityData
                        ? accountProfile?.nationalityData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />

                </Col>
                : null }
                {config.add_patient_config[11].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Tingkat Pendidikan"
                    label="Tingkat Pendidikan"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionTingkatPendidikan}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionTingkatPendidikan(e);
                      setProfileData({
                        ...profileData,
                        education: e.value,
                      });

                    }}
                    required={config.add_patient_config[11].required ? true : false}
                    isShowWarning={errorField && !optionTingkatPendidikan && config.add_patient_config[11].required}
                    options={
                      accountProfile?.educationData
                        ? accountProfile?.educationData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />

                </Col>
                : null}
                {config.add_patient_config[13].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Pilih Pekerjaan"
                    label="Pekerjaan"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionPekerjaan}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionPekerjaan(e);
                      setProfileData({
                        ...profileData,
                        occupation: e.value,
                      });

                    }}
                    required={config.add_patient_config[13].required ? true : false}
                    isShowWarning={errorField && !optionPekerjaan && config.add_patient_config[13].required}
                    options={
                      accountProfile?.jobData
                        ? accountProfile?.jobData?.map((e) => {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          })
                        : ''
                    }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                :null}
                <Col xs={12} sm={12} md={12} lg={12} xl={12} ></Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} >Alamat Pasien (Sesuai Kartu Identitas)</Col>
        </Row>
        <div style={{ padding: '10px', marginBottom: '20px' }}>
          <Row>
            {config.add_patient_config[15].show ?   
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <SelectOptions
                placeholder="Pilih Propinsi"
                label="Propinsi"
                labelClassName="mt-3"
                background="#ffff"
                value={optionPropinsi}
                fontSize="lg"
                setValue={(e) => {
                  setOptionPropinsi(e);
                  setOptionKabupaten('')
                  setOptionKecamatan('')
                  setOptionKelurahan('')
                  setOptionKodePos('')
                  setProfileData({
                    ...profileData,
                    province_id: e.value,
                  });

                }}
                required={config.add_patient_config[15].required ? true : false}
                isShowWarning={errorField && !optionPropinsi && config.add_patient_config[15].required}
                options={
                  accountProfile?.provinceData
                    ? accountProfile?.provinceData?.map((e) => {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      })
                    : ''
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />
            </Col>
            : null }
            {config.add_patient_config[16].show ? 
              
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >

              <SelectOptions
                placeholder="Pilih Kabupaten/Kota"
                label="Kabupaten/Kota"
                labelClassName="mt-3"
                background="#ffff"
                value={optionKabupaten}
                fontSize="lg"
                setValue={(e) => {
                  setOptionKabupaten(e)
                  setOptionKecamatan('')
                  setOptionKelurahan('')
                  setOptionKodePos('')
                  setProfileData({
                    ...profileData,
                    city_id: e.value,
                  });

                }}
                required={config.add_patient_config[16].required ? true : false}
                isShowWarning={errorField && !optionKabupaten && config.add_patient_config[16].required}
                options={
                  accountProfile?.cityData
                    ? accountProfile?.cityData?.map((e) => {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      })
                    : ''
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />

            </Col>
            : null }
          </Row>
          <Row>
            {config.add_patient_config[17].show ? 
              
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <SelectOptions
                placeholder="Pilih Kecamatan"
                label="Kecamatan"
                labelClassName="mt-3"
                background="#ffff"
                value={optionKecamatan}
                fontSize="lg"
                setValue={(e) => {
                  setOptionKecamatan(e);
                  setOptionKelurahan('')
                  setOptionKodePos('')
                  setProfileData({
                    ...profileData,
                    district_id: e.value,
                  });

                }}
                required={config.add_patient_config[17].required ? true : false}
                isShowWarning={errorField && !optionKecamatan && config.add_patient_config[17].required}
                options={
                  accountProfile?.districtData
                    ? accountProfile?.districtData?.map((e) => {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      })
                    : ''
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />

            </Col>
            : null }
            {config.add_patient_config[18].show ? 
              
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <SelectOptions
                placeholder="Pilih Kelurahan"
                label="Jenis Kelurahan"
                labelClassName="mt-3"
                background="#ffff"
                value={optionKelurahan}
                fontSize="lg"
                setValue={(e) => {
                  setOptionKelurahan(e);
                  setOptionKodePos('')
                  setProfileData({
                    ...profileData,
                    sub_district_id: e.value,
                  });
                }}
                required={config.add_patient_config[18].required ? true : false}
                isShowWarning={errorField && !optionKelurahan && config.add_patient_config[18].required}
                options={
                  accountProfile?.subdistrictData
                    ? accountProfile?.subdistrictData?.map((e) => {
                        return {
                          ...e,
                          label: e?.name,
                          value: e?.id,
                        };
                      })
                    : ''
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />

            </Col>
            : null}
          </Row>

          <Row>
            {config.add_patient_config[19].show ? 
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
            <SelectOptions
              placeholder="Pilih Kode Pos"
              label="Kode Pos"
              labelClassName="mt-3"
              background="#ffff"
              value={optionKodePos}
              fontSize="lg"
              setValue={(e) => {
                setOptionKodePos(e);
                setProfileData({
                  ...profileData,
                  postal_code: e.value,
                });
              }}
              required={config.add_patient_config[19].required ? true : false}
              isShowWarning={errorField && !optionKodePos && config.add_patient_config[19].required}
              options={
                accountProfile?.postalCodeData
                  ? accountProfile?.postalCodeData?.map((e) => {
                      return {
                        ...e,
                        label: e?.code,
                        value: e?.code,
                      };
                    })
                  : ''
              }
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
            />

            </Col>
            : null}
            {config.add_patient_config[20].show ? 
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
            <InputTextArea
              labelClassName="mt-3"
              label="Alamat"
              placeholder="Masukan Alamat"
              maxText={255}
              value={txtAlamat}
              setValue={(value) => {
                setTxtAlamat(value)
                setProfileData({
                  ...profileData,
                  identity_address: value,
                });
              }}
              required={config.add_patient_config[20].required ? true : false}
              isShowWarning={errorField && !txtAlamat && config.add_patient_config[20].required}
            />
            </Col>
            : null }
          </Row>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} >Keluarga/Kontak Darurat</Col>
        </Row>
        <div style={{ padding: '10px' }}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <Row>
                {config.add_patient_config[21].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <SelectOptions
                    placeholder="Status Hubungan"
                    label="Hubungan Dengan Pasien"
                    labelClassName="mt-3"
                    background="#ffff"
                    value={optionHubunganPasien}
                    fontSize="lg"
                    setValue={(e) => {
                      setOptionHubunganPasien(e);
                      setProfileData({
                        ...profileData,
                        emergency_contact_relation: e.value,
                      });
                    }}
                    required={config.add_patient_config[21].required ? true : false}
                    isShowWarning={errorField && !optionHubunganPasien && config.add_patient_config[21].required}
                    options={
                      accountProfile?.relationData
                      ? accountProfile?.relationData?.map((e) => {
                          if (e.id !== 1) {
                            return {
                              ...e,
                              label: e?.name,
                              value: e?.id,
                            };
                          } else {
                            return {
                              ...e,
                              label: '',
                              value: '',
                            };
                          }
                        })
                      : ''
                  }
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                : null }
                {config.add_patient_config[22].show ? 
              
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <InputText
                    label="Nama Kerabat"
                    labelClassName="mt-3"
                    placeholder="Masukkan Nama Kerabat"
                    required={config.add_patient_config[22].required ? true : false}
                    isShowWarning={errorField && !txtKerabat && config.add_patient_config[22].required}
                    value={txtKerabat}
                    setValue={(value) => {
                      setTxKerabat(value);
                      setProfileData({
                        ...profileData,
                        emergency_contact_name: value,
                      });
                    }}
                    errorMessage="Nama Kerabat tidak boleh kosong!"
                    type="alphabet"
                  />
                </Col>
                : null}
              </Row>
              

            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <Row>
                {config.add_patient_config[23].show ? 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <InputText
                    label="No. Telepon Kerabat"
                    labelClassName="mt-3"
                    placeholder="Masukan No. Telepon Kerabat"
                    required={config.add_patient_config[23].required ? true : false}
                    isShowWarning={errorField && !txtNomorTeleponKerabat && config.add_patient_config[23].required}
                    value={txtNomorTeleponKerabat}
                    maxText={13}
                    setValue={(value) => {
                      setTxtNomorTeleponKerabat(value);
                      setProfileData({
                        ...profileData,
                        emergency_contact_phone: value,
                      });

                    }}
                    
                    type="number"
                  />
                </Col>
                : null }
              </Row>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };
  
  const handleEditProfile = (profile) => {
    if(profile) {
      setProfileData({
        id: profile.id ? profile.id : '',
        name: profile.name ? profile.name: '',
        gender: profile.gender ? profile.gender :'' ,
        dob: profile.dob ? moment(profile.dob).format('YYYY-MM-DD') : '',
        phone_1: profile.phone_1 ? profile.phone_1 : '',
        email: profile.email ? profile.email : '',
        identity_type: profile.identity_type ? profile.identity_type : '',
        identity_name: profile.identity_name ? profile.identity_name : '',
        identity_number: profile.identity_number ? profile.identity_number : '',
        identity_file: profile.identity_file ? profile.identity_file : '',
        emergency_contact_name: profile.emergency_contact_name ? profile.emergency_contact_name : '',
        emergency_contact_phone: profile.emergency_contact_phone ? profile.emergency_contact_phone : '',
        emergency_contact_email: profile.emergency_contact_email ? profile.emergency_contact_email : '',
        emergency_contact_relation: profile.emergency_contact_relation ? profile.emergency_contact_relation : '',
        relation: profile.relation ? profile.relation : '',
        user_id: profile.user_id ? profile.user_id : '',
        marital_status: profile.marital_status ? profile.marital_status : '',
        blood_type: profile.blood_type ? profile.blood_type : '',
        ethincities: profile.ethincities ? profile.ethincities : '',
        nationality: profile.nationality ? profile.nationality: '',
        education: profile.education ? profile.education:'',
        occupation: profile.occupation ? profile.occupation:'',
        religion: profile.religion?profile.religion:'',
        province_id: profile.province_id ? profile.province_id : '',
        city_id: profile.city_id ? profile.city_id:'',
        district_id: profile.district_id ? profile.district_id:'',
        sub_district_id: profile.sub_district_id ? profile.sub_district_id:'',
        postal_code: profile.postal_code ? profile.postal_code: '',
        identity_address: profile.identity_address ? profile.identity_address: '',
      });

      setErrorRequired([]);
      setEditProfileModal(true);
      initiateForm(profile)
      setTempProfile(profile)
    }
  };
  
  const initiateForm = (profile) => {
    setOptionJenisIdentitas(
      accountProfile.identityData &&
        accountProfile.identityData
          ?.filter((item) => +item?.id === +profile?.identity_type)
          ?.map((e) => {
            return { ...e, value: e?.id, label: e?.name };
          })[0]
    );

    setOptionStatusPernikahan(
      accountProfile.marriageData &&
        accountProfile.marriageData
          ?.filter((item) => +item?.id === +profile?.marital_status)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    setOptionEtnis(
      accountProfile.ethnicData &&
        accountProfile.ethnicData
          ?.filter((item) => +item?.id === +profile?.ethincities)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );


    setOptionGolonganDarah(
      accountProfile.bloodTypeData &&
        accountProfile.bloodTypeData
          ?.filter((item) => +item?.id === +profile?.blood_type)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    setOptionAgama(
      accountProfile.religionData &&
        accountProfile.religionData
          ?.filter((item) => +item?.id === +profile?.religion)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    setOptionKewarganegaraan(
      accountProfile.nationalityData &&
        accountProfile.nationalityData
          ?.filter((item) => +item?.id === +profile?.nationality)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    setOptionTingkatPendidikan(
      accountProfile.educationData &&
        accountProfile.educationData
          ?.filter((item) => +item?.id === +profile?.education)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    setOptionPekerjaan(
      accountProfile.jobData &&
        accountProfile.jobData
          ?.filter((item) => +item?.id === +profile?.occupation)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    setOptionPropinsi(
      accountProfile.provinceData &&
        accountProfile.provinceData
          ?.filter((item) => +item?.id === +profile?.province_id)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );

    // setOptionKabupaten(
    //   accountProfile.cityData &&
    //     accountProfile.cityData
    //       ?.filter((item) => +item?.id === +profile?.city_id)
    //       ?.map((e) => {
    //         return {
    //           ...e,
    //           label: e?.name,
    //           value: e?.id,
    //         };
    //       })
    // );

    // setOptionKecamatan(
    //   accountProfile.districtData &&
    //     accountProfile.districtData
    //       ?.filter((item) => +item?.id === +profile?.district_id)
    //       ?.map((e) => {
    //         return {
    //           ...e,
    //           label: e?.name,
    //           value: e?.id,
    //         };
    //       })
    // );

    // setOptionKelurahan(
    //   accountProfile.subdistrictData &&
    //     accountProfile.subdistrictData
    //       ?.filter((item) => +item?.id === +profile?.sub_district_id)
    //       ?.map((e) => {
    //         return {
    //           ...e,
    //           label: e?.name,
    //           value: e?.id,
    //         };
    //       })
    // );

    setTxtNomorIdentitas(profile.identity_number)

    setOptionHubunganPasien(
      accountProfile.relationData &&
        accountProfile.relationData
          ?.filter((item) => +item?.id === +profile?.emergency_contact_relation)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );


    // setOptionKodePos(
    //   accountProfile.postalCodeData &&
    //     accountProfile.postalCodeData
    //       ?.filter((item) => +item?.id === +profile?.postal_code)
    //       ?.map((e) => {
    //         return {
    //           ...e,
    //           label: e?.name,
    //           value: e?.id,
    //         };
    //       })
    // );

    setTxtNamaKtp(profile.name)

    setOptionHubungan(
      accountProfile.relationData &&
        accountProfile.relationData
          ?.filter((item) => +item?.id === +profile?.relation)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })[0]
    );
    console.log("accountProfile",profile)
    setOptionJenisKelamin({
      value: profile?.gender,
      label: profile?.gender === '1' ?'Laki-laki' : profile?.gender === '2' ? 'Perempuan' : '',
    });

    setTxtNomorTelepon(profile.phone_1)
    setTxtNomorTeleponKerabat(profile.emergency_contact_phone)
    setTxtEmail(profile.email)
    setTxKerabat(profile.emergency_contact_name,)
    setTxtTanggalLahir(moment(profile.dob).format('YYYY-MM-DD'))
    setTxtAlamat(profile.identity_address)
  }

  useEffect(() => {
    setOptionKabupaten(
      accountProfile.cityData &&
        accountProfile.cityData
          ?.filter((item) => +item?.id === +tempProfile?.city_id)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })
    );

  },[tempProfile,accountProfile.cityData])

  useEffect(() => {
    setOptionKecamatan(
      accountProfile.districtData &&
        accountProfile.districtData
          ?.filter((item) => +item?.id === +tempProfile?.district_id)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })
    );
  },[tempProfile,accountProfile.districtData])


  useEffect(() => {
    setOptionKelurahan(
      accountProfile.subdistrictData &&
        accountProfile.subdistrictData
          ?.filter((item) => +item?.id === +tempProfile?.sub_district_id)
          ?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e?.id,
            };
          })
    );
  },[tempProfile,accountProfile.subdistrictData])


  useEffect(() => {
    setOptionKodePos(
      accountProfile.postalCodeData &&
        accountProfile.postalCodeData
          ?.filter((item) => +item?.code === +tempProfile?.postal_code)
          ?.map((e) => {
            return {
              ...e,
              label: e?.code,
              value: e?.id,
            };
          })
    );
  },[tempProfile,accountProfile.postalCodeData])

  const actionEditProfile = () => {

    
    var errorData = [];
    var tempErrorFormat = [];
    if (config.add_patient_config[6].code === "name" && config.add_patient_config[6].required && !profileData?.name) {
      errorData.push('name');
    }

    if (config.add_patient_config[4].code === "relation" && config.add_patient_config[4].required && !profileData?.relation) {
      errorData.push('relation');
    }

    if (config.add_patient_config[8].code === "dob" && config.add_patient_config[8].required && !profileData?.dob) {
      errorData.push('dob');
    }

    if (config.add_patient_config[10].code === "gender" && config.add_patient_config[10].required && !profileData?.gender) {
      errorData.push('gender');
    }

    if (config.add_patient_config[12].code === "phone_1" && config.add_patient_config[12].required && !profileData?.phone_1) {
      errorData.push('phone');
    }

    if (config.add_patient_config[14].code === "email" && config.add_patient_config[14].required&& !profileData?.email) {
      errorData.push('email');
    }

    if (config.add_patient_config[0].code === "identity_type" && config.add_patient_config[0].required && !profileData?.identity_type) {
      errorData.push('identity_type');
    }

    if (config.add_patient_config[2].code === "identity_number" && config.add_patient_config[2].required && !profileData?.identity_number) {
      errorData.push('identity_number');
    }

    if (config.add_patient_config[15].code === "province_id" && config.add_patient_config[15].required && !profileData?.province_id) {
      errorData.push('province_id');
    }

    if (config.add_patient_config[16].code === "city_id" && config.add_patient_config[16].required && !profileData?.city_id) {
      errorData.push('city_id');
    }

    if (config.add_patient_config[17].code === "district_id" && config.add_patient_config[17].required && !profileData?.district_id) {
      errorData.push('district_id');
    }

    if (config.add_patient_config[18].code === "sub_district_id" && config.add_patient_config[18].required && !profileData?.sub_district_id) {
      errorData.push('sub_district_id');
    }

    if (config.add_patient_config[19].code === "postal_code" && config.add_patient_config[19].required && !profileData?.postal_code) {
      errorData.push('postal_code');
    }

    if (config.add_patient_config[20].code === "identity_address" && config.add_patient_config[20].required && !profileData?.identity_address) {
      errorData.push('identity_address');
    }

    if (config.add_patient_config[21].code === "emergency_contact_relation" && config.add_patient_config[21].required && !profileData?.emergency_contact_relation) {
      errorData.push('emergency_contact_relation');
    }

    if (config.add_patient_config[22].code === "emergency_contact_name" && config.add_patient_config[22].required && !profileData?.emergency_contact_name) {
      errorData.push('emergency_contact_name');
    }

    if (config.add_patient_config[23].code === "emergency_contact_phone" && config.add_patient_config[23].required && !profileData?.emergency_contact_phone) {
      errorData.push('emergency_contact_phone');
    }
    if (errorData.length === 0 && profileData.name) {
      if (profileData?.email && !isEmail(profileData?.email)) {
        tempErrorFormat.push('email');
      } else {
        
        const form = new FormData();
        var groupId = localStorage.getItem('klinikGrupId');
        form.append('hospitalGroupId', groupId ? groupId : null);
        form.append('id', profileData?.id);
        form.append('name', profileData?.name ? profileData?.name : "");
        form.append('gender', profileData?.gender ? profileData?.gender : "");
        form.append('dob', profileData?.dob ? moment(profileData.dob).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD'));
        form.append('phone_1', profileData?.phone_1 ? profileData?.phone_1 : "");
        form.append('email', profileData?.email ? profileData?.email : "");
        form.append('identity_type', profileData?.identity_type ? profileData?.identity_type : "");
        form.append('identity_name', profileData?.identity_name ? profileData?.identity_name : "");
        form.append('identity_number', profileData?.identity_number ? profileData?.identity_number : "");
        form.append('identity_file', profileData?.identity_file ? profileData?.identity_file : "");
        form.append(
          'emergency_contact_name',
          profileData?.emergency_contact_name ? profileData?.emergency_contact_name : ""
        );
        form.append(
          'emergency_contact_phone',
          profileData?.emergency_contact_phone ? profileData?.emergency_contact_phone : ""
        );
        form.append(
          'emergency_contact_email',
          profileData?.emergency_contact_email ? profileData?.emergency_contact_email : ""
        );
        form.append(
          'emergency_contact_relation',
          profileData?.emergency_contact_relation ? profileData?.emergency_contact_relation : ""
        );
        form.append('relation', profileData?.relation ? profileData?.relation : 0);
        form.append('marital_status', profileData?.marital_status ? profileData?.marital_status : "");
        form.append('blood_type', profileData?.blood_type ? profileData?.blood_type : "");
        form.append('ethincities', profileData?.ethincities ? profileData?.ethincities : 0);
        form.append('nationality', profileData?.nationality ? profileData?.nationality : 0);
        form.append('education', profileData?.education ? profileData?.education : 0);
        form.append('occupation', profileData?.occupation ? profileData?.occupation : "");
        form.append('religion', profileData?.religion ? profileData?.religion : ""); 
        form.append('province_id', profileData?.province_id ? profileData?.province_id : "");
        form.append('city_id', profileData?.city_id ? profileData?.city_id : "");
        form.append('district_id', profileData?.district_id ? profileData?.district_id : "");
        form.append('sub_district_id', profileData?.sub_district_id ? profileData?.sub_district_id : ""); 
        form.append('postal_code', profileData?.postal_code ? profileData?.postal_code : "");
        form.append('identity_address', profileData?.identity_address ? profileData?.identity_address : "");
        if (profileData?.relation === 1) {
          form.append('user_id', userId);
        }
        // console.log('Form', form);
        // console.log('profileData', profileData);
        dispatch(editProfile(form))
          .then((response) => {
            var datas = response.data;
            if (datas) {
              setTimeout(
                () =>
                  Swal.fire({
                    icon: 'success',
                    title: 'Account Profile Data Updated',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                  }).then(() => {
                    if (datas.userData) {
                      localStorage.setItem(
                        'firstName',
                        datas.userData?.firstName
                      );
                      localStorage.setItem(
                        'fullName',
                        datas.userData?.fullName
                      );
                    }
                    window.location.reload();
                  }),
                500
              );
            }
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: 'error',
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: 'error',
                text: err.message,
              });
            }
          });
      } 
    } else {
      setErrorField(true);
    }
    setErrorRequired(errorData);
    setErrorFormat(tempErrorFormat);
  };

  const renderEditProfile = () => {
    // console.log("profileData", profileData);
    return (
      <ModalComp
        title="Edit Data Pasien"
        show={editProfileModal}
        setShow={handleCloseModal}
        useButtonClose
        buttonCloseLabel="Batal"
        buttonSubmitLabel="Kirim"
        onSubmit={actionEditProfile}
        size="lg"
      >
        <Row>
          <Col md>Data Pribadi</Col>
        </Row>
          <div style={{ padding: '10px', marginBottom: '20px' }}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <Row>
                  {config.add_patient_config[0].show ? 
              
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Jenis Identitas"
                      label="Jenis Identitas"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionJenisIdentitas}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionJenisIdentitas(e);
                        setProfileData({
                          ...profileData,
                          identity_type: e?.value,
                          identity_name: e?.label
                        });

                      }}
                      required={config.add_patient_config[0].required ? true : false}
                      isShowWarning={errorField && !optionJenisIdentitas && config.add_patient_config[0].required}
                      options={
                        accountProfile?.identityData
                          ? accountProfile?.identityData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[2].show ?             
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputText
                      label="Nomor Identitas"
                      labelClassName="mt-3"
                      placeholder="Masukkan Nomor Identitas"
                      required={config.add_patient_config[2].required ? true : false}
                      isShowWarning={errorField && !txtNomorIdentitas && config.add_patient_config[2].required}
                      value={txtNomorIdentitas}
                      setValue={(value) => {
                        setTxtNomorIdentitas(value);
                        setProfileData({
                          ...profileData,
                          identity_number: value,
                        });
                        
                      }}
                      type="number"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[4].show ?               
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Status Hubungan"
                      label="Hubungan Dengan User"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionHubungan}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionHubungan(e);
                        setProfileData({
                          ...profileData,
                          relation: e?.value,
                        });
                      }}
                      required={config.add_patient_config[4].required ? true : false}
                      isShowWarning={errorField && !optionHubungan && config.add_patient_config[4].required}
                      options={
                          accountProfile?.relationData
                          ? accountProfile?.relationData?.map((e) => {
                              if (e.id !== 1) {
                                return {
                                  ...e,
                                  label: e?.name,
                                  value: e?.id,
                                };
                              } else {
                                return {
                                  ...e,
                                  label: '',
                                  value: '',
                                };
                              }
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[6].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputText
                      label="Nama Lengkap (Sesuai Kartu Identitas)"
                      labelClassName="mt-3"
                      placeholder="Masukkan Nama Lengkap"
                      required={config.add_patient_config[6].required ? true : false}
                      isShowWarning={errorField && !txtNamaKtp && config.add_patient_config[6].required}
                      value={txtNamaKtp}
                      setValue={(value) => {
                        setTxtNamaKtp(value);
                        setProfileData({
                          ...profileData,
                          name: value,
                        });
                      }}
                      errorMessage="Nama Lengkap tidak boleh kosong!"
                      type="alphabet"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[8].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputDate
                      placeholder="mm/dd/yyyy"
                      labelClassName="mt-3"
                      className="text-lg"
                      label="Tanggal Lahir"
                      required={config.add_patient_config[8].required ? true : false}
                      isShowWarning={errorField && !txtTanggalLahir && config.add_patient_config[8].required}
                      setDate={(e) => {
                        console.log(e)
                        setTxtTanggalLahir(e)
                        setProfileData({
                          ...profileData,
                          dob: e,
                        });
                        
                      }}
                      selectedDate={profileData?.dob !== 'Invalid date' ? profileData?.dob : ''}
                      icon={IconCalenderGrey}
                      iconPosition="right"
                      showMonthDropdown
                      showYearDropdown
                      format="MM/dd/yyyy"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[10].show ? 
              
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div className="select-option">
                      <SelectOptions
                        placeholder="Pilih Jenis Kelamin"
                        label="Jenis Kelamin"
                        labelClassName="mt-3"
                        background="#ffff"
                        value={optionJenisKelamin}
                        fontSize="lg"
                        setValue={(e) => {
                          setOptionJenisKelamin(e);
                          setProfileData({
                            ...profileData,
                            gender: e.value,
                          });
                        }}
                        required={config.add_patient_config[10].required ? true : false}
                        isShowWarning={errorField && !optionJenisKelamin && config.add_patient_config[10].required}
                        options={[
                          {
                            label: 'Laki-Laki',
                            value: 1,
                          },
                          {
                            label: 'Perempuan',
                            value: 2,
                          },
                        ]}
                        customIcon={IconArrowGrey}
                        iconClassName="mr-2"
                      />
                    </div>
                    {errorRequired.find((val) => val === 'gender') ? (
                      <span className="span-error">
                        <i className="fa fa-warning" /> Jenis Kelamin harus diisi
                      </span>
                    ) : (
                      ''
                    )}
                  </Col>
                  : null}
                  {config.add_patient_config[12].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputText
                      label="Nomor Telepon"
                      labelClassName="mt-3"
                      placeholder="Masukan No. Telepon: 08xxxxxxxxx"
                      isShowWarning={errorField && !txtNomorTelepon && config.add_patient_config[12].required}
                      required={config.add_patient_config[12].required ? true : false}
                      value={txtNomorTelepon}
                      maxText={13}
                      setValue={(value) => {
                        setTxtNomorTelepon(value);
                        setProfileData({
                          ...profileData,
                          phone_1: value,
                        });

                      }}
                      
                      type="number"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[14].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputText
                      label="Email"
                      labelClassName="mt-3"
                      placeholder="Masukkan Email"
                      required={config.add_patient_config[14].required ? true : false}
                      isShowWarning={errorField && (!txtEmail || !isEmail(txtEmail)) && config.add_patient_config[14].required}
                      errorMessage={
                        txtEmail ? !isEmail(txtEmail) && 'Format email salah' : null
                      }
                      value={txtEmail}
                      setValue={(value) => {
                        setTxtEmail(value);
                        setProfileData({
                          ...profileData,
                          email: value,
                        });
                      }}
                    />
                  </Col>
                  : null}
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <Row>
                  {config.add_patient_config[1].show ? 
              
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Status Pernikahan"
                      label="Status Pernikahan"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionStatusPernikahan}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionStatusPernikahan(e);
                        setProfileData({
                          ...profileData,
                          marital_status: e.value,
                        })

                      }}
                    required={config.add_patient_config[1].required ? true : false}
                    isShowWarning={errorField && !optionStatusPernikahan && config.add_patient_config[1].required}
                      options={
                        accountProfile?.marriageData
                          ? accountProfile?.marriageData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                  {config.add_patient_config[3].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Etnis"
                      label="Etnis"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionEtnis}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionEtnis(e);
                        setProfileData({
                          ...profileData,
                          ethincities: e.value,
                        });

                      }}
                      required={config.add_patient_config[3].required ? true : false}
                      isShowWarning={errorField && !optionEtnis && config.add_patient_config[3].required}
                      options={
                        accountProfile?.ethnicData
                          ? accountProfile?.ethnicData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null }
                  {config.add_patient_config[5].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Golongan Darah"
                      label="Golongan Darah"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionGolonganDarah}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionGolonganDarah(e);
                        setProfileData({
                          ...profileData,
                          blood_type: e.value,
                        });

                      }}
                      required={config.add_patient_config[5].required ? true : false}
                      isShowWarning={errorField && !optionGolonganDarah && config.add_patient_config[5].required}
                      options={
                        accountProfile?.bloodTypeData
                          ? accountProfile?.bloodTypeData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                  {config.add_patient_config[7].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Agama"
                      label="Agama"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionAgama}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionAgama(e);
                        setProfileData({
                          ...profileData,
                          religion: e.value,
                        });

                      }}
                      required={config.add_patient_config[7].required ? true : false}
                      isShowWarning={errorField && !optionAgama && config.add_patient_config[7].required}
                      options={
                        accountProfile?.religionData
                          ? accountProfile?.religionData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                  {config.add_patient_config[9].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Kewarganegaraan"
                      label="Kewarganegaraan"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionKewarganegaraan}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionKewarganegaraan(e);
                        setProfileData({
                          ...profileData,
                          nationality: e.value,
                        });

                      }}
                      required={config.add_patient_config[9].required ? true : false}
                      isShowWarning={errorField && !optionKewarganegaraan && config.add_patient_config[9].required}
                      options={
                        accountProfile?.nationalityData
                          ? accountProfile?.nationalityData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                  {config.add_patient_config[11].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Tingkat Pendidikan"
                      label="Tingkat Pendidikan"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionTingkatPendidikan}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionTingkatPendidikan(e);
                        setProfileData({
                          ...profileData,
                          education: e.value,
                        });

                      }}
                      required={config.add_patient_config[11].required ? true : false}
                      isShowWarning={errorField && !optionTingkatPendidikan && config.add_patient_config[11].required}
                      options={
                        accountProfile?.educationData
                          ? accountProfile?.educationData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                  {config.add_patient_config[13].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Pilih Pekerjaan"
                      label="Pekerjaan"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionPekerjaan}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionPekerjaan(e);
                        setProfileData({
                          ...profileData,
                          occupation: e.value,
                        });

                      }}
                      required={config.add_patient_config[13].required ? true : false}
                      isShowWarning={errorField && !optionPekerjaan && config.add_patient_config[13].required}
                      options={
                        accountProfile?.jobData
                          ? accountProfile?.jobData?.map((e) => {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            })
                          : ''
                      }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                </Row>
                
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >Alamat Pasien (Sesuai Kartu Identitas)</Col>
          </Row>
          <div style={{ padding: '10px', marginBottom: '20px' }}>
            <Row>
              {config.add_patient_config[15].show ?  
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <SelectOptions
                  placeholder="Pilih Propinsi"
                  label="Propinsi"
                  labelClassName="mt-3"
                  background="#ffff"
                  value={optionPropinsi}
                  fontSize="lg"
                  setValue={(e) => {
                    setOptionPropinsi(e);
                    setOptionKabupaten('')
                    setOptionKecamatan('')
                    setOptionKelurahan('')
                    setOptionKodePos('')
                    setProfileData({
                      ...profileData,
                      province_id: e.value,
                    });

                  }}
                  required={config.add_patient_config[15].required ? true : false}
                  isShowWarning={errorField && !optionPropinsi && config.add_patient_config[15].required}
                  options={
                    accountProfile?.provinceData
                      ? accountProfile?.provinceData?.map((e) => {
                          return {
                            ...e,
                            label: e?.name,
                            value: e?.id,
                          };
                        })
                      : ''
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />

              </Col>
              :null}
              {config.add_patient_config[16].show ? 
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <SelectOptions
                  placeholder="Pilih Kabupaten/Kota"
                  label="Kabupaten/Kota"
                  labelClassName="mt-3"
                  background="#ffff"
                  value={optionKabupaten}
                  fontSize="lg"
                  setValue={(e) => {
                    setOptionKabupaten(e)
                    setOptionKecamatan('')
                    setOptionKelurahan('')
                    setOptionKodePos('')
                    setProfileData({
                      ...profileData,
                      city_id: e.value,
                    });

                  }}
                  required={config.add_patient_config[16].required ? true : false}
                  isShowWarning={errorField && !optionKabupaten && config.add_patient_config[16].required}
                  options={
                    accountProfile?.cityData
                      ? accountProfile?.cityData?.map((e) => {
                          return {
                            ...e,
                            label: e?.name,
                            value: e?.id,
                          };
                        })
                      : ''
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />

              </Col>
              : null}
            </Row>
            <Row>
              {config.add_patient_config[17].show ? 
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <SelectOptions
                  placeholder="Pilih Kecamatan"
                  label="Kecamatan"
                  labelClassName="mt-3"
                  background="#ffff"
                  value={optionKecamatan}
                  fontSize="lg"
                  setValue={(e) => {
                    setOptionKecamatan(e);
                    setOptionKelurahan('')
                    setOptionKodePos('')
                    setProfileData({
                      ...profileData,
                      district_id: e.value,
                    });

                  }}
                  required={config.add_patient_config[17].required ? true : false}
                  isShowWarning={errorField && !optionKecamatan && config.add_patient_config[17].required}
                  options={
                    accountProfile?.districtData
                      ? accountProfile?.districtData?.map((e) => {
                          return {
                            ...e,
                            label: e?.name,
                            value: e?.id,
                          };
                        })
                      : ''
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />

              </Col>
              : null }
              {config.add_patient_config[18].show ? 
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <SelectOptions
                  placeholder="Pilih Kelurahan"
                  label="Jenis Kelurahan"
                  labelClassName="mt-3"
                  background="#ffff"
                  value={optionKelurahan}
                  fontSize="lg"
                  setValue={(e) => {
                    setOptionKelurahan(e);
                    setOptionKodePos('')
                    setProfileData({
                      ...profileData,
                      sub_district_id: e.value,
                    });
                  }}
                  required={config.add_patient_config[18].required ? true : false}
                  isShowWarning={errorField && !optionKelurahan && config.add_patient_config[18].required}
                  options={
                    accountProfile?.subdistrictData
                      ? accountProfile?.subdistrictData?.map((e) => {
                          return {
                            ...e,
                            label: e?.name,
                            value: e?.id,
                          };
                        })
                      : ''
                  }
                  customIcon={IconArrowGrey}
                  iconClassName="mr-2"
                />

              </Col>
              : null}
            </Row>

            <Row>
              {config.add_patient_config[19].show ? 
              
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <SelectOptions
                placeholder="Pilih Kode Pos"
                label="Kode Pos"
                labelClassName="mt-3"
                background="#ffff"
                value={optionKodePos}
                fontSize="lg"
                setValue={(e) => {
                  setOptionKodePos(e);
                  setProfileData({
                    ...profileData,
                    postal_code: e.value,
                  });
                }}
                required={config.add_patient_config[19].required ? true : false}
                isShowWarning={errorField && !optionKodePos && config.add_patient_config[19].required}
                options={
                  accountProfile?.postalCodeData
                    ? accountProfile?.postalCodeData?.map((e) => {
                        return {
                          ...e,
                          label: e?.code,
                          value: e?.code,
                        };
                      })
                    : ''
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />

              </Col>
              : null}
              {config.add_patient_config[20].show ? 
              
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
              <InputTextArea
                labelClassName="mt-3"
                label="Alamat"
                placeholder="Masukan Alamat"
                maxText={255}
                value={txtAlamat}
                setValue={(value) => {
                  setTxtAlamat(value)
                  setProfileData({
                    ...profileData,
                    identity_address: value,
                  });
                }}
                required={config.add_patient_config[20].required ? true : false}
                isShowWarning={errorField && !txtAlamat && config.add_patient_config[20].required}
              />
              </Col>
              : null}
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} >Keluarga/Kontak Darurat</Col>
          </Row>
          <div style={{ padding: '10px' }}>
            <Row>
              
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <Row>
                  {config.add_patient_config[21].show ? 
              
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <SelectOptions
                      placeholder="Status Hubungan"
                      label="Hubungan Dengan Pasien"
                      labelClassName="mt-3"
                      background="#ffff"
                      value={optionHubunganPasien}
                      fontSize="lg"
                      setValue={(e) => {
                        setOptionHubunganPasien(e);
                        setProfileData({
                          ...profileData,
                          emergency_contact_relation: e.value,
                        });


                      }}
                      required={config.add_patient_config[21].required ? true : false}
                      isShowWarning={errorField && !optionHubunganPasien && config.add_patient_config[21].required}
                      options={
                        accountProfile?.relationData
                        ? accountProfile?.relationData?.map((e) => {
                            if (e.id !== 1) {
                              return {
                                ...e,
                                label: e?.name,
                                value: e?.id,
                              };
                            } else {
                              return {
                                ...e,
                                label: '',
                                value: '',
                              };
                            }
                          })
                        : ''
                    }
                      customIcon={IconArrowGrey}
                      iconClassName="mr-2"
                    />
                  </Col>
                  : null}
                  {config.add_patient_config[22].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputText
                      label="Nama Kerabat"
                      labelClassName="mt-3"
                      placeholder="Masukkan Nama Kerabat"
                      required={config.add_patient_config[22].required ? true : false}
                      isShowWarning={errorField && !txtKerabat && config.add_patient_config[22].required}
                      value={txtKerabat}
                      setValue={(value) => {
                        setTxKerabat(value);
                        setProfileData({
                          ...profileData,
                          emergency_contact_name: value,
                        });
                      }}
                      errorMessage="Nama Kerabat tidak boleh kosong!"
                      type="alphabet"
                    />
                  </Col> 
                  : null}   
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                <Row>
                  {config.add_patient_config[23].show ? 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <InputText
                      label="No. Telepon Kerabat"
                      labelClassName="mt-3"
                      placeholder="Masukan No. Telepon Kerabat"
                      required={config.add_patient_config[23].required ? true : false}
                      isShowWarning={errorField && !txtNomorTeleponKerabat && config.add_patient_config[23].required}
                      value={txtNomorTeleponKerabat}
                      maxText={13}
                      setValue={(value) => {
                        setTxtNomorTeleponKerabat(value);
                        setProfileData({
                          ...profileData,
                          emergency_contact_phone: value,
                        });

                      }}
                      
                      type="number"
                    />
                  </Col>
                  : null}
                </Row>
              </Col>
            </Row>
          </div>

      </ModalComp>
    );
  };

  const handleEditUser = (user) => {
    setUserData({
      id: user.id,
      uuid: user.uuid,
      userName: user.userName,
      fullName: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      image: user.image,
      email: user.email,
      phone: user.phone,
      gender: user.gender,
    });

    setErrorRequired([]);
    setEditUserModal(true);
  };

  const actionEditUser = () => {
    if (
      userData?.id &&
      userData?.uuid &&
      userData?.userName &&
      userData?.fullName &&
      userData?.firstName &&
      userData?.phone &&
      userData?.gender
    ) {
      const form = new FormData();
      form.append('id', userData?.id);
      form.append('uuid', userData?.uuid);
      form.append('userName', userData?.userName);
      form.append('fullName', userData?.fullName);
      form.append('firstName', userData?.firstName);
      form.append('lastName', userData?.lastName);
      form.append('image', userData?.image);
      form.append('email', userData?.email);
      form.append('phone', userData?.phone);
      form.append('gender', userData?.gender);

      dispatch(editUser(form))
        .then(() => {
          setTimeout(
            () =>
              Swal.fire({
                icon: 'success',
                title: 'User Data Updated',
                showConfirmButton: true,
                confirmButtonText: 'OK',
              }).then(() => {
                window.location.reload();
              }),
            500
          );
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: 'error',
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: 'error',
              text: err.message,
            });
          }
        });
    } else {
      var errorData = [];
      if (!userData?.userName) {
        errorData.push('userName');
      }

      if (!userData?.fullName) {
        errorData.push('fullName');
      }

      if (!userData?.firstName) {
        errorData.push('firstName');
      }

      if (!userData?.phone) {
        errorData.push('phone');
      }

      if (!userData?.gender) {
        errorData.push('gender');
      }

      setErrorRequired(errorData);
    }
  };

  const renderEditUser = () => {
    return (
      <ModalComp
        title="Edit Data User"
        show={editUserModal}
        setShow={handleCloseModal}
        useButtonClose
        buttonCloseLabel="Batal"
        buttonSubmitLabel="Kirim"
        onSubmit={actionEditUser}
        size="lg"
      >
        <div style={{ padding: '10px' }}>
          <Row>
            <Col md>
              <label>Upload Avatar</label>
              <Form.Control
                type="text"
                placeholder="Unggah Avatar"
                value={userData?.image}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    image: e.target.value,
                  })
                }
              />
              <label htmlFor="uploadavatar" style={{ display: 'inline' }}>
                <i
                  className="fa fa-paperclip"
                  style={{
                    marginLeft: '10px',
                    position: 'absolute',
                    top: 47,
                    right: 23,
                    background: 'white',
                    width: '20px',
                    height: '20px',
                  }}
                />
              </label>
              <input
                id="uploadavatar"
                type="file"
                style={{ display: 'none' }}
                accept="image/png, image/gif, image/jpeg"
                onChange={() => uploadAvatar()}
              />
            </Col>
            <Col md>
              <label>User ID</label>
              <Form.Control
                type="text"
                className="text-mute"
                readOnly="true"
                value={userData?.uuid}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    uuid: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md>
              <label>User Name</label>
              <Form.Control
                type="text"
                className={
                  errorRequired.find((val) => val === 'userName')
                    ? 'errorRequired'
                    : ''
                }
                placeholder="Masukan UserName"
                value={userData?.userName}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    userName: e.target.value,
                  });

                  if (e.target.value) {
                    e.target.className = 'form-control';
                    var removeError = errorRequired.filter(
                      (val) => val !== 'userName'
                    );
                    setErrorRequired(removeError);
                  } else {
                    e.target.className = 'form-control errorRequired';
                    setErrorRequired([...errorRequired, 'userName']);
                  }
                }}
              />
              {errorRequired.find((val) => val === 'userName') ? (
                <span className="span-error">
                  <i className="fa fa-warning" /> User Name harus diisi
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col md>
              <label>Nama Lengkap</label>
              <Form.Control
                type="text"
                className={
                  errorRequired.find((val) => val === 'fullName')
                    ? 'errorRequired'
                    : ''
                }
                placeholder="Masukan Nama Lengkap"
                value={userData?.fullName}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    fullName: e.target.value,
                  });

                  if (e.target.value) {
                    e.target.className = 'form-control';
                    var removeError = errorRequired.filter(
                      (val) => val !== 'fullName'
                    );
                    setErrorRequired(removeError);
                  } else {
                    e.target.className = 'form-control errorRequired';
                    setErrorRequired([...errorRequired, 'fullName']);
                  }
                }}
              />
              {errorRequired.find((val) => val === 'fullName') ? (
                <span className="span-error">
                  <i className="fa fa-warning" /> Nama Lengkap harus diisi
                </span>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col md>
              <label>First Name</label>
              <Form.Control
                type="text"
                className={
                  errorRequired.find((val) => val === 'firstName')
                    ? 'errorRequired'
                    : ''
                }
                placeholder="Masukan First Name"
                value={userData?.firstName}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    firstName: e.target.value,
                  });

                  if (e.target.value) {
                    e.target.className = 'form-control';
                    var removeError = errorRequired.filter(
                      (val) => val !== 'firstName'
                    );
                    setErrorRequired(removeError);
                  } else {
                    e.target.className = 'form-control errorRequired';
                    setErrorRequired([...errorRequired, 'firstName']);
                  }
                }}
              />
              {errorRequired.find((val) => val === 'firstName') ? (
                <span className="span-error">
                  <i className="fa fa-warning" /> First Name harus diisi
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col md>
              <label>Last Name</label>
              <Form.Control
                type="text"
                placeholder="Masukan Last Name"
                value={userData?.lastName}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    lastName: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md>
              <label>Nomor Telepon</label>
              <Form.Control
                type="number"
                className={
                  errorRequired.find((val) => val === 'phone')
                    ? 'errorRequired'
                    : ''
                }
                placeholder="Masukan No. Telepon: 08xxxxxxxxx"
                value={userData?.phone}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    phone: e.target.value,
                  });

                  if (e.target.value) {
                    e.target.className = 'form-control';
                    var removeError = errorRequired.filter(
                      (val) => val !== 'phone'
                    );
                    setErrorRequired(removeError);
                  } else {
                    e.target.className = 'form-control errorRequired';
                    setErrorRequired([...errorRequired, 'phone']);
                  }
                }}
              />
              {errorRequired.find((val) => val === 'phone') ? (
                <span className="span-error">
                  <i className="fa fa-warning" /> Nomor Telepon harus diisi
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col md>
              <label>Email</label>
              <Form.Control
                type="text"
                placeholder="Masukan Email"
                value={userData?.email}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    email: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md>
              <label>Jenis Kelamin</label>
              <div className="select-option">
                <select
                  className={
                    errorRequired.find((val) => val === 'gender')
                      ? 'form-control errorRequired'
                      : 'form-control'
                  }
                  value={userData?.gender}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      gender: e.target.value,
                    });

                    if (e.target.value) {
                      e.target.className = 'form-control';
                      var removeError = errorRequired.filter(
                        (val) => val !== 'gender'
                      );
                      setErrorRequired(removeError);
                    } else {
                      e.target.className = 'form-control errorRequired';
                      setErrorRequired([...errorRequired, 'gender']);
                    }
                  }}
                >
                  <option value="" disabled selected hidden>
                    {' '}
                    Masukan Jenis Kelamin{' '}
                  </option>
                  <option value="1"> Laki-Laki </option>
                  <option value="2"> Perempuan </option>
                </select>
              </div>
              {errorRequired.find((val) => val === 'gender') ? (
                <span className="span-error">
                  <i className="fa fa-warning" /> Jenis Kelamin harus diisi
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col></Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  return (
    <Layout>
      <div className="mt-4">
        <ListProfile
          data={data}
          currentPage={currentPage}
          totalPage={totalPage}
          setEditProfile={handleEditProfile}
          setAddProfile={handleAddProfile}
          setCurrentPage={(pageNum) => setCurrentPage(pageNum)}
          logOut={logOut}
        />
      </div>
      {renderAddProfile()}
      {renderEditProfile()}
      {renderEditUser()}
    </Layout>
  );
};

export default AccountProfile;
