import axiosHisApi from '../helpers/axiosHisApi';
import { proceduresConstant } from './constants';

export const getProcedureMCU = (form) => {
  const filter = form ? JSON.parse(form) : null;
  return async (dispatch) => {
    dispatch({ type: proceduresConstant.GET_PROCEDURE_MCU_REQUEST });
    const res = await axiosHisApi.get(
      `/v1/api/clinic-procedures/mcu/${filter?.hospital_id || ''}?limit=${
        filter?.limit || 3
      }&key=${filter?.key || ''}`
    );

    if (res.status === 200) {
      dispatch({
        type: proceduresConstant.GET_PROCEDURE_MCU_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: proceduresConstant.GET_PROCEDURE_MCU_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getLovProcedureMCU = ({ hospitalId = null }) => {
  return async (dispatch) => {
    dispatch({
      type: proceduresConstant.GET_LOV_PROCEDURE_MCU_REQUEST,
      payload: true,
    });
    try {
      const { data, status } = await axiosHisApi.get(
        `/v1/api/clinic-procedures/mcu/lov/${hospitalId || ''}`
      );
      if (status === 200) {
        dispatch({
          type: proceduresConstant.GET_LOV_PROCEDURE_MCU_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: proceduresConstant.GET_LOV_PROCEDURE_MCU_FAILURE,
          payload: { error: data.error },
        });
      }
    } catch (error) {
      dispatch({
        type: proceduresConstant.GET_PROCEDURE_MCU_FAILURE,
        payload: { error },
      });
      return error;
    }
  };
};
