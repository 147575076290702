import React from "react";
import "./Textarea.style.scss";

const Textarea = ({
  label = null,
  labelClassName = "",
  value = "",
  setValue,
  placeholder = "Masukkan teks",
  maxText = 120,
  isShowWarning = false,
  readOnly = false,
  required = false,
}) => {
  return (
    <React.Fragment>
      {label && (
        <p className={`${labelClassName} font-weight-bold label-input-text`}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </p>
      )}
      <textarea
        type="text"
        className={`w-100 textarea${isShowWarning ? "-warning" : ""} mb-0 pb-0`}
        value={value}
        onChange={(e) =>
          setValue(e.target.value?.length > maxText ? value : e.target.value)
        }
        placeholder={placeholder}
        disabled={readOnly}
        readOnly={readOnly}
      />
      <div className="d-flex justify-content-between mt-0 pt-0">
        {isShowWarning && (
          <p className="warning-message-input">
            <i className="fa fa-warning" /> {label || "Kolom ini"} tidak boleh
            kosong!
          </p>
        )}
        <div className="text-right text-size text-dark">
          {value ? value?.length : "0"}/{maxText}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Textarea;
