import React, { useEffect, useState } from 'react';
import './Button.style.scss';

const Button = ({
  label,
  onClick = null,
  icon = null,
  iconPosition = 'right' | 'left' | null,
  iconSize = 'xl' | 'lg' | 'sm' | 'md',
  iconClassName = '',
  disable = false,
  type = 'primary' | 'danger' | 'primary-blue' | 'white',
  outline = false,
  className,
  styles,
  size = 'xl' | 'lg' | 'sm' | 'md',
}) => {
  const [icSize, setIcSize] = useState(15);

  useEffect(() => {
    setIcSize(
      iconSize === 'sm'
        ? 15
        : iconSize === 'md'
        ? 20
        : iconSize === 'lg'
        ? 25
        : iconSize === 'xl'
        ? 30
        : 15
    );
  }, [iconSize]);

  return (
    <React.Fragment>
      <button
        disabled={disable}
        className={`${className} w-100 button-${size || 'md'}-${
          outline ? 'outline-' + type || 'primary' : type || 'primary'
        } ${disable && 'btn-disable'} font-weight-bold `}
        onClick={onClick}
        style={styles}
      >
        {iconPosition === 'right' ? (
          <div className="ml-2">
            {label}
            <span>
              <img
                className={`mb-1 ml-2 ${iconClassName}`}
                src={icon}
                alt={`${label} aido health`}
                height={icSize}
                width={icSize}
              />
            </span>
          </div>
        ) : iconPosition === 'left' ? (
          <div className="mr-2">
            <img
              className={`mr-2 my-auto ${iconClassName}`}
              src={icon}
              alt={`${label} aido health`}
              height={icSize}
              width={icSize}
            />
            {label}
          </div>
        ) : (
          label
        )}
      </button>
    </React.Fragment>
  );
};

export default Button;
