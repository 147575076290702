import React, { useEffect, useState } from "react";
import FooterDetailProductPackage from "./FooterDetailProductPackage";
import FooterCopyright from "./FooterCopyright";
import FooterCopyrightV2 from "./FooterCopyrightV2";
import Header from "./Header";
import "./index.scss";
import HeaderV2 from "./HeaderV2";
import DropdownMenu from "./DropdownMenu";

const LayoutV2 = ({
  header = false,
  headerGlobal = false,
  headerWithDropdownMenu = false,
  footer = false,
  children,
  footerCopyright = false,
  footerCopyrightV2 = false,
  footerDetailProductPackage = false,
  footerDetailProductPackageProps,
}) => {
  const [childrenClass, setChildrenClass] = useState("");
  const [isMenuShow, setIsMenuShow] = useState(false);

  useEffect(() => {
    if (header && footer) {
      if (footerDetailProductPackage) {
        setChildrenClass("container-web min-h-calc-160 margin-t-b-80");
      } else {
        setChildrenClass("container-web min-h-calc-160 margin-t-80");
      }
    } else if (header) {
      setChildrenClass("container-web min-h-calc-80 margin-t-80");
    } else if (footer) {
      setChildrenClass("container-web min-h-calc-80");
    } else {
      setChildrenClass("container-web min-h-100");
    }
  }, []);

  return (
    <div className="main-container-web bg-white">
      {headerGlobal && (
        <div className="container-header">
          <Header />
        </div>
      )}

      {headerWithDropdownMenu && (
        <div className="container-header">
          <HeaderV2 isMenuShow={isMenuShow} setIsMenuShow={setIsMenuShow} />
        </div>
      )}

      {isMenuShow && (
        <div className="container-dropdown-menu">
          <DropdownMenu setIsMenuShow={setIsMenuShow} />
        </div>
      )}

      <div className={childrenClass}>{children}</div>

      {footerCopyright && <FooterCopyright />}

      {footerCopyrightV2 && <FooterCopyrightV2 />}

      {footerDetailProductPackage && (
        <FooterDetailProductPackage
          footerDetailProductPackageProps={footerDetailProductPackageProps}
        />
      )}
    </div>
  );
};

export default LayoutV2;
