import React from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';

const Layout = (props) => {
  return (
    <div className="container">
      <Header />
      <div className="main-content">{props.children}</div>
      {/* <div style={{ marginTop: '90px' }}> */}
      <div style={{ marginTop: '10px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
