import { appointmentConstants } from "../actions/constants";

const initState = {
  appointmentData: [],
  appointmentDetail: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case appointmentConstants.CREATE_APPOINTMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case appointmentConstants.CREATE_APPOINTMENT_SUCCESS:
      state = {
        ...state,
        appointmentData: action.payload.data,
        loading: false,
      };
      break;
    case appointmentConstants.CREATE_APPOINTMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case appointmentConstants.CREATE_APPOINTMENT_PACKAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case appointmentConstants.CREATE_APPOINTMENT_PACKAGE_SUCCESS:
      state = {
        ...state,
        appointmentData: action.payload.data,
        loading: false,
      };
      break;
    case appointmentConstants.CREATE_APPOINTMENT_PACKAGE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case appointmentConstants.GET_DETAIL_APPOINTMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case appointmentConstants.GET_DETAIL_APPOINTMENT_SUCCESS:
      state = {
        ...state,
        appointmentDetail: action.payload.data,
        loading: false,
      };
      break;
    case appointmentConstants.GET_DETAIL_APPOINTMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case appointmentConstants.GET_DETAIL_APPOINTMENT_PACKAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case appointmentConstants.GET_DETAIL_APPOINTMENT_PACKAGE_SUCCESS:
      state = {
        ...state,
        appointmentDetail: action.payload.data,
        loading: false,
      };
      break;
    case appointmentConstants.GET_DETAIL_APPOINTMENT_PACKAGE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
