import React, { useEffect, useState } from 'react';
import { IconChevronGreen, IconChevronGray } from '../../assets/img/icon';
import './Accordion.style.scss';

const AccordionComp = ({ isOpen = false, title = null, children = null }) => {
  const [isActive, setIsActive] = useState();

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <div className="accordion mb-4">
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => setIsActive(!isActive)}
          >
            <div className="d-inline title-accordion ml-4">{title || '-'}</div>
            {isActive ? (
              <img
                src={IconChevronGreen}
                alt="icon"
                height={16}
                className="accordion-icon"
              />
            ) : (
              <img
                src={IconChevronGray}
                alt="icon"
                className="rotate-180 accordion-icon"
                height={16}
              />
            )}
          </div>
          {isActive && <div className="accordion-content">{children}</div>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccordionComp;
