import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getTransactionHistory } from "../../../actions";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import ModalComp from "../../../components/Modal";
import LayoutV2 from "../Components/LayoutV2";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { InputDate, SelectOptions } from "../../../components/Input";
import { formatDateAsData } from "../../../helpers/utils";
import {
  IconArrowGrey,
  IconCalenderGrey,
  IconDummyAvatar,
} from "../../../assets/img/icon";
import moment from "moment";
import "moment/locale/id";
import "./index.scss";
import Swal from "sweetalert2";

moment.locale("id");

const History = () => {
  const dispatch = useDispatch();

  const { state } = useLocation();
  if (!state) window.location = "/";

  const transactionHistory = useSelector((state) => state.transactionHistory);
  const [detailModal, setDetailModal] = useState(false);
  const [konsultasiModal, setKonsultasiModal] = useState(false);
  const [active, setActive] = useState(1);
  const [filter, setFilter] = useState({
    profilId: state.profilId,
    consultationType: "",
    scheduleDate: "",
  });
  const [searchDate, setSearchDate] = useState(null);
  const [searchConsultationType, setSearchConsultationType] = useState(null);
  const [status, setStatus] = useState("9,80,85,81,86");
  const [userId, setUserId] = useState(window.localStorage.getItem("userId"));

  const [listProfil, setListProfil] = useState([]);
  const [listConsultationType, setListConsultationType] = useState([]);
  const [history, setHistory] = useState([]);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState([]);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  useEffect(() => {
    dispatch(getTransactionHistory(userId, status, filter));

    navigator.geolocation.getCurrentPosition(function (position) {
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
    });
  }, [userId, status, filter]);

  useEffect(() => {
    setData(history);
  }, [history]);

  useEffect(() => {
    if (transactionHistory?.transactionHistoryData?.listTrans) {
      setHistory(transactionHistory?.transactionHistoryData?.listTrans);
    }

    if (transactionHistory?.transactionHistoryData?.listProfil) {
      setListProfil(transactionHistory?.transactionHistoryData?.listProfil);
    }

    if (transactionHistory?.transactionHistoryData?.listConsultationType) {
      setListConsultationType(
        transactionHistory?.transactionHistoryData?.listConsultationType
      );
    }
  }, [transactionHistory.transactionHistoryData]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
  });

  const resetFilter = () => {
    setSearchConsultationType(null);
    setSearchDate(null);
    setFilter({
      ...filter,
      consultationType: "",
      scheduleDate: "",
    });
  };

  const handleOngoing = () => {
    setStatus("9,80,85,81,86");
    setActive(1);
  };

  const handleDone = () => {
    setActive(2);
    setStatus("87,88,89,90,99");
  };

  const handleClose = () => {
    setKonsultasiModal(false);
    setDetailModal(false);
  };

  const handleDetailModal = (data) => {
    setDetail(data);
    setDetailModal(true);
  };

  const renderDetailTrans = () => {
    return (
      <ModalComp
        title="Detail Transaksi"
        show={detailModal}
        setShow={handleClose}
        size="sm"
        useButtonClose
        buttonCloseLabel="Tutup"
      >
        <Row className="margin-b-4">
          <Col>
            <span className="font-s-14 font-w-400">Nomor Order</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              : {detail.registrationNumber || "-"}
            </span>
          </Col>
        </Row>

        <Row className="margin-b-4">
          <Col>
            <span className="font-s-14 font-w-400">Rekap Medis</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              : {detail.medicalnumber || "-"}
            </span>
          </Col>
        </Row>

        <Row className="margin-b-4">
          <Col>
            <span className="font-s-14 font-w-400">Nama Dokter</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              : {detail.doctorname || "-"}
            </span>
          </Col>
        </Row>

        <Row className="margin-b-4">
          <Col>
            <span className="font-s-14 font-w-400">Nama Poli</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              : {detail.poliname || "-"}
            </span>
          </Col>
        </Row>

        <Row className="margin-b-4">
          <Col>
            <span className="font-s-14 font-w-400">Faskes</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              : {detail.hospitalname}
            </span>
          </Col>
        </Row>

        <Row className="margin-b-4">
          <Col>
            <span className="font-s-14 font-w-400">Tanggal</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              : {moment(detail.date).format("D MMMM YYYY")}
            </span>
          </Col>
        </Row>

        <Row className="margin-b-24">
          <Col>
            <span className="font-s-14 font-w-400">Waktu</span>
          </Col>

          <Col>
            <span className="font-s-14 font-w-700">
              :{" "}
              {moment(detail.date + " " + detail.from_time).format("H:mm") +
                " - " +
                moment(detail.date + " " + detail.to_time).format("H:mm") +
                " " +
                detail.timezone}
            </span>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center">
              <span className="font-s-14 font-w-500 margin-r-8">Bagikan</span>

              <img
                src="/img/icon/icon-wa.svg"
                className="margin-r-8 cursor-point"
                style={{
                  borderRadius: "50px",
                  boxShadow: "0px 2px 4px 0px #D4D4D4",
                }}
                onClick={() =>
                  window.open(
                    "whatsapp://send?text=" +
                      window.location.origin +
                      "/appointment-created/" +
                      detail.appointmentid
                  )
                }
              />
              <img
                src="/img/icon/icon-link.svg"
                className="cursor-point"
                style={{
                  borderRadius: "50px",
                  boxShadow: "0px 2px 4px 0px #D4D4D4",
                }}
                onClick={() =>
                  {navigator.clipboard.writeText(
                    window.location.origin +
                      "/appointment-created/" +
                      detail.appointmentid
                  );
                  Toast.fire({
                    icon: "info",
                    title: "Berhasil menyalin link URL",
                  });}
                }
              />
            </div>
          </Col>
        </Row>
      </ModalComp>
    );
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col>
          <span className="font-s-18 font-w-700 margin-b-8">
            Riwayat Janji Temu
          </span>
        </Col>
      </Row>

      <Row className="justify-content-end">
        <Col className="margin-b-8" xs={12} lg={6}>
          <img
            className="margin-r-8"
            src={IconDummyAvatar}
            alt="avatar"
            height="40px"
          ></img>

          <span className="font-s-14 font-w-700">
            {listProfil
              .filter((data) => data.id === state.profilId)
              .map((data) => {
                return data.name;
              })}
          </span>
        </Col>

        <Col className="margin-b-8" xs={12} lg={3}>
          <SelectOptions
            placeholder="Tipe konsultasi"
            fontSize="lg"
            color="#757575"
            background="white"
            options={
              listConsultationType &&
              listConsultationType.map((e) => {
                return {
                  ...e,
                  value: e?.consultation_type,
                  label: `${e?.consultation_name}`,
                };
              })
            }
            value={searchConsultationType}
            setValue={(e) => {
              setFilter({
                ...filter,
                consultationType: e?.value,
              });
              setSearchConsultationType(e);
            }}
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
          />
        </Col>

        <Col className="margin-b-8 d-flex" xs={12} lg={3}>
          <InputDate
            placeholder="dd/mm/yyyy"
            className="text-lg"
            setDate={(e) => {
              setFilter({
                ...filter,
                scheduleDate: formatDateAsData(e),
              });
              setSearchDate(e);
            }}
            selectedDate={searchDate}
            icon={IconCalenderGrey}
            iconPosition="right"
            showMonthDropdown
            showYearDropdown
            format="dd/MM/yyyy"
          />

          <img
            src="/img/icon/icon-reset.svg"
            className="margin-l-16 cursor-point"
            onClick={resetFilter}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="header-tab">
            <span
              className={active == 1 ? "btn-tab active" : "btn-tab"}
              onClick={handleOngoing}
            >
              <b
                className={
                  active !== 1
                    ? "font-weight-normal font16"
                    : "font-weight-700 font16"
                }
              >
                Berlangsung
              </b>
            </span>
            <span
              className={active !== 1 ? "btn-tab active" : "btn-tab"}
              onClick={handleDone}
            >
              <b
                className={
                  active === 1
                    ? "font-weight-normal font16"
                    : "font-weight-700 font16"
                }
              >
                Selesai
              </b>
            </span>
          </div>
        </Col>
      </Row>

      <Card
        type="primary"
        className="mb-3 card-border"
        innerClassName="no-padding"
      >
        {data && data.length ? (
          data.map((data, i) => (
            <>
              {active === 1 ? (
                <div key={i} className={`card-inner mb-3`}>
                  <Row>
                    <Col xs={6} className="d-flex flex-column">
                      <span className="font-s-14 font-w-700">
                        {data.consultationtype == 3
                          ? data.consultationdesc
                          : "Konsultasi " + data.consultationdesc}
                      </span>

                      <span className="font-s-14 font-w-400">
                        {moment(data.date).format("LL")}
                      </span>
                    </Col>

                    <Col
                      xs={6}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <img
                        className="cursor-point"
                        src="/img/icon/ic_edit.svg"
                        height="20px"
                        onClick={() =>
                          window.open("https://wa.me/628118481436")
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>

                  <Row className="margin-b-8">
                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">Nomor Order</span>
                      <span className="font-s-14 font-w-700">
                        {data.registrationNumber || "-"}
                      </span>
                    </Col>

                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">Status</span>
                      <span className="font-s-14 font-w-700">
                        {data.orderstatusdesc || "-"}
                      </span>
                    </Col>

                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">
                        Petunjuk Lokasi
                      </span>
                      <span
                        className="font-s-14 font-w-700 cl-green cursor-point"
                        onClick={() => {
                          window.open(
                            "https://www.google.com/maps/dir/" +
                              latitude +
                              "," +
                              longitude +
                              "/" +
                              data.hospitalname +
                              "/@" +
                              data.hospitallatitude +
                              "," +
                              data.hospitallongitude
                          );
                        }}
                      >
                        <LocationOnOutlinedIcon />
                        {data.hospitalname || "-"}
                      </span>
                    </Col>

                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">Dokter</span>
                      <span className="font-s-14 font-w-700">
                        {data.doctorname || "-"}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <Button
                        label="Lihat Detail"
                        type="primary"
                        className="margin-b-8"
                        onClick={() => handleDetailModal(data)}
                      />
                    </Col>

                    <Col xs={12}>
                      <Button
                        label="Batalkan"
                        type="outline-danger"
                        onClick={() =>
                          window.open("https://wa.me/628118481436")
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                <div key={i} className={`card-inner mb-3`}>
                  <Row>
                    <Col xs={12} className="d-flex flex-column">
                      <span className="font-s-14 font-w-700">
                        {data.consultationtype == 3
                          ? data.consultationdesc
                          : "Konsultasi " + data.consultationdesc}
                      </span>

                      <span className="font-s-14 font-w-400">
                        {moment(data.date).format("LL")}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>

                  <Row className="margin-b-8">
                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">Nomor Order</span>
                      <span className="font-s-14 font-w-700">
                        {data.registrationNumber || "-"}
                      </span>
                    </Col>

                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">Status</span>
                      <span className="font-s-14 font-w-700">
                        {data.orderstatusdesc || "-"}
                      </span>
                    </Col>

                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">
                        Petunjuk Lokasi
                      </span>
                      <span
                        className="font-s-14 font-w-700 cl-green cursor-point"
                        onClick={() => {
                          window.open(
                            "https://www.google.com/maps/dir/" +
                              latitude +
                              "," +
                              longitude +
                              "/" +
                              data.hospitalname +
                              "/@" +
                              data.hospitallatitude +
                              "," +
                              data.hospitallongitude
                          );
                        }}
                      >
                        {data.hospitalname || "-"}
                      </span>
                    </Col>

                    <Col
                      className="d-flex flex-column margin-b-8"
                      xs={12}
                      lg={3}
                    >
                      <span className="font-s-14 font-w-400">Dokter</span>
                      <span className="font-s-14 font-w-700">
                        {data.doctorname || "-"}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <Button
                        label="Lihat Detail"
                        type="primary"
                        onClick={() => handleDetailModal(data)}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </>
          ))
        ) : (
          <Card useInnerBorder>
            <p className="text-dark text-center my-5">Tidak ada data</p>
          </Card>
        )}
      </Card>
      {renderDetailTrans()}
    </LayoutV2>
  );
};

export default History;
