import moment from 'moment';
import React, { useMemo,useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDiagnosis, getMedicalRecord, getRecipe } from '../../../../actions';
import { IconDownload, IconPrintGreen } from '../../../../assets/img/icon';
import Button from '../../../../components/Button';
import ModalComp from '../../../../components/Modal';
import { wordingGender } from '../../../../constants/wording';
// const [diagnosa, setDiagnosa] = useState("name");

const ConsultationResult = ({
  showModalDetail,
  setShowModalDetail,
  detail,
  handlePrintMedicalResult,
}) => {
  /* Redux */
  const dispatch = useDispatch();
  const [diagnosa, setDiagnosa] = useState('');
  const apptData = useMemo(() => {

    if(detail && detail.registration_id) {
      let result = detail;
      // console.log("result", detail?.registration_id);
      dispatch(getDiagnosis(detail?.profileid, detail?.registration_id));
      dispatch(getRecipe(detail?.profileid, detail?.registration_id));
      dispatch(getMedicalRecord(detail?.registration_id)).then((response) => {
        const datas = response ? response.data : [];
        if (datas) {
          const complaints = datas.registrationData
            ? datas.registrationData.keluhan
            : '';
          const diagnosa = datas.registrationData
            ? datas.registrationData.objective
            : '';
          const icd10 = datas.icd10 ? datas.icd10 : [];
          const documentRegistration = datas.documentRegistration
            ? datas.documentRegistration
            : [];
          const documentPmr = datas.documentPmr ? datas.documentPmr : [];
  
          result['complaints'] = complaints;
          result['diagnosis'] = diagnosa;
          result['icd10'] = icd10;
          result['documentRegistration'] = documentRegistration;
          result['documentPmr'] = documentPmr;
          result['documentAppointment'] = JSON.parse(detail?.medicalDoc)
          setDiagnosa(diagnosa)
          // console.log("result", result);
        }
      });

      return result;
    }
      // console.log("result", "ini ya mas");
  }, [detail]);

  // console.log("appt", apptData);

  const medicalDocData = () => {
    if(detail && detail.medicalDoc) {
      const appointmentDocument = JSON.parse(detail?.medicalDoc)

      // console.log("appointmentDocument", appointmentDocument[0].docType.label);
      return(
        appointmentDocument.map((e) => (
          <div className="download-doc-card text-left px-3 pb-3 pt-1">
            <div className="row">
              <p
                 className="col pt-1"
                 style={{
                   color: '#00BD84',
                   fontSize: '14px',
                   fontWeight: '500',
                 }}
               >
                 {e?.docType?.label}
               </p>
              <div className="col-5 pt-2">
                 <Button
                   label="Download"
                   size="sm"
                   type="white"
                   className="font12"
                   icon={IconDownload}
                   iconPosition="right"
                   onClick={() => window.open(e?.docUrl)}
                 />
               </div>

            </div>

          </div>
        ))
      )
    }
  }

  return (
    <React.Fragment>
      <ModalComp
        show={showModalDetail}
        title="Hasil Konsultasi"
        useHeaderButtonClose
        setShow={setShowModalDetail}
        size="lg"
        className="mt-4"
      >
        <div className="row text-left mb-5" id='capture'>
          <div className="col">
            <span className="font16 py-0 font-weight-600 d-block">
              Order ID
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {apptData?.code || '-'}
            </span>
            <span className="font16 py-0 font-weight-600 d-block mt-2">
              Nama
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {apptData?.profilename || '-'}
            </span>
            <span className="font16 py-0 font-weight-600 d-block mt-2">
              Tanggal Lahir
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {apptData?.profiledob
                ? moment(apptData?.profiledob).format('DD-MM-YYYY')
                : '-'}
            </span>
            <span className="font16 py-0 font-weight-600 d-block mt-2">
              Jenis Kelamin
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {wordingGender[apptData?.profilegender] || '-'}
            </span>
            <span className="font16 py-0 font-weight-600 d-block mt-2">
              No. Telepon
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              0{apptData?.profilephone || '-'}
            </span>
          </div>
          <div className="col">
            <span className="font16 py-0 font-weight-600 d-block">
              Tanggal Konsultasi
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {apptData?.date
                ? moment(apptData?.date).format('dddd, D MMM YYYY')
                : '-'}
            </span>
            <span className="font16 py-0 font-weight-600 d-block mt-2">
              Nama Dokter
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {apptData?.doctorname || '-'}
            </span>
            <span className="font16 py-0 font-weight-600 d-block mt-2">
              Tanggal Lahir
            </span>
            <span className="font16 py-0 font-weight-800 d-block">
              {apptData?.poliname || '-'}
            </span>
          </div>
          <div className="col-12 col-md-3 mt-4 mt-md-0" id='btn-cetak'> 
            <Button
              label="Cetak"
              icon={IconPrintGreen}
              iconPosition="left"
              type="outline-primary"
              onClick={() => handlePrintMedicalResult(detail)}
            />
          </div>
          <div className='col-12 mt-4'>

            <div className="diagnosa-result px-3 d-flex my-auto">
              <p className="font-weight-bold font16 text-left d-flex my-auto">
                Hasil Diagnosa
              </p>
            </div>
            <p className="px-3 font16 text-left mb-2">{apptData?.diagnosis}</p>
              {apptData?.icd10 ? (
                apptData?.icd10?.map((e) => {
                  return(
                    <p className="px-3 font16 text-left">
                      - {e?.name}
                    </p>
                  )
                })
              ) : ''}
            <hr />
          </div>
        </div>

        <div className="download-doc-card text-left px-3 pb-3">
          <p className="font-16 font-weight-bold mb-2 text-dark">
            Dokumen Medis
          </p>
          {/* {apptData?.documentAppointment ? (
            apptData?.documentAppointment?.map((e) => {
              return (
                <div className="download-doc-card text-left px-3 pb-3 pt-1">
                  <div className="row">
                    <p
                      className="col pt-1"
                      style={{
                        color: '#00BD84',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {e?.docType}
                    </p>
                    <div className="col-5 pt-2">
                      <Button
                        label="Download"
                        size="sm"
                        type="white"
                        className="font12"
                        icon={IconDownload}
                        iconPosition="right"
                        onClick={() => window.open(e?.docUrl)}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="font12 font-italic font-weight-normal text-dark text-center my-2">
              -- Tidak ada dokumen --
            </p>
          )} */}

          {
            medicalDocData()
          }
        </div>
      </ModalComp>
    </React.Fragment>
  );
};

export default ConsultationResult;
