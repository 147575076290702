import React, { useMemo } from 'react';
import { Dropdown as DropdwonReact } from 'react-bootstrap';
import './Dropdown.style.scss';

const Dropdown = ({
  className,
  icon,
  iconClassName,
  style,
  label,
  menus,
  iconPosition = 'right' | 'left' | null,
  disable = false,
  iconSize,
}) => {
  const icSize = useMemo(() => {
    return iconSize === 'sm'
      ? 15
      : iconSize === 'md'
      ? 20
      : iconSize === 'lg'
      ? 25
      : iconSize === 'xl'
      ? 30
      : 15;
  }, [iconSize]);
  return (
    <React.Fragment>
      <DropdwonReact className="h-100 w-100">
        <DropdwonReact.Toggle
          disabled={disable}
          className={`${className}`}
          style={style}
        >
          {iconPosition === 'right' ? (
            <div className="ml-2">
              {label}
              <span>
                <img
                  className={`${iconClassName} mb-1 ml-2`}
                  src={icon}
                  alt={`${label} aido health`}
                  height={icSize}
                  width={icSize}
                />
              </span>
            </div>
          ) : iconPosition === 'left' ? (
            <div className="mr-2">
              <img
                className={`${iconClassName} mb-1 mr-2`}
                src={icon}
                alt={`${label} aido health`}
                height={icSize}
                width={icSize}
              />
              {label}
            </div>
          ) : (
            label
          )}
        </DropdwonReact.Toggle>
        <DropdwonReact.Menu>
          {menus &&
            menus?.map(({ onClick, menuLabel }, i) => {
              return (
                <DropdwonReact.Item key={i} onClick={onClick}>
                  {menuLabel}
                </DropdwonReact.Item>
              );
            })}
        </DropdwonReact.Menu>
      </DropdwonReact>
    </React.Fragment>
  );
};

export default Dropdown;
