import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import LayoutV2 from "../../Components/LayoutV2";
import { IconArrowGreen } from "../../../../assets/img/icon";
import { getDetailPackage } from "../../../../actions/package/package.actions";
import ModalLogin from "../../../../components/Modal/Login";
import { otpRequest, validateOTP } from "../../../../actions";
import { storeLocalStorageItem } from "../../../../helpers/localStorage";
import Swal from "sweetalert2";

const DetailProductPackage = () => {
  const { uuid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const productPackage = useSelector((state) => state.productPackage);

  const { state } = useLocation();
  if (!state) window.location = "/";

  // Login
  const [showModal, setShowModal] = React.useState(false);
  const [SpanPhone, setSpanPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [TimerOnGoing, setTimerOnGoing] = useState(false);
  const [InputForm, setInputForm] = useState(!false);
  const [OtpForm, setOtpForm] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const [Otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [remainingTime, setRemainingTime] = useState("00:00");
  const Ref = useRef(null);

  useEffect(() => {
    if (uuid) {
      dispatch(getDetailPackage(uuid));
    }
  }, []);

  useEffect(() => {
    if (Otp) {
      if (Otp.length == 6) {
        validateOtp();
      }
    }
  }, [Otp]);

  const closeModal = () => {
    setPhone("");
    return setShowModal(false);
  };

  const clearTimer = (e) => {
    setTimer("00:60");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const getTimeRemainingAttemp = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimerAttemp = (e) => {
    let { total, minutes, seconds } = getTimeRemainingAttemp(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setRemainingTime(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimerAttemp = (e) => {
    setRemainingTime("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimerAttemp(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTimeAttemp = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const requestOtp = () => {
    if (phone) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("urlWeb", window.location.href);

      setSpanPhone("+62" + phone.toString());
      clearTimer(getDeadTime());

      dispatch(otpRequest(form))
        .then((response) => {
          if (response.status == 200) {
            setStatus(response.status);
            setMessage(response.message);
            setOtpForm(!false);
            setInputForm(false);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          } else {
            clearTimerAttemp(getDeadTimeAttemp());
            setOtpForm(!false);
            setInputForm(false);
            setStatus(response.status);
            setMessage(response.message);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    }
  };

  const rerequestOtp = () => {
    setTimerOnGoing(false);
    clearTimer(getDeadTime());
  };

  const validateOtp = () => {
    closeModal();
    setOtpForm(!false);
    setInputForm(false);

    if (Otp) {
      const form = new FormData();

      form.append("phone", phone);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("otp", Otp);

      dispatch(validateOTP(form)).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
    }
  };

  const handleClick = () => {
    if (localStorage.accessToken) {
      return history.push({
        pathname: "/patient-v2",
        state: {
          clinicId: state.clinicId,
          clinicName: state.clinicName,
          packageUuid: uuid,
          packageId: productPackage.detailPackageData?.id,
          packageName: productPackage.detailPackageData?.name || "",
          packagePrice: productPackage.detailPackageData?.price || 0,
          packageValidDays: productPackage.detailPackageData?.validDays,
        },
      });
    }

    return setShowModal(true);
  };

  const toIdr = (value, locale = "id-Id") => {
    if (!value || isNaN(value)) return 0;

    const currency = new Intl.NumberFormat(locale);

    return currency.format(value);
  };

  return (
    <LayoutV2
      header={true}
      headerGlobal={true}
      footer={true}
      footerDetailProductPackage={true}
      footerDetailProductPackageProps={{
        name: productPackage.detailPackageData?.name,
        price: `Rp${toIdr(productPackage.detailPackageData?.price)}`,
        onClick: () => handleClick(),
      }}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
              onClick={() => history.replace("/product-package")}
            />
            &nbsp;
            <span
              className="font-s-18 font-w-700 cl-green cursor-point"
              onClick={() => history.replace("/product-package")}
            >
              Kembali
            </span>
          </div>
        </Col>
      </Row>

      <Row className="margin-b-16">
        <Col xs={5}>
          <img
            src={
              productPackage.detailPackageData?.image
                ? productPackage.detailPackageData.image
                : "/img/logo/logo-package-default.svg"
            }
            className="detail-product-package-image"
          />
        </Col>
        <Col xs={7}>
          <div className="d-flex flex-column">
            <span className="font-s-24 font-w-600">
              {productPackage.detailPackageData?.name}
            </span>

            <span className="font-s-24 font-w-700 cl-green margin-b-16">{`Rp${toIdr(
              productPackage.detailPackageData?.price
            )}`}</span>

            <div
              dangerouslySetInnerHTML={{
                __html: productPackage.detailPackageData?.description,
              }}
            ></div>
          </div>
        </Col>
      </Row>

      <ModalLogin
        InputForm={InputForm}
        TimerOnGoing={TimerOnGoing}
        closeModal={closeModal}
        isShow={showModal}
        phone={phone}
        requestOtp={requestOtp}
        rerequestOtp={rerequestOtp}
        setOtp={setOtp}
        validateOtp={validateOTP}
        OtpForm={OtpForm}
        SpanPhone={SpanPhone}
        setPhone={setPhone}
        timer={timer}
        message={message}
        remainingTime={remainingTime}
        setInputForm={setInputForm}
        setOtpForm={setOtpForm}
        status={status}
      />
    </LayoutV2>
  );
};

export default DetailProductPackage;
