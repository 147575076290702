import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconDummyAvatar, IconPlusWhite } from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { formatDate } from '../../../helpers/utils';

const ListProfile = ({
  data = [],
  setEditProfile,
  setAddProfile,
  logOut,
}) => {
  const navigate = useHistory();
  return (
    <React.Fragment>
      <div className="mb-3">
        <Card key={0} className="mb-3">
          <div className="row">
            <div className="col-10">
              <img
                src={IconDummyAvatar}
                alt="icon"
                className="d-inline my-auto mr-3"
                height={60}
                width={60}
              />
              <p className="mobile-profile-name d-inline my-auto">
                {data[0]?.name || '-'}
              </p>
            </div>
            <div className="col-2 d-flex justify-content-end my-auto">
              <img
                src="/img/icon/ic_edit.svg"
                className="icon-edit"
                onClick={() => setEditProfile(data[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="hr-no-margin"/>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12"
              style={{textAlign: "center", cursor: "pointer"}}
              onClick={() => navigate.push(`/history`, {
                profilId: data[0].id,
              })}
            >
              <img
                src="/img/icon/ic_history.svg"
                className="icon-edit"
              />
              <span className="font-w-700 font-s-14 ml-2" style={{color: "#00BD84"}}>Riwayat Janji Temu</span>
            </div>
          </div>
        </Card>
        <div className="d-flex justify-content-end mb-3">
          <div className="mr-1" style={{ width: '200px' }}>
            <Button
              label="Tambah Profil"
              icon={IconPlusWhite}
              iconPosition="left"
              iconSize="sm"
              btnSize="md"
              disable={false}
              type="primary"
              onClick={() => setAddProfile()}
            />
          </div>
        </div>
        <div className="w-100 row row-no-margin">
          {data?.length &&
            data.map((item, index) => {
              if (index > 0) {
                return (
                  <>
                    <div className={`col-lg-4 col-md-12 col-sm-12`}>
                      <Card key={index} className="mb-3">
                        <div className="row">
                          <div className="col-10">
                            <img
                              src={IconDummyAvatar}
                              alt="icon"
                              className="d-inline my-auto mr-3"
                              height={60}
                              width={60}
                            />
                            <p className="mobile-profile-name d-inline my-auto">
                              {item?.name || '-'}
                            </p>
                          </div>
                          <div className="col-2 d-flex justify-content-end my-auto">
                            <img
                              src="/img/icon/ic_edit.svg"
                              className="icon-edit"
                              onClick={() => setEditProfile(item)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <hr className="hr-no-margin"/>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-12"
                            style={{textAlign: "center", cursor: "pointer"}}
                            onClick={() => navigate.push(`/history`, {
                              profilId: item.id,
                            })}
                          >
                            <img
                              src="/img/icon/ic_history.svg"
                              className="icon-edit"
                            />
                            <span className="font-w-700 font-s-14 ml-2" style={{color: "#00BD84"}}>Riwayat Janji Temu</span>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </>
                );
              }
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListProfile;
