import React from 'react';
import {
  IconCheckedDisbled,
  IconCheckedEnabled,
  IconDummyAvatar,
  IconFindDocuments,
  IconLoader,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { wordingResultDoctorList } from '../../../constants/wording';
import { formatRupiah } from '../../../helpers/formatDigit';
import Pagination from '../Components/Pagination';
import './ResultFindDoctor.scss';

const MobileListResultFindDoctor = ({
  data = [],
  setSchedule,
  serviceType,
  doctor,
  currentPage,
  PageSize,
  setCurrentPage,
  loading = true,
}) => {

  const renderContent = () => {
    switch (serviceType) {
      case 'JT':
        return renderConsultation()
      case 'TL' :
        return renderConsultation()
      case 'PM': 
        return renderMedicalCheckUp()
      default:
        return renderConsultation()
    }
  }

  const renderConsultation = () => {
    return(
      <div className="col-md-12 d-xl-none d-block px-0 mx-0">
        {data && data?.length ? (
          data?.map((item) => {
            return (
              <Card
                useInnerBorder
                className={`${
                  item?.buttonActionType !== 1 ? 'd-none' : ''
                } mb-3`}
              >
                <img
                  src={IconDummyAvatar}
                  alt="icon"
                  className="mx-auto d-flex"
                  height={60}
                  width={60}
                />
                <p className="mobile-doctor-name text-center my-1">
                  {item?.doctorName || '-'}
                </p>
                <div
                  style={{ width: '218px' }}
                  className="mx-auto d-flex mb-2"
                >
                  <Button
                    styles={{ fontSize: '16px' }}
                    type="outline-primary"
                    label="Lihat Profil Dokter"
                    onClick={() =>
                      setSchedule(
                        item?.doctorId,
                        item?.hospitalId,
                        item?.doctorName,
                        item?.doctorImageUrl,
                        item?.hospitalName,
                        item?.hospitalImageUrl,
                        item?.hospitalType,
                        item?.hospitalTimezone,
                        item?.specializationName,
                        item?.doctorHospitalId,
                        item?.specializationId,
                        item?.poliId,
                        item?.serviceFee,
                        '/doctor-detail'
                      )
                    }
                  />
                </div>
                <p className="text-center mobile-sub-title mt-3">
                  {item?.specializationName || '-'}
                </p>
                <p className="text-center mobile-sub-title">
                  {item?.hospitalName || '-'}
                </p>
                {serviceType === 'TL' && (
                  <p className="text-center mobile-sub-title">
                    Rp {formatRupiah(item?.serviceFee)}
                  </p>
                )}
                {item?.buttonActionType === 1 && serviceType !== 'TL' ? (
                  <div class="d-flex  justify-content-center justify-content-xl-between px-2 my-3">
                    {item?.regularChecked ? (
                      <div className="d-flex align-items-center consultation-type px-2">
                        <img
                          src={IconCheckedEnabled}
                          width="20"
                          height="20"
                          alt="Doctor"
                        />
                        <span className="enabled">Reguler</span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center consultation-type">
                        <img
                          src={IconCheckedDisbled}
                          width="20"
                          height="20"
                          alt="Doctor"
                        />
                        <span className="disabled">Reguler</span>
                      </div>
                    )}
                    {item?.bpjsChecked ? (
                      <div className="d-flex align-items-center consultation-type px-2">
                        <img
                          src={IconCheckedEnabled}
                          width="20"
                          height="20"
                          alt="Doctor"
                        />
                        <span className="enabled">Melayani BPJS</span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center consultation-type">
                        <img
                          src={IconCheckedDisbled}
                          width="20"
                          height="20"
                          alt="Doctor"
                        />
                        <span className="disabled">Melayani BPJS</span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div class="d-flex justify-content-center px-2 my-3">
                    {item?.teleChecked ? (
                      <div className="d-flex align-items-center consultation-type">
                        <img
                          src={IconCheckedEnabled}
                          width="20"
                          height="20"
                          alt="Doctor"
                        />
                        <span className="enabled">Telekonsultasi</span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center consultation-type">
                        <img
                          src={IconCheckedDisbled}
                          width="20"
                          height="20"
                          alt="Doctor"
                        />
                        <span className="disabled">Telekonsultasi</span>
                      </div>
                    )}
                  </div>
                )}
                <Button
                  label={
                    serviceType === 'TL'
                      ? wordingResultDoctorList.labelAddTele
                      : wordingResultDoctorList.labelAddReguler
                  }
                  iconSize="sm"
                  btnSize="sm"
                  type="primary"
                  onClick={() =>
                    setSchedule(
                      item?.doctorId,
                      item?.hospitalId,
                      item?.doctorName,
                      item?.doctorImageUrl,
                      item?.hospitalName,
                      item?.hospitalImageUrl,
                      item?.hospitalType,
                      item?.hospitalTimezone,
                      item?.specializationName,
                      item?.doctorHospitalId,
                      item?.specializationId,
                      item?.poliId,
                      item?.serviceFee
                    )
                  }
                />
              </Card>
            );
          })
        ) : (
          <div className="text-center mt-5" style={{ marginBottom: '14em' }}>
            <img src={IconFindDocuments} alt="icon" />
            <p
              className="text-white mt-3 text-center"
              style={{ fontSize: '18px' }}
            >
              Dokter tidak ditemukan
            </p>
          </div>
        )}
        {doctor ? (
          <Pagination
            className="pagination-bar d-flex justify-content-center"
            currentPage={currentPage}
            totalCount={doctor.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        ) : null}
      </div>
    )
  }

  const renderMedicalCheckUp = () => {
    return(
      <div className="col-md-12 d-xl-none d-block px-0 mx-0">
        {data && data?.length ? (
          data?.map((item) => {
            return (
              <Card
                useInnerBorder
                className={`${
                  item?.buttonActionType !== 1 ? 'd-none' : ''
                } mb-3`}
              >
                <img
                  src={IconDummyAvatar}
                  alt="icon"
                  className="mx-auto d-flex"
                  height={60}
                  width={60}
                />
                <p className="mobile-doctor-name text-center my-1">
                  {item?.doctorName || '-'}
                </p>
                <div
                  style={{ width: '218px' }}
                  className="mx-auto d-flex mb-2"
                >
                </div>

                  <p className="text-center mobile-sub-title mb-2">
                    Rp {formatRupiah(item?.serviceFee)}
                  </p>

                <Button
                  label="Pilih Paket"
                  iconSize="sm"
                  btnSize="sm"
                  type="primary"
                  onClick={() =>
                    setSchedule(
                      item?.doctorId,
                      item?.hospitalId,
                      item?.doctorName,
                      item?.doctorImageUrl,
                      item?.hospitalName,
                      item?.hospitalImageUrl,
                      item?.hospitalType,
                      item?.hospitalTimezone,
                      item?.specializationName,
                      item?.doctorHospitalId,
                      item?.specializationId,
                      item?.poliId,
                      item?.serviceFee
                    )
                  }
                />
              </Card>
            );
          })
        ) : (
          <div className="text-center mt-5" style={{ marginBottom: '14em' }}>
            <img src={IconFindDocuments} alt="icon" />
            <p
              className="text-white mt-3 text-center"
              style={{ fontSize: '18px' }}
            >
              Dokter tidak ditemukan
            </p>
          </div>
        )}
        {doctor ? (
          <Pagination
            className="pagination-bar d-flex justify-content-center"
            currentPage={currentPage}
            totalCount={doctor.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        ) : null}
        </div>
    )
  }


  return (
    <React.Fragment>
      {loading ? (
        <img
          src={IconLoader}
          alt="loader"
          className="mt-5 d-flex mx-auto d-xl-none d-block"
        />
      ) : (
        renderContent()
      )}
    </React.Fragment>
  );
};

export default MobileListResultFindDoctor;
