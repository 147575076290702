import axios from '../helpers/axios';
import { appointmentConstants } from './constants';

export const createAppointment = (form) => {
  return async (dispatch) => {
    dispatch({ type: appointmentConstants.CREATE_APPOINTMENT_REQUEST });
    const res = await axios.post(`/appointments/create`, form);
    if (res.data.status === 200) {
      dispatch({
        type: appointmentConstants.CREATE_APPOINTMENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: appointmentConstants.CREATE_APPOINTMENT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const createAppointmentPackage = (form) => {
  return async (dispatch) => {
    dispatch({ type: appointmentConstants.CREATE_APPOINTMENT_PACKAGE_REQUEST });
    const res = await axios.post(`/appointments/create/package-sales`, form);
    if (res.data.status === 200) {
      dispatch({
        type: appointmentConstants.CREATE_APPOINTMENT_PACKAGE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: appointmentConstants.CREATE_APPOINTMENT_PACKAGE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getDetailAppointment = (appointmentId) => {
  return async (dispatch) => {
    dispatch({ type: appointmentConstants.GET_DETAIL_APPOINTMENT_REQUEST });
    const res = await axios.get(`/appointments/detailV2/${appointmentId}`);
    if (res.status === 200) {
      dispatch({
        type: appointmentConstants.GET_DETAIL_APPOINTMENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: appointmentConstants.GET_DETAIL_APPOINTMENT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getDetailAppointmentPackage = (appointmentId) => {
  return async (dispatch) => {
    dispatch({ type: appointmentConstants.GET_DETAIL_APPOINTMENT_PACKAGE_REQUEST });
    const res = await axios.get(`/appointments/detail-package/${appointmentId}`);
    if (res.status === 200) {
      dispatch({
        type: appointmentConstants.GET_DETAIL_APPOINTMENT_PACKAGE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: appointmentConstants.GET_DETAIL_APPOINTMENT_PACKAGE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const checkIn = (form) => {
  return async (dispatch) => {
    dispatch({ type: appointmentConstants.CHECKIN_APPOINTMENT_REQUEST });
    const res = await axios.post(`/appointments/checkin`, form);
    if (res.data.status === 200) {
      dispatch({
        type: appointmentConstants.CHECKIN_APPOINTMENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: appointmentConstants.CHECKIN_APPOINTMENT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};
