import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createAppointment, uploadFKTLDocFile } from '../../../../actions';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCloseGreen,
  IconPlusWhite,
} from '../../../../assets/img/icon';
import Button from '../../../../components/Button';
import { SelectOptions } from '../../../../components/Input';
import ModalComp from '../../../../components/Modal';
import { documentBpjsTypes } from '../../../../constants/common';
import { wordingResultDoctorList } from '../../../../constants/wording';
import Layout from '../../Components/Layout';
import '../../PaymentMethod/paymentMethod.scss';
import MobileBPJS from './MobileBPJS';

moment.locale('id');

const BPJS = () => {
  /* Router DOM */
  const navigate = useHistory();

  /* Redux */
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const { loading } = useSelector((state) => state.appointment);

  /* Local State */
  const [userId, setUserId] = useState(window.localStorage.getItem('userId'));
  const [fullName, setFullName] = useState(
    window.localStorage.getItem('fullName') != 'null'
      ? window.localStorage.getItem('fullName')
      : ''
  );
  const [firstName, setFirstName] = useState(
    window.localStorage.getItem('firstName') != 'null'
      ? window.localStorage.getItem('firstName')
      : ''
  );
  const { state } = useLocation();

  if (!state) window.location = '/patient';

  const hospitalName = state.appointmentData
    ? state.appointmentData.hospitalName
    : '';
  var hospitalType = state.appointmentData
    ? state.appointmentData.hospitalType
    : '';

  state.appointmentData.bpjsNo = state.appointmentData
    ? state.appointmentData.bpjsNo
    : null;
  state.appointmentData.bpjsDoc = state.appointmentData
    ? state.appointmentData.bpjsDoc
    : null;
  const [appointmentData, setAppointmentData] = useState(state.appointmentData);

  const [FKTLDoc, setFKTLDoc] = useState([]);
  const [docType, setDocType] = useState();
  const [FKTLDocModal, setFKTLDocModal] = useState(false);

  const [informationModal, setInformationModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [errorRequired, setErrorRequired] = useState([]);

  const dispatch = useDispatch();

  const prevPageUrl = '/patient';

  useEffect(() => {
    var urlFKTLDoc = paymentMethod.urlFKTLDoc
      ? paymentMethod.urlFKTLDoc.url
      : '';

    if (urlFKTLDoc) {
      setFKTLDoc([
        ...FKTLDoc,
        {
          id: FKTLDoc.length + 1,
          docType: docType,
          docUrl: urlFKTLDoc,
        },
      ]);

      handleCloseModal();
      var removeError = errorRequired.filter((val) => val != 'FKTLDoc');
      setErrorRequired(removeError);
    }
  }, [paymentMethod.urlFKTLDoc]);

  useEffect(() => {
    setAppointmentData({ ...appointmentData, FKTLDoc });
  }, [FKTLDoc]);

  const handleConfirmationModal = () => {
    if (hospitalType == '2') {
      if (appointmentData.payer_no && FKTLDoc.length > 0) {
        setInformationModal(true);
      } else {
        var errorData = [];
        if (!appointmentData.payer_no) {
          errorData.push('payer_no');
        }

        if (FKTLDoc.length <= 0) {
          errorData.push('FKTLDoc');
        }

        setErrorRequired(errorData);
      }
    } else {
      if (appointmentData.payer_no) {
        setInformationModal(true);
      } else {
        setErrorRequired('payer_no');
      }
    }
  };

  const renderInformationModal = () => {
    return (
      <ModalComp
        title="Informasi Untuk Anda"
        show={informationModal}
        setShow={() => {
          setInformationModal(false);
          setConfirmationModal(true);
          setFKTLDocModal(false);
          setDocType('');
        }}
        size="sm"
        buttonSubmitLabel="Tutup"
      >
        <Row style={{ textAlign: 'center', fontSize: '16px', padding: '50px' }}>
          <p>
            Halo {firstName}, <br />
            Jaminan ini hanya berlaku sementara untuk melanjutkan pesanan kamu.
            Sebelum konsultasi, pihak Klinik akan melakukan verifikasi jaminan
            kamu dan jika tidak lolos verifikasi atau ada potensi ekses biaya,
            pihak klinik akan menginformasikannya saat anda melakukan registrasi
            di klinik.
          </p>
        </Row>
      </ModalComp>
    );
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setFKTLDocModal(false);
    setDocType('');
  };

  const actionCreateAppointment = () => {
    const form = new FormData();

    var userPhone = window.localStorage.getItem('phone');
    var config = JSON.parse(window.localStorage.getItem('headerData'));

    form.append('doctor_hospital_id', appointmentData.doctor_hospital_id);
    form.append('specialization_id', appointmentData.specialization_id);
    form.append('poli_id', appointmentData.poli_id);
    form.append('hospitalGroupId', config ? config.klinik_group_id : null);
    form.append('userPhone', userPhone);
    form.append('identity_type', state.selectedPatient.identity_type);
    form.append('identity_name', state.selectedPatient.identity_name);
    form.append('identity_number', state.selectedPatient.identity_number);
    form.append('name', state.selectedPatient.name);
    form.append('gender', state.selectedPatient.gender);
    form.append('dob', state.selectedPatient.dob);
    form.append('phone_1', state.selectedPatient.phone_1);
    form.append('email', state.selectedPatient.email);
    form.append('relation', state.selectedPatient.relationname);
    form.append('user_id', userId);
    form.append('hospital_id', appointmentData.hospital_id);
    form.append('doctor_id', appointmentData.doctor_id);
    form.append('schedule_id', appointmentData.doctor_schedule_id);
    form.append('doctor_schedule_id', appointmentData.doctor_schedule_id);

    if (
      state.selectedPatient.id != null &&
      state.selectedPatient.id != undefined &&
      state.selectedPatient.id != ''
    ) {
      form.append('profile_id', state.selectedPatient.id);
    }

    form.append(
      'date',
      moment(appointmentData.date, 'DDMMYYYY').format('YYYY-MM-DD')
    );
    form.append('from_time', appointmentData.from_time);
    form.append('to_time', appointmentData.to_time);
    form.append('note', appointmentData.note);

    if (appointmentData.medicalDoc) {
      form.append(
        'medical_doc',
        appointmentData.medicalDoc?.length
          ? JSON.stringify(appointmentData.medicalDoc)
          : ''
      );
    }

    form.append('payer', 'BP');
    form.append('payerClinicsId', appointmentData.hospitalPayer[0].id);
    form.append('insuranceClinicsId', 0);

    if (appointmentData.payer_name) {
      form.append('payer_name', appointmentData.payer_name);
    }

    if (appointmentData.payer_no) {
      form.append('payer_no', appointmentData.payer_no);
    }

    if (appointmentData.payer_owner_name) {
      form.append('payer_owner_name', appointmentData.payer_owner_name);
    }

    if (FKTLDoc.length != 0) {
      form.append('payer_doc', JSON.stringify(FKTLDoc));
    }

    form.append('complaints', appointmentData.complaints);
    form.append('firstName', firstName);
    form.append('status', 85);
    form.append('isCMS', false);
    form.append('scheduleType', appointmentData.scheduleType);
    form.append('consultationType', appointmentData.consultation_type);
    form.append('serviceType', appointmentData.serviceType);

    if (appointmentData.is_waiting_list) {
      form.append('is_waiting_list', appointmentData.is_waiting_list);
    }

    dispatch(createAppointment(form))
      .then((response) => {
        const datas = response.data;
        if (datas.data) {
          setTimeout(
            () =>
              Swal.fire({
                icon: 'success',
                title: response.message,
                showConfirmButton: true,
                confirmButtonText: 'OK',
              }).then(() => {
                if (datas.userData) {
                  localStorage.setItem('firstName', datas.userData.firstName);
                  localStorage.setItem('fullName', datas.userData.fullName);
                }
                if (datas.data) {
                  window.location = '/appointment-created/' + datas.data.id;
                }
              }),
            200
          );
        } else {
          return Swal.fire({
            icon: 'error',
            text: response.message,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: 'error',
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: 'error',
            text: err.message,
          });
        }
      });
  };

  const renderConfirmationModal = () => {
    return (
      <ModalComp
        title="Konfirmasi Data"
        show={confirmationModal}
        setShow={handleCloseModal}
        buttonSubmitLabel="Buat Janji Temu"
        buttonCloseLabel="Tutup"
        useButtonClose
        onSubmit={actionCreateAppointment}
        size="md"
        submitLoading={loading}
      >
        <p className="font16 text-center text-dark font-weight-normal">
          Halo {fullName}, <br />
          pastikan data yang Anda masukkan telah sesuai.
        </p>
        <div className="paymentMethodConfirmation">
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Jenis Identitas :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.identity_name}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Nomor Identitas :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.identity_number}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Nama :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.name}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Tanggal Lahir :
              </label>
            </Col>
            <Col md style={{ maxWidth: '60%' }}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {moment(state.selectedPatient.dob).format('D MMMM YYYY')}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Nomor HP :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {'+62' + state.selectedPatient.phone_1}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Email :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.selectedPatient.email}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Jenis Kelamin :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {' '}
                {state.selectedPatient.gender == 1 ? 'Laki-Laki' : 'Perempuan'}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Nama Dokter :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.appointmentData.doctorName}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Klinik :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {state.appointmentData.hospitalName}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Tanggal :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {' '}
                {moment(state.appointmentData.date, 'DDMMYYYY').format(
                  'dddd, D MMMM YYYY'
                )}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Waktu :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                {' '}
                {state.appointmentData.from_time +
                  ' - ' +
                  state.appointmentData.to_time +
                  ' ' +
                  state.appointmentData.timezone}{' '}
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label className='label-confirmation-left'>
                Jenis Jaminan :
              </label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <label style={{ display: 'inline', fontSize: '16px' }}>
                BPJS
              </label>
            </Col>
          </Row>
        </div>
        <label
          style={{
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: 'normal',
            wordWrap: 'break-word',
          }}
        >
          dengan melanjutkan proses reservasi ini, Anda telah menyetujui
          <a href="/tnc" target="_blank" style={{ color: '#00BD84' }}>
            {' '}
            Syarat dan Ketentuan{' '}
          </a>
          penggunaan dan kebijakan privasi janji temu {hospitalName}
        </label>
      </ModalComp>
    );
  };

  //upload dokumen bpjs
  const uploadDocumment = async () => {
    if (!docType) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tipe Dokumen',
      });
    } else {
      const uploadfktldoc = document.getElementById('uploadfktldoc').files;
      dispatch(uploadFKTLDocFile(uploadfktldoc[0], docType?.value));
    }
  };

  const handleBPJSDocModal = () => {
    setFKTLDocModal(true);
  };

  const renderBPJSDocModal = () => {
    return (
      <ModalComp
        title="Tambah Dokumen BPJS"
        show={FKTLDocModal}
        setShow={handleCloseModal}
        size="sm"
        buttonSubmitLabel="Simpan"
        onSubmit={handleCloseModal}
        submitLoading={paymentMethod?.loading}
      >
        <div style={{ height: '120px' }}>
          <SelectOptions
            isClearable
            isSearchable
            placeholder="Pilih tipe dokumen"
            customIcon={IconArrowGrey}
            iconClassName="mr-2"
            setValue={setDocType}
            value={docType}
            options={documentBpjsTypes}
            fontSize="lg"
          />
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <input
                type="file"
                id="uploadfktldoc"
                style={{ display: 'none' }}
                onChange={uploadDocumment}
              />
              <label
                htmlFor="uploadfktldoc"
                className="label-file-upload"
                style={{ paddingBottom: '30px' }}
              >
                <img src={IconPlusWhite} alt="icon" /> Unggah
              </label>
            </Col>
          </Row>
        </div>
      </ModalComp>
    );
  };

  return (
    <Layout>
      <div className="content-payment-method col-12 mt-5 d-xl-block d-none">
        <Row className="col-5">
          <div
            className="cursor-pointer d-flex flex-row"
            onClick={() =>
              navigate.push({
                pathname: prevPageUrl,
                state: {
                  selectedPatient: state.selectedPatient,
                  appointmentData: appointmentData,
                },
              })
            }
          >
            <img src={IconArrowGreen} alt="icon" />
            <p className="title-page ml-2 text-white">Data Pasien</p>
          </div>
        </Row>
        <Row>
          <div className="col-3 mt-5 ml-5">
            <div className="nav-step">
              <button className="circle-btn-step">1</button>
              <span className="label-step">Jadwal</span>
            </div>
            <div className="nav-step">
              <button className="circle-btn-step">2</button>
              <span className="label-step">Data Pasien</span>
            </div>
            <div className="nav-step">
              <button className="circle-btn-step activePage">3</button>
              <span className="label-step">
                <b>Pembayaran (BPJS)</b>
              </span>
            </div>
          </div>
          <div className="col-8">
            <div className="content-paymentMethod-step">
              <h1>Jaminan</h1>
              <h2>Silahkan masukkan nomor peserta BPJS kesehatan Anda</h2>
              <div className="find-insurance">
                <div className="choose-paymentType col-13 mx-auto text-left">
                  <b className="text-dark">Nomor Peserta BPJS Kesehatan</b>
                  <div className="paymentType-card col-13 mx-auto">
                    <Form.Control
                      type="number"
                      className={
                        errorRequired.find((val) => val == 'payer_no')
                          ? 'errorRequired'
                          : ''
                      }
                      placeholder="Nomor BPJS Kesehatan"
                      value={appointmentData.payer_no}
                      onChange={(e) => {
                        if (e.target.value.length <= 13) {
                          setAppointmentData({
                            ...appointmentData,
                            payer_no: e.target.value,
                          });

                          if (e.target.value) {
                            e.target.className = 'form-control';
                            var removeError = errorRequired.filter(
                              (val) => val != 'payer_no'
                            );
                            setErrorRequired(removeError);
                          } else {
                            e.target.className = 'form-control errorRequired';
                            setErrorRequired([...errorRequired, 'payer_no']);
                          }
                        }
                      }}
                    />
                    {errorRequired.find((val) => val == 'payer_no') ? (
                      <h6 className="span-error">
                        <i className="fa fa-warning" /> Nomor Peserta BPJS
                        Kesehatan harus diisi
                      </h6>
                    ) : (
                      ''
                    )}
                    <h5 style={{fontSize:'14px'}}>
                      *Pastikan BPJS Anda dalam status aktif dan tidak menunggak
                    </h5>
                  </div>
                  {hospitalType == '1' ? (
                    <></>
                  ) : (
                    <Form.Group className="col-13 mx-auto text-left">
                      <b className="text-dark">Unggah Dokumen BPJS</b>
                      <div className="w-50">
                        <Button
                          className="mt-2"
                          label="Tambah Dokumen"
                          icon={IconPlusWhite}
                          iconPosition="left"
                          type="primary-blue"
                          onClick={handleBPJSDocModal}
                        />
                      </div>
                      {errorRequired.find((val) => val == 'FKTLDoc') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Dokumen BPJS Kesehatan
                          harus diisi
                        </h6>
                      ) : (
                        ''
                      )}
                      <div
                        style={{
                          marginTop: '5px',
                          padding: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {FKTLDoc.length > 0 ? (
                          FKTLDoc.map((data, index) => (
                            <Row key={'row' + index}>
                              <Col md style={{ width: '5px', maxWidth: '3%' }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    color: '#00BD84',
                                    marginRight: '5px',
                                  }}
                                />
                              </Col>
                              <Col
                                md
                                style={{ width: '10px', maxWidth: '57%' }}
                              >
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {data
                                    ? data.docUrl.split('/').length == 6
                                      ? data.docUrl.split('/')[5]
                                      : ''
                                    : ''}
                                </span>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  &nbsp;
                                  {data?.docType === 'RujukanFKTL'
                                    ? 'Kartu Rujukan'
                                    : data?.docType === 'Lainnya'
                                    ? 'Dokumen Lainnya'
                                    : 'Berhasil Terunggah!'}
                                </span>
                              </Col>
                              <Col
                                md
                                style={{ width: '30px', maxWidth: '30%' }}
                              >
                                <a
                                  href={data.docUrl}
                                  target="_blank"
                                  style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: '#00BD84',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Lihat Dokumen
                                </a>
                              </Col>
                              <Col md>
                                <img
                                  src={IconCloseGreen}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setFKTLDoc(
                                      FKTLDoc.filter((e) => e.id !== data.id)
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </Form.Group>
                  )}
                </div>
              </div>
              <div className="btn-prev-next">
                <Button
                  label={wordingResultDoctorList.labelAddReguler}
                  type="primary"
                  onClick={handleConfirmationModal}
                />
              </div>
            </div>
          </div>
        </Row>
        {renderConfirmationModal()}
      </div>
      <MobileBPJS
        state={state}
        prevPageUrl={prevPageUrl}
        appointmentData={appointmentData}
        setAppointmentData={setAppointmentData}
        errorRequired={errorRequired}
        setErrorRequired={setErrorRequired}
        hospitalType={hospitalType}
        FKTLDoc={FKTLDoc}
        handleBPJSDocModal={handleBPJSDocModal}
        handleConfirmationModal={handleConfirmationModal}
        setMedicalDoc={setFKTLDoc}
      />
      {renderBPJSDocModal()}
      {renderInformationModal()}
    </Layout>
  );
};

export default BPJS;
