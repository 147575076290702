import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import { InputText, SelectOptions } from "../../../components/Input";
import { IconArrowGrey, IconSearchGreen } from "../../../assets/img/icon";
import { getFaskes } from "../../../actions";
import { getPackage } from "../../../actions/package/package.actions";
import queryString from "query-string";
import "./index.scss";

const ProductPackage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const productPackage = useSelector((state) => state.productPackage);

  const par = localStorage.headerData
    ? JSON.parse(localStorage.headerData)
    : "";

  const [faskes, setFaskes] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [search, setSearch] = useState({
    clinicId: null,
    key: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    totalData: null,
  });
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    dispatch(getFaskes(par.klinik_group_id)).then((response) => {
      setFaskes(response);
      setPackageData([]);

      if (response?.data?.length) {
        let firstData = response.data[0];

        let values = {
          value: firstData.id,
          label: firstData.name,
        };

        setClinic(values);

        setSearch({
          ...search,
          clinicId: firstData.id,
        });
      }
    });
  }, []);

  useEffect(() => {
    setPackageData([...packageData, ...productPackage.packageData]);
    setPagination({
      ...pagination,
      totalData: productPackage.packageTotalData,
    });
  }, [productPackage.packageData]);

  useEffect(() => {
    if (search.clinicId) {
      let body = {
        hospitalId: search.clinicId,
        status: true,
        isOnline: true,
        key: search.key,
        page: pagination.page,
        limit: pagination.limit,
      };

      let query = queryString.stringify(body);

      dispatch(getPackage(query));
    }
  }, [search, pagination.page]);

  const toIdr = (value, locale = "id-Id") => {
    if (!value || isNaN(value)) return 0;

    const currency = new Intl.NumberFormat(locale);

    return currency.format(value);
  };

  const handleSeeMore = () => {
    setPagination({
      ...pagination,
      page: Number(pagination.page) + 1,
    });
  };

  return (
    <LayoutV2
      header={true}
      headerGlobal={true}
      footer={true}
      footerCopyright={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col lg={6}>
          <span className="font-s-36 font-w-700 cl-dark-blue">
            Pilih Paket Produk
          </span>
        </Col>
        <Col className="align-s-c" lg={6}>
          <Row>
            <Col lg={6} className="margin-b-16">
              <SelectOptions
                borderColor="#CCCCCC"
                placeholder="Pilih klinik"
                fontSize="lg"
                options={faskes?.data?.map((e) => {
                  return {
                    ...e,
                    value: e?.id,
                    label: e?.name,
                  };
                })}
                setValue={(data) => {
                  setClinic(data);
                  setSearch({
                    ...search,
                    clinicId: data.value,
                  });
                  setPackageData([]);
                  setPagination({
                    ...pagination,
                    page: 1,
                    totalData: 0,
                  });
                }}
                value={clinic}
                color="#212121"
                background="#FFFFFF"
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />
            </Col>

            <Col lg={6} className="margin-b-16">
              <InputText
                placeholder="Cari paket produk"
                value={search.key}
                icon={IconSearchGreen}
                iconPosition="right"
                size="lg"
                setValue={(data) => {
                  setSearch({
                    ...search,
                    key: data,
                  });
                  setPackageData([]);
                  setPagination({
                    ...pagination,
                    page: 1,
                    totalData: 0,
                  });
                }}
                type="text"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="margin-b-16">
        {packageData.length ? (
          packageData.map((data, index) => (
            <Col lg={4} className="margin-b-32" key={index}>
              <div
                className="card-product-package"
                onClick={() =>
                  history.push({
                    pathname: `/product-package/detail/${data.uuid}`,
                    state: {
                      clinicId: clinic.value,
                      clinicName: clinic.label,
                    },
                  })
                }
              >
                <img
                  src={
                    data.image
                      ? data.image
                      : "/img/logo/logo-package-default.svg"
                  }
                  className="card-product-package-image"
                />

                <div className="d-flex flex-column text-center margin-b-16">
                  <span className="font-s-14 font-w-500">{data.name}</span>
                  <span className="font-s-14 font-w-700 cl-green">
                    {`Rp${toIdr(data.price)}`}
                  </span>
                </div>

                <button className="button-green-56">Pilih Paket</button>
              </div>
            </Col>
          ))
        ) : (
          <Col xs={12} className="margin-b-32 d-flex justify-content-center">
            <img src="/img/icon/icon-empty.svg" />
          </Col>
        )}
      </Row>

      {packageData.length === pagination.totalData ? null : (
        <Row className="margin-b-16">
          <Col xs={12} className="d-flex justify-content-center cursor-point">
            <span
              className="font-s-16 font-w-700 cl-green"
              onClick={() => handleSeeMore()}
            >
              Lihat Lebih Banyak
            </span>
          </Col>
        </Row>
      )}
    </LayoutV2>
  );
};

export default ProductPackage;
