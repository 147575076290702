import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconDummyAvatar, IconPlusWhite } from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { formatDate } from '../../../helpers/utils';
import MobileListProfile from './MobileListProfile';

const ListProfile = ({
  data = [],
  currentPage,
  totalPage,
  setEditProfile,
  setAddProfile,
  setCurrentPage,
  logOut,
}) => {
  /* Router DOM */
  const navigate = useHistory();

  // const formatDob = (dob = null) => {
  //   const currentDate = new Date();
  //   const userDob = new Date(dob);
  //   const year = currentDate.getFullYear() - userDob.getFullYear();
  //   const month = currentDate.getMonth() - userDob.getMonth();
  //   const day = currentDate.getDate() - userDob.getDate();
  //   if (dob == null) {
  //     return `-`;
  //   } else {
  //     return `${year - 1} Tahun ${12 + month} Bulan ${day} Hari`;
  //   }
  // };
  return (
    <React.Fragment>
      {/* Mobile View */}
      <MobileListProfile
        setAddProfile={setAddProfile}
        data={data}
        setEditProfile={setEditProfile}
        logOut={logOut}
      />

      {/* Desktop View */}
      <div className="d-none d-xl-block" style={{ marginBottom: '25em' }}>
        <p className="title-card mt-0 mb-2 text-center text-white">
          Daftar Profil
        </p>
        <div className="d-flex justify-content-end float-right">
          <div className="mr-1" style={{ width: '300px' }}>
            <Button
              label="Tambah Anggota"
              icon={IconPlusWhite}
              iconPosition="right"
              iconSize="sm"
              btnSize="md"
              disable={false}
              type="primary"
              onClick={() => setAddProfile()}
            />
          </div>
          <div className="ml-1" style={{ width: '150px' }}>
            <Button
              label="Keluar"
              iconSize="sm"
              btnSize="md"
              disable={false}
              type="primary-blue"
              onClick={logOut}
            />
          </div>
        </div>
        <br />
        <div className="mt-5 w-100">
          {data?.length &&
            data.map((item, index) => {
              return (
                <Card key={index} useInnerBorder className="mb-3">
                  <div className="row">
                    <div className="col-5">
                      <img
                        src={IconDummyAvatar}
                        alt="icon"
                        className="d-inline my-auto mr-3"
                        height={60}
                        width={60}
                      />
                      <p className="mobile-profile-name d-inline my-auto">
                        {item?.name || '-'}
                      </p>
                    </div>
                    <div className="col-3 my-auto">
                      <p className="mobile-profile-dob">
                        {formatDate(item?.dob, false) || '-'}
                      </p>
                      <p className="mobile-profile-dob">{item?.email || '-'}</p>
                    </div>
                    <div className="col-4 d-flex float-right">
                      <Button
                        label="Ubah"
                        className="mr-2 font-weight-normal my-auto"
                        onClick={() => setEditProfile(item)}
                      />
                      <Button
                        label="Riwayat"
                        className="ml-2 font-weight-normal my-auto"
                        onClick={() =>
                          navigate.push(`/history`, {
                            profilId: item.id,
                          })
                        }
                      />
                    </div>
                  </div>
                </Card>
              );
            })}
        </div>
        {/* Temporary Comment
        <CutomPagination
          currentPage={currentPage}
          totalPage={totalPage}
          totalRows={data?.length}
          setPage={setCurrentPage}
        /> */}
      </div>
    </React.Fragment>
  );
};

export default ListProfile;
