export const wordingFindDoctor = {
  titleTele: 'Telekonsultasi',
  titleReguler: 'Janji Temu Dokter',
};

export const wordingResultDoctorList = {
  labelAddTele: 'Buat Telekonsultasi',
  labelAddReguler: 'Buat Janji Temu',
};

export const wordingDoctorSchedule = {
  titleTele: 'Jadwal Telekonsultasi',
  titleReguler: 'Jadwal Janji Temu',
};

export const wordingHopitalType = {
  1: 'Faskes Tingkat Pertama',
  2: 'Faskes Tingkat Lanjut',
};

export const wordingGender = {
  1: 'Laki-laki',
  2: 'Perempuan',
};
