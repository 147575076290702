import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getDetailAppointment } from "../../../actions";
import LayoutV2 from "../Components/LayoutV2";
import "moment/locale/id";
import Swal from "sweetalert2";

moment.locale("id");

const OrderDoneV2 = () => {
  var { id } = useParams();

  const firstName =
    localStorage.getItem("firstName") != "null"
      ? localStorage.getItem("firstName")
      : "";
  const appointment = useSelector((state) => state.appointment);
  const [appointmentData, setAppointmentData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetailAppointment(id));
  }, [id]);

  useEffect(() => {
    if (appointment.appointmentDetail) {
      if (appointment.appointmentDetail.length != 0) {
        var appointmentDetail = appointment.appointmentDetail[0];

        setAppointmentData({
          code: appointmentDetail.code,
          patientName: appointmentDetail.profilename,
          appointmentDate: moment(appointmentDetail.date).format(
            "dddd, D MMMM YYYY"
          ),
          appointmentDateOR: moment(appointmentDetail.date).format(
            "YYYY-MM-DD"
          ),
          fromTime: appointmentDetail.from_time,
          toTime: appointmentDetail.to_time,
          doctorName: appointmentDetail.doctorname,
          hospitalName: appointmentDetail.hospitalname,
          scheduleType: appointmentDetail.schedule_type,
          registnumber: appointmentDetail.registrationNumber,
          registid: appointmentDetail.registration_id,
          consultationType: appointmentDetail.consultationtype,
          status: appointmentDetail.orderstatus,
          zoomUrl: appointmentDetail.zoomurl,
          zoomPin: appointmentDetail.zoompin,
          meetingId: appointmentDetail.meetingid,
          linkPayment: appointmentDetail.linkPayment,
          poliname: appointmentDetail.poliname,
          noAntrian: appointmentDetail.no,
        });
      }
    } else {
      window.location = "/home";
    }
  }, [appointment.appointmentDetail]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
  });

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16 d-flex justify-content-center">
        <Col xs={12} lg={10}>
          <div className="card-patient-v3">
            <Row className="margin-b-16">
              <Col className="d-flex justify-content-center">
                {appointmentData.status == 85 ||
                appointmentData.status == 80 ? (
                  <span className="font-s-16 font-w-700 font-w-500">
                    {`Halo ${appointmentData.patientName}, Pemesanan Anda berhasil!`}
                  </span>
                ) : null}

                {appointmentData.status == 81 && (
                  <span className="font-s-16 font-w-700 font-w-500">
                    {`Halo ${appointmentData.patientName}, Anda berhasil Check In`}
                  </span>
                )}
              </Col>
            </Row>

            <Row className="margin-b-16">
              <Col className="d-flex justify-content-center">
                <img
                  className="image"
                  src="/img/icon/success.png"
                  alt="logo"
                  height="80px"
                  width="80px"
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-center margin-b-16 font-s-14">
              <Col xs={12} lg={6}>
                {appointmentData.status == 81 && (
                  <Row>
                    <Col xs={6} lg={6}>
                      <div>
                        <span>Nomor Antrian</span>
                      </div>
                    </Col>
                    <Col xs={6} lg={6}>
                      <span>:</span>&nbsp;
                      <span className="font-w-700">
                        {appointmentData.noAntrian || "-"}
                      </span>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={6} lg={6}>
                    <div>
                      <span>Nomor Reservasi</span>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <span>:</span>&nbsp;
                    <span className="font-w-700">
                      {appointmentData.registnumber || "-"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} lg={6}>
                    <div>
                      <span>Nama Pasien</span>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <span>:</span>&nbsp;
                    <span className="font-w-700">
                      {appointmentData.patientName || "-"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} lg={6}>
                    <div>
                      <span>Jadwal Kunjungan</span>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <span>:</span>&nbsp;
                    <span className="font-w-700">
                      {appointmentData.appointmentDate || "-"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} lg={6}>
                    <div>
                      <span>Jam</span>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <span>:</span>&nbsp;
                    <span className="font-w-700">
                      {appointmentData.fromTime
                        ? appointmentData.fromTime +
                          " - " +
                          appointmentData.toTime
                        : "-"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} lg={6}>
                    <div>
                      <span>Dokter</span>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <span>:</span>&nbsp;
                    <span className="font-w-700">
                      {appointmentData.doctorName || "-"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} lg={6}>
                    <div>
                      <span>Klinik</span>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <span>:</span>&nbsp;
                    <span className="font-w-700">
                      {appointmentData.hospitalName || "-"}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="text-center">
                {appointmentData.status == 85 ||
                appointmentData.status == 80 ? (
                  <span className="font-s-14 font-w-500">
                    Pastikan Anda hadir di klinik pada jadwal yang telah
                    ditentukan.
                  </span>
                ) : null}

                {appointmentData.status == 81 && (
                  <span className="font-s-14 font-w-500">
                    Anda telah melakukan check in secara mandiri. Silahkan
                    langsung menuju poli untuk melakukan konsultasi
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="text-center">
                <span className="font-s-14 font-w-500">
                  Saya menyetujui{" "}
                  <span
                    className="font-w-700 cl-green cursor-point"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("/terms-and-conditions");
                    }}
                  >
                    Syarat dan Ketentuan
                  </span>{" "}
                  dan{" "}
                  <span
                    className="font-w-700 cl-green cursor-point"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("/privacy-policy");
                    }}
                  >
                    Kebijakan Privasi
                  </span>{" "}
                  janji temu.
                </span>
              </Col>

              <Col xs={12} className="text-center">
                <span className="font-s-14 font-w-500">
                  Terima kasih telah memilih kami sebagai Rumah Sakit pilihan
                  Anda, kami akan menghubungi Anda melalui email/SMS
                </span>
              </Col>
            </Row>

            <Row>
              <Col>
                <hr />
              </Col>
            </Row>

            <Row className="margin-b-32">
              <Col>
                <div className="text-center">
                  <span className="font-s-14 font-w-500 margin-r-8">
                    Bagikan
                  </span>

                  <img
                    src="/img/icon/icon-wa.svg"
                    className="margin-r-8 cursor-point"
                    style={{
                      borderRadius: "50px",
                      boxShadow: "0px 2px 4px 0px #D4D4D4",
                    }}
                    onClick={() =>
                      window.open(
                        "whatsapp://send?text=" +
                          window.location.origin +
                          "/appointment-created/" +
                          id
                      )
                    }
                  />
                  <img
                    src="/img/icon/icon-link.svg"
                    className="cursor-point"
                    style={{
                      borderRadius: "50px",
                      boxShadow: "0px 2px 4px 0px #D4D4D4",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window.location.origin + "/appointment-created/" + id
                      );
                      Toast.fire({
                        icon: "info",
                        title: "Berhasil menyalin link URL",
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  label="Selesai"
                  type="primary"
                  onClick={() => {
                    window.location = "/home";
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </LayoutV2>
  );
};

export default OrderDoneV2;
