import { render } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFaskes } from '../../../actions';
import {
  IconArrowGrey,
  IconCrossRed,
  IconNurseBlurBottom,
  IconSearch,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import { InputText, SelectOptions } from '../../../components/Input';
import ModalComp from '../../../components/Modal';
import { wordingFindDoctor } from '../../../constants/wording';
import Layout from '../Components/Layout';
import './FindDoctor.scss';
import MobileFindDoctor from './MobileFindDoctor';

function FindDoctor(props) {
  /* Redux */
  const dispatch = useDispatch();

  /* Local State */
  const [showError, setShowError] = useState(false);
  const [faskes, setFaskes] = useState([]);
  const [clinic, setClinic] = useState();
  const [doctorName, setDoctorName] = useState();
  const [search, setSearch] = useState({
    hospital_id: [],
    name_doctor: '',
    serviceType:
      props.location.state?.length > 0
        ? props.location.state[0].serviceType
        : '',
    serviceName:
      props.location.state?.length > 0
        ? props.location.state[0].serviceName
        : '',
    titleList:
      props.location.state?.length > 0
        ? props.location.state[0].listName
        : '',
    listUrl:
      props.location.state?.length > 0
        ? props.location.state[0].url
        : '',
  });

  const par = localStorage.headerData
  ? JSON.parse(localStorage.headerData)
  : '';

  useEffect(() => {
    // console.log("serach", search);
    let newSearch = search;
    newSearch['hospital_group_id'] = par.klinik_group_id;
    setSearch(newSearch);
    dispatch(getFaskes(par.klinik_group_id)).then((response) => {
      setFaskes(response);
    });
  }, []);

  const history = useHistory();

  const handleNameDoctor = (event) => {
    let newSearch = search;
    newSearch['name_doctor'] = event.target;
    setSearch(newSearch);
  };

  const handleFaskes = (event) => {
    let temp = [];
    temp.push(event.target.value);
    let newSearch = search;
    newSearch['hospital_id'] = temp;
    setSearch(newSearch);
  };

  const handleSearchDoctor = (payload) => {

    
    const pilihFaskes = payload?.clinic || clinic?.value;
    const searchName = payload?.name || doctorName;
    if (!pilihFaskes) {
      let newSearch = search;
      const val = [];
      if (faskes && faskes.length > 0) {
        faskes.data.forEach((data) => {
          val.push(data.id);
        });
      }
      newSearch['hospital_id'] = val;
      setSearch(newSearch);
    }
    
    if (pilihFaskes || searchName) {
      const url = search.listUrl
      history.push(`../list/${url}`, [
        { ...search, name_doctor: searchName },
      ]);
    } else {
      return setShowError(true);
    }
  };

  const renderContent = () => {
    switch (search.serviceType) {
      case 'JT':
        return renderConsultation()
      case 'TL' :
        return renderConsultation()
      case 'PM': 
        return renderMedicalCheckUp()
      default:
        return renderConsultation()
    }
  }

  const renderConsultation =() => {
    return(
      <div
      className="content-janji-temu d-xl-block d-none"
      style={{ marginBottom: '8em' }}
      >
        <Col xs="12" md="12" lg="9" xl="9" className="appointment">
          <Row>
            <Col md="12">
              <Row>
                <div className="title">
                  <h1 className="pl-4">
                    {/* {search.serviceType === 'TL'
                      ? wordingFindDoctor.titleTele
                      : wordingFindDoctor.titleReguler} */}
                    {search.serviceName}
                  </h1>
                </div>
              </Row>
            </Col>
            <Col md="7">
              <div className="find-dokter row mt-4">
                <div className="col-md-5">
                  <InputText
                    placeholder="Ketik nama dokter"
                    value={doctorName}
                    icon={IconSearch}
                    iconPosition="right"
                    size='lg'
                    setValue={(value) => {
                      const result = value.replace(/[^A-Za-z ]/gi, '');
                      const formatPayload = {
                        target: result,
                      };
                      setDoctorName(result);
                      handleNameDoctor(formatPayload);
                    }}
                    type="text"
                    iconOnClick={handleSearchDoctor}
                    enterOnPress={handleSearchDoctor}
                  />
                </div>
                <div className="col-md-4">
                  <SelectOptions
                    borderColor="white"
                    placeholder="Pilih klinik"
                    fontSize="lg"
                    options={faskes.data?.map((e) => {
                      return {
                        ...e,
                        value: e?.id,
                        label: e?.name,
                      };
                    })}
                    setValue={(value) => {
                      const formatPayload = {
                        target: value,
                      };
                      setClinic(value);
                      handleFaskes(formatPayload);
                    }}
                    value={clinic}
                    color="#757575"
                    background="white"
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </div>
                <div className="col-md-3">
                  <Button
                    label="Cari"
                    disable={false}
                    type="primary"
                    onClick={handleSearchDoctor}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <>
        <img className="image-dokter" src={"https://aido-website.s3.ap-southeast-1.amazonaws.com/booking-app/dokter-desktop-2.png"} />
        </>
      </div>
    )
  }

  const renderMedicalCheckUp =() => {
    return(
      <div
      className="content-janji-temu d-xl-block d-none"
      style={{ marginBottom: '8em' }}
      >
        <Col xs="12" md="12" lg="9" xl="9" className="appointment">
          <Row>
            <Col md="12">
              <Row>
                <div className="title">
                  <h1 className="pl-4">
                    {search.serviceName}
                  </h1>
                </div>
              </Row>
            </Col>
            <Col md="7">
              <div className="find-dokter row mt-4">
                <div className="col-md-5">
                  <InputText
                    placeholder="Ketik Paket Prosedur"
                    value={doctorName}
                    icon={IconSearch}
                    iconPosition="right"
                    size='lg'
                    setValue={(value) => {
                      const result = value.replace(/[^A-Za-z ]/gi, '');
                      const formatPayload = {
                        target: result,
                      };
                      setDoctorName(result);
                      handleNameDoctor(formatPayload);
                    }}
                    type="text"
                    iconOnClick={handleSearchDoctor}
                    enterOnPress={handleSearchDoctor}
                  />
                </div>
                <div className="col-md-4">
                  <SelectOptions
                    borderColor="white"
                    placeholder="Pilih klinik"
                    fontSize="lg"
                    options={faskes.data?.map((e) => {
                      return {
                        ...e,
                        value: e?.id,
                        label: e?.name,
                      };
                    })}
                    setValue={(value) => {
                      const formatPayload = {
                        target: value,
                      };
                      setClinic(value);
                      handleFaskes(formatPayload);
                    }}
                    value={clinic}
                    color="#757575"
                    background="white"
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </div>
                <div className="col-md-3">
                  <Button
                    label="Cari"
                    disable={false}
                    type="primary"
                    onClick={handleSearchDoctor}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <>
        <img className="image-dokter" src={"https://aido-website.s3.ap-southeast-1.amazonaws.com/booking-app/dokter-desktop-2.png"} />
        </>
      </div>
    )
  }
  
  return (
    <Layout>
      {/* Desktop View */}
      <ModalComp
        show={showError}
        setShow={setShowError}
        useButtonClose
        useHeaderButtonClose
      >
        <img
          src={IconCrossRed}
          className="d-flex mx-auto"
          alt="icon"
          height={80}
          width={80}
        />
        <p
          className="text-center warning-modal-text"
          style={{
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#424242',
            marginTop: '32px',
            marginBottom: '72px',
          }}
        >
          Untuk melakukan pencarian silahkan isi terlebih dahulu nama dokter
          atau pilih klinik.
        </p>
      </ModalComp>
      {renderContent()}
      {/* Mobile View */}
      <MobileFindDoctor
        onChangeName={handleNameDoctor}
        onEnterName={handleSearchDoctor}
        clinicOptions={faskes?.data?.map((e) => {
          return {
            value: e?.id,
            label: e?.name,
          };
        })}
        onChangeClinic={handleFaskes}
        onSearch={handleSearchDoctor}
        serviceType={search.serviceType}
        serviceName={search.serviceName}
      />
    </Layout>
  );
}

export default FindDoctor;
