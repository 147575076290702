import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./components/HOC/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { isUserLogin } from "./actions";
import DoctorSchedule from "./pages/LandingPage/DoctorSchedule";
import Patient from "./pages/LandingPage/Patient";
import Insurance from "./pages/LandingPage/PaymentMethod/Insurance";
import BPJS from "./pages/LandingPage/PaymentMethod/BPJS";
import FindDoctor from "./pages/LandingPage/FindDoctor";
import ResultFindDoctor from "./pages/LandingPage/ResultFindDoctor";
import ResultFindDoctorV2 from "./pages/LandingPage/ResultFindDoctorV2";
import DoctorDetail from "./pages/LandingPage/DoctorDetail";
import DoctorDetailV2 from "./pages/LandingPage/DoctorDetailV2";
import AccountProfile from "./pages/LandingPage/AccountProfile";
import TransactionHistory from "./pages/LandingPage/TransactionHistory";
import PrintInvoice from "./pages/LandingPage/TransactionHistory/Invoice";
import PrintRecipe from "./pages/LandingPage/TransactionHistory/Recipe";
import PrintDiagnosis from "./pages/LandingPage/TransactionHistory/Diagnosis";
import DetailInvoice from "./pages/LandingPage/TransactionHistory/InvoicePage";
import OrderPackageDone from "./pages/LandingPage/OrderPackageDone";
import TnC from "./pages/LandingPage/TnC";
import Checkin from "./pages/LandingPage/Checkin";
import NotFound from "./pages/LandingPage/NotFound";
import ProductPackage from "./pages/LandingPage/ProductPackage";
import DetailProductPackage from "./pages/LandingPage/ProductPackage/Detail";
import PatientV2 from "./pages/LandingPage/PatientV2";
import HomeV2 from "./pages/LandingPage/HomeV2";
import AccountProfileV2 from "./pages/LandingPage/AccountProfileV2";
import Article from "./pages/LandingPage/Article";
import DetailArticle from "./pages/LandingPage/Article/Detail";
import News from "./pages/LandingPage/News";
import DetailNews from "./pages/LandingPage/News/Detail";
import AboutUs from "./pages/LandingPage/AboutUs";
import PrivacyPolicy from "./pages/LandingPage/PrivacyPolicy";
import TermsAndConditions from "./pages/LandingPage/TermsAndConditions";
import ContactUs from "./pages/LandingPage/ContactUs";
import History from "./pages/LandingPage/History";
import PatientV3 from "./pages/LandingPage/PatientV3";
import Payment from "./pages/LandingPage/Payment";
import Schedule from "./pages/LandingPage/Schedule";
import OrderDoneV2 from "./pages/LandingPage/OrderDoneV2";
import PartnerUs from "./pages/LandingPage/PartnerUs";

const Routes = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const routeManagement = role
    ? Object.keys(JSON.parse(role.user_role_management_id.route_group))
    : [];

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLogin());
    }
  }, [auth.authenticate]);

  return (
    <Router>
      <Switch>

        {/* this for landing page */}
        <Route path="/home" component={HomeV2} />
        {/* <Route path="/home-v2" component={HomeV2} /> */}
        <Route path="/doctor/schedule" component={DoctorSchedule} />
        <Route path="/find/:slug" component={FindDoctor} />
        <Route path="/list/:slug" component={ResultFindDoctor} />
        <Route path="/list-v2/:slug" component={ResultFindDoctorV2} />
        <Route path="/doctor-detail" component={DoctorDetail} />
        <Route path="/doctor-detail-v2" component={DoctorDetailV2} />
        <PrivateRoute path="/account-profile" exact component={AccountProfile} />
        <PrivateRoute path="/account-profile-v2" exact component={AccountProfileV2} />
        <PrivateRoute path="/history-order" exact component={TransactionHistory} />
        <Route path="/print/invoice" exact component={PrintInvoice} />
        <Route path="/print/recipe" exact component={PrintRecipe} />
        <Route path="/print/diagnosis" exact component={PrintDiagnosis} />
        <PrivateRoute path="/patient" component={Patient} />
        <PrivateRoute path="/patient-v2" component={PatientV2} />
        <PrivateRoute path="/patient-v3" component={PatientV3} />
        <PrivateRoute path="/payment-method/insurance" component={Insurance} />
        <PrivateRoute path="/payment-method/bpjs" component={BPJS} />
        <Route path="/appointment-created/:id" component={OrderDoneV2} />
        <Route path="/appointment-package-created/:id" component={OrderPackageDone} />
        <Route path="/tnc" component={TnC} />
        <Route path="/checkin/:id" component={Checkin} />
        <Route path="/history-order/invoice" exact component={DetailInvoice} />
        <Route path="/product-package" exact component={ProductPackage} />
        <Route path="/product-package/detail/:uuid" exact component={DetailProductPackage} />
        <Route path="/article" exact component={Article} />
        <Route path="/article/:slug" exact component={DetailArticle} />
        <Route path="/news" exact component={News} />
        <Route path="/news/:slug" exact component={DetailNews} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/partner-us/:id" exact component={PartnerUs} />
        <Route path="/history" exact component={History} />
        <Route path="/payment" exact component={Payment} />
        <Route path="/schedule" exact component={Schedule} />
        <Route path="/404" exact component={NotFound} />

        <Route path=" ">
          <Redirect to="/home" />
        </Route>
       
       <Route path="/">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
