import React from 'react';
import './notFound.scss';

const NotFound = () => {
  return (
      <div className="content-home-notfound" style={{ marginBottom: '9.6em' }}>
        <div className="centered-text">
          <p>Oops! Halaman Klinik yang Anda cari tidak ditemukkan</p>
        </div>
        <div className="centered">
              <img src='https://aido-website.s3.ap-southeast-1.amazonaws.com/microservices/notfound/404_480x389.png'></img>
        </div>
      </div>
  );
};

export default NotFound;
