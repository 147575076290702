import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { IconArrowBackBlack, IconIndoFlag } from '../../../assets/img/icon';
import './index.scss';

const ModalLogin = ({
  isShow,
  closeModal,
  InputForm,
  setInputForm,
  requestOtp,
  Otp,
  setOtp,
  TimerOnGoing,
  rerequestOtp,
  validateOtp,
  phone,
  setPhone,
  OtpForm,
  setOtpForm,
  SpanPhone,
  status,
  message,
  timer,
  remainingTime,
  wrongCode
}) => {
  return (
    <React.Fragment>
      <Modal
        dialogClassName="modal-dialogs-margin-0 modal-dialogs modal-mobile-v2"
        show={isShow}
        centered
        onHide={closeModal}
        size="sm"
      >
        <Modal.Body className="modal-container" centered>
          {InputForm && (
            <div>
              <h1 className="mb-3">Selamat Datang</h1>
              <p className="mb-3">
                Silahkan masukkan nomor handphone Anda
              </p>
              {status == 400 && remainingTime !== '00:00' && (
                <>
                  <p className="mb-3" style={{ color: 'red' }}>
                    {message} {remainingTime}
                  </p>
                </>
              )}
              <div className="d-flex align-items-center justify-content-center mb-3">
                <div className="input-countrycode mt-lg-0 mt-2">
                  <img
                    src={IconIndoFlag}
                    width="32"
                    height="32"
                    className="img-flag"
                    alt="Flag"
                  />
                  <p className="d-flex my-auto">+62</p>
                </div>
                <div className="input-icons d-flex my-auto">
                  <input
                    type="text"
                    value={phone}
                    className="input-phoneNumber"
                    placeholder="8123456789"
                    onChange={(e) => setPhone(e.target.value)}
                    pattern="[0-9]*"
                    maxlength="14"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        requestOtp();
                      }
                    }}
                  />
                </div>
              </div>
              <p style={{marginBottom: "8px"}}>
                Kami akan mengirimkan kode verifikasi ke nomor ini. Pastikan nomor Anda aktif dan dapat dihubungi.
              </p>
              <label className="information-tnc" style={{marginBottom: "8px"}}>
                Saya menyetujui
                <a href="/terms-and-conditions" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Ketentuan Pengguna Janji Temu{' '}
                </a>
                dan
                <a href="/privacy-policy" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Kebijakan Privasi.
                </a>
              </label>
              <div className="mt-4">
                <Button
                    onClick={() => requestOtp()}
                    style={{width: "100%", paddingTop: "8px", paddingBottom: "8px"}}
                >
                  Verifikasi
                </Button>
              </div>
            </div>
          )}
          {OtpForm && status == 400 && (
            <div>
              <h1 className="mb-3">Selamat Datang</h1>
              <p className="mb-3">
                Silahkan masukkan nomor handphone Anda
              </p>
              {status == 400 && remainingTime !== '00:00' && (
                <>
                  <p className="mb-3" style={{ color: 'red' }}>
                    {message} {remainingTime}
                  </p>
                </>
              )}
              <div className="d-flex align-items-center justify-content-center">
                <div className="input-countrycode mt-lg-0 mt-2">
                  <img
                    src={IconIndoFlag}
                    width="32"
                    height="32"
                    className="img-flag"
                    alt="Flag"
                  />
                  <p className="d-flex my-auto">+62</p>
                </div>
                <div className="input-icons d-flex my-auto">
                  <input
                    type="text"
                    value={phone}
                    className="input-phoneNumber"
                    onChange={(e) => setPhone(e.target.value)}
                    pattern="[0-9]*"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        requestOtp();
                      }
                    }}
                  />
                  <img
                    onClick={requestOtp}
                    src="https://storage.aido.id/microservices/icon/arrow-circle.svg"
                    width="24"
                    height="24"
                    className="buttonsubs"
                    alt=""
                  />
                </div>
              </div>
              <p className="mb-3 mt-4 mt-lg-3">
                Kami akan mengirimkan kode verifikasi ke nomor ini. Pastikan nomor Anda aktif dan dapat dihubungi.
              </p>
              <label className="information-tnc">
                Saya menyetujui
                <a href="/terms-and-conditions" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Ketentuan Pengguna Janji Temu{' '}
                </a>
                dan
                <a href="/privacy-policy" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Kebijakan Privasi.
                </a>
              </label>
            </div>
          )}
          <div>
            {OtpForm && status == 200 && (
              <div>
                <h1 className="mb-3 d-none d-md-block">Masukkan Kode OTP</h1>
                <h4 className="mb-3 d-md-none d-block">Masukkan Kode OTP</h4>
                <p className="mb-3 mt-4" hidden={!wrongCode}>
                  Masukkan 6-digit kode yang dikirim melalui WA ke {SpanPhone}
                </p>
                <p className="mb-3 mt-4" hidden={wrongCode}>
                  <span style={{color: "#E02B1D"}}>Kode yang Anda masukkan salah</span>
                </p>
                <div className={`d-flex align-items-center justify-content-center mb-3 ${!wrongCode ? "pincode-input-text-red" : ""}`}>
                  <PinInput
                    length={6}
                    initialValue=""
                    secret
                    onChange={(value) => {
                      setOtp(value);
                    }}
                    type="numeric"
                    inputMode="number"
                    onComplete={(value, index) => {}}
                    autoSelect
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </div>
                <p className="mb-3">{timer}</p>
                {timer == "00:00" ? (
                  <>
                    <p className="mb-3">
                      <a
                        href="#"
                        style={{ fontWeight: 'bold' }}
                        className="green"
                        onClick={(e) => {
                          requestOtp()
                          rerequestOtp()
                        }}
                      >
                        Kirim ulang kode OTP
                      </a>
                    </p>
                  </>
                ) : null}
                <Button
                    onClick={() => validateOtp()}
                    className="link-login"
                    style={{width: "100%", paddingTop: "8px", paddingBottom: "8px"}}
                    disabled={Otp.length == 6 ? false : true}
                >
                  Verifikasi
                </Button>
                <p className="mb-3">
                  <a
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    className="green"
                    onClick={(e) => {
                      setOtpForm(false);
                      setInputForm(true);
                    }}
                  >
                    Gunakan nomor lain?
                  </a>
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalLogin;
