import React from "react";
import { Col, Row } from "react-bootstrap";

const FooterDetailProductPackage = ({ footerDetailProductPackageProps }) => {
  return (
    <div className="container-footer">
      <div className="container-web">
        <Row>
          <Col xs={7}>
            <div className="d-flex flex-column">
              <span className="font-s-16 font-w-600">
                {footerDetailProductPackageProps?.name}
              </span>
              <span className="font-s-16 font-w-700 cl-green">
                {footerDetailProductPackageProps?.price}
              </span>
            </div>
          </Col>
          <Col xs={5}>
            <button
              className="button-green-56"
              onClick={() => footerDetailProductPackageProps?.onClick()}
            >
              Beli Paket
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FooterDetailProductPackage;
