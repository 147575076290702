import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import {Row, Col} from "react-bootstrap";

const FooterCopyrightV2 = () => {
  const history = useHistory();
  const headerData = JSON.parse(localStorage.getItem("headerData"));

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div className="container-footer-copyright">
      <div className=" container-web text-left d-lg-inline d-none">
        <Row>
          <Col md={6}>
            <div>
              <span
                className="font-s-14 font-w-700 cl-green cursor-point"
                onClick={() => history.push("/terms-and-conditions")}
              >
                Syarat & Ketentuan
              </span>
              &nbsp;
              <span className="font-s-14 font-w-500">|</span>
              &nbsp;
              <span
                className="font-s-14 font-w-700 cl-green cursor-point"
                onClick={() => history.push("/privacy-policy")}
              >
                Kebijakan Privasi
              </span>
            </div>

            <div>
              <span className="font-s-14 font-w-500">
                Powered by
              </span>
              &nbsp;
              <span className="font-s-14 font-w-700 cl-green">
                Aido Health
              </span>
            </div>
          </Col>

          <Col md={6}>
            <div className="text-right">
                {(headerData?.klinik_apps?.android || headerData?.klinik_apps?.ios) && (
                  <>
                    Download Aplikasi
                  </>
                )}
                {headerData?.klinik_apps?.android && (
                  <img
                      role="button"
                      src={'/img/logo/get-on-playstore.svg'}
                      alt="playstore"
                      className="ml-2"
                      onClick={() => openLink(headerData?.klinik_apps?.android)}
                  />
                )}
                {headerData?.klinik_apps?.ios && (
                  <img
                      role="button"
                      src={'/img/logo/get-on-appstore.svg'}
                      alt="playstore"
                      className="ml-2"
                      onClick={() => openLink(headerData?.klinik_apps?.ios)}
                  />
                )}
            </div>
          </Col>
        </Row>
      </div>

      {/* mobile */}
      <div className=" container-web text-center d-lg-none d-md-none">
        <div>
          <span
            className="font-s-14 font-w-700 cl-green cursor-point"
            onClick={() => history.push("/terms-and-conditions")}
          >
            Syarat & Ketentuan
          </span>
          &nbsp;
          <span className="font-s-14 font-w-500">|</span>
          &nbsp;
          <span
            className="font-s-14 font-w-700 cl-green cursor-point"
            onClick={() => history.push("/privacy-policy")}
          >
            Kebijakan Privasi
          </span>
          &nbsp;
        </div>

        <div>
          <span className="font-s-14 font-w-500">
            Powered by
          </span>
          &nbsp;
          <span className="font-s-14 font-w-700 cl-green">
            Aido Health
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterCopyrightV2;
