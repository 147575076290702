import { inquiryConstants } from "../actions/appointmentWeb/constants";

const initState = {
  inquiryData: [],
  hospitalInfoData: [],
  inquiryLoading: false,
  hospitalInfoLoading: false,
  inquiryError: null,
  hospitalInfoError: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case inquiryConstants.CREATE_INQUIRY_REQUEST:
      state = {
        ...state,
        inquiryLoading: true,
      };
      break;
    case inquiryConstants.CREATE_INQUIRY_SUCCESS:
      state = {
        ...state,
        inquiryLoading: false,
      };
      break;
    case inquiryConstants.CREATE_INQUIRY_FAILURE:
      state = {
        ...state,
        inquiryError: action.payload.error,
        inquiryLoading: false,
      };
      break;
    case inquiryConstants.GET_INQUIRY_REQUEST:
      state = {
        ...state,
        inquiryLoading: true,
      };
      break;
    case inquiryConstants.GET_INQUIRY_SUCCESS:
      state = {
        ...state,
        inquiryData: action.payload.data,
        inquiryLoading: false,
      };
      break;
    case inquiryConstants.GET_INQUIRY_FAILURE:
      state = {
        ...state,
        inquiryError: action.payload.error,
        inquiryLoading: false,
      };
      break;
    case inquiryConstants.GET_HOSPITAL_INFO_REQUEST:
      state = {
        ...state,
        hospitalInfoLoading: true,
      };
      break;
    case inquiryConstants.GET_HOSPITAL_INFO_SUCCESS:
      state = {
        ...state,
        hospitalInfoData: action.payload.data,
        hospitalInfoLoading: false,
      };
      break;
    case inquiryConstants.GET_HOSPITAL_INFO_FAILURE:
      state = {
        ...state,
        hospitalInfoError: action.payload.error,
        hospitalInfoLoading: false,
      };
      break;
    default:
      return state;
  }
  return state;
};
