import React, { useEffect, useState } from 'react';
import { IconArrowGrey, IconCalenderGrey } from '../../../assets/img/icon';
import AccordionComp from '../../../components/Accordion';
import { InputDate } from '../../../components/Input';
import SelectOptions from '../../../components/Input/SelectOptions';
import './ResultFindDoctor.scss';

const MobileResultFindDoctor = ({
  filter,
  faskes,
  specializations,
  genders,
  consultations,
  setFaskes,
  setGender,
  setSpecialization,
  setConsultation,
  setSearchDate,
  serviceType,
  titleList
}) => {
  const [clinic, setClinic] = useState();
  const [gendered, setGendered] = useState();
  const [specialized, setSpecialized] = useState();
  const [consultationType, setConsultationType] = useState();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setClinic(faskes?.filter((e) => e?.value === filter?.hospital_id[0]));
  }, [faskes, filter?.hospital_id]);

  const onChangeSelectOptions = ({ value, type }) => {
    const formatPayload = {
      target: value,
    };

    switch (type) {
      case 'clinic':
        setClinic(value);
        return setFaskes(formatPayload);
      case 'gender':
        setGendered(value);
        return setGender(formatPayload);
      case 'specialize':
        setSpecialized(value);
        return setSpecialization(formatPayload);
      case 'consultation':
        setConsultationType(value);
        return setConsultation(formatPayload);
      case 'date':
        setDate(value);
        return setSearchDate(value);
    }
  };

  return (
    <React.Fragment>
      <div className="col-md-12 d-xl-none d-block mx-0 px-0">
        <p
          className="text-white text-center font-weight-bold mt-5 mb-4"
          style={{ fontSize: '27px' }}
        >
          {titleList}
        </p>
        <AccordionComp title="Filter">
        {localStorage.getItem('klinikId') === null || localStorage.getItem('klinikId') === "null" &&(
          <div className="mt-3">
            <SelectOptions
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
              borderColor="transparent"
              placeholder="Klinik"
              fontSize="lg"
              options={faskes}
              setValue={(value) =>
                onChangeSelectOptions({ value, type: 'clinic' })
              }
              value={clinic}
              color="#CCCCCC"
              background="white"
              isClearable
            />
          </div>)}

          <div className="mt-3">
            <SelectOptions
              customIcon={IconArrowGrey}
              iconClassName="mr-2"
              borderColor="transparent"
              isSearchable
              placeholder="Spesialis"
              fontSize="lg"
              options={specializations}
              setValue={(value) =>
                onChangeSelectOptions({ value, type: 'specialize' })
              }
              value={specialized}
              color="#CCCCCC"
              background="white"
              isClearable
            />
          </div>
          {/* Temporary Comment
            <div className="mt-3">
              <InputDate
                placeholder="Tanggal"
                iconPosition="right"
                icon={IconCalenderGrey}
                showMonthDropdown
                showYearDropdown
                format="dd MMMM yyyy"
                setDate={(value) =>
                  onChangeSelectOptions({ value, type: 'date' })
                }
                selectedDate={date}
              />
            </div>
          */}
          <div className="mt-3">
            <SelectOptions
              customIcon={IconArrowGrey}
              isSearchable
              iconClassName="mr-2"
              borderColor="transparent"
              placeholder="Gender"
              fontSize="lg"
              options={genders}
              setValue={(value) =>
                onChangeSelectOptions({ value, type: 'gender' })
              }
              value={gendered}
              color="#CCCCCC"
              background="white"
              isClearable
            />
          </div>
          {serviceType !== 'TL' && (
            <div className="mt-3">
              <SelectOptions
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
                borderColor="transparent"
                isSearchable
                placeholder="Tipe Konsultasi"
                fontSize="lg"
                options={consultations}
                setValue={(value) =>
                  onChangeSelectOptions({ value, type: 'consultation' })
                }
                value={consultationType}
                color="#CCCCCC"
                background="white"
                isClearable
              />
            </div>
          )}
        </AccordionComp>
      </div>
    </React.Fragment>
  );
};

export default MobileResultFindDoctor;
