import axios from "axios";
import { api } from "../urlConfig";

const accessToken = window.localStorage.getItem("accessToken");

export default function(options = {}) {
  const { 
    headers = {} 
  } = options;
  
  return axios.create({
    baseURL: api,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...headers
    }
  });
}
