import { transactionHistoryConstants } from "../actions/constants";

const initState = {
  transactionHistoryData: [],
  medicalRecordData: [],
  invoiceData: [],
  recipeData: [],
  diagnosisData: [],
  medicalResultUrl: "",
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case transactionHistoryConstants.GET_TRANSACTIONHISTORY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case transactionHistoryConstants.GET_TRANSACTIONHISTORY_SUCCESS:
      state = {
        ...state,
        transactionHistoryData: action.payload.data,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_TRANSACTIONHISTORY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_INVOICE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case transactionHistoryConstants.GET_INVOICE_SUCCESS:
      state = {
        ...state,
        invoiceData: action.payload.data.transaction,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_INVOICE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_DIAGNOSIS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case transactionHistoryConstants.GET_DIAGNOSIS_SUCCESS:
      state = {
        ...state,
        diagnosisData: action.payload.data.transaction,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_DIAGNOSIS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_RECIPE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case transactionHistoryConstants.GET_RECIPE_SUCCESS:
      state = {
        ...state,
        recipeData: action.payload.data.transaction,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_RECIPE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case transactionHistoryConstants.PRINT_MEDICALRESULT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case transactionHistoryConstants.PRINT_MEDICALRESULT_SUCCESS:
      state = {
        ...state,
        medicalResultUrl: action.payload.data.medicalResultUrl,
        loading: false,
      };
      break;
    case transactionHistoryConstants.PRINT_MEDICALRESULT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_MEDICALRECORD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case transactionHistoryConstants.GET_MEDICALRECORD_SUCCESS:
      state = {
        ...state,
        medicalRecordData: action.payload.data,
        loading: false,
      };
      break;
    case transactionHistoryConstants.GET_MEDICALRECORD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
