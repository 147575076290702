import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDoctorAvailabilities } from '../../../actions';
import {
  IconCheckedDisbled,
  IconCheckedEnabled,
  IconDummyAvatar,
  IconSearchWhite,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { consultationTypeCode } from '../../../constants/common';
import { wordingResultDoctorList } from '../../../constants/wording';
import Layout from '../Components/Layout';
import './DoctorDetail.scss';
import MobileDoctorDetail from './MobileDoctorDetail';

const DoctorDetail = (props) => {
  /* Redux */
  const dispatch = useDispatch();

  /* Router DOM */
  const history = useHistory();

  /* Local State */
  const [doctor, setDoctor] = useState({});

  const search = useMemo(() => {
    return {
      doctor_id:
        props.location.state.length > 0
          ? props.location.state[0].doctor_id
          : '',
      hospital_id:
        props.location.state.length > 0
          ? [props.location.state[0].hospital_id]
          : [],
      specialization_id:
        props.location.state.length > 0
          ? props.location.state[0].specialization_id
          : '',
      name_doctor:
        props.location.state.length > 0
          ? props.location.state[0].name_doctor
          : '',
      gender:
        props.location.state.length > 0 ? props.location.state[0].gender : '',
      consultation:
        props.location.state.length > 0
          ? props.location.state[0].consultation
          : '',
      serviceType:
        props.location?.state?.length > 0
          ? props.location.state[0].serviceType
          : '',
    };
  }, [props.location.state]);

  useEffect(() => {
    getListDoctor();
  }, []);

  const getListDoctor = () => {
    dispatch(getDoctorAvailabilities(JSON.stringify(search))).then(
      (response) => {
        if (response.data.length > 0) {
          setDoctor(response.data[0]);
        }
      }
    );
  };

  const handleDoctorSchedule = () => {
    history.push('/doctor/schedule', props.location.state);
  };

  const handleAnotherDoctor = () => {
    const state = props.location.state;
    const params = {
      ...state[0],
      hospital_id: state[0].search_id_hospital?.length
        ? [state[0].search_id_hospital[0]]
        : [],
      specialization_id: state[0]?.search_specialization_id || null,
      hospital_group_id: state[0]?.hospital_group_id || null,
      schedule_date: state[0]?.schedule_date || null,
      gender: state[0]?.search_gender || null,
      consultation: state[0]?.search_consultation_type || null,
    };
    history.push(`/result-find-doctor`, [params]);
  };

  const longDate = useMemo(() => {
    const date = new Date();
    const result = [];
    for (let i = 0; i < 6; i++) {
      const nextDate = new Date(date.setDate(date.getDate() + 1));
      result.push(nextDate);
    }
    return [new Date(), ...result];
  }, []);

  const schedules = useMemo(() => {
    let bpjsChecked = 0;
    let regularChecked = 0;
    let teleChecked = 0;
    const bpjs = doctor?.scheduleList?.tm_doctor_schedules_v2s?.filter(
      (item) => item?.consultation_type_id === consultationTypeCode.bpjs
    )[0];
    const reguler = doctor?.scheduleList?.tm_doctor_schedules_v2s?.filter(
      (item) => item?.consultation_type_id === consultationTypeCode.reguler
    )[0];
    const tele = doctor?.scheduleList?.tm_doctor_schedules_v2s?.filter(
      (item) => item?.consultation_type_id === consultationTypeCode.tele
    )[0];

    const slotBpjs = longDate.map((date) => {
      if (!bpjs?.tx_schedule_detail_v2s?.length) return null;
      const day = new Date(date).getDay() || 7;
      const tempFilter = bpjs?.tm_doctor_schedule_items?.filter(
        (tdri) => +tdri?.day === day
      );
      if (tempFilter?.length)
        return {
          ...tempFilter[0],
          dateView: date,
          scheduleDetails: bpjs?.tx_schedule_detail_v2s,
        };
      return null;
    });
    const slotReguler = longDate.map((date) => {
      if (!reguler?.tx_schedule_detail_v2s?.length) return null;
      const day = new Date(date).getDay() || 7;
      const tempFilter = reguler?.tm_doctor_schedule_items?.filter(
        (tdri) => +tdri?.day === day
      );
      if (tempFilter?.length)
        return {
          ...tempFilter[0],
          dateView: date,
          scheduleDetails: reguler?.tx_schedule_detail_v2s,
        };
      return null;
    });
    const slotTele = longDate.map((date) => {
      if (!tele?.tx_schedule_detail_v2s?.length) return null;
      const day = new Date(date).getDay() || 7;
      const tempFilter = tele?.tm_doctor_schedule_items?.filter(
        (tdri) => +tdri?.day === day
      );
      if (tempFilter?.length)
        return {
          ...tempFilter[0],
          dateView: date,
          scheduleDetails: tele?.tx_schedule_detail_v2s,
        };
      return null;
    });
    const bpjsFlag = slotBpjs?.filter((e) => e);
    const regulerFlag = slotReguler?.filter((e) => e);
    const teleFlag = slotTele?.filter((e) => e);

    if (bpjsFlag?.length) bpjsChecked = 1;
    if (regulerFlag?.length) regularChecked = 1;
    if (teleFlag?.length) teleChecked = 1;

    return {
      bpjsChecked,
      regularChecked,
      teleChecked,
      doctorHospitalId: doctor?.scheduleList?.id,
      serviceFee: doctor?.scheduleList?.serviceFee,
      doctorId: doctor?.scheduleList?.doctor_id,
      doctorName: doctor?.scheduleList?.tm_doctor?.name,
      doctorImageUrl: doctor?.scheduleList?.tm_doctor?.image_url,
      specializationId: doctor?.scheduleList?.tm_specialization?.id,
      specializationName: doctor?.scheduleList?.tm_specialization?.name,
      poliId: doctor?.scheduleList?.tm_poli?.id,
      hospitalId: doctor?.scheduleList?.hospital_id,
      hospitalName: doctor?.scheduleList?.tm_hospital?.name,
      hospitalImageUrl: doctor?.scheduleList?.tm_hospital?.image_url,
      hospitalType: doctor?.scheduleList?.tm_hospital?.hospital_type,
      hospitalTimezone: doctor?.scheduleList?.tm_hospital?.timezone,
      scheduleType:
        doctor?.scheduleList?.tm_doctor_schedules_v2?.tr_schedule_type,
      consultationType:
        doctor?.scheduleList?.tm_doctor_schedules_v2?.tr_consultation_type,
      slotBpjs,
      slotReguler,
      slotTele,
    };
  }, [doctor]);

  return (
    <Layout>
      <div
        className="pt-4 px-3 d-md-block d-none"
        style={{ marginBottom: '220px' }}
      >
        <div className="position-absolute mt-2" style={{ width: '200px' }}>
          <Button
            label="Cari Dokter Lain"
            icon={IconSearchWhite}
            iconPosition="right"
            onClick={handleAnotherDoctor}
          />
        </div>
        <p
          className="font-weight-bold text-center text-white"
          style={{ fontSize: '36px' }}
        >
          Profil Dokter
        </p>
        <Card className="mt-3">
          <div className="row card-inner mx-1">
            <div className="col-4 my-auto">
              <img
                src={IconDummyAvatar}
                alt="icon"
                className="d-inline my-auto mr-3"
                height={60}
                width={60}
              />
              <p className="mobile-profile-name d-inline my-auto">
                {doctor?.scheduleList?.tm_doctor?.name || '-'}
              </p>
            </div>
            <div className="col-5 my-auto">
              <p className="mobile-profile-dob text-left">
                {doctor?.scheduleList?.tm_specialization?.name || '-'}
              </p>
              <p className="mobile-profile-dob text-left my-2">
                {doctor?.scheduleList?.tm_hospital?.name || '-'}
              </p>
              {search.serviceType !== 'TL' ? (
                <div className="d-flex justify-content-start">
                  {schedules?.regularChecked ? (
                    <div className="d-flex align-items-center consultation-type">
                      <img
                        src={IconCheckedEnabled}
                        width="20"
                        height="20"
                        alt="Doctor"
                      />
                      <span className="enabled">Reguler</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center consultation-type">
                      <img
                        src={IconCheckedDisbled}
                        width="20"
                        height="20"
                        alt="Doctor"
                      />
                      <span className="disabled">Reguler</span>
                    </div>
                  )}
                  {schedules?.bpjsChecked ? (
                    <div className="d-flex align-items-center consultation-type ml-4">
                      <img
                        src={IconCheckedEnabled}
                        width="20"
                        height="20"
                        alt="Doctor"
                      />
                      <span className="enabled">Melayani BPJS</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center consultation-type ml-4">
                      <img
                        src={IconCheckedDisbled}
                        width="20"
                        height="20"
                        alt="Doctor"
                      />
                      <span className="disabled">Melayani BPJS</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="px-2 my-3">
                  {schedules?.teleChecked ? (
                    <div className="consultation-type">
                      <img
                        src={IconCheckedEnabled}
                        width="20"
                        height="20"
                        alt="Doctor"
                      />
                      <span className="enabled">Telekonsultasi</span>
                    </div>
                  ) : (
                    <div className="consultation-type">
                      <img
                        src={IconCheckedDisbled}
                        width="20"
                        height="20"
                        alt="Doctor"
                      />
                      <span className="disabled">Telekonsultasi</span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-3 d-flex float-right">
              <Button
                label={
                  search.serviceType === 'TL'
                    ? wordingResultDoctorList.labelAddTele
                    : wordingResultDoctorList.labelAddReguler
                }
                className="mr-2 font-weight-normal my-auto"
                onClick={handleDoctorSchedule}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <p className="font-weight-bold text-dark font14">Pendidikan:</p>
              <p className="text-dark font14">Universitas Indonesia</p>
            </div>
            <div className="col">
              <p className="font-weight-bold text-dark font14">
                Kondisi dan Minat Klinis:
              </p>
              <ul className="pl-4">
                <li className="text-dark font14">Selulitis</li>
                <li className="text-dark font14">Dermatofitosis (kurap)</li>
                <li className="text-dark font14">
                  Kelainan kulit pada bayi baru lahir yang di dapat saat
                  kehamilan atau persalinan ibu
                </li>
                <li className="text-dark font14">
                  Penyakit genetik pada kulit
                </li>
                <li className="text-dark font14">Hiperhidrosis osmidrosis</li>
                <li className="text-dark font14">Kelainan rambut</li>
              </ul>
            </div>
            <div className="col">
              <p className="font-weight-bold text-dark font14">Prestasi:</p>
              <ul className="pl-4">
                <li className="text-dark font14">
                  Tahun 2011 IDI (Ikatan Dokter Indonesia)
                </li>
                <li className="text-dark font14">
                  Tahun 2013 IDI (Ikatan Dokter Indonesia)
                </li>
                <li className="text-dark font14">
                  Tahun 2017 IDI (Ikatan Dokter Indonesia)
                </li>
                <li className="text-dark font14">
                  Tahun 2020 IDI (Ikatan Dokter Indonesia)
                </li>
                <li className="text-dark font14">
                  Tahun 2021 IDI (Ikatan Dokter Indonesia)
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
      <MobileDoctorDetail
        doctor={doctor}
        handleAnotherDoctor={handleAnotherDoctor}
        handleDoctorSchedule={handleDoctorSchedule}
        schedules={schedules}
        search={search}
      />
    </Layout>
  );
};

export default DoctorDetail;
