import React, { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { formatDate } from "../../../helpers/utils";
import "react-datepicker/dist/react-datepicker.css";
import "./FormDate.style.scss";
import { id } from "date-fns/locale";

registerLocale("indonesia", id)

const FormDate = ({
  selectedDate = null,
  startDate = null,
  endDate = null,
  setDate,
  setStartDate,
  setEndDate,
  label = null,
  labelClassName,
  isShowWarning = false,
  placeholder,
  readOnly = false,
  format = "dd-MM-yyyy",
  minDate = null,
  maxDate = null,
  icon = null,
  iconPosition = "left",
  showMonthDropdown = false,
  showYearDropdown = false,
  selectsRange = false,
  isClearable = false,
  monthsShown = 1,
  required = false,
  className = "",
  customInput = null,
  locale = id,
}) => {
  const CustomInputRangeDate = forwardRef(({ onClick }, ref) => {
    return (
      <>
        {label && (
          <p className={`${labelClassName} font-weight-bold label-input-date`}>
            {label}
            {required && <span className="text-danger">*</span>}
          </p>
        )}
        <div className="container-field">
          <input
            className={`form-input${isShowWarning ? "-warning" : ""} w-100 ${
              icon && `input-icon-${iconPosition}`
            }`}
            onClick={onClick}
            ref={ref}
            value={
              startDate || endDate
                ? `${
                    formatDate(startDate?.toString(), false, format) || "-"
                  }   ➡   ${
                    formatDate(endDate?.toString(), false, format) || "-"
                  }`
                : null
            }
            type="text"
            placeholder={placeholder}
          />
          {icon && (
            <img
              src={icon}
              alt={`icon-${iconPosition}`}
              className={`${iconPosition}-icon`}
            />
          )}
        </div>
        {isShowWarning && (
          <p className="warning-message-input mt-2">
            <i className="fa fa-warning" /> {label || "Kolom ini"} tidak boleh
            kosong!
          </p>
        )}
      </>
    );
  });
  return (
    <React.Fragment>
      {selectsRange ? (
        <DatePicker
          locale={locale}
          maxDate={maxDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          shouldCloseOnSelect={false}
          customInput={<CustomInputRangeDate />}
          monthsShown={monthsShown}
        />
      ) : (
        <>
          {label && (
            <p
              className={`${labelClassName} font-weight-bold label-input-date`}
            >
              {label} {required && <span className="text-danger">*</span>}
            </p>
          )}
          <div className="container-field">
            <DatePicker
              locale={locale}
              maxDate={maxDate}
              selected={selectedDate && new Date(selectedDate)}
              dateFormat={format}
              className={` input-custom-date${
                isShowWarning ? "-warning" : ""
              } w-100 ${icon && `input-icon-${iconPosition}`} ${className}`}
              onChange={setDate}
              placeholderText={placeholder}
              wrapperClassName="w-100"
              readOnly={readOnly}
              disabled={readOnly}
              minDate={minDate}
              showYearPicker={format === "yyyy" || false}
              showMonthYearPicker={
                format === "MMMM yyyy" || format === "mm yyyy" || false
              }
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              selectsRange={selectsRange}
              monthsShown={monthsShown}
              isClearable={isClearable}
              customInput={customInput}
            />
            {icon && (
              <img
                src={icon}
                alt={`icon-${iconPosition}`}
                className={`${iconPosition}-icon`}
              />
            )}
          </div>
          {isShowWarning && (
            <p className="warning-message-input mt-2">
              <i className="fa fa-warning" />
              {label || "Kolom ini"} tidak boleh kosong!
            </p>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default FormDate;
