import moment from 'moment';
import 'moment/locale/id';
import React from 'react';
import { Button as ButtonReact, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  IconArrowGreen,
  IconArrowGrey,
  IconCloseGreen,
} from '../../../../assets/img/icon';
import Button from '../../../../components/Button';
import { SelectOptions } from '../../../../components/Input';
import '../../PaymentMethod/paymentMethod.scss';
moment.locale('id');

const MobileInsurance = ({
  state,
  prevPageUrl,
  appointmentData,
  setAppointmentData,
  paymentTypeSelected,
  paymentTypeId,
  setPaymentTypeId,
  errorRequired,
  setErrorRequired,
  insurance,
  insuranceDoc,
  handleInsuranceDocModal,
  jaminanDoc,
  handleJaminanDocModal,
  handleConfirmationModal,
  setInsuranceDoc,
  setJaminanDoc,
  setPaymentType,
  paymentType,
}) => {
  /* Router DOM */
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="content-payment-method-mobile d-xl-none d-block">
        <div
          className="cursor-pointer d-flex flex-row mt-4"
          onClick={() => {
            history.push({
              pathname: prevPageUrl,
              state: {
                selectedPatient: state.selectedPatient,
                appointmentData: appointmentData,
              },
            });
          }}
        >
          <img src={IconArrowGreen} alt="icon" />
          <p className="title-page ml-2 text-white">Kembali</p>
        </div>
        <p
          className="text-center font-weight-bold mt-3 text-white"
          style={{ fontSize: '16px' }}
        >
          Langkah 3 dari 3
        </p>
        <Row>
          <div className="col-11">
            <div className="content-paymentMethod-step">
              <p className="text-center title1">Jaminan/Pembayar</p>
              <p className="text-center title2">
                Silahkan pilih jaminan yang ingin Anda gunakan
              </p>
              <SelectOptions
                placeholder="Pilih jenis jaminan"
                label="Pilih Jenis Jaminan"
                labelClassName="mt-3"
                background="#ffff"
                value={paymentType}
                fontSize="lg"
                setValue={(e) => {
                  const formatPayload = {
                    target: e,
                  };
                  setPaymentType(e);
                  paymentTypeSelected(formatPayload);
                }}
                options={
                  appointmentData?.hospitalPayer?.length &&
                  appointmentData?.hospitalPayer?.map((e) => {
                    return {
                      ...e,
                      label: e?.payerName,
                      value: e?.prefix,
                    };
                  })
                }
                customIcon={IconArrowGrey}
                iconClassName="mr-2"
              />
              {paymentTypeId ? (
                <div className="choose-paymentType col-13 mx-auto">
                  {paymentTypeId == 'AS' ? (
                    <div className="paymentType-card col-13 mx-auto">
                      <h3>Pilih Asuransi</h3>
                      <div className="select-option">
                        <select
                          className={
                            errorRequired.find((val) => val == 'payer_name')
                              ? 'form-control errorRequired'
                              : 'form-control'
                          }
                          value={appointmentData.payer_name}
                          onChange={(e) => {
                            setAppointmentData({
                              ...appointmentData,
                              payer_name: e.target.value,
                              insuranceClinicsId:
                                e.target[e.target.selectedIndex].getAttribute(
                                  'id'
                                ),
                            });
                            if (e.target.value == 'RE') {
                              document.getElementById(
                                'selectOptionPatient1'
                              ).selectedIndex = 3;
                              setPaymentTypeId('RE');
                              setAppointmentData({
                                ...appointmentData,
                                payer: 'RE',
                              });
                            }
                            if (e.target.value) {
                              e.target.className = 'form-control';
                              var removeError = errorRequired.filter(
                                (val) => val != 'payer_name'
                              );
                              setErrorRequired(removeError);
                            } else {
                              e.target.className = 'form-control errorRequired';
                              setErrorRequired([
                                ...errorRequired,
                                'payer_name',
                              ]);
                            }
                          }}
                        >
                          <option value="" disabled selected hidden>
                            {' '}
                            Pilih Nama Asuransi{' '}
                          </option>
                          {insurance.insuranceData ? (
                            insurance.insuranceData.map((data, index) => (
                              <option
                                key={index}
                                value={data.name}
                                id={data.id}
                              >
                                {' '}
                                {data.name}{' '}
                              </option>
                            ))
                          ) : (
                            <>
                              <option value="RE"> Reimburse </option>
                            </>
                          )}
                        </select>
                      </div>
                      {errorRequired.find((val) => val == 'payer_name') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nama Asuransi harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}
                      <h3>Nomor Peserta</h3>
                      <Form.Control
                        type="number"
                        className={
                          errorRequired.find((val) => val == 'payer_no')
                            ? 'errorRequired'
                            : ''
                        }
                        placeholder="Masukkan Nomor Peserta"
                        value={appointmentData.payer_no}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            payer_no: e.target.value,
                          });

                          if (e.target.value) {
                            e.target.className = 'form-control';
                            var removeError = errorRequired.filter(
                              (val) => val != 'payer_no'
                            );
                            setErrorRequired(removeError);
                          } else {
                            e.target.className = 'form-control errorRequired';
                            setErrorRequired([...errorRequired, 'payer_no']);
                          }
                        }}
                      />
                      {errorRequired.find((val) => val == 'payer_no') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nomor Peserta harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}
                      <h3>Nama Peserta</h3>
                      <Form.Control
                        type="text"
                        className={
                          errorRequired.find((val) => val == 'payer_owner_name')
                            ? 'errorRequired'
                            : ''
                        }
                        placeholder="Masukkan Nama Peserta"
                        value={appointmentData.payer_owner_name}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            payer_owner_name: e.target.value,
                          });

                          if (e.target.value) {
                            e.target.className = 'form-control';
                            var removeError = errorRequired.filter(
                              (val) => val != 'payer_owner_name'
                            );
                            setErrorRequired(removeError);
                          } else {
                            e.target.className = 'form-control errorRequired';
                            setErrorRequired([
                              ...errorRequired,
                              'payer_owner_name',
                            ]);
                          }
                        }}
                      />
                      {errorRequired.find(
                        (val) => val == 'payer_owner_name'
                      ) ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nama Peserta harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}
                      <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        <h3>Unggah Dokumen Asuransi</h3>
                        <ButtonReact
                          className="btn-add-dokumen"
                          onClick={() => handleInsuranceDocModal()}
                        >
                          <i className="fa fa-plus" /> Tambah Dokumen
                        </ButtonReact>
                      </div>
                      <div
                        style={{
                          marginTop: '5px',
                          padding: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {insuranceDoc.length > 0 ? (
                          insuranceDoc.map((data, index) => (
                            <Row key={'row' + index}>
                              <div style={{ marginBottom: '10px' }}>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '13px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'lighter',
                                  }}
                                >
                                  <i
                                    className="fa fa-check"
                                    style={{
                                      color: '#00BD84',
                                      marginRight: '5px',
                                    }}
                                  />
                                  {data
                                    ? data.docUrl.split('/').length == 6
                                      ? data.docUrl.split('/')[5]
                                      : ''
                                    : ''}
                                </span>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '12px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  &nbsp;
                                  {data?.docType === 'KartuAsuransi'
                                    ? 'Kartu Asuransi'
                                    : data?.docType === 'Lainnya'
                                    ? 'Dokumen Lainnya'
                                    : 'Berhasil Terunggah!'}
                                </span>
                                <a
                                  href={data.docUrl}
                                  target="_blank"
                                  style={{
                                    display: 'inline',
                                    fontSize: '13px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: '#00BD84',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                  }}
                                >
                                  Lihat Dokumen
                                </a>
                                <img
                                  src={IconCloseGreen}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setInsuranceDoc(
                                      insuranceDoc.filter(
                                        (e) => e.id !== data.id
                                      )
                                    )
                                  }
                                />
                              </div>
                            </Row>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : paymentTypeId == 'PE' ? (
                    <div className="paymentType-card col-13 mx-auto">
                      <h3>Pilih Perusahaan</h3>
                      <div className="select-option">
                        <select
                          className={
                            errorRequired.find((val) => val == 'payer_name')
                              ? 'form-control errorRequired'
                              : 'form-control'
                          }
                          value={appointmentData.payer_name}
                          onChange={(e) => {
                            setAppointmentData({
                              ...appointmentData,
                              payer_name: e.target.value,
                              insuranceClinicsId:
                                e.target[e.target.selectedIndex].getAttribute(
                                  'id'
                                ),
                            });

                            if (e.target.value == 'RE') {
                              document.getElementById(
                                'selectOptionPatient1'
                              ).selectedIndex = 3;
                              setPaymentTypeId('RE');
                              setAppointmentData({
                                ...appointmentData,
                                payer: 'RE',
                              });
                            }

                            if (e.target.value) {
                              e.target.className = 'form-control';
                              var removeError = errorRequired.filter(
                                (val) => val != 'payer_name'
                              );
                              setErrorRequired(removeError);
                            } else {
                              e.target.className = 'form-control errorRequired';
                              setErrorRequired([
                                ...errorRequired,
                                'payer_name',
                              ]);
                            }
                          }}
                        >
                          <option value="" disabled selected hidden>
                            {' '}
                            Pilih Nama Perusahaan{' '}
                          </option>
                          {insurance.companyData ? (
                            insurance.companyData.map((data, index) => (
                              <option
                                key={index}
                                value={data.name}
                                id={data.id}
                              >
                                {' '}
                                {data.name}{' '}
                              </option>
                            ))
                          ) : (
                            <>
                              <option value="RE"> Reimburse </option>
                            </>
                          )}
                        </select>
                      </div>
                      {errorRequired.find((val) => val == 'payer_name') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nama Asuransi harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}
                      <h3>Nama Karyawan</h3>
                      <Form.Control
                        type="text"
                        className={
                          errorRequired.find((val) => val == 'payer_owner_name')
                            ? 'errorRequired'
                            : ''
                        }
                        placeholder="Masukkan Nama Karyawan"
                        value={appointmentData.payer_owner_name}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            payer_owner_name: e.target.value,
                          });

                          if (e.target.value) {
                            e.target.className = 'form-control';
                            var removeError = errorRequired.filter(
                              (val) => val != 'payer_owner_name'
                            );
                            setErrorRequired(removeError);
                          } else {
                            e.target.className = 'form-control errorRequired';
                            setErrorRequired([
                              ...errorRequired,
                              'payer_owner_name',
                            ]);
                          }
                        }}
                      />
                      {errorRequired.find(
                        (val) => val == 'payer_owner_name'
                      ) ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nama Karyawan harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}

                      <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        <h3>Unggah Dokumen Jaminan Perusahaan</h3>
                        <ButtonReact
                          className="btn-add-dokumen"
                          onClick={() => handleJaminanDocModal()}
                        >
                          <i className="fa fa-plus" /> Tambah Dokumen
                        </ButtonReact>
                      </div>
                      <div
                        style={{
                          marginTop: '5px',
                          padding: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {jaminanDoc.length > 0 ? (
                          jaminanDoc.map((data, index) => (
                            <Row key={'row' + index}>
                              <div style={{ marginBottom: '10px' }}>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '13px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'lighter',
                                  }}
                                >
                                  <i
                                    className="fa fa-check"
                                    style={{
                                      color: '#00BD84',
                                      marginRight: '5px',
                                    }}
                                  />
                                  {data
                                    ? data.docUrl.split('/').length == 6
                                      ? data.docUrl.split('/')[5]
                                      : ''
                                    : ''}
                                </span>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '12px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  &nbsp;
                                  {data?.docType === 'KartuJaminan'
                                    ? 'Kartu Jaminan'
                                    : data?.docType === 'Lainnya'
                                    ? 'Dokumen Lainnya'
                                    : 'Berhasil Terunggah!'}
                                </span>
                                <a
                                  href={data.docUrl}
                                  target="_blank"
                                  style={{
                                    display: 'inline',
                                    fontSize: '13px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: '#00BD84',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                  }}
                                >
                                  Lihat Dokumen
                                </a>
                                <img
                                  src={IconCloseGreen}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setJaminanDoc(
                                      jaminanDoc.filter((e) => e.id !== data.id)
                                    )
                                  }
                                />
                              </div>
                            </Row>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : paymentTypeId == 'RE' ? (
                    <div className="paymentType-card col-13 mx-auto">
                      <h3>Nama Asuransi</h3>
                      <Form.Control
                        type="text"
                        className={
                          errorRequired.find((val) => val == 'payer_name')
                            ? 'errorRequired'
                            : ''
                        }
                        placeholder="Masukan Nama Asuransi"
                        value={appointmentData.payer_name}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            payer_name: e.target.value,
                          });

                          if (e.target.value) {
                            e.target.className = 'form-control';
                            var removeError = errorRequired.filter(
                              (val) => val != 'payer_name'
                            );
                            setErrorRequired(removeError);
                          } else {
                            e.target.className = 'form-control errorRequired';
                            setErrorRequired([...errorRequired, 'payer_name']);
                          }
                        }}
                      />
                      {errorRequired.find((val) => val == 'payer_name') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Nama Asuransi harus
                          diisi
                        </h6>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="btn-prev-next">
                {paymentTypeId ? (
                  <Button
                    label="Buat Janji Temu"
                    onClick={handleConfirmationModal}
                    className="mt-5"
                    styles={{ fontSize: '16px' }}
                  />
                ) : (
                  <Button
                    disable
                    label="Pilih Jenis Jaminan"
                    className="mt-5"
                    styles={{ fontSize: '16px' }}
                  />
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MobileInsurance;
