import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import { getAllArticles } from "../../../actions";
import queryString from "query-string";
import "./index.scss";

const Article = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const articles = useSelector((state) => state.articles);

  const hospitalGroupId = localStorage.getItem("hospitalGrupId");

  const [articleData, setArticleData] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);

  const [getData, setGetData] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(getData){
      if(articles?.articlesData?.list?.length > 0){
        let data = []
        articles.articlesData.list.map((dataItem) => {
          
          let checkData = articleData.filter((item) => item.id === dataItem.id)
          if(checkData?.length === 0){
            var item = {
              id : dataItem.id,
              date : dataItem.createdDate,
              title : dataItem.title,
              imageUrl : dataItem.thumbnail,
              description : dataItem?.content?  dataItem?.content.replace(/<img[^>]*>/g,"").replace(/(<([^>]+)>)/ig,""):"",
              slug: dataItem.slug
            }
          
            data.push(item)
          }
        
        })
        setArticleData((prevData) => [...prevData, ...data])
      }
    }
  },[articles])

  useEffect(() => {
    if(pageNumber) {

      let params = {
        klinik_group_id : hospitalGroupId,
        is_published : true,
        active: '1',
        type: 'articles',
        page: pageNumber,
        pageSize: 20,
        sort: JSON.stringify([{"created_date": "DESC"}])
      }
      setGetData(true)
      dispatch(getAllArticles(queryString.stringify(params)));
    }
  },[pageNumber])

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

    const remainingScroll = scrollHeight - (scrollTop + clientHeight);

    if (remainingScroll < 1 ) {
      
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col xs={12}>
          <span className="font-title-24-16 font-w-700">Artikel Kesehatan</span>
        </Col>
      </Row>

      <Row className="margin-b-16">
        {articleData.map((data, index) => (
          <Col xs={12} lg={6} key={index}>
            <Row
              className="margin-b-16 cursor-point"
              onClick={() => history.push(`/article/${data.slug}`)}
            >
              <Col xs={4} lg={3}>
                <img src={data.imageUrl} className="home-article-image" />
              </Col>

              <Col xs={8} lg={9}>
                <div className="d-flex flex-column">
                  <span className="font-s-14 font-w-500 cl-green">
                    {data.date}
                  </span>

                  <span className="font-s-16 font-w-700 margin-b-8">
                    {data.title}
                  </span>

                  <span className="home-article-desc font-s-14 font-w-500"
                   dangerouslySetInnerHTML={{
                    __html: data.description
                  }}>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </LayoutV2>
  );
};

export default Article;
