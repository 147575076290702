import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import '../../PaymentMethod/paymentMethod.scss';
import moment from 'moment';
import 'moment/locale/id';
import { IconArrowGreen, IconCloseGreen } from '../../../../assets/img/icon';
moment.locale('id');

const MobileBPJS = ({
  state,
  prevPageUrl,
  appointmentData,
  setAppointmentData,
  errorRequired,
  setErrorRequired,
  hospitalType,
  FKTLDoc,
  handleBPJSDocModal,
  handleConfirmationModal,
  setFKTLDoc,
}) => {
  /* Router DOM */
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="content-payment-method-mobile d-xl-none d-block">
        <Row className="col-8 mt-5">
          <div
            className="cursor-pointer"
            onClick={() =>
              history.push({
                pathname: prevPageUrl,
                state: {
                  selectedPatient: state.selectedPatient,
                  appointmentData: appointmentData,
                },
              })
            }
          >
            <img src={IconArrowGreen} alt="icon" className="d-inline my-auto" />
            <p className="title-page ml-2 text-white d-inline my-auto">
              Data Pasien
            </p>
          </div>
        </Row>
        <p className="text-center step-mobile-title">Langkah 3 dari 3</p>
        <p className="text-center payment-method-mobile-title">Pembayaran</p>

        <Row>
          <div className="col-11">
            <div className="content-paymentMethod-step">
              <p className="text-center title1">Jaminan</p>
              <p className="text-center title2">
                Silahkan masukkan nomor peserta BPJS kesehatan Anda
              </p>

              <div className="find-insurance">
                <div className="choose-paymentType col-13 mx-auto">
                  <h3>Nomor Peserta BPJS Kesehatan</h3>
                  <div className="paymentType-card col-13 mx-auto">
                    <Form.Control
                      type="number"
                      className={
                        errorRequired.find((val) => val == 'payer_no')
                          ? 'errorRequired'
                          : ''
                      }
                      placeholder="Nomor BPJS Kesehatan"
                      value={appointmentData.payer_no}
                      onChange={(e) => {
                        setAppointmentData({
                          ...appointmentData,
                          payer_no: e.target.value,
                        });

                        if (e.target.value) {
                          e.target.className = 'form-control';
                          var removeError = errorRequired.filter(
                            (val) => val != 'payer_no'
                          );
                          setErrorRequired(removeError);
                        } else {
                          e.target.className = 'form-control errorRequired';
                          setErrorRequired([...errorRequired, 'payer_no']);
                        }
                      }}
                    />
                    {errorRequired.find((val) => val == 'payer_no') ? (
                      <h6 className="span-error">
                        <i className="fa fa-warning" /> Nomor Peserta BPJS
                        Kesehatan harus diisi
                      </h6>
                    ) : (
                      ''
                    )}
                    <h5 style={{fontSize:'14px'}}>
                      *Pastikan BPJS Anda dalam status aktif dan tidak menunggak
                    </h5>
                  </div>

                  {hospitalType == '1' ? (
                    <></>
                  ) : (
                    <Form.Group className="col-13 mx-auto">
                      <h3>Unggah Dokumen BPJS</h3>
                      <div style={{ textAlign: 'left' }}>
                        <Button
                          className="btn-add-dokumen"
                          onClick={() => handleBPJSDocModal()}
                        >
                          <i className="fa fa-plus" /> Tambah Dokumen
                        </Button>
                      </div>
                      {errorRequired.find((val) => val == 'FKTLDoc') ? (
                        <h6 className="span-error">
                          <i className="fa fa-warning" /> Dokumen BPJS Kesehatan
                          harus diisi
                        </h6>
                      ) : (
                        ''
                      )}
                      <div
                        style={{
                          marginTop: '5px',
                          padding: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {FKTLDoc.length > 0 ? (
                          FKTLDoc.map((data, index) => (
                            <Row key={'row' + index}>
                              <div style={{ marginBottom: '10px' }}>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '13px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'lighter',
                                  }}
                                >
                                  <i
                                    className="fa fa-check"
                                    style={{
                                      color: '#00BD84',
                                      marginRight: '5px',
                                    }}
                                  />
                                  {data
                                    ? data.docUrl.split('/').length == 6
                                      ? data.docUrl.split('/')[5]
                                      : ''
                                    : ''}
                                </span>
                                <span
                                  style={{
                                    display: 'inline',
                                    fontSize: '12px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  &nbsp;
                                  {data?.docType === 'RujukanFKTL'
                                    ? 'Kartu Rujukan'
                                    : data?.docType === 'Lainnya'
                                    ? 'Dokumen Lainnya'
                                    : 'Berhasil Terunggah!'}
                                </span>
                                <a
                                  href={data.docUrl}
                                  target="_blank"
                                  style={{
                                    display: 'inline',
                                    fontSize: '13px',
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                    color: '#00BD84',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                  }}
                                >
                                  Lihat Dokumen
                                </a>
                                <img
                                  src={IconCloseGreen}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setFKTLDoc(
                                      FKTLDoc.filter((e) => e.id !== data.id)
                                    )
                                  }
                                />
                              </div>
                            </Row>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </Form.Group>
                  )}
                </div>
              </div>
              <div className="btn-prev-next">
                <Button
                  className="btn-prev"
                  variant="primary"
                  style={{ marginTop: '30px' }}
                  onClick={() => handleConfirmationModal()}
                >
                  Buat Janji Temu
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MobileBPJS;
